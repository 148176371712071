import React, { useState, useEffect } from 'react';
import { DataGrid,GridToolbar,GridToolbarContainer,GridToolbarExport } from '@material-ui/data-grid';
import { Link } from 'react-router-dom';
import Button from '@material-ui/core/Button';
import axios from 'axios';
import PropTypes from 'prop-types';
import EditIcon from '@material-ui/icons/Edit';
import { makeStyles, ThemeProvider, createMuiTheme } from '@material-ui/core/styles';
import config from "./../config";
import CircularProgress from '@material-ui/core/CircularProgress';
import './GenericDataGrid.css';

const useStyles = makeStyles((theme) => ({
  
    
    col:{
        color:'black',
    },
    title:{
      fontFamily:'Barlow, sans-serif',
      fontSize:'1.6rem',
      textAlign:'center',
      marginTop:'10px',
    marginBottom:'45px'
  },
  load:{
    fontFamily:'Barlow, sans-serif',
    fontSize:'1.6rem',
    textAlign:'center',
},
cent:{display: 'flex',
    justifyContent:'center',
    alignItems:'center',
    alignContent:'center',
    marginTop:'180px',
},
box:{
    borderBottomStyle:'solid',
    borderWidth:'1px',
    borderColor:'#E2E2E2',
    display: 'flex',
    justifyContent:'center',
    alignItems:'center',
    alignContent:'center',
},
root: {
    marginTop:'40px',
    '& .super--cell': {
      backgroundColor: '#4fc3f7',
      color: 'black',
      fontWeight: '600',
      textAlign:'center'
      
    },
    '& .super--cell2': {
      color: 'black',
      textAlign:'center'
      
    },
    '& .super--cell3': {
      color: 'black',
      fontWeight: '600',
      textAlign:'center',
      
    },
    '& .super4--cell': {
      backgroundColor: '#4fc3f7',
      color: 'black',
      fontWeight: '600',
      textAlign:'center'
    },
    '& .super--header': {
      backgroundColor: 'rgba(255, 7, 0, 0.55)',
      fontWeight: 'bold',
      flexWrap:'wrap',
      alignItems:'center',
      textAlign:'center',
    },
  }
  }));

const DataGridAP = () => {
    const classes = useStyles();
    function ucfirst(str) {
        var firstLetter = str.substr(0, 1);
        return firstLetter.toUpperCase() + str.substr(1);
    }
    const capital = {
        valueFormatter: ({ value }) => ucfirst(String(value)),
      };

      const currencyFormatter = new Intl.NumberFormat('en-US', {
        style: 'currency',
        currency: 'USD',
        minimumFractionDigits:0,
      });
      
      const usdPrice = {
        type: 'number',
        width: 130,
        valueFormatter: ({ value }) => currencyFormatter.format(Number(value)),
        cellClassName: 'super--cell',
      };
      const usdPrice2 = {
        type: 'number',
        width: 180,
        valueFormatter: ({ value }) => currencyFormatter.format(Number(value)),
        cellClassName: 'super--cell3',
      };
      const usdPrice4 = {
        type: 'number',
        width: 130,
        valueFormatter: ({ value }) => currencyFormatter.format(Number(value)),
        cellClassName: 'super4--cell',
      };
    const columns=[
        {
            field: 'action',
            headerName: 'Action',
            description: 'This column has a value getter and is not sortable.',
            sortable: false,
            width: 100,
            renderCell: (params) => <Link to={`/editsts/${params.row.CUSTOMER_ID}`}><EditIcon className={classes.col}/></Link>
          },
            { field: 'CUSTOMER_ID', headerName: 'Customer ID', width:130,cellClassName: 'super--cell2',headerAlign: 'center'},
            { field: 'APPROVED', headerName: 'Approved', width: 130,cellClassName: 'super--cell2',headerAlign: 'center' },
            { field: 'COMM_CONDOS', headerName: 'Comm Condos', width: 150,cellClassName: 'super--cell2',headerAlign: 'center' },
            { field: 'CONDOTELS', headerName: 'Condotels', width: 130,cellClassName: 'super--cell2',headerAlign: 'center',type:'number' },
            { field: 'EQUESTRIAN_EXPOSURES', headerName: 'Equestrian Exposures', width: 130,cellClassName: 'super--cell2',headerAlign: 'center' },
            { field: 'HUNTING_SHOOTING', headerName: 'Hunting Shooting', width: 150,cellClassName: 'super--cell2',headerAlign: 'center',type:'number'},
            { field: 'ICE_FISHING', headerName: 'Ice Fishing',cellClassName: 'super--cell2',headerAlign: 'center',type:'number', width: 130},
            { field: 'ICE_SKATING', headerName: 'Ice Skating', width: 130,cellClassName: 'super--cell2',headerAlign: 'center' },
            { field: 'JET_SKIING', headerName: 'Jet Skiing', width: 130,cellClassName: 'super--cell2',headerAlign: 'center' },
            { field: 'LAKE_OVER_100_ACRE', headerName: 'Lake over 100 Acre', width: 150,cellClassName: 'super--cell2',headerAlign: 'center' },
            { field: 'ON_PREM_GOLF', headerName: 'On Prem Golf', width: 150,cellClassName: 'super--cell2',headerAlign: 'center' },
            { field: 'POOLS_DIVING_BOARDS', headerName: 'Pools Diving Boards', width: 150 ,cellClassName: 'super--cell2',headerAlign: 'center'},
            { field: 'POOLS_SLIDES', headerName: 'Pools Slides', width: 130,cellClassName: 'super--cell2',headerAlign: 'center' },
            { field: 'SNOW_SKIING', headerName: 'Snow Skiing', width: 130,cellClassName: 'super--cell2',headerAlign: 'center' },
            { field: 'STATE_PARKS', headerName: 'State Parks', width: 130,cellClassName: 'super--cell2',headerAlign: 'center' },
            { field: 'WATER_SKIING', headerName: 'Water Skiing', width: 130,cellClassName: 'super--cell2',headerAlign: 'center' },
            { field: 'WOOD_DECK_GRILLING', headerName: 'Wood Deck Grilling', width: 130,cellClassName: 'super--cell2',headerAlign: 'center' },
            { field: 'CARRIER', headerName: 'Carrier', width: 130,cellClassName: 'super--cell2',headerAlign: 'center' },
            { field: 'EFF_DATE', headerName: 'Eff Date', width: 130,cellClassName: 'super--cell2',headerAlign: 'center' },
            { field: 'EXP_DATE', headerName: 'Exp Date', width: 130,cellClassName: 'super--cell2',headerAlign: 'center' },
            { field: 'EXPIRING_GL_PREMIUM', headerName: 'Expiring GL Premium', width: 130,cellClassName: 'super--cell2',headerAlign: 'center',...usdPrice2 },
            { field: 'EXPIRING_PREMIUM', headerName: 'Expiring Premium', width: 130,cellClassName: 'super--cell2',headerAlign: 'center',...usdPrice2 },
            { field: 'EXPIRING_PROP_PREMIUM', headerName: 'Expiring Prop Premium', width: 150,cellClassName: 'super--cell2',headerAlign: 'center',...usdPrice2 },
            { field: 'POLICY_TYPE', headerName: 'Policy Type', width: 130,cellClassName: 'super--cell2',headerAlign: 'center' },
            { field: 'PROPERTY_TYPE', headerName: 'Property Type', width: 130,cellClassName: 'super--cell2',headerAlign: 'center' },
            { field: 'TAM_CUSTOMER_FULL_NAME', headerName: 'TAM Customer Full Name', width: 180,cellClassName: 'super--cell2',headerAlign: 'center' },
            { field: 'MISCELLANEOUS_OUTDOOR_PROPERTY_TOTAL', headerName: 'Misc Outdoor Property Total',cellClassName: 'super--cell2',headerAlign: 'center',...usdPrice2, width: 150 },
            { field: 'POLICY_NUMBER', headerName: 'Policy Number', width: 130,cellClassName: 'super--cell2',headerAlign: 'center' },
            { field: 'COMMENTS', headerName: 'Comments', width: 130,cellClassName: 'super--cell2',headerAlign: 'center' },
        ];
    const [dataRows, setDataRows ] = useState([]);
    const [loading, setLoading] = useState(true);
    const [shouldRefresh, setShouldRefresh] = useState(false);
    
    function CustomToolbar() {
        return (
          <GridToolbarContainer className={classes.box}>
            <GridToolbarExport />
          </GridToolbarContainer>
        );
      }

    useEffect( () => {
        axios.get(config.server_url + '/pt/header/app')
        .then(response => {
            const newdata = response.data.map((d,index)=> {d.id = index; return d})
            console.log(newdata)
            setDataRows(newdata);
            setLoading(false);
        })
        .catch((error) => {
            console.log(error);
        })
    }, [shouldRefresh]);

  const renderLoadingMessage = () => <div className={classes.cent}><CircularProgress color="primary" /></div>;

  const renderGrid = () => (
    <div className={classes.root} style={{ height: 800, width: '100%' }}>
        <DataGrid headerHeight={70} density={'compact'} rows={dataRows} columns={columns} pageSize={100} getRowId={(row)=> row.id } components={{
                Toolbar: CustomToolbar,
              }} />
    </div>
    );

    return (<>
         <h3 className={classes.title}>Submitted Policies</h3>
        {
            loading ? renderLoadingMessage() : renderGrid()
        }
    </>);
}

DataGridAP.propTypes = {
    columns: PropTypes.array.isRequired,
    pageSize: PropTypes.number.isRequired,
  };

export default DataGridAP;