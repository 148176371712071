const CLIENT_ID = process.env.REACT_APP_CLIENT_ID ;
const ISSUER = process.env.REACT_APP_ISSUER ;
const OKTA_TESTING_DISABLEHTTPSCHECK = false;
const SERVER_URL = process.env.REACT_APP_SERVER_URL ;
const REDIRECT_URI = `${window.location.origin}/login/callback`;

export default {
  oidc: {
    clientId: CLIENT_ID,
    issuer: ISSUER,
    redirectUri: REDIRECT_URI,
    scopes: ['openid', 'profile', 'email'],
    pkce: true,
    disableHttpsCheck: OKTA_TESTING_DISABLEHTTPSCHECK,
  },
  server_url:SERVER_URL
};
