import React from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
import AppBar from '@material-ui/core/AppBar';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import Typography from '@material-ui/core/Typography';
import Box from '@material-ui/core/Box';
import { useEffect, useState } from 'react';
import axios from 'axios';
import CreateHD from './CreateHD'
import CreateCVG from './CreateCVG'
import CreateBD from './CreateBD'
import CreateMO from './CreateMO'
import CreatePGL from './CreatePGL'
import CreateEND from './CreateEND'
import EditBD from './EditBD'
import EditMO from './EditMO'
import Bench from './Bench'
import { Link } from 'react-router-dom';
import { createMuiTheme, ThemeProvider, withStyles } from '@material-ui/core/styles';
import ArrowBackIcon from '@material-ui/icons/ArrowBack';
import { Button } from '@material-ui/core';
import CssBaseline from '@material-ui/core/CssBaseline';
import config from "./../config"
import CircularProgress from '@material-ui/core/CircularProgress';
import { setGridPaginationModeActionCreator } from '@material-ui/data-grid';
import GenericDataGrid from '../components/GenericDataGrid';


let theme = createMuiTheme({
    palette: {
      primary: {
        light: '#63ccff',
        main: '#009be5',
        dark: '#006db3',
      },
    },
    typography: {
      fontFamily: 'Barlow, sans-serif',
      h5: {
        fontWeight: 500,
        fontSize: 26,
        letterSpacing: 0.5,
        fontFamily:'Barlow, sans-serif',
      },
    },
    shape: {
      borderRadius: 8,
    },
    props: {
      MuiTab: {
        disableRipple: true,
      },
    },
    mixins: {
      toolbar: {
        minHeight: 48,
      },
    },
  });
  
  theme = {
    ...theme,
    overrides: {
      MuiDrawer: {
        paper: {
          backgroundColor: 'black',
        },
      },
      MuiButton: {
        label: {
          textTransform: 'none',
        },
        contained: {
          boxShadow: 'none',
          '&:active': {
            boxShadow: 'none',
          },
        },
      },
      MuiTabs: {
        root: {
          marginLeft: theme.spacing(1),
        },
        indicator: {
          height: 3,
          borderTopLeftRadius: 3,
          borderTopRightRadius: 3,
          backgroundColor: '#4fc3f7',
        },
      },
      MuiTab: {
        
        root: {
          textTransform: 'none',
          margin: '0 16px',
          minWidth: 0,
          padding: 0,
          [theme.breakpoints.up('md')]: {
            padding: 0,
            minWidth: 0,
          },
        },
      },
      MuiIconButton: {
        root: {
          padding: theme.spacing(1),
        },
      },
      MuiTooltip: {
        tooltip: {
          borderRadius: 4,
        },
      },
      MuiDivider: {
        root: {
          backgroundColor: '#404854',
        },
      },
      MuiListItemText: {
        primary: {fontSize: 25,
          fontWeight: theme.typography.fontWeightMedium,
        },
      },
      MuiListItemIcon: {
        root: {
          color: 'inherit',
          marginRight: 0,
          '& svg': {
            fontSize: 20,
          },
        },
      },
      MuiAvatar: {
        root: {
          width: 32,
          height: 32,
        },
      },
    },
  };

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box p={3}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.any.isRequired,
  value: PropTypes.any.isRequired,
};

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    'aria-controls': `simple-tabpanel-${index}`,
  };
}

const useStyles = makeStyles((theme) => ({

    font:{
        fontFamily: 'Barlow, sans-serif',
        fontSize: '1.1rem',
        textDecoration: 'none',
        paddingTop:'10px',
        color:'black',
        fontWeight:'bold',
        paddingBottom:'0px',
    },
    col:{
        backgroundColor:'black',
        width:'60%',
        minWidth:'60%',
        display:'flex',
        justifyContent:'center',
        borderRadius:'8px'
    },
    coldiv:{
      display:'flex',
      justifyContent:'center'
    },
    size:{
        fontSize:'1.1rem',
        display:'inline-block'
    },
  root1: {
    flexGrow: 1,
    
  },
  title:{
    fontFamily:'Barlow, sans-serif',
    fontSize:'1.6rem',
    textAlign:'center',
    paddingTop:'0px',
    paddingBottom:'0px',
    marginTop: '20px',
    marginBottom: '30px',
},
load:{
  fontFamily:'Barlow, sans-serif',
  fontSize:'1.6rem',
  textAlign:'center',
},
button: {
  backgroundColor: '#F6FBFE',
  fontFamily:'Barlow, sans-serif',
  border: 'none',
  color: 'black',
  padding: '8px 8px',
  textAlign: 'center',
  textDecoration: 'none',
  display: 'inline-block',
  fontSize: '16px',
  margin: '8px 8px',
  cursor: 'pointer',
  borderRadius: '8px',
  outline: 0,
  fontWeight:'bold',
},
agn:{
  marginLeft: 'auto',
marginRight: '0'
},
cent:{
  display: 'flex',
    justifyContent:'center',
    alignItems:'center',
    alignContent:'center',
    height: '1100px'
},
centb:{
  display: 'flex',
    justifyContent:'center',
    alignItems:'align-start',
    alignContent:'align-start',
    height: '1100px',
    marginTop:'150px'
},
root: {
  marginTop:'40px',
  '& .super--cell': {
    backgroundColor: '#4fc3f7',
    color: 'black',
    fontWeight: '600',
    textAlign:'center'
    
  },
  '& .super--cell2': {
    color: 'black',
    textAlign:'center'
    
  },
  '& .super--cell3': {
    color: 'black',
    fontWeight: '600',
    textAlign:'center',
    
  },
  '& .super4--cell': {
    backgroundColor: '#4fc3f7',
    color: 'black',
    fontWeight: '600',
    textAlign:'center'
  },
  '& .super--header': {
    backgroundColor: 'rgba(255, 7, 0, 0.55)',
    fontWeight: 'bold',
    flexWrap:'wrap',
    alignItems:'center',
    textAlign:'center',
  },
}
}));

export default function SimpleTabs(props) {
  const classes = useStyles();
  const [value, setValue] = React.useState(0);
  const [result, setResult] = useState([]);
  const [resultGl, setGlResult] = useState([]);
  const [resultEnd, setEndResult] = useState([]);
  const [resultCvg, setCvgResult] = useState([]);
  const [resultBn, setBnResult] = useState([]);
  const [rateCustomer, setRateCustomer] = useState('None');
  const [loading, setLoading] = useState(true);
  const [customerPrevHD, setCustomerPrevHD] = useState('ALL');
  const [customerPrevBn, setCustomerPrevBn] = useState('ALL');
  const [customerPrevGL, setCustomerPrevGL] = useState('ALL');
  const [customerPrevEnd, setCustomerPrevEnd] = useState('ALL');
  const [customerPrevCvg, setCustomerPrevCvg] = useState('ALL');
  const [customerPrevCp, setCustomerPrevCp] = useState(['ALL']);
  const [iter, setIteration] = useState(1);
  const [iterMo, setIterationMo] = useState(1);
  const [dataBnRows, setDataBnRows ] = useState([]);
  const [refresh, setRefresh] = useState(false);
  const [isCheckedBB, setCheckedBB] = useState(false);
  const [isCheckedWP, setCheckedWP] = useState(false);
  const [isCheckedSU, setCheckedSU] = useState(false);
  const [isCheckedNO, setCheckedNO] = useState(true);
  const [isCheckedTB, setCheckedTB] = useState(false);
  const [isCheckedGC, setCheckedGC] = useState(false);
  const [isCheckedDM, setCheckedDM] = useState(false);

  const [isCheckedA, setCheckedA] = useState(false);
  const [isCheckedAO, setCheckedAO] = useState(false);
  const [isCheckedPP, setCheckedPP] = useState(false);
  const [isCheckedOPP, setCheckedOPP] = useState(false);
  const [isCheckedNF, setCheckedNF] = useState(false);
  const [isCheckedML, setCheckedML] = useState(false);
  const [isCheckedDA, setCheckedDA] = useState(false);
  const [isCheckedHA, setCheckedHA] = useState(false);
  const [isCheckedNAO, setCheckedNAO] = useState(false);

  const [savedCP, setSavedCP] = useState(false);
  const [savedMO, setSavedMO] = useState(false);
  const [buildings, setBuildings] = useState([]);
  const [outdoors, setOutdoors] = useState([]);
  const [savedCPE, setSavedCPE] = useState(false);
  const [savedMOE, setSavedMOE] = useState(false);
  const [dataBDRows, setDataBDRows ] = useState([]);
  const [dataMORows, setDataMORows ] = useState([]);

  const [GeoData, setGeoData] = useState([{CUSTOMER_ID:"",LOCATION_NUMBER:"",StAdd1: '',StAdd2: '',
  City: '',State: '',Zipcode: ''}])

  const [data2, setData2] = useState([{county: ""}])

  const [isSubmit, setIsSubmit] = useState(false);
  const [isSubmitCPE, setIsSubmitCPE] = useState(false);
  const [isSubmitMOE, setIsSubmitMOE] = useState(false);


  const [PlData, setPlData] = useState([{CUSTOMER_ID: "",COMM_CONDOS:"No",CONDOTELS:"No",EQUESTRIAN_EXPOSURES:"No",HUNTING_SHOOTING:"No",ICE_FISHING:"No",ICE_SKATING:"No",
  JET_SKIING:"No",LAKE_OVER_100_ACRE:"No",ON_PREM_GOLF:"No",POOLS_DIVING_BOARDS:"No",POOLS_SLIDES:"No",SNOW_SKIING:"No",STATE_PARKS:"No",WATER_SKIING:"No",
  WOOD_DECK_GRILLING:"No",CARRIER:"",EFF_DATE:"",EXP_DATE:"",EXPIRING_GL_PREMIUM:"",EXPIRING_PREMIUM:"",EXPIRING_PROP_PREMIUM:"",
  POLICY_TYPE:"",PROPERTY_TYPE:"",TAM_CUSTOMER_FULL_NAME:"",MISCELLANEOUS_OUTDOOR_PROPERTY_TOTAL:"",POLICY_NUMBER:"",COMMENTS:"",MISC_EXPOSURES:""}]);

  const [BnData, setBnData] = useState([{CUSTOMER_ID: "",BLANKET_NUMBER_1:"",BLANKET_NUMBER_2:"",BLANKET_PROPERTY_COINSURANCE_1:"",
  BLANKET_PROPERTY_COINSURANCE_2:"",PROPERTY_BLANKET_DESCRIPTION_1:"",PROPERTY_BLANKET_DESCRIPTION_2:"",PROPERTY_BLANKET_LIMIT_1:"",
  PROPERTY_BLANKET_LIMIT_2:"",PROPERTY_BLANKET_TYPE_1:"",PROPERTY_BLANKET_TYPE_2:""}]);

  const [CpData, setCpData] = useState([{CUSTOMER_ID: "",ITERATION_NUMBER:"1",ALUMINUM_WIRING:"",AUTOMATIC_SPRINKLER:"",BUILDING_NAME:"",
  BLANKET_1_IND:"",BLANKET_2_IND:"",BUILDING_NUMBER:"",
  CONSTRUCTION_TYPE:"",ELECTRICAL_LAST_UPDATED:"",NUM_STORIES:"",PLUMBING_LAST_UPDATED:"",POLYBUTYLENE_PIPING:"",ROOF_LAST_UPDATED:"",ROOF_TYPE:"",TOTAL_BUILDING_AREA:"",
  WATCHMEN_SECURITY_SERVICE:"",YEAR_BUILT:"",RENTAL_PERIOD:"",RENTALS:"",BUILDING_AMOUNT_OF_INSURANCE:"",BUILDING_COINSURANCE_PERCENTAGE:"",
  BUILDING_DEDUCTIBLE:"",BUILDING_VALUATION_TYPE:"",CLASS_CODE:"",CLASSIFICATION:"",ICE_DAMMING_DEDUCTIBLE:"",LOCATION_NUMBER:"",ARMED_SECURITY_GUARDS:"",
  CONTRACTOR_GL_REQUIRED:"",HO_6_POLICY:"",UNOCCUPIED_UNIT_HEAT:"",NUMBER_OF_UNITS:"",
  ASSOC_BY_LAWS_COVERAGE:"Single Entity"}]);


  const [GlData, setGlData] = useState([{CUSTOMER_ID: "",POOLS_DIVING_BOARDS:"",POOLS_SLIDES:"",BEACHES_NUMBER_OF_BEACHES:"",
    BOAT_STORAGE_AND_MOORAGE_GROSS_SALES:"",BUILDING_OR_PREMISES_LESSORS_RISK_DESCRIBE_RETAIL_EXPOSURE:"",
    BUILDING_OR_PREMISES_LESSORS_RISK_OTHER_THAN_RETAIL_EXPOSURE:"",BUILDING_OR_PREMISES_LESSORS_RISK_SQUARE_FOOTAGE:"",
    CLUBS_SQUARE_FOOTAGE:"",LAKES_OR_RESERVOIRS_NUMBER_OF_LAKES_OR_RESERVOIRS:"",PARKING_PARKING_SQUARE_FOOTAGE:"",
    PARKS_OR_PLAYGROUNDS_NUMBER_OF_PARKS_INCLUDING_DOG_PARKS:"",STREETS_ROADS_HIGHWAYS_OR_BRIDGES_MILES:"",
    SWIMMING_POOLS_COMPLIANCE:"",SWIMMING_POOLS_NUMBER_OF_POOLS:"",WAREHOUSES_SQUARE_FOOTAGE:"",BEACHES_LIFEGUARDS_PRESENT:"",
    BEACHES_MARKED_SWIMMING_AREA:"",BEACHES_POSTED_RULES:"",BOAT_DOCKS_SLIPS_COVERED:"",BOAT_DOCKS_SLIPS_INSPECTED_ANNUALLY:"",
    BOAT_DOCKS_SLIPS_NONSLIP_SURFACE:"",BOAT_DOCKS_SLIPS_NUMBER:"",BOAT_DOCKS_SLIPS_POSTED_RULES:"",PLAYGROUND_AGE_OF_EQUIPMENT:"",
    PLAYGROUND_REGULAR_INSPECTIONS:"",PLAYGROUND_SURFACE:"",PLAYGROUND_SURFACE_DESCRIBE_OTHER:"",POOLS_DESCRIBE_ADDITIONAL_EQUIPMENT:"",
    POOLS_LOCATION:"",DOG_PARK:""
    }]);

    const [EndData, setEndData] = useState([{CUSTOMER_ID: "",DISCHARGE_WATER_BACKUP_AND_SUMP_OVERFLOW_COVERAGE_INDICATOR:"",
    DISCHARGE_WATER_BACKUP_PROPERTY_DAMAGE_LIMIT:"",UTILITY_SERVICES_DD_CAUSES_OF_LOSS_FORM_APPLICABLE:"",
    UTILITY_SERVICES_DD_COMMUNICATION_SUPPLY_INCLUDING_OVERHEAD_TRANSMISSION_LINES:"",
    UTILITY_SERVICES_DD_COMMUNICATION_SUPPLY_NOT_INCLUDING_OVERHEAD_TRANSMISSION_LINES:"",
    UTILITY_SERVICES_DD_POWER_SUPPLY_INCLUDING_OVERHEAD_TRANSMISSION_LINES:"",
    UTILITY_SERVICES_DD_POWER_SUPPLY_NOT_INCLUDING_OVERHEAD_TRANSMISSION_LINES:"",
    UTILITY_SERVICES_DD_UTILITY_SERVICES_LIMIT:"",
    UTILITY_SERVICES_DD_WATER_SUPPLY_PROPERTY_COVERAGE_INDICATOR:"",
    UTILITY_SERVICES_DIRECT_DAMAGE_COVERED_PROPERTY_DESCRIPTION:"",
    UTILITY_SERVICES_TE_CAUSES_OF_LOSS_FORM_APPLICABLE:"",
    UTILITY_SERVICES_TE_COMMUNICATION_SUPPLY_INCLUDING_OVERHEAD_TRANSMISSION_LINES:"",
    UTILITY_SERVICES_TE_COMMUNICATION_SUPPLY_NOT_INCLUDING_OVERHEAD_TRANSMISSION_LINES:"",
    UTILITY_SERVICES_TE_COVERED_PROPERTY_DESCRIPTION:"",
    UTILITY_SERVICES_TE_POWER_SUPPLY_INCLUDING_OVERHEAD_TRANSMISSION_LINES:"",
    UTILITY_SERVICES_TE_POWER_SUPPLY_NOT_INCLUDING_OVERHEAD_TRANSMISSION_LINES:"",
    UTILITY_SERVICES_TE_UTILITY_SERVICES_LIMIT:"",
    UTILITY_SERVICES_TE_WASTEWATER_REMOVAL_PROPERTY:"",
    UTILITY_SERVICES_TE_WATER_SUPPLY_PROPERTY_COVERAGE:"",
    AMENDMENT_LIQUOR_LIABILITY_EXCLUSION_DESCRIPTION_PREMISES_ACTIVITIES_PREM:"",
    AMENDMENT_LIQUOR_LIABILITY_EXCLUSION_DESCRIPTION_PREMISES_ACTIVITIES_PROD:"",
    AMENDMENT_LIQUOR_LIABILITY_EXCLUSION_EXCEPTION_FOR_PREMISES_OPS_PREM_IND:"",
    AMENDMENT_LIQUOR_LIABILITY_EXCLUSION_EXCEPTION_FOR_PREMISES_OPS_PROD_IND:"",
    BOAT_LIABILITY_COV_IND:"",
    BOAT_LIABILITY_DESCRIBE_WATERCRAFT:"",
    EMP_BENEFITS_AGGREGATE_LIMIT:"",
    EMP_BENEFITS_AMOUNT_INSURANCE_EACH_EMP:"",
    EMP_BENEFITS_COV_IND:"",
    EMP_BENEFITS_LIABILITY_DEDUCTIBLE_PER_CLAIM:"",
    EMP_BENEFITS_NUMBER_EMPS:"",
    EMP_BENEFITS_RETROACTIVE_DATE:""
    }]);

    const [CvgData, setCvgData] = useState([{CUSTOMER_ID: "",COMPANYPROPPREMIERENDORSEMENT:"",BUSINESS_INCOME_WITH_EXTRA_EXPENSE_LIMIT:"",
    BUSINESS_INCOMEEXTRA_EXPENSE_INDICATOR:"",ORDINANCE_OR_LAW_COVERAGE_A_INDICATOR:"",
    ORDINANCE_OR_LAW_COVERAGE_B_LIMIT:"",ORDINANCE_OR_LAW_COVERAGE_C_LIMIT:"",PERSONAL_PROPERTY_INDICATOR:"",
    PERSONAL_PROPERTY_LIMIT:"", AUTO_TYPE:"",GL_EACH_OCCURRENCE_LIMIT_LIST:"",GL_ENHANCEMENT_IND:"",
    GL_GENERAL_AGGREGATE_LIMIT_LIST:"",GL_MEDICAL_EXPENSE_LIMIT_LIST:"",GL_PERSONAL_ADVERTISING_INJURY_LIABILITY_LIMIT_LIST:"",
    GL_PRODUCTS_COMPLETED_OPERATIONS_AGGREGATE:"",POLICYGL_GL_DAMAGE_TO_RENTED_PREMISES_COV_LIMIT:"",
    TERRORISM_IND:""
    }]);

    const [MoData, setMoData] = useState([{CUSTOMER_ID: "",ITERATION_NUMBER:"",MISC_OUTDOOR_PROPERTY_AMOUNT_OF_INSURANCE:"",
    MISC_OUTDOOR_PROPERTY_DESCRIBE:"",MISC_OUTDOOR_PROPERTY_TYPE:"",
    }]);

    const [ExpData, setExpData] = useState([{CUSTOMER_ID: "",MISC_EXPOSURES:""
    }]);


    useEffect( () => {
      axios.get(config.server_url + '/pt/header')
      .then(response => {
          let result = response.data.map(a => a.CUSTOMER_ID);
          setResult(result)
    
      })
      .catch((error) => {
          console.log(error);
      })

      axios.get(config.server_url + '/pt/gl')
      .then(response => {
          let result = response.data.map(a => a.CUSTOMER_ID);
          setGlResult(result)
      })
      .catch((error) => {
          console.log(error);
      })

      axios.get(config.server_url + '/pt/end')
      .then(response => {
          let result = response.data.map(a => a.CUSTOMER_ID);
          setEndResult(result)
          
      })
      .catch((error) => {
          console.log(error);
      })

      axios.get(config.server_url + '/pt/bn')
      .then(response => {
          let result = response.data.map(a => a.CUSTOMER_ID);
          const newdata = response.data.map((d,index)=> {d.id = index; return d})
          console.log('Refresh2')
          setDataBnRows(newdata);
          setBnResult(result)
          
      })
      .catch((error) => {
          console.log(error);
      })

      axios.get(config.server_url + '/pt/cvg')
      .then(response => {
          let result = response.data.map(a => a.CUSTOMER_ID);
          setCvgResult(result)
          setLoading(false);
      })
      .catch((error) => {
          console.log(error);
      })
  }, [refresh]);

  const updateFunc =  (params) => {

    console.log(params.data.CUSTOMER_ID)
    console.log(buildings)
    setIsSubmitCPE(false)
    axios.get(config.server_url + '/pt/building/' + params.data.CUSTOMER_ID)
      .then(response => {
          const newdata = response.data.map((d,index)=> {d.id = index; return d})
          const editRow = newdata.filter(row => row.ITERATION_NUMBER===params.data.ITERATION_NUMBER)
          console.log(editRow[0]._ID)
          setDataBDRows(editRow);
          setIsSubmitCPE(true)
          setSavedCPE(true)
      })
      .catch((error) => {
          console.log(error);
      })

  }

  const updateFuncMO =  (params) => {

    console.log(params.data.CUSTOMER_ID)
    console.log(buildings)
    setIsSubmitMOE(false)
    axios.get(config.server_url + '/pt/outdoor/' + params.data.CUSTOMER_ID)
      .then(response => {
          const newdata = response.data.map((d,index)=> {d.id = index; return d})
          const editRow = newdata.filter(row => row.ITERATION_NUMBER===params.data.ITERATION_NUMBER)
          console.log(editRow[0]._ID)
          setDataMORows(editRow);
          setIsSubmitMOE(true)
          setSavedMOE(true)
      })
      .catch((error) => {
          console.log(error);
      })

  }


const BuildingGrid = () => {
 
  const currencyFormatter = new Intl.NumberFormat('en-US', {
    style: 'currency',
    currency: 'USD',
    minimumFractionDigits:0,
  });
  
  const usdPrice = {
    type: 'number',
    width: 130,
    valueFormatter: ({ value }) => currencyFormatter.format(Number(value)),
    cellClassName: 'super--cell',
  };
  const usdPrice2 = {
    type: 'number',
    width: 180,
    valueFormatter: ({ value }) => currencyFormatter.format(Number(value)),
    cellClassName: 'super--cell3',
  };
  const usdPrice4 = {
    type: 'number',
    width: 130,
    valueFormatter: ({ value }) => currencyFormatter.format(Number(value)),
    cellClassName: 'super4--cell',
  };


  const renderPolicyList = () => (
    
    <ThemeProvider >
    <div className={classes.root}>
    <h3 className={classes.title}>Buildings</h3>
    <GenericDataGrid 
      dataRows={buildings}
      updateFunc={updateFunc}
      ID = {'id'}
      ht={400}
      columns={[
        { field: 'CUSTOMER_ID', headerName: 'Customer ID', width: 130,cellClassName: 'super--cell2',headerAlign: 'center' },
        { field: 'ITERATION_NUMBER', headerName: 'Iteration Number', width: 130,cellClassName: 'super--cell2',headerAlign: 'center' },
        { field: 'BLANKET_1_IND', headerName: 'Blanket 1 Indicator', width: 130,cellClassName: 'super--cell2',headerAlign: 'center' },
        { field: 'BLANKET_2_IND', headerName: 'Blanket 2 Indicator', width: 130,cellClassName: 'super--cell2',headerAlign: 'center' },
        { field: 'ALUMINUM_WIRING', headerName: 'Aluminum Wiring', width: 130,cellClassName: 'super--cell2',headerAlign: 'center' },
        { field: 'AUTOMATIC_SPRINKLER', headerName: 'Automatic Sprinkler', width: 130,cellClassName: 'super--cell2',headerAlign: 'center',type:'number' },
        { field: 'BUILDING_NAME', headerName: 'Building Name', width: 130,cellClassName: 'super--cell2',headerAlign: 'center' },
        { field: 'BUILDING_NUMBER', headerName: 'Building Number', width: 130,cellClassName: 'super--cell2',headerAlign: 'center',type:'number'},
        { field: 'CONSTRUCTION_TYPE', headerName: 'Construction Type',cellClassName: 'super--cell2',headerAlign: 'center',type:'number', width: 170},
        { field: 'ELECTRICAL_LAST_UPDATED', headerName: 'Electrical Last Updated', width: 150,cellClassName: 'super--cell2',headerAlign: 'center' },
        { field: 'NUM_STORIES', headerName: 'Number of Stories', width: 130,cellClassName: 'super--cell2',headerAlign: 'center' },
        { field: 'PLUMBING_LAST_UPDATED', headerName: 'Plumbing Last Updated', width: 150,cellClassName: 'super--cell2',headerAlign: 'center' },
        { field: 'POLYBUTYLENE_PIPING', headerName: 'Polybutylene Piping', width: 150,cellClassName: 'super--cell2',headerAlign: 'center' },
        { field: 'ROOF_LAST_UPDATED', headerName: 'Roof Last Updated', width: 150 ,cellClassName: 'super--cell2',headerAlign: 'center'},
        { field: 'ROOF_TYPE', headerName: 'Roof Type', width: 180,cellClassName: 'super--cell2',headerAlign: 'center' },
        { field: 'TOTAL_BUILDING_AREA', headerName: 'Total Building Area', width: 150,cellClassName: 'super--cell2',headerAlign: 'center' },
        { field: 'WATCHMEN_SECURITY_SERVICE', headerName: 'Watchmen Security Service', width: 180,cellClassName: 'super--cell2',headerAlign: 'center' },
        { field: 'YEAR_BUILT', headerName: 'Year Built', width: 130,cellClassName: 'super--cell2',headerAlign: 'center' },
        { field: 'RENTAL_PERIOD', headerName: 'Rental Period', width: 150,cellClassName: 'super--cell2',headerAlign: 'center' },
        { field: 'RENTALS', headerName: 'Rentals', width: 130,cellClassName: 'super--cell2',headerAlign: 'center' },
        { field: 'BUILDING_AMOUNT_OF_INSURANCE', headerName: 'Building Amount of Insurance', width: 180,cellClassName: 'super--cell2',headerAlign: 'center',...usdPrice2 },
        { field: 'BUILDING_COINSURANCE_PERCENTAGE', headerName: 'Building Coinsurance Percentage', width: 190,cellClassName: 'super--cell2',headerAlign: 'center' },
        { field: 'BUILDING_DEDUCTIBLE', headerName: 'Building Deductible', width: 150,cellClassName: 'super--cell2',headerAlign: 'center',...usdPrice2 },
        { field: 'BUILDING_VALUATION_TYPE', headerName: 'Building Valuation Type', width: 150,cellClassName: 'super--cell2',headerAlign: 'center' },
        { field: 'CLASS_CODE', headerName: 'Class Code', width: 130,cellClassName: 'super--cell2',headerAlign: 'center' },
        { field: 'CLASSIFICATION', headerName: 'Classification', width: 170,cellClassName: 'super--cell2',headerAlign: 'center' },
        { field: 'ICE_DAMMING_DEDUCTIBLE', headerName: 'Ice Damming Deductible', width: 150,cellClassName: 'super--cell2',headerAlign: 'center',...usdPrice2 },
        { field: 'LOCATION_NUMBER', headerName: 'Location Number', width: 130,cellClassName: 'super--cell2',headerAlign: 'center' },
        { field: 'ARMED_SECURITY_GUARDS', headerName: 'Armed Security Guards', width: 150,cellClassName: 'super--cell2',headerAlign: 'center' },
        { field: 'CONTRACTOR_GL_REQUIRED', headerName: 'Contractor GL Required', width: 130,cellClassName: 'super--cell2',headerAlign: 'center' },
        { field: 'HO_6_POLICY', headerName: 'HO 6 Policy', width: 130,cellClassName: 'super--cell2',headerAlign: 'center' },
        { field: 'UNOCCUPIED_UNIT_HEAT', headerName: 'Unoccupied Unit Heat', width: 130,cellClassName: 'super--cell2',headerAlign: 'center' },
        { field: 'NUMBER_OF_UNITS', headerName: 'Number of Units', width: 160,cellClassName: 'super--cell2',headerAlign: 'center' },
        { field: 'ASSOC_BY_LAWS_COVERAGE', headerName: 'Assoc By Laws Covg', width: 130,cellClassName: 'super--cell2',headerAlign: 'center' },
    ]}
    pageSize={50}
    />
  </div>
  </ThemeProvider>
  );


    return renderPolicyList();
}

const MoGrid = () => {

  const currencyFormatter = new Intl.NumberFormat('en-US', {
    style: 'currency',
    currency: 'USD',
    minimumFractionDigits:0,
  });
  
  const usdPrice = {
    type: 'number',
    width: 130,
    valueFormatter: ({ value }) => currencyFormatter.format(Number(value)),
    cellClassName: 'super--cell',
  };
  const usdPrice2 = {
    type: 'number',
    width: 180,
    valueFormatter: ({ value }) => currencyFormatter.format(Number(value)),
    cellClassName: 'super--cell3',
  };
  const usdPrice4 = {
    type: 'number',
    width: 130,
    valueFormatter: ({ value }) => currencyFormatter.format(Number(value)),
    cellClassName: 'super4--cell',
  };

  const renderPolicyList = () => (
    
    <ThemeProvider >
    <div className={classes.root}>
    <h3 className={classes.title}>Misc. Outdoors</h3>
    <GenericDataGrid 
      dataRows={outdoors}
      updateFunc={updateFuncMO}
      ID = {'id'}
      ht={400}
      columns={[
        { field: 'CUSTOMER_ID', headerName: 'Customer ID', width: 130,cellClassName: 'super--cell2',headerAlign: 'center' },
        { field: 'ITERATION_NUMBER', headerName: 'Iteration Number', width: 160,cellClassName: 'super--cell2',headerAlign: 'center' },
        { field: 'MISC_OUTDOOR_PROPERTY_AMOUNT_OF_INSURANCE', headerName: 'Property Amt of Insurance',cellClassName: 'super--cell2',headerAlign: 'center' ,...usdPrice2, width: 250},
        { field: 'MISC_OUTDOOR_PROPERTY_DESCRIBE', headerName: 'Property Description', width: 300,cellClassName: 'super--cell2',headerAlign: 'center' },
        { field: 'MISC_OUTDOOR_PROPERTY_TYPE', headerName: 'Property Type', width: 480,cellClassName: 'super--cell2',headerAlign: 'center',type:'number' },
    ]}
    pageSize={50}
    />
  </div>
  </ThemeProvider>
  );


    return renderPolicyList();
}

const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  const onChangeFormGeoData = (e, fieldName) => {
    const newData = [...GeoData]
    newData[0] = {
        ...newData[0],
      [fieldName]: e.target.value
    }
    console.log(newData)
    setGeoData(newData)
  }

  const onChangeFormExpData = (e, fieldName) => {
    const newData = [...ExpData]
    newData[0] = {
        ...newData[0],
      [fieldName]: e.target.value
    }
    console.log(newData)
    setExpData(newData)
  }

  const onChangeCvgChecked=(e,fieldName) => {
    e.preventDefault();
    if(fieldName==='A') {
      const newData = e.target.checked;
        console.log(newData);
        setCheckedA(newData);
  }

  if(fieldName==='AO') {
    const newData = e.target.checked;
      console.log(newData);
      setCheckedAO(newData);
}
  if(fieldName==='PP') {
    const newData = e.target.checked;
      console.log(newData);
      setCheckedPP(newData);
  }

  if(fieldName==='OPP') {
    const newData = e.target.checked;
      console.log(newData);
      setCheckedOPP(newData);
  }

  if(fieldName==='NF') {
    const newData = e.target.checked;
      console.log(newData);
      setCheckedNF(newData);
  }

  if(fieldName==='ML') {
    const newData = e.target.checked;
      console.log(newData);
      setCheckedML(newData);
  }

  if(fieldName==='DA') {
    const newData = e.target.checked;
      console.log(newData);
      setCheckedDA(newData);
  }

  if(fieldName==='HA') {
    const newData = e.target.checked;
      console.log(newData);
      setCheckedHA(newData);
  }

  if(fieldName==='NAO') {
    const newData = e.target.checked;
      console.log(newData);
      setCheckedNAO(newData);
  }

}

  const onChangeChecked=(e,fieldName) => {
    e.preventDefault();
    if(fieldName==='NO') {
      if(e.target.checked===true){
        const newData = e.target.checked;
        setCheckedNO(newData);
        setCheckedWP(false)
        setCheckedSU(false)
        setCheckedTB(false)
        setCheckedGC(false)
        setCheckedDM(false)
        setCheckedBB(false)
    }
      else{
      const newData = e.target.checked;
        console.log(newData);
        setCheckedNO(newData);
    }
  }
    else if(fieldName==='WP') {
      if(isCheckedNO===true){
        setCheckedWP(false);
      }
      else {
        const newData = e.target.checked;
        console.log(newData);
        setCheckedWP(newData);
      }
    }

    else if(fieldName==='DM') {
      if(isCheckedNO===true){
        setCheckedDM(false);
      }
      else {
        const newData = e.target.checked;
        console.log(newData);
        setCheckedDM(newData);
      }
    }

    else if(fieldName==='SU') {
      if(isCheckedNO===true){
        setCheckedSU(false);
      }
      else {
        const newData = e.target.checked;
        console.log(newData);
        setCheckedSU(newData);
      }
    }

    else if(fieldName==='GC') {
      if(isCheckedNO===true){
        setCheckedGC(false);
      }
      else {
        const newData = e.target.checked;
        console.log(newData);
        setCheckedGC(newData);
      }
    }

    else if(fieldName==='TB') {
      if(isCheckedNO===true){
        setCheckedTB(false);
      }
      else {
        const newData = e.target.checked;
        console.log(newData);
        setCheckedTB(newData);
      }
    }

     else if(fieldName==='BB') {
      if(isCheckedNO===true){
        setCheckedBB(false);
      }
      else {
        const newData = e.target.checked;
        console.log(newData);
        setCheckedBB(newData);
      }
    }
    
}
  

  const onChangeFormPlData = (index, e, fieldName) => {
    const newData = [...PlData]
    const newCpData = [...CpData]
    const newGlData = [...GlData]
    const newEndData = [...EndData]
    const newCvgData = [...CvgData]
    const newMoData = [...MoData]
    const newBnData = [...BnData]
    const newGeoData = [...GeoData]
    const newExpData = [...ExpData]
    if (fieldName==='EFF_DATE') {
      console.log(e.target.value)
      var event = new Date(e.target.value);
      function convert(str) {
        var date = new Date(str),
          mnth = ("0" + (date.getMonth() + 1)).slice(-2),
          day = ("0" + (date.getDate()+1)).slice(-2);
        return [date.getFullYear()+1, mnth, day].join("-");
      }
      newData[index] = {
        ...newData[index],
      [fieldName]: e.target.value,['EXP_DATE']:convert(event)
    }
    setPlData(newData)
    }
    else if (fieldName==='CUSTOMER_ID') {
      newData[index] = {
        ...newData[index],
      [fieldName]: e.target.value
    }
    newCpData[index] = {
      ...newCpData[index],
    [fieldName]: e.target.value
  }
    newGlData[index] = {
      ...newGlData[index],
    [fieldName]: e.target.value
  }
  newEndData[index] = {
    ...newEndData[index],
  [fieldName]: e.target.value
}

newCvgData[index] = {
  ...newCvgData[index],
[fieldName]: e.target.value
}

newMoData[index] = {
  ...newMoData[index],
[fieldName]: e.target.value
}

newBnData[index] = {
  ...newBnData[index],
[fieldName]: e.target.value
}

newGeoData[index] = {
  ...newGeoData[index],
[fieldName]: e.target.value
}

newExpData[index] = {
  ...newExpData[index],
[fieldName]: e.target.value
}
    console.log(newBnData)
    // console.log(newEndData)
    setCpData(newCpData)
    setGlData(newGlData)
    setPlData(newData)
    setEndData(newEndData)
    setCvgData(newCvgData)
    setMoData(newMoData)
    setBnData(newBnData)
    setGeoData(newGeoData)
    setExpData(newExpData)
    }
    else {
    newData[index] = {
        ...newData[index],
      [fieldName]: e.target.value
    }
    console.log(newData)
    setPlData(newData)
  }
  }

  const onChangeFormCpData = (index, e, fieldName) => {
    const newData = [...CpData]
    if (fieldName==='CLASS_CODE') {
      console.log('Hi')
      switch(e.target.value.toString()) {
        case '68500':
          newData[index] = {
            ...newData[index],
          [fieldName]: e.target.value,['CLASSIFICATION']: 'Townhouse or Homeowner Associations (association risk only)'
        }
        console.log(newData)
        setCpData(newData)
        break;

        case '62003':
          newData[index] = {
            ...newData[index],
          [fieldName]: e.target.value,['CLASSIFICATION']: 'Condominiums-Residential - (Association Risk Only)'
        }
        console.log(newData)
        setCpData(newData)
        break;

      }
    }
    else {
    newData[index] = {
        ...newData[index],
      [fieldName]: e.target.value
    }
    console.log(newData)
    setCpData(newData)
  }
  }

  const onChangeFormCpEData = (index, e, fieldName) => {
    const newData = [...dataBDRows]
    if (fieldName==='CLASS_CODE') {
      switch(e.target.value.toString()) {
        case '68500':
          newData[index] = {
            ...newData[index],
          [fieldName]: e.target.value,['CLASSIFICATION']: 'Townhouse or Homeowner Associations (association risk only)'
        }
        console.log(newData)
        setDataBDRows(newData)
        break;

        case '62003':
          newData[index] = {
            ...newData[index],
          [fieldName]: e.target.value,['CLASSIFICATION']: 'Condominiums-Residential - (Association Risk Only)'
        }
        console.log(newData)
        setDataBDRows(newData)
        break;

      }
    }
    else {
    newData[index] = {
        ...newData[index],
      [fieldName]: e.target.value
    }
    console.log(newData)
    setDataBDRows(newData)
  }
  }

  const onChangeFormGlData = (index, e, fieldName) => {
    const newData = [...GlData]
    newData[index] = {
        ...newData[index],
      [fieldName]: e.target.value
    }
    console.log(newData)
    setGlData(newData)
  
  }

  const onChangeFormEndData = (index, e, fieldName) => {
    const newData = [...EndData]
    newData[index] = {
        ...newData[index],
      [fieldName]: e.target.value
    }
    console.log(newData)
    setEndData(newData)
  
  }

  const onChangeFormCvgData = (index, e, fieldName) => {
    const newData = [...CvgData]
    newData[index] = {
        ...newData[index],
      [fieldName]: e.target.value
    }
    console.log(newData)
    setCvgData(newData)
  
  }

  const onChangeFormMoData = (index, e, fieldName) => {
    const newData = [...MoData]
    newData[index] = {
        ...newData[index],
      [fieldName]: e.target.value
    }
    console.log(newData)
    setMoData(newData)
  
  }

  const onChangeFormMoEData = (index, e, fieldName) => {
    const newData = [...dataMORows]
    newData[index] = {
        ...newData[index],
      [fieldName]: e.target.value
    }
    console.log(newData)
    setDataMORows(newData)
  
  }

  const onChangeFormBnData = (index, e, fieldName) => {
    const newData = [...BnData]
    if (fieldName==='BLANKET_NUMBER_1') {
      switch(e.target.value.toString()) {
        case '':
          newData[index] = {
            ...newData[index],
          [fieldName]: e.target.value,['PROPERTY_BLANKET_TYPE_1']: '',
          ['PROPERTY_BLANKET_DESCRIPTION_1']: '', ['BLANKET_PROPERTY_COINSURANCE_1']: '',
          ['PROPERTY_BLANKET_LIMIT_1']: ''
        }
        console.log(newData)
        setBnData(newData)
        break;

        case '1':
          newData[index] = {
            ...newData[index],
          [fieldName]: e.target.value
        }
        console.log(newData)
        setBnData(newData)
        break;
      }
    } 

    else if (fieldName==='BLANKET_NUMBER_2') {
      switch(e.target.value.toString()) {
        case '':
          newData[index] = {
            ...newData[index],
          [fieldName]: e.target.value,['PROPERTY_BLANKET_TYPE_2']: '',
          ['PROPERTY_BLANKET_DESCRIPTION_2']: '', ['BLANKET_PROPERTY_COINSURANCE_2']: '',
          ['PROPERTY_BLANKET_LIMIT_2']: ''
        }
        console.log(newData)
        setBnData(newData)
        break;

        case '2':
          newData[index] = {
            ...newData[index],
          [fieldName]: e.target.value
        }
        console.log(newData)
        setBnData(newData)
        break;
      }
    } 
    else {
    newData[index] = {
        ...newData[index],
      [fieldName]: e.target.value
    }
    console.log(newData)
    setBnData(newData)
    }
  }

  const failure = (reason) => {
    alert('Failed to save policy!' + ' Reason: ' + reason);
  }

  const failureGeo = (reason) => {
    alert('Failed to retrieve data!' + ' Reason: ' + reason);
  }


  
  const onSubmitPl = (event,index) => {
    setIsSubmit(true)
    event.preventDefault();
    if(result.includes(PlData[index].CUSTOMER_ID) || customerPrevHD===PlData[index].CUSTOMER_ID) {
      setIsSubmit(false)
      alert("Customer ID already exists. Please enter a different Customer ID.")
      return;
    }
    else {
    const policy = {
        
        CUSTOMER_ID: PlData[index].CUSTOMER_ID,
        COMM_CONDOS: PlData[index].COMM_CONDOS,
        CONDOTELS: PlData[index].CONDOTELS,
        EQUESTRIAN_EXPOSURES: PlData[index].EQUESTRIAN_EXPOSURES,
        HUNTING_SHOOTING: PlData[index].HUNTING_SHOOTING,
        ICE_FISHING: PlData[index].ICE_FISHING,
        ICE_SKATING: PlData[index].ICE_SKATING,
        JET_SKIING: PlData[index].JET_SKIING,
        LAKE_OVER_100_ACRE: PlData[index].LAKE_OVER_100_ACRE,
        ON_PREM_GOLF: PlData[index].ON_PREM_GOLF,
        POOLS_DIVING_BOARDS: PlData[index].POOLS_DIVING_BOARDS,
        POOLS_SLIDES: PlData[index].POOLS_SLIDES,
        SNOW_SKIING: PlData[index].SNOW_SKIING,
        STATE_PARKS: PlData[index].STATE_PARKS,
        WATER_SKIING: PlData[index].WATER_SKIING,
        WOOD_DECK_GRILLING: PlData[index].WOOD_DECK_GRILLING,
        CARRIER: PlData[index].CARRIER,
        EFF_DATE: PlData[index].EFF_DATE,
        EXP_DATE: PlData[index].EXP_DATE,
        EXPIRING_GL_PREMIUM: PlData[index].EXPIRING_GL_PREMIUM,
        EXPIRING_PREMIUM: PlData[index].EXPIRING_PREMIUM,
        EXPIRING_PROP_PREMIUM: PlData[index].EXPIRING_PROP_PREMIUM,
        POLICY_TYPE: PlData[index].POLICY_TYPE,
        PROPERTY_TYPE: PlData[index].PROPERTY_TYPE,
        TAM_CUSTOMER_FULL_NAME: PlData[index].TAM_CUSTOMER_FULL_NAME,
        MISCELLANEOUS_OUTDOOR_PROPERTY_TOTAL: PlData[index].MISCELLANEOUS_OUTDOOR_PROPERTY_TOTAL,
        POLICY_NUMBER: PlData[index].POLICY_NUMBER,
        COMMENTS: PlData[index].COMMENTS,
        APPROVED: false,
        MISC_EXPOSURES:PlData[index].MISC_EXPOSURES

    }

    

    axios.post(config.server_url + '/pt/header/insert/' + PlData[index].CUSTOMER_ID, policy)
          .then(res => {
            if(res && res.status === 200){
              setIsSubmit(false)
              setCustomerPrevHD(PlData[index].CUSTOMER_ID)
              setRefresh((prev) => !prev)
              alert('Policy Successfuly Saved!');
            //   window.location = '/wb/pol'
            }
          }, rejection => {
            setIsSubmit(false)
            failure('Request rejected for Policy!');
          }).catch(error => {
            setIsSubmit(false)
            failure('Error sending request.');
          });
        }
    
  }

  const onSubmitCp = (event, index) => {
    setIsSubmit(true)
    event.preventDefault();
    var iterNum = iter
    console.log(iter)
    if (CpData[index].BLANKET_1_IND==='Yes' || CpData[index].BLANKET_2_IND==='Yes' || CpData[index].BLANKET_2_IND==='No' || CpData[index].BLANKET_1_IND==='No') {
      const result = dataBnRows.filter(row => row.CUSTOMER_ID===CpData[index].CUSTOMER_ID)
      console.log(typeof(result))
      if (Object.keys(result).length === 0) {
        alert("Please Enter Blanket Information for this policy!")
        setIsSubmit(false)
        return;
      }
      else {
        if(CpData[index].BLANKET_1_IND==='Yes' && result[0].BLANKET_NUMBER_1 !=1) {
          alert("Blanket Number 1 details does not exist for this policy! Please check Blanket Details.")
          setIsSubmit(false)
          return;
        }
        else if(CpData[index].BLANKET_2_IND==='Yes' && result[0].BLANKET_NUMBER_2 !=2) {
          alert("Blanket Number 2 details does not exist for this policy! Please check Blanket Details.")
          setIsSubmit(false)
          return;
        }
        else if(CpData[index].BLANKET_1_IND==='' && result[0].BLANKET_NUMBER_1 ==1) {
          alert("Please specify the Blanket Num 1 Indicator for this Building!")
          setIsSubmit(false)
          return;
        }

        else if(CpData[index].BLANKET_2_IND==='' && result[0].BLANKET_NUMBER_2 ==2) {
          alert("Please specify the Blanket Num 2 Indicator for this Building!")
          setIsSubmit(false)
          return;
        }

        else {
          axios.get(config.server_url + '/pt/build/' + CpData[index].CUSTOMER_ID)
          .then(response => {
              const newdata = response.data.map(a => a.ITERATION_NUMBER.toString())
              const newdata1 = response.data.map(a => a.ITERATION_NUMBER)
              console.log(newdata1)
              var max = Math.max(...newdata1);
              console.log(max)
  
              if (max > 0){
                iterNum=max+1
              }
              else {
                iterNum=1
              }
              
              const policy = {
                
                CUSTOMER_ID: CpData[index].CUSTOMER_ID,
                ITERATION_NUMBER: iterNum,
                ALUMINUM_WIRING: CpData[index].ALUMINUM_WIRING,
                AUTOMATIC_SPRINKLER: CpData[index].AUTOMATIC_SPRINKLER,
                BUILDING_NAME: CpData[index].BUILDING_NAME,
                BLANKET_1_IND: CpData[index].BLANKET_1_IND,
                BLANKET_2_IND: CpData[index].BLANKET_2_IND,
                BUILDING_NUMBER: CpData[index].BUILDING_NUMBER,
                CONSTRUCTION_TYPE: CpData[index].CONSTRUCTION_TYPE,
                ELECTRICAL_LAST_UPDATED: CpData[index].ELECTRICAL_LAST_UPDATED,
                NUM_STORIES: CpData[index].NUM_STORIES,
                PLUMBING_LAST_UPDATED: CpData[index].PLUMBING_LAST_UPDATED,
                POLYBUTYLENE_PIPING: CpData[index].POLYBUTYLENE_PIPING,
                ROOF_LAST_UPDATED: CpData[index].ROOF_LAST_UPDATED,
                ROOF_TYPE: CpData[index].ROOF_TYPE,
                TOTAL_BUILDING_AREA: CpData[index].TOTAL_BUILDING_AREA,
                WATCHMEN_SECURITY_SERVICE: CpData[index].WATCHMEN_SECURITY_SERVICE,
                YEAR_BUILT: CpData[index].YEAR_BUILT,
                RENTAL_PERIOD: CpData[index].RENTAL_PERIOD,
                RENTALS: CpData[index].RENTALS,
                BUILDING_AMOUNT_OF_INSURANCE: CpData[index].BUILDING_AMOUNT_OF_INSURANCE,
                BUILDING_COINSURANCE_PERCENTAGE: CpData[index].BUILDING_COINSURANCE_PERCENTAGE,
                BUILDING_DEDUCTIBLE: CpData[index].BUILDING_DEDUCTIBLE,
                BUILDING_VALUATION_TYPE: CpData[index].BUILDING_VALUATION_TYPE,
                CLASS_CODE: CpData[index].CLASS_CODE,
                CLASSIFICATION: CpData[index].CLASSIFICATION,
                ICE_DAMMING_DEDUCTIBLE: CpData[index].ICE_DAMMING_DEDUCTIBLE,
                LOCATION_NUMBER: CpData[index].LOCATION_NUMBER,
                ARMED_SECURITY_GUARDS: CpData[index].ARMED_SECURITY_GUARDS,
                CONTRACTOR_GL_REQUIRED: CpData[index].CONTRACTOR_GL_REQUIRED,
                HO_6_POLICY: CpData[index].HO_6_POLICY,
                UNOCCUPIED_UNIT_HEAT: CpData[index].UNOCCUPIED_UNIT_HEAT,
                NUMBER_OF_UNITS: CpData[index].NUMBER_OF_UNITS,
                ASSOC_BY_LAWS_COVERAGE: CpData[index].ASSOC_BY_LAWS_COVERAGE,
            }
            console.log(policy);
            axios.post(config.server_url + '/pt/building/insert/' + CpData[index].CUSTOMER_ID, policy)
                  .then(res => {
                    if(res && res.status === 200){
                      setIsSubmit(false)
                      var maxIter = iterNum;
                      console.log(maxIter)
                      const newdata = [...buildings]
                      newdata.push(policy)
                      
                      const newDataId = newdata.map((d,index)=> {d.id = index; return d} )
                      console.log(newDataId)
                      setIteration(maxIter)
                      setBuildings(newDataId)
                      setSavedCP(true)
                      setRefresh((prev) => !prev)
                      alert('Building Successfuly Saved!');
                    //   window.location = '/wb/pol'
                    }
                  }, rejection => {
                    setIsSubmit(false)
                    failure('Request rejected for Building!');
                  }).catch(error => {
                    setIsSubmit(false)
                    failure('Error sending request.');
                  });
                
          })
          .catch((error) => {
            setIsSubmit(false)
              console.log(error);
          })
        }
      }
 
    }
    else {
      if (CpData[index].BLANKET_1_IND==='' && CpData[index].BLANKET_2_IND==='') {
        const resultBn = dataBnRows.filter(row => row.CUSTOMER_ID===CpData[index].CUSTOMER_ID)
        console.log(typeof(result))
        if (Object.keys(resultBn).length === 0) {
          if(result.includes(CpData[index].CUSTOMER_ID)|| customerPrevHD===CpData[index].CUSTOMER_ID) {

                      axios.get(config.server_url + '/pt/build/' + CpData[index].CUSTOMER_ID)
                      .then(response => {
                      const newdata = response.data.map(a => a.ITERATION_NUMBER.toString())
                      const newdata1 = response.data.map(a => a.ITERATION_NUMBER)
                      console.log(newdata1)
                      var max = Math.max(...newdata1);
                      console.log(max)

                      if (max > 0){
                        iterNum=max+1
                      }
                      else {
                        iterNum=1
                      }
                      
                      const policy = {
                        
                        CUSTOMER_ID: CpData[index].CUSTOMER_ID,
                        ITERATION_NUMBER: iterNum,
                        ALUMINUM_WIRING: CpData[index].ALUMINUM_WIRING,
                        AUTOMATIC_SPRINKLER: CpData[index].AUTOMATIC_SPRINKLER,
                        BUILDING_NAME: CpData[index].BUILDING_NAME,
                        BLANKET_1_IND: CpData[index].BLANKET_1_IND,
                        BLANKET_2_IND: CpData[index].BLANKET_2_IND,
                        BUILDING_NUMBER: CpData[index].BUILDING_NUMBER,
                        CONSTRUCTION_TYPE: CpData[index].CONSTRUCTION_TYPE,
                        ELECTRICAL_LAST_UPDATED: CpData[index].ELECTRICAL_LAST_UPDATED,
                        NUM_STORIES: CpData[index].NUM_STORIES,
                        PLUMBING_LAST_UPDATED: CpData[index].PLUMBING_LAST_UPDATED,
                        POLYBUTYLENE_PIPING: CpData[index].POLYBUTYLENE_PIPING,
                        ROOF_LAST_UPDATED: CpData[index].ROOF_LAST_UPDATED,
                        ROOF_TYPE: CpData[index].ROOF_TYPE,
                        TOTAL_BUILDING_AREA: CpData[index].TOTAL_BUILDING_AREA,
                        WATCHMEN_SECURITY_SERVICE: CpData[index].WATCHMEN_SECURITY_SERVICE,
                        YEAR_BUILT: CpData[index].YEAR_BUILT,
                        RENTAL_PERIOD: CpData[index].RENTAL_PERIOD,
                        RENTALS: CpData[index].RENTALS,
                        BUILDING_AMOUNT_OF_INSURANCE: CpData[index].BUILDING_AMOUNT_OF_INSURANCE,
                        BUILDING_COINSURANCE_PERCENTAGE: CpData[index].BUILDING_COINSURANCE_PERCENTAGE,
                        BUILDING_DEDUCTIBLE: CpData[index].BUILDING_DEDUCTIBLE,
                        BUILDING_VALUATION_TYPE: CpData[index].BUILDING_VALUATION_TYPE,
                        CLASS_CODE: CpData[index].CLASS_CODE,
                        CLASSIFICATION: CpData[index].CLASSIFICATION,
                        ICE_DAMMING_DEDUCTIBLE: CpData[index].ICE_DAMMING_DEDUCTIBLE,
                        LOCATION_NUMBER: CpData[index].LOCATION_NUMBER,
                        ARMED_SECURITY_GUARDS: CpData[index].ARMED_SECURITY_GUARDS,
                        CONTRACTOR_GL_REQUIRED: CpData[index].CONTRACTOR_GL_REQUIRED,
                        HO_6_POLICY: CpData[index].HO_6_POLICY,
                        UNOCCUPIED_UNIT_HEAT: CpData[index].UNOCCUPIED_UNIT_HEAT,
                        NUMBER_OF_UNITS: CpData[index].NUMBER_OF_UNITS,
                        ASSOC_BY_LAWS_COVERAGE: CpData[index].ASSOC_BY_LAWS_COVERAGE,
                        }
                        console.log(policy);
                        axios.post(config.server_url + '/pt/building/insert/' + CpData[index].CUSTOMER_ID, policy)
                              .then(res => {
                                if(res && res.status === 200){
                                  setIsSubmit(false)
                                  var maxIter = iterNum;
                                  console.log(maxIter)
                                  setIteration(maxIter)
                                  const newdata = [...buildings]
                                  newdata.push(policy)
                                  const newDataId = newdata.map((d,index)=> {d.id = index; return d} )
                                  setBuildings(newDataId)
                                  setSavedCP(true)
                                  setRefresh((prev) => !prev)
                                  alert('Building Successfuly Saved!');
                                //   window.location = '/wb/pol'
                                }
                              }, rejection => {
                                setIsSubmit(false)
                                failure('Request rejected for Building!');
                              }).catch(error => {
                                setIsSubmit(false)
                                failure('Error sending request.');
                              });
                            
                      })
                      .catch((error) => {
                        setIsSubmit(false)
                          console.log(error);
                      })

          }
          else {
            alert("Please enter Policy Details for this building!")
            setIsSubmit(false)
            return;
          }
          
        }
        else {
          alert("Blanket details exist for this Customer ID. Please indicate Blanket Numbers!")
          setIsSubmit(false)
          return;
        }
      }
    

      }

  }

  const onSubmitGl = (event,index) => {
    setIsSubmit(true)
    event.preventDefault()
    if(result.includes(GlData[index].CUSTOMER_ID) || customerPrevHD===GlData[index].CUSTOMER_ID) {
        if(resultGl.includes(GlData[index].CUSTOMER_ID) || customerPrevGL===GlData[index].CUSTOMER_ID) {
          setIsSubmit(false)
          alert("Customer ID already exists. Please enter a different Customer ID.")
          return;
        }

            const policy = {
                
                CUSTOMER_ID: GlData[index].CUSTOMER_ID,
                POOLS_DIVING_BOARDS: GlData[index].POOLS_DIVING_BOARDS,
                POOLS_SLIDES: GlData[index].POOLS_SLIDES,
                BEACHES_NUMBER_OF_BEACHES: GlData[index].BEACHES_NUMBER_OF_BEACHES,
                BOAT_STORAGE_AND_MOORAGE_GROSS_SALES: GlData[index].BOAT_STORAGE_AND_MOORAGE_GROSS_SALES,
                BUILDING_OR_PREMISES_LESSORS_RISK_DESCRIBE_RETAIL_EXPOSURE: GlData[index].BUILDING_OR_PREMISES_LESSORS_RISK_DESCRIBE_RETAIL_EXPOSURE,
                BUILDING_OR_PREMISES_LESSORS_RISK_OTHER_THAN_RETAIL_EXPOSURE: GlData[index].BUILDING_OR_PREMISES_LESSORS_RISK_OTHER_THAN_RETAIL_EXPOSURE,
                BUILDING_OR_PREMISES_LESSORS_RISK_SQUARE_FOOTAGE: GlData[index].BUILDING_OR_PREMISES_LESSORS_RISK_SQUARE_FOOTAGE,
                CLUBS_SQUARE_FOOTAGE: GlData[index].CLUBS_SQUARE_FOOTAGE,
                LAKES_OR_RESERVOIRS_NUMBER_OF_LAKES_OR_RESERVOIRS: GlData[index].LAKES_OR_RESERVOIRS_NUMBER_OF_LAKES_OR_RESERVOIRS,
                PARKING_PARKING_SQUARE_FOOTAGE: GlData[index].PARKING_PARKING_SQUARE_FOOTAGE,
                PARKS_OR_PLAYGROUNDS_NUMBER_OF_PARKS_INCLUDING_DOG_PARKS: GlData[index].PARKS_OR_PLAYGROUNDS_NUMBER_OF_PARKS_INCLUDING_DOG_PARKS,
                STREETS_ROADS_HIGHWAYS_OR_BRIDGES_MILES: GlData[index].STREETS_ROADS_HIGHWAYS_OR_BRIDGES_MILES,
                SWIMMING_POOLS_COMPLIANCE: GlData[index].SWIMMING_POOLS_COMPLIANCE,
                SWIMMING_POOLS_NUMBER_OF_POOLS: GlData[index].SWIMMING_POOLS_NUMBER_OF_POOLS,
                WAREHOUSES_SQUARE_FOOTAGE: GlData[index].WAREHOUSES_SQUARE_FOOTAGE,
                BEACHES_LIFEGUARDS_PRESENT: GlData[index].BEACHES_LIFEGUARDS_PRESENT,
                BEACHES_MARKED_SWIMMING_AREA: GlData[index].BEACHES_MARKED_SWIMMING_AREA,
                BEACHES_POSTED_RULES: GlData[index].BEACHES_POSTED_RULES,
                BOAT_DOCKS_SLIPS_COVERED: GlData[index].BOAT_DOCKS_SLIPS_COVERED,
                BOAT_DOCKS_SLIPS_INSPECTED_ANNUALLY: GlData[index].BOAT_DOCKS_SLIPS_INSPECTED_ANNUALLY,
                BOAT_DOCKS_SLIPS_NONSLIP_SURFACE: GlData[index].BOAT_DOCKS_SLIPS_NONSLIP_SURFACE,
                BOAT_DOCKS_SLIPS_NUMBER: GlData[index].BOAT_DOCKS_SLIPS_NUMBER,
                BOAT_DOCKS_SLIPS_POSTED_RULES: GlData[index].BOAT_DOCKS_SLIPS_POSTED_RULES,
                PLAYGROUND_AGE_OF_EQUIPMENT: GlData[index].PLAYGROUND_AGE_OF_EQUIPMENT,
                PLAYGROUND_REGULAR_INSPECTIONS: GlData[index].PLAYGROUND_REGULAR_INSPECTIONS,
                PLAYGROUND_SURFACE: GlData[index].PLAYGROUND_SURFACE,
                PLAYGROUND_SURFACE_DESCRIBE_OTHER: GlData[index].PLAYGROUND_SURFACE_DESCRIBE_OTHER,
                POOLS_DESCRIBE_ADDITIONAL_EQUIPMENT: GlData[index]. POOLS_DESCRIBE_ADDITIONAL_EQUIPMENT,
                POOLS_LOCATION: GlData[index].POOLS_LOCATION,
                DOG_PARK: GlData[index].DOG_PARK,
                
            }

            console.log(policy);

            axios.post(config.server_url + '/pt/gl/insert/' + GlData[index].CUSTOMER_ID, policy)
                .then(res => {
                    if(res && res.status === 200){
                      setIsSubmit(false)
                      setCustomerPrevGL(GlData[index].CUSTOMER_ID)
                      setRefresh((prev) => !prev)
                    alert('GL Successfuly Saved!');
                    //   window.location = '/wb/pol'
                    }
                }, rejection => {
                  setIsSubmit(false)
                    failure('Request rejected for GL!');
                }).catch(error => {
                  setIsSubmit(false)
                    failure('Error sending request.');
                });
          }

          else {
            setIsSubmit(false)
            alert("Please Enter Policy Header details for this Customer ID!")
            return;
          }
    
  }

  const onSubmitBn = (event,index) => {
    setIsSubmit(true)
    event.preventDefault()
    if(BnData[index].BLANKET_NUMBER_1==='' && BnData[index].BLANKET_NUMBER_2==='') {
      alert("")
    }
    if(result.includes(BnData[index].CUSTOMER_ID) || customerPrevHD===BnData[index].CUSTOMER_ID) {
      if(resultBn.includes(BnData[index].CUSTOMER_ID) || customerPrevBn===BnData[index].CUSTOMER_ID) {
        setIsSubmit(false)
        alert("Blanket details already exists for this policy. Please enter a different Customer ID!")
        return;
      }
      else {
  
          const policy = {
              
              CUSTOMER_ID: BnData[index].CUSTOMER_ID,
              BLANKET_NUMBER_1: BnData[index].BLANKET_NUMBER_1,
                BLANKET_NUMBER_2: BnData[index].BLANKET_NUMBER_2,
                BLANKET_PROPERTY_COINSURANCE_1: BnData[index].BLANKET_PROPERTY_COINSURANCE_1,
                BLANKET_PROPERTY_COINSURANCE_2: BnData[index].BLANKET_PROPERTY_COINSURANCE_2,
                PROPERTY_BLANKET_DESCRIPTION_1: BnData[index].PROPERTY_BLANKET_DESCRIPTION_1,
                PROPERTY_BLANKET_DESCRIPTION_2: BnData[index].PROPERTY_BLANKET_DESCRIPTION_2,
                PROPERTY_BLANKET_LIMIT_1: BnData[index].PROPERTY_BLANKET_LIMIT_1,
                PROPERTY_BLANKET_LIMIT_2: BnData[index].PROPERTY_BLANKET_LIMIT_2,
                PROPERTY_BLANKET_TYPE_1: BnData[index].PROPERTY_BLANKET_TYPE_1,
                PROPERTY_BLANKET_TYPE_2: BnData[index].PROPERTY_BLANKET_TYPE_2,
              
          }
  
          console.log(policy);
  
          axios.post(config.server_url + '/pt/bn/insert/' + BnData[index].CUSTOMER_ID, policy)
              .then(res => {
                  if(res && res.status === 200){
                    setIsSubmit(false)
                    console.log('Refresh1')
                    setCustomerPrevBn(BnData[index].CUSTOMER_ID)
                    setRefresh((prev) => !prev)
                  alert('Blanket Successfuly Saved!');
                  //   window.location = '/wb/pol'
                  }
              }, rejection => {
                setIsSubmit(false)
                  failure('Request rejected for Blanket!');
              }).catch(error => {
                setIsSubmit(false)
                  failure('Error sending request.');
              });
            }
    }

    else {
      setIsSubmit(false)
      alert("Please Enter Policy Header details for this Customer ID!")
      return;
    }
    
    
  }


  const onSubmitEnd = (event,index) => {
    setIsSubmit(true)
    event.preventDefault()
    if(result.includes(EndData[index].CUSTOMER_ID) || customerPrevHD===EndData[index].CUSTOMER_ID) {
    if(resultEnd.includes(EndData[index].CUSTOMER_ID) || customerPrevEnd===EndData[index].CUSTOMER_ID) {
      setIsSubmit(false)
      alert("Customer ID already exists. Please enter a different Customer ID.")
      return;
    }

        const policy = {
            
            CUSTOMER_ID: EndData[index].CUSTOMER_ID,
            DISCHARGE_WATER_BACKUP_AND_SUMP_OVERFLOW_COVERAGE_INDICATOR: EndData[index].DISCHARGE_WATER_BACKUP_AND_SUMP_OVERFLOW_COVERAGE_INDICATOR,
            DISCHARGE_WATER_BACKUP_PROPERTY_DAMAGE_LIMIT: EndData[index].DISCHARGE_WATER_BACKUP_PROPERTY_DAMAGE_LIMIT,
            UTILITY_SERVICES_DD_CAUSES_OF_LOSS_FORM_APPLICABLE: EndData[index].UTILITY_SERVICES_DD_CAUSES_OF_LOSS_FORM_APPLICABLE,
            UTILITY_SERVICES_DD_COMMUNICATION_SUPPLY_INCLUDING_OVERHEAD_TRANSMISSION_LINES: EndData[index].UTILITY_SERVICES_DD_COMMUNICATION_SUPPLY_INCLUDING_OVERHEAD_TRANSMISSION_LINES,
            UTILITY_SERVICES_DD_COMMUNICATION_SUPPLY_NOT_INCLUDING_OVERHEAD_TRANSMISSION_LINES: EndData[index].UTILITY_SERVICES_DD_COMMUNICATION_SUPPLY_NOT_INCLUDING_OVERHEAD_TRANSMISSION_LINES,
            UTILITY_SERVICES_DD_POWER_SUPPLY_INCLUDING_OVERHEAD_TRANSMISSION_LINES: EndData[index].UTILITY_SERVICES_DD_POWER_SUPPLY_INCLUDING_OVERHEAD_TRANSMISSION_LINES,
            UTILITY_SERVICES_DD_POWER_SUPPLY_NOT_INCLUDING_OVERHEAD_TRANSMISSION_LINES: EndData[index].UTILITY_SERVICES_DD_POWER_SUPPLY_NOT_INCLUDING_OVERHEAD_TRANSMISSION_LINES,
            UTILITY_SERVICES_DD_UTILITY_SERVICES_LIMIT: EndData[index].UTILITY_SERVICES_DD_UTILITY_SERVICES_LIMIT,
            UTILITY_SERVICES_DD_WATER_SUPPLY_PROPERTY_COVERAGE_INDICATOR: EndData[index].UTILITY_SERVICES_DD_WATER_SUPPLY_PROPERTY_COVERAGE_INDICATOR,
            UTILITY_SERVICES_DIRECT_DAMAGE_COVERED_PROPERTY_DESCRIPTION: EndData[index].UTILITY_SERVICES_DIRECT_DAMAGE_COVERED_PROPERTY_DESCRIPTION,
            UTILITY_SERVICES_TE_CAUSES_OF_LOSS_FORM_APPLICABLE: EndData[index].UTILITY_SERVICES_TE_CAUSES_OF_LOSS_FORM_APPLICABLE,
            UTILITY_SERVICES_TE_COMMUNICATION_SUPPLY_INCLUDING_OVERHEAD_TRANSMISSION_LINES: EndData[index].UTILITY_SERVICES_TE_COMMUNICATION_SUPPLY_INCLUDING_OVERHEAD_TRANSMISSION_LINES,
            UTILITY_SERVICES_TE_COMMUNICATION_SUPPLY_NOT_INCLUDING_OVERHEAD_TRANSMISSION_LINES: EndData[index].UTILITY_SERVICES_TE_COMMUNICATION_SUPPLY_NOT_INCLUDING_OVERHEAD_TRANSMISSION_LINES,
            UTILITY_SERVICES_TE_COVERED_PROPERTY_DESCRIPTION: EndData[index].UTILITY_SERVICES_TE_COVERED_PROPERTY_DESCRIPTION,
            UTILITY_SERVICES_TE_POWER_SUPPLY_INCLUDING_OVERHEAD_TRANSMISSION_LINES: EndData[index].UTILITY_SERVICES_TE_POWER_SUPPLY_INCLUDING_OVERHEAD_TRANSMISSION_LINES,
            UTILITY_SERVICES_TE_POWER_SUPPLY_NOT_INCLUDING_OVERHEAD_TRANSMISSION_LINES: EndData[index].UTILITY_SERVICES_TE_POWER_SUPPLY_NOT_INCLUDING_OVERHEAD_TRANSMISSION_LINES,
            UTILITY_SERVICES_TE_UTILITY_SERVICES_LIMIT: EndData[index].UTILITY_SERVICES_TE_UTILITY_SERVICES_LIMIT,
            UTILITY_SERVICES_TE_WASTEWATER_REMOVAL_PROPERTY: EndData[index].UTILITY_SERVICES_TE_WASTEWATER_REMOVAL_PROPERTY,
            UTILITY_SERVICES_TE_WATER_SUPPLY_PROPERTY_COVERAGE: EndData[index].UTILITY_SERVICES_TE_WATER_SUPPLY_PROPERTY_COVERAGE,
            AMENDMENT_LIQUOR_LIABILITY_EXCLUSION_DESCRIPTION_PREMISES_ACTIVITIES_PREM: EndData[index].AMENDMENT_LIQUOR_LIABILITY_EXCLUSION_DESCRIPTION_PREMISES_ACTIVITIES_PREM,
            AMENDMENT_LIQUOR_LIABILITY_EXCLUSION_DESCRIPTION_PREMISES_ACTIVITIES_PROD: EndData[index].AMENDMENT_LIQUOR_LIABILITY_EXCLUSION_DESCRIPTION_PREMISES_ACTIVITIES_PROD,
            AMENDMENT_LIQUOR_LIABILITY_EXCLUSION_EXCEPTION_FOR_PREMISES_OPS_PREM_IND: EndData[index].AMENDMENT_LIQUOR_LIABILITY_EXCLUSION_EXCEPTION_FOR_PREMISES_OPS_PREM_IND,
            AMENDMENT_LIQUOR_LIABILITY_EXCLUSION_EXCEPTION_FOR_PREMISES_OPS_PROD_IND: EndData[index].AMENDMENT_LIQUOR_LIABILITY_EXCLUSION_EXCEPTION_FOR_PREMISES_OPS_PROD_IND,
            BOAT_LIABILITY_COV_IND: EndData[index].BOAT_LIABILITY_COV_IND,
            BOAT_LIABILITY_DESCRIBE_WATERCRAFT: EndData[index].BOAT_LIABILITY_DESCRIBE_WATERCRAFT,
            EMP_BENEFITS_AGGREGATE_LIMIT: EndData[index].EMP_BENEFITS_AGGREGATE_LIMIT,
            EMP_BENEFITS_AMOUNT_INSURANCE_EACH_EMP: EndData[index].EMP_BENEFITS_AMOUNT_INSURANCE_EACH_EMP,
            EMP_BENEFITS_COV_IND: EndData[index].EMP_BENEFITS_COV_IND,
            EMP_BENEFITS_LIABILITY_DEDUCTIBLE_PER_CLAIM: EndData[index].EMP_BENEFITS_LIABILITY_DEDUCTIBLE_PER_CLAIM,
            EMP_BENEFITS_NUMBER_EMPS: EndData[index].EMP_BENEFITS_NUMBER_EMPS,
            EMP_BENEFITS_RETROACTIVE_DATE: EndData[index].EMP_BENEFITS_RETROACTIVE_DATE,
            
        }

        console.log(policy);

        axios.post(config.server_url + '/pt/end/insert/' + EndData[index].CUSTOMER_ID, policy)
            .then(res => {
                if(res && res.status === 200){
                  setIsSubmit(false)
                  setCustomerPrevEnd(EndData[index].CUSTOMER_ID)
                  setRefresh((prev) => !prev)
                alert('Endorsement Successfuly Saved!');
                //   window.location = '/wb/pol'
                }
            }, rejection => {
              setIsSubmit(false)
                failure('Request rejected for Endorsement!');
            }).catch(error => {
              setIsSubmit(false)
                failure('Error sending request.');
            });
          }
          else {
            setIsSubmit(false)
            alert("Please Enter Policy Header details for this Customer ID!")
            return;
          }
    
  }

  const onSubmitCvg = (event,index) => {
    setIsSubmit(true)
    event.preventDefault()
    const type = []
    var auto=''
    if (isCheckedA===true) {
      type.push('Any Auto')
    }
    if (isCheckedAO===true) {
      type.push('Owned Autos Only')
    }
    if (isCheckedPP===true) {
      type.push('Owned Private Passenger Autos Only')
    }
    if (isCheckedOPP===true) {
      type.push('Owned Autos Other than Private Passenger Autos Only')
    }
    if (isCheckedNF===true) {
      type.push('Owned Autos Subject to a No-Fault')
    }
    if (isCheckedML===true) {
      type.push('Owned Autos Subject to a Compulsory Uninsured Motorists Law')
    }
    if (isCheckedDA===true) {
      type.push('Specifically Described Autos')
    }
    if (isCheckedHA===true) {
      type.push('Hired Autos Only')
    }
    if (isCheckedNAO===true) {
      type.push('Non-Owned Autos Only')
    }
    if (type.length >=1) {
      auto = type.join('; ')
    }
    console.log(auto)
    if(result.includes(CvgData[index].CUSTOMER_ID) || customerPrevHD===CvgData[index].CUSTOMER_ID) {
        if(resultCvg.includes(CvgData[index].CUSTOMER_ID) || customerPrevCvg===CvgData[index].CUSTOMER_ID) {
          setIsSubmit(false)
          alert("Customer ID already exists. Please enter a different Customer ID.")
          return;
        }

            const policy = {
                
                CUSTOMER_ID: CvgData[index].CUSTOMER_ID,
                COMPANYPROPPREMIERENDORSEMENT: CvgData[index].COMPANYPROPPREMIERENDORSEMENT,
                BUSINESS_INCOME_WITH_EXTRA_EXPENSE_LIMIT: CvgData[index].BUSINESS_INCOME_WITH_EXTRA_EXPENSE_LIMIT,
                BUSINESS_INCOMEEXTRA_EXPENSE_INDICATOR: CvgData[index].BUSINESS_INCOMEEXTRA_EXPENSE_INDICATOR,
                ORDINANCE_OR_LAW_COVERAGE_A_INDICATOR: CvgData[index].ORDINANCE_OR_LAW_COVERAGE_A_INDICATOR,
                ORDINANCE_OR_LAW_COVERAGE_B_LIMIT: CvgData[index].ORDINANCE_OR_LAW_COVERAGE_B_LIMIT,
                ORDINANCE_OR_LAW_COVERAGE_C_LIMIT: CvgData[index].ORDINANCE_OR_LAW_COVERAGE_C_LIMIT,
                PERSONAL_PROPERTY_INDICATOR: CvgData[index].PERSONAL_PROPERTY_INDICATOR,
                PERSONAL_PROPERTY_LIMIT: CvgData[index].PERSONAL_PROPERTY_LIMIT,
                AUTO_TYPE: auto,
                GL_EACH_OCCURRENCE_LIMIT_LIST: CvgData[index].GL_EACH_OCCURRENCE_LIMIT_LIST,
                GL_ENHANCEMENT_IND: CvgData[index].GL_ENHANCEMENT_IND,
                GL_GENERAL_AGGREGATE_LIMIT_LIST: CvgData[index].GL_GENERAL_AGGREGATE_LIMIT_LIST,
                GL_MEDICAL_EXPENSE_LIMIT_LIST: CvgData[index].GL_MEDICAL_EXPENSE_LIMIT_LIST,
                GL_PERSONAL_ADVERTISING_INJURY_LIABILITY_LIMIT_LIST: CvgData[index].GL_PERSONAL_ADVERTISING_INJURY_LIABILITY_LIMIT_LIST,
                GL_PRODUCTS_COMPLETED_OPERATIONS_AGGREGATE: CvgData[index].GL_PRODUCTS_COMPLETED_OPERATIONS_AGGREGATE,
                POLICYGL_GL_DAMAGE_TO_RENTED_PREMISES_COV_LIMIT: CvgData[index].POLICYGL_GL_DAMAGE_TO_RENTED_PREMISES_COV_LIMIT,
                TERRORISM_IND: CvgData[index].TERRORISM_IND,
                
                
            }

            console.log(policy);

            axios.post(config.server_url + '/pt/cvg/insert/' + CvgData[index].CUSTOMER_ID, policy)
                .then(res => {
                    if(res && res.status === 200){
                      setIsSubmit(false)
                      setCustomerPrevCvg(CvgData[index].CUSTOMER_ID)
                      setRefresh((prev) => !prev)
                    alert('Coverage Successfuly Saved!');
                    //   window.location = '/wb/pol'
                    }
                }, rejection => {
                  setIsSubmit(false)
                    failure('Request rejected for Coverage!');
                }).catch(error => {
                  setIsSubmit(false)
                    failure('Error sending request.');
                });
          }
          else {
            setIsSubmit(false)
            alert("Please Enter Policy Header details for this Customer ID!")
            return;
          }
    
  }

  const onSubmitMo = (event, index) => {
    setIsSubmit(true)
    event.preventDefault();
    
    var iterNum = iterMo
    if(result.includes(MoData[index].CUSTOMER_ID)||customerPrevHD===MoData[index].CUSTOMER_ID) {
    axios.get(config.server_url + '/pt/mo/' + MoData[index].CUSTOMER_ID)
        .then(response => {
          const newdata = response.data.map(a => a.ITERATION_NUMBER.toString())
          const newdata1 = response.data.map(a => a.ITERATION_NUMBER)
          console.log(newdata)
          console.log(newdata1)
          var max = Math.max(...newdata1);
          console.log(max)
          console.log(iterNum)

          if (max > 0){
            iterNum=max+1
          }
          else {
            iterNum=1
          }
          
          const policy = {
              
              CUSTOMER_ID: MoData[index].CUSTOMER_ID,
              ITERATION_NUMBER: iterNum,
              MISC_OUTDOOR_PROPERTY_AMOUNT_OF_INSURANCE: MoData[index].MISC_OUTDOOR_PROPERTY_AMOUNT_OF_INSURANCE,
              MISC_OUTDOOR_PROPERTY_DESCRIBE: MoData[index].MISC_OUTDOOR_PROPERTY_DESCRIBE,
              MISC_OUTDOOR_PROPERTY_TYPE: MoData[index].MISC_OUTDOOR_PROPERTY_TYPE,

          }
          console.log(policy);
          axios.post(config.server_url + '/pt/mo/insert/' + MoData[index].CUSTOMER_ID, policy)
                .then(res => {
                  if(res && res.status === 200){
                    setIsSubmit(false)
                    var maxIter = iterNum;
                      console.log(maxIter) 
                      const newdata = [...outdoors]
                      newdata.push(policy)
                      const newDataId = newdata.map((d,index)=> {d.id = index; return d} )
                      setOutdoors(newDataId)
                      setSavedMO(true)
                      setIterationMo(maxIter)
                    setRefresh((prev) => !prev)
                    alert('Outdoor details Successfuly Saved!');
                  //   window.location = '/wb/pol'
                  }
                }, rejection => {
                  setIsSubmit(false)
                  failure('Request rejected for Outdoor!');
                }).catch(error => {
                  setIsSubmit(false)
                  failure('Error sending request.');
                });
              
        })
        .catch((error) => {
          setIsSubmit(false)
            console.log(error);
        })
      }
      else {
            setIsSubmit(false)
            alert("Please Enter Policy Header details for this Customer ID!")
            return;
      }

  }

  const onSubmitAdd = (event, index) => {
    setIsSubmit(true)
    event.preventDefault();
    if(result.includes(GeoData[index].CUSTOMER_ID)||customerPrevHD===GeoData[index].CUSTOMER_ID) {
          
          const policy = {
              
              CUSTOMER_ID: GeoData[index].CUSTOMER_ID,
              LOCATION_NUMBER: GeoData[index].LOCATION_NUMBER,
              ADDRESS_LINE_1: data2[0].street1,
              ADDRESS_LINE_2: GeoData[index].StAdd2,
              CITY: data2[0].city,
              STATE: data2[0].state,
              ZIP: data2[0].zipcode,
              COUNTY: data2[0].county,
          }
          console.log(policy);
          axios.post(config.server_url + '/pt/add/insert/' + GeoData[index].CUSTOMER_ID, policy)
                .then(res => {
                  if(res && res.status === 200){
                    setIsSubmit(false)
                    setRefresh((prev) => !prev)
                    alert('Address Successfuly Saved!');
                  //   window.location = '/wb/pol'
                  }
                }, rejection => {
                  setIsSubmit(false)
                  failure('Request rejected for Address!');
                }).catch(error => {
                  setIsSubmit(false)
                  failure('Error sending request.');
                });
              
      }
      else {
            setIsSubmit(false)
            alert("Please Enter Policy Header details for this Customer ID!")
            return;
      }

  }

  const onSubmitExp = (event, index) => {
    setIsSubmit(true)
    event.preventDefault();
    const misc = []
    var exp=''
    if (isCheckedNO===true) {
      misc.push('None of the Above')
    }
    if (isCheckedWP===true) {
      misc.push('Whirlpool')
    }
    if (isCheckedSU===true) {
      misc.push('Sauna')
    }
    if (isCheckedBB===true) {
      misc.push('Baseball Diamonds')
    }
    if (isCheckedTB===true) {
      misc.push('Tennis & Basketball Courts')
    }
    if (isCheckedGC===true) {
      misc.push('Golf Carts')
    }
    if (isCheckedDM===true) {
      misc.push('Dams')
    }
    if (misc.length >=1) {
      exp = misc.join('; ')
    }
    console.log(exp)
    if(result.includes(ExpData[index].CUSTOMER_ID)||customerPrevHD===ExpData[index].CUSTOMER_ID) {
          
          const policy = {
              
              CUSTOMER_ID: ExpData[index].CUSTOMER_ID,
              MISC_EXPOSURES: exp
              
          }
          console.log(policy);
          axios.post(config.server_url + '/pt/update/add/' + ExpData[index].CUSTOMER_ID, policy)
                .then(res => {
                  if(res && res.status === 200){
                    setIsSubmit(false)
                    setRefresh((prev) => !prev)
                    alert('Misc Exposure Successfuly Saved!');
                  //   window.location = '/wb/pol'
                  }
                }, rejection => {
                  setIsSubmit(false)
                  failure('Request rejected for Exposure!');
                }).catch(error => {
                  setIsSubmit(false)
                  failure('Error sending request.');
                });
              
      }
      else {
            setIsSubmit(false)
            alert("Please Enter Policy Header details for this Customer ID!")
            return;
      }

  }


  const onGeoSubmit = (event) => {
    setIsSubmit(true)
    event.preventDefault();

    const geoadd = {
        
        StAdd1:GeoData[0].StAdd1,
        StAdd2:GeoData[0].StAdd2,
        City:GeoData[0].City,
        State:GeoData[0].State,
        Zipcode:GeoData[0].Zipcode,
    }
    console.log(geoadd);

    axios.post(config.server_url + '/geo/', geoadd)
            .then(res => {
                console.log(res.data)
                if(res && res.status === 200){
                    setData2(res.data);
                    console.log(res.data)
                    setIsSubmit(false)
                alert('Location details loaded successfully!');
                //   window.location = '/wb/pol'
                }
            }, rejection => {
                setIsSubmit(false)
                failureGeo('Request rejected! Have you input all details?');
                return;
            }).catch(error => {
                
                setIsSubmit(false)
                failureGeo('Error sending request due to invalid address.');
                return;
            });
            
}

const onSubmitCpE = (event, index) => {
  setIsSubmitCPE(false)
  event.preventDefault();
  console.log(dataBDRows[index]._ID)
  if (dataBDRows[index].BLANKET_1_IND==='Yes' || dataBDRows[index].BLANKET_2_IND==='Yes' || dataBDRows[index].BLANKET_2_IND==='No' || dataBDRows[index].BLANKET_1_IND==='No') {
    const result = dataBnRows.filter(row => row.CUSTOMER_ID===dataBDRows[index].CUSTOMER_ID)
    console.log(typeof(result))
    if (Object.keys(result).length === 0) {
      alert("Please Enter Blanket Information for this policy!")
      setIsSubmitCPE(true)
      return;
    }
    else {
      if(dataBDRows[index].BLANKET_1_IND==='Yes' && result[0].BLANKET_NUMBER_1 !=1) {
        alert("Blanket Number 1 details does not exist for this policy! Please check Blanket Details.")
        setIsSubmitCPE(true)
        return;
      }
      else if(dataBDRows[index].BLANKET_2_IND==='Yes' && result[0].BLANKET_NUMBER_2 !=2) {
        alert("Blanket Number 2 details does not exist for this policy! Please check Blanket Details.")
        setIsSubmitCPE(true)
        return;
      }
      else if(dataBDRows[index].BLANKET_1_IND==='' && result[0].BLANKET_NUMBER_1 ==1) {
        alert("Please specify the Blanket Num 1 Indicator for this Building!")
        setIsSubmitCPE(true)
        return;
      }

      else if(dataBDRows[index].BLANKET_2_IND==='' && result[0].BLANKET_NUMBER_2 ==2) {
        alert("Please specify the Blanket Num 2 Indicator for this Building!")
        setIsSubmitCPE(true)
        return;
      }

      else {
            
            const policy = {
              
              CUSTOMER_ID: dataBDRows[index].CUSTOMER_ID,
              ITERATION_NUMBER: dataBDRows[index].ITERATION_NUMBER,
              ALUMINUM_WIRING: dataBDRows[index].ALUMINUM_WIRING,
              AUTOMATIC_SPRINKLER: dataBDRows[index].AUTOMATIC_SPRINKLER,
              BUILDING_NAME: dataBDRows[index].BUILDING_NAME,
              BLANKET_1_IND: dataBDRows[index].BLANKET_1_IND,
              BLANKET_2_IND: dataBDRows[index].BLANKET_2_IND,
              BUILDING_NUMBER: dataBDRows[index].BUILDING_NUMBER,
              CONSTRUCTION_TYPE: dataBDRows[index].CONSTRUCTION_TYPE,
              ELECTRICAL_LAST_UPDATED: dataBDRows[index].ELECTRICAL_LAST_UPDATED,
              NUM_STORIES: dataBDRows[index].NUM_STORIES,
              PLUMBING_LAST_UPDATED: dataBDRows[index].PLUMBING_LAST_UPDATED,
              POLYBUTYLENE_PIPING: dataBDRows[index].POLYBUTYLENE_PIPING,
              ROOF_LAST_UPDATED: dataBDRows[index].ROOF_LAST_UPDATED,
              ROOF_TYPE: dataBDRows[index].ROOF_TYPE,
              TOTAL_BUILDING_AREA: dataBDRows[index].TOTAL_BUILDING_AREA,
              WATCHMEN_SECURITY_SERVICE: dataBDRows[index].WATCHMEN_SECURITY_SERVICE,
              YEAR_BUILT: dataBDRows[index].YEAR_BUILT,
              RENTAL_PERIOD: dataBDRows[index].RENTAL_PERIOD,
              RENTALS: dataBDRows[index].RENTALS,
              BUILDING_AMOUNT_OF_INSURANCE: dataBDRows[index].BUILDING_AMOUNT_OF_INSURANCE,
              BUILDING_COINSURANCE_PERCENTAGE: dataBDRows[index].BUILDING_COINSURANCE_PERCENTAGE,
              BUILDING_DEDUCTIBLE: dataBDRows[index].BUILDING_DEDUCTIBLE,
              BUILDING_VALUATION_TYPE: dataBDRows[index].BUILDING_VALUATION_TYPE,
              CLASS_CODE: dataBDRows[index].CLASS_CODE,
              CLASSIFICATION: dataBDRows[index].CLASSIFICATION,
              ICE_DAMMING_DEDUCTIBLE: dataBDRows[index].ICE_DAMMING_DEDUCTIBLE,
              LOCATION_NUMBER: dataBDRows[index].LOCATION_NUMBER,
              ARMED_SECURITY_GUARDS: dataBDRows[index].ARMED_SECURITY_GUARDS,
              CONTRACTOR_GL_REQUIRED: dataBDRows[index].CONTRACTOR_GL_REQUIRED,
              HO_6_POLICY: dataBDRows[index].HO_6_POLICY,
              UNOCCUPIED_UNIT_HEAT: dataBDRows[index].UNOCCUPIED_UNIT_HEAT,
              NUMBER_OF_UNITS: dataBDRows[index].NUMBER_OF_UNITS,
              ASSOC_BY_LAWS_COVERAGE: dataBDRows[index].ASSOC_BY_LAWS_COVERAGE,
          }
          console.log(policy);
          axios.post(config.server_url + '/pt/building/update/' + dataBDRows[index]._ID, policy)
                .then(res => {
                  if(res && res.status === 200){
                    setIsSubmitCPE(true)
                    setSavedCPE(true)
                    setRefresh((prev) => !prev)
                    alert('Building Successfuly Edited!');
                  //   window.location = '/wb/pol'
                  }
                }, rejection => {
                  setIsSubmitCPE(true)
                  failure('Request rejected for Building!');
                }).catch(error => {
                  setIsSubmitCPE(true)
                  failure('Error sending request.');
                });
              
        
      }
    }
    
  }
  else {
    console.log(index)
    if ((dataBDRows[index].BLANKET_1_IND===''||dataBDRows[index].BLANKET_1_IND===null) && (dataBDRows[index].BLANKET_2_IND==='' || dataBDRows[index].BLANKET_2_IND===null)) {
      const resultBn = dataBnRows.filter(row => row.CUSTOMER_ID===dataBDRows[index].CUSTOMER_ID)
      console.log(typeof(result))
      if (Object.keys(resultBn).length === 0) {
        if(result.includes(dataBDRows[index].CUSTOMER_ID)|| customerPrevHD===dataBDRows[index].CUSTOMER_ID) {
                    
                    const policy = {
                      
                      CUSTOMER_ID: dataBDRows[index].CUSTOMER_ID,
                      ITERATION_NUMBER: dataBDRows[index].ITERATION_NUMBER,
                      ALUMINUM_WIRING: dataBDRows[index].ALUMINUM_WIRING,
                      AUTOMATIC_SPRINKLER: dataBDRows[index].AUTOMATIC_SPRINKLER,
                      BUILDING_NAME: dataBDRows[index].BUILDING_NAME,
                      BLANKET_1_IND: dataBDRows[index].BLANKET_1_IND,
                      BLANKET_2_IND: dataBDRows[index].BLANKET_2_IND,
                      BUILDING_NUMBER: dataBDRows[index].BUILDING_NUMBER,
                      CONSTRUCTION_TYPE: dataBDRows[index].CONSTRUCTION_TYPE,
                      ELECTRICAL_LAST_UPDATED: dataBDRows[index].ELECTRICAL_LAST_UPDATED,
                      NUM_STORIES: dataBDRows[index].NUM_STORIES,
                      PLUMBING_LAST_UPDATED: dataBDRows[index].PLUMBING_LAST_UPDATED,
                      POLYBUTYLENE_PIPING: dataBDRows[index].POLYBUTYLENE_PIPING,
                      ROOF_LAST_UPDATED: dataBDRows[index].ROOF_LAST_UPDATED,
                      ROOF_TYPE: dataBDRows[index].ROOF_TYPE,
                      TOTAL_BUILDING_AREA: dataBDRows[index].TOTAL_BUILDING_AREA,
                      WATCHMEN_SECURITY_SERVICE: dataBDRows[index].WATCHMEN_SECURITY_SERVICE,
                      YEAR_BUILT: dataBDRows[index].YEAR_BUILT,
                      RENTAL_PERIOD: dataBDRows[index].RENTAL_PERIOD,
                      RENTALS: dataBDRows[index].RENTALS,
                      BUILDING_AMOUNT_OF_INSURANCE: dataBDRows[index].BUILDING_AMOUNT_OF_INSURANCE,
                      BUILDING_COINSURANCE_PERCENTAGE: dataBDRows[index].BUILDING_COINSURANCE_PERCENTAGE,
                      BUILDING_DEDUCTIBLE: dataBDRows[index].BUILDING_DEDUCTIBLE,
                      BUILDING_VALUATION_TYPE: dataBDRows[index].BUILDING_VALUATION_TYPE,
                      CLASS_CODE: dataBDRows[index].CLASS_CODE,
                      CLASSIFICATION: dataBDRows[index].CLASSIFICATION,
                      ICE_DAMMING_DEDUCTIBLE: dataBDRows[index].ICE_DAMMING_DEDUCTIBLE,
                      LOCATION_NUMBER: dataBDRows[index].LOCATION_NUMBER,
                      ARMED_SECURITY_GUARDS: dataBDRows[index].ARMED_SECURITY_GUARDS,
                      CONTRACTOR_GL_REQUIRED: dataBDRows[index].CONTRACTOR_GL_REQUIRED,
                      HO_6_POLICY: dataBDRows[index].HO_6_POLICY,
                      UNOCCUPIED_UNIT_HEAT: dataBDRows[index].UNOCCUPIED_UNIT_HEAT,
                      NUMBER_OF_UNITS: dataBDRows[index].NUMBER_OF_UNITS,
                      ASSOC_BY_LAWS_COVERAGE: dataBDRows[index].ASSOC_BY_LAWS_COVERAGE,
                      }
                      console.log(policy);
                      axios.post(config.server_url + '/pt/building/update/' + dataBDRows[index]._ID, policy)
                            .then(res => {
                              if(res && res.status === 200){
                                setIsSubmitCPE(true)
                                setSavedCPE(true)
                                setRefresh((prev) => !prev)
                                alert('Building Successfuly Edited!');
                              //   window.location = '/wb/pol'
                              }
                            }, rejection => {
                              setIsSubmitCPE(true)
                              failure('Request rejected for Building!');
                            }).catch(error => {
                              setIsSubmitCPE(true)
                              failure('Error sending request.');
                            });

        }
        else {
          alert("Please enter Policy Details for this building!")
          setIsSubmitCPE(true)
          return;
        }
        
      }
      else {
        alert("Blanket details exist for this Customer ID. Please indicate Blanket Numbers!")
        setIsSubmitCPE(true)
        return;
      }
    }
  

    }

}

const onSubmitMoE = (event, index) => {
  setIsSubmitMOE(false)
  event.preventDefault();
  
  if(result.includes(dataMORows[index].CUSTOMER_ID)||customerPrevHD===dataMORows[index].CUSTOMER_ID) {
  
        const policy = {
            
            CUSTOMER_ID: dataMORows[index].CUSTOMER_ID,
            ITERATION_NUMBER: dataMORows[index].ITERATION_NUMBER,
            MISC_OUTDOOR_PROPERTY_AMOUNT_OF_INSURANCE: dataMORows[index].MISC_OUTDOOR_PROPERTY_AMOUNT_OF_INSURANCE,
            MISC_OUTDOOR_PROPERTY_DESCRIBE: dataMORows[index].MISC_OUTDOOR_PROPERTY_DESCRIBE,
            MISC_OUTDOOR_PROPERTY_TYPE: dataMORows[index].MISC_OUTDOOR_PROPERTY_TYPE,

        }
        console.log(policy);
        axios.post(config.server_url + '/pt/outdoor/update/' + dataMORows[index]._ID, policy)
              .then(res => {
                if(res && res.status === 200){
                  setIsSubmitMOE(true)
                    setSavedMOE(true)
                  setRefresh((prev) => !prev)
                  alert('Outdoor details Successfuly Edited!');
                //   window.location = '/wb/pol'
                }
              }, rejection => {
                setIsSubmitMOE(true)
                failure('Request rejected for Outdoor!');
              }).catch(error => {
                setIsSubmitMOE(true)
                failure('Error sending request.');
              });
            
    }
    else {
          setIsSubmitMOE(true)
          alert("Please Enter Policy Header details for this Customer ID!")
          return;
    }

}


  const renderLoadingMessage = () => <div className={classes.cent}><CircularProgress color="primary" /></div>;
  const renderHDRows = () => {
    return PlData.map((currentpolicy, index) => {
        return <CreateHD  onSubmitAdd={onSubmitAdd} geo2={data2} onChangeFormGeoData={onChangeFormGeoData} onGeoSubmit={onGeoSubmit} geo ={GeoData} policy={currentpolicy} index={index} onSubmit={onSubmitPl} onChangeFormData={onChangeFormPlData}  key={index}/>;
    })
}

  const renderCVGRows = () => {
    return CvgData.map((currentpolicy, index) => {
        return <CreateCVG policy={currentpolicy} index={index} onChangeCvgCheck={onChangeCvgChecked} onSubmit={onSubmitCvg} onChangeFormData={onChangeFormCvgData}  
        isCheckedA={isCheckedA}
        isCheckedAO={isCheckedAO}
        isCheckedPP={isCheckedPP}
        isCheckedOPP={isCheckedOPP}
        isCheckedNF={isCheckedNF}
        isCheckedML={isCheckedML}
        isCheckedDA={isCheckedDA}
        isCheckedHA={isCheckedHA}
        isCheckedNAO={isCheckedNAO}
        key={index}/>;
    })
  }

  const renderBDRows = () => {
    return CpData.map((currentpolicy, index) => {
    return <CreateBD bnData={BnData} onChangeFormBnData={onChangeFormBnData} onSubmitBn={onSubmitBn} policy={currentpolicy} index={index} onSubmit={onSubmitCp} onChangeFormData={onChangeFormCpData}  key={index}/>;
    })
  }

  const renderBDERows = () => {
    return dataBDRows.map((currentpolicy, index) => {
    return <EditBD policy={currentpolicy} index={index} onSubmit={onSubmitCpE} onChangeFormData={onChangeFormCpEData}  key={index}/>;
    })
  }

  const renderMOERows = () => {
    return dataMORows.map((currentpolicy, index) => {
    return <EditMO policy={currentpolicy} index={index} onSubmit={onSubmitMoE} onChangeFormData={onChangeFormMoEData}  key={index}/>;
    })
  }

  const renderMORows = () => {
    return MoData.map((currentpolicy, index) => {
    return <CreateMO  exp={ExpData} onChangeExp={onChangeFormExpData} onChangeCheck={onChangeChecked} policy={currentpolicy} index={index} onSubmitExp={onSubmitExp} onSubmit={onSubmitMo} onChangeFormData={onChangeFormMoData}  
    isCheckedNO={isCheckedNO}
    isCheckedWP={isCheckedWP}
    isCheckedSU={isCheckedSU}
    isCheckedTB={isCheckedTB}
    isCheckedGC={isCheckedGC}
    isCheckedDM={isCheckedDM}
    isCheckedBB={isCheckedBB}
    key={index}/>;
    })
  }
  const renderPGLRows = () => {
    return GlData.map((currentpolicy, index) => {
    return <CreatePGL  policy={currentpolicy} index={index} onSubmit={onSubmitGl} onChangeFormData={onChangeFormGlData}  key={index}/>;
    })
  }

  const renderENDRows = () => {
    return EndData.map((currentpolicy, index) => {
    return <CreateEND  policy={currentpolicy} index={index} onSubmit={onSubmitEnd} onChangeFormData={onChangeFormEndData}  key={index}/>;
    })
  }
  // console.log(PlData)

  return (
    <ThemeProvider theme={theme}>
      <CssBaseline />
    <div className={classes.root1}>
        <div className={classes.coldiv}>
      <AppBar className={classes.col} position="static">
        <Tabs centered value={value} onChange={handleChange} aria-label="simple tabs example">
          <Tab className={classes.size} label="Policy Header" {...a11yProps(0)} />
          <Tab className={classes.size} label="Current Coverage" {...a11yProps(1)} />
          <Tab className={classes.size} label="Building" {...a11yProps(2)} />
          <Tab className={classes.size} label="Misc Outdoor" {...a11yProps(3)} />
          <Tab className={classes.size} label="General Liability" {...a11yProps(4)} />
          <Tab className={classes.size} label="Endorsements" {...a11yProps(5)} />
        </Tabs>
      </AppBar>
      </div>
      <TabPanel value={value} index={0}>
      <div>
      <h3 className={classes.title}>Enter Policy Details for Customer: {PlData[0].CUSTOMER_ID}</h3>
        {loading? <div className={classes.cent}><CircularProgress color="primary" /></div> : isSubmit? <div className={classes.cent}><CircularProgress color="primary" /></div>:renderHDRows()}
        </div>
      </TabPanel>
      <TabPanel value={value} index={1}>
        <div>
        <h3 className={classes.title}>Enter Coverage Details for Customer: {CvgData[0].CUSTOMER_ID}</h3>
        {isSubmit? <div className={classes.cent}><CircularProgress color="primary" /></div>:renderCVGRows()}
        </div>
      </TabPanel>
      <TabPanel value={value} index={2}>
        <div >
          <div style={{minHeight: '1200px',backgroundColor:'#F0EEEE',borderStyle:'solid',borderColor:'#4fc3f7',borderWidth:'thin'}}>
        <h3 className={classes.title}>Enter Building Details for Customer: {CpData[0].CUSTOMER_ID}</h3>
        <div style={{minHeight: '550px'}}>
           {isSubmit ? <div className={classes.cent}><CircularProgress color="primary" /></div>: renderBDRows()}
        </div>
        </div>
        {savedCP? <div style={{backgroundColor:'#F2FBFF',borderStyle:'solid',borderColor:'#000000',borderWidth:'thin',marginTop:'15px'}}>
        {savedCP? BuildingGrid():null}
        {savedCPE? isSubmitCPE? renderBDERows():<div className={classes.centb}><CircularProgress color="primary" /></div>:null}
        </div>:null}
        </div>
      </TabPanel>
      <TabPanel value={value} index={3}>
        <div>
        <div style={{backgroundColor:'#F0EEEE',borderStyle:'solid',borderColor:'#4fc3f7',borderWidth:'thin'}}>
        <h3 className={classes.title}>Enter Misc Outdoor Details for Customer: {MoData[0].CUSTOMER_ID}</h3>
        <div style={{minHeight: '550px'}}>
        {isSubmit? <div className={classes.cent}><CircularProgress color="primary" /></div>: renderMORows()}
        </div>
        </div>
        {savedMO? <div style={{backgroundColor:'#F2FBFF',borderStyle:'solid',borderColor:'#000000',borderWidth:'thin',marginTop:'15px'}}>
        {savedMO? MoGrid():null}
        {savedMOE? isSubmitMOE? renderMOERows():<div className={classes.centb}><CircularProgress color="primary" /></div>:null}
        </div>:null}
        </div>
      </TabPanel>
      <TabPanel value={value} index={4}>
        <div>
        <h3 className={classes.title}>Enter GL Details for Customer: {GlData[0].CUSTOMER_ID}</h3>
        {isSubmit? <div className={classes.cent}><CircularProgress color="primary" /></div>: renderPGLRows()}
        </div>
      </TabPanel>
      <TabPanel value={value} index={5}>
        <div>
        <h3 className={classes.title}>Enter Endorsement Details for Customer: {EndData[0].CUSTOMER_ID}</h3>
        {isSubmit? <div className={classes.cent}><CircularProgress color="primary" /></div>: renderENDRows()}
        </div>
      </TabPanel>
    </div>
    </ThemeProvider>
  );
}
 