import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import axios from 'axios';
import { makeStyles, ThemeProvider, createMuiTheme } from '@material-ui/core/styles';
import {orange} from '@material-ui/core/colors'
import GenericDataGrid from './GenericDataGrid';
import AppBar from './AppBar';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';
import config from "./../config"
import CircularProgress from '@material-ui/core/CircularProgress';

const useStyles = makeStyles((theme) => ({
    
  col:{
      color:'black',
  },
  title:{
    fontFamily:'Barlow, sans-serif',
    fontSize:'1.6rem',
    textAlign:'center',
    marginTop:'3px'
},

textleft:{
    fontFamily:'Barlow, sans-serif',
    fontSize:'1.6rem',
    textAlign:'left',
},

load:{
    fontFamily:'Barlow, sans-serif',
    fontSize:'1.6rem',
    textAlign:'center',
},
cent:{display: 'flex',
    justifyContent:'center',
    alignItems:'center',
    alignContent:'center',
    marginTop:'150px',
},
text:{
    fontFamily:'Barlow, sans-serif',
    fontSize:'1.6rem',
    color:'black'
},
}));

const HomePage = () => {
const classes = useStyles();

const [policies, setPolicies] = useState([]);
const [loading, setLoading] = useState(true);
const [shouldRefresh, setShouldRefresh] = useState(false);
const [customer, setCustomer] = useState([]);

useEffect( () => {
    axios.get(config.server_url + '/pl/customer')
    .then(response => {
        setPolicies(response.data);
        setLoading(false);
    })
    .catch((error) => {
        console.log(error);
    })
}, [shouldRefresh]);

const onChangeData=(e) => {
    e.preventDefault();
    console.log(e.target.value);
    setCustomer(e.target.value);
    console.log(customer);
}

const renderLoadingMessage = () => <div className={classes.cent}><CircularProgress color="primary" /></div>;
// console.log(policies);

  const renderCustList = () => (
    <div>
    <h3 className={classes.textleft}>Choose a Policy:</h3>
        <div >
                <FormControl size={'small'} variant='outlined'>
                    <Select
                    native
                    value={customer}
                    onChange={(e) => onChangeData(e)}
                    >
                        {policies.map(policy => {
                            return <option value={policy.CUSTOMER_ID}>{policy.CUSTOMER_ID}</option>
                        })}
                    </Select>
                </FormControl>
                <h3 className={classes.textleft}>Link to view Documents:</h3>
                <Link className={classes.text} to={{ pathname: "https://s3.console.aws.amazon.com/s3/buckets/labarre-tam-data-bucket?region=us-east-1&tab=objects" }} target="_blank">LaBarre Data</Link>
        </div>
  </div>
  );


  return (<>
    <h3 className={classes.title}>Policy Documents</h3>
   {
       loading ? renderLoadingMessage() : renderCustList()
   }
</>);
}

export default HomePage;