import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import axios from 'axios';
import { makeStyles, ThemeProvider, createMuiTheme } from '@material-ui/core/styles';
import {orange} from '@material-ui/core/colors'
import GenericDataGrid from './GenericDataGrid';
import AppBar from './AppBar';
import config from "./../config"
import EditIcon from '@material-ui/icons/Edit';

const useStyles = makeStyles((theme) => ({
    
  col:{
      color:'black',
  },
  title:{
    fontFamily:'Barlow, sans-serif',
    fontSize:'1.6rem',
    textAlign:'center',
    marginTop:'10px',
    marginBottom:'45px'
},
root: {
  marginTop:'40px',
  '& .super--cell': {
    backgroundColor: '#4fc3f7',
    color: 'black',
    fontWeight: '600',
    textAlign:'center'
    
  },
  '& .super--cell2': {
    color: 'black',
    textAlign:'center'
    
  },
  '& .super--cell3': {
    color: 'black',
    fontWeight: '600',
    textAlign:'center',
    
  },
  '& .super--header': {
    backgroundColor: 'rgba(255, 7, 0, 0.55)',
    fontWeight: 'bold',
    flexWrap:'wrap',
    alignItems:'flex-start'
  },
}
}));

const HomePage = ({data}) => {
  const classes = useStyles();

  const [policies, setPolicies] = useState([]);
  const currencyFormatter = new Intl.NumberFormat('en-US', {
    style: 'currency',
    currency: 'USD',
    minimumFractionDigits:0,
  });
  
  const usdPrice = {
    type: 'number',
    width: 130,
    valueFormatter: ({ value }) => currencyFormatter.format(Number(value)),
    cellClassName: 'super--cell',
  };
  const usdPrice2 = {
    type: 'number',
    width: 180,
    valueFormatter: ({ value }) => currencyFormatter.format(Number(value)),
    cellClassName: 'super--cell3',
  };

  const renderPolicyList = () => (
    
    <ThemeProvider >
    <div className={classes.root}>
    <GenericDataGrid 
    dataRows={data}
      ID = {'PROPERTY_DETAILS_KEY'}
      ht={800}
      columns={[
        {
          field: 'action',
          headerName: 'Action',
          description: 'This column has a value getter and is not sortable.',
          sortable: false,
          width: 100,
          renderCell: (params) => <Link to={`/tab/${params.row.CUSTOMER_ID}`}><EditIcon className={classes.col}/></Link>,
          cellClassName: 'super--cell2',headerAlign: 'center',
        },
        { field: 'PROPERTY_DETAILS_KEY', headerName: 'ID', width:70,cellClassName: 'super--cell2',headerAlign: 'center'},
        { field: 'CUSTOMER_ID', headerName: 'Customer ID', width:130 ,cellClassName: 'super--cell2',headerAlign: 'center'},
        { field: 'ITERATION_NUMBER', headerName: 'Iteration Number', width:130,cellClassName: 'super--cell2',headerAlign: 'center',type:'number' },
        { field: 'BUILDING_NUM', headerName: 'Building Number', width:130,cellClassName: 'super--cell2',headerAlign: 'center',type:'number' },
        { field: 'PREMISES_NUM', headerName: 'Premises Number', width:130 ,cellClassName: 'super--cell2',headerAlign: 'center',type:'number'},
        { field: 'CLASS_CODE', headerName: 'Class Code', width:130 ,cellClassName: 'super--cell2',headerAlign: 'center'},
        { field: 'NUM_STORIES', headerName: 'Num of Stories', width:130 ,cellClassName: 'super--cell2',headerAlign: 'center'},
        { field: 'PROPERTY_LIMIT_OF_INSURANCE', headerName: 'Property Limit of Insurance' ,cellClassName: 'super--cell2',headerAlign: 'center',...usdPrice, width:180},
        { field: 'BI_LIMIT_OF_INSURANCE', headerName: 'BI Limit of Insurance', width:130 ,cellClassName: 'super--cell2',headerAlign: 'center',...usdPrice},
        { field: 'BPP_LIMIT_OF_INSURANCE', headerName: 'BPP Limit of Insurance', width:130 ,cellClassName: 'super--cell2',headerAlign: 'center',...usdPrice},
        { field: 'THEFT_DEDUCTIBLE', headerName: 'Theft Deductible', width:130,cellClassName: 'super--cell2',headerAlign: 'center'},
        { field: 'WINDHAIL_DEDUCTIBLE', headerName: 'Windhail Deductible', width:130,cellClassName: 'super--cell2',headerAlign: 'center' },
        { field: 'PROPERTY_DEDUCTIBLE', headerName: 'Property Deductible', width:130,cellClassName: 'super--cell2',headerAlign: 'center' },
        { field: 'WINDSTORM_OR_HAIL_EXCLUDED', headerName: 'Windstorm Or Hail Excl', width:150,cellClassName: 'super--cell2',headerAlign: 'center' },
        { field: 'SEWER_PD', headerName: 'Sewer PD', width:130,cellClassName: 'super--cell2',headerAlign: 'center' },
        { field: 'SEWER_BI', headerName: 'Sewer BI', width:130,cellClassName: 'super--cell2',headerAlign: 'center' },
        { field: 'TOTAL_BUILDING_AREA', headerName: 'Total Building Area', width:150,cellClassName: 'super--cell2',headerAlign: 'center' ,type:'number'},
        { field: 'CONSTRUCTION_TYPE', headerName: 'Construction Type', width:130 ,cellClassName: 'super--cell2',headerAlign: 'center'},
        { field: 'CONSTRUCTION_TYPE_TO_USE', headerName: 'Construction Type to Use', width:130 ,cellClassName: 'super--cell2',headerAlign: 'center'},
        { field: 'STATE', headerName: 'State', width:130 ,cellClassName: 'super--cell2',headerAlign: 'center'},
        { field: 'CITY', headerName: 'City', width:130 },
        { field: 'COUNTY', headerName: 'County', width:130 ,cellClassName: 'super--cell2',headerAlign: 'center'},
        { field: 'PPC', headerName: 'PPC', width:130 ,cellClassName: 'super--cell2',headerAlign: 'center'},
        { field: 'DISTRICT', headerName: 'District', width:130 ,cellClassName: 'super--cell2',headerAlign: 'center'},
        { field: 'BGI_TERRITORY', headerName: 'BGI Territory', width:160 ,cellClassName: 'super--cell2',headerAlign: 'center'},
        { field: 'BGII_TERRITORY', headerName: 'BGII Territory', width:130 ,cellClassName: 'super--cell2',headerAlign: 'center'},
        { field: 'SPECIAL_TERRITORY', headerName: 'Special Territory', width:160 ,cellClassName: 'super--cell2',headerAlign: 'center'},
        { field: 'TERRORISM_TERRITORY', headerName: 'Terrorism Territory', width:220 ,cellClassName: 'super--cell2',headerAlign: 'center'},
        
        
    ]}
    pageSize={100}
    />
  </div>
  </ThemeProvider>
  );


    return renderPolicyList();
}

export default HomePage;