import React from 'react';
import TextField from '@material-ui/core/TextField'
import Grid from '@material-ui/core/Grid';
import { makeStyles, ThemeProvider, createMuiTheme } from '@material-ui/core/styles';
import clsx from 'clsx';
import Select from '@material-ui/core/Select';
import FormControl from '@material-ui/core/FormControl';

const useStyles = makeStyles((theme) => ({
    
    col:{
        color:'black',
    },
    titleb:{
      fontFamily:'Barlow, sans-serif',
  },
  title:{
    fontFamily:'Barlow, sans-serif',
    fontSize:'1.5rem',
    textAlign:'center',
    paddingTop:'0px',
    paddingBottom:'0px',
    marginTop: '10px',
    marginBottom: '10px',
},
  formField:{
    display:'flex',
    justifyContent:'space-between',
    fontWeight:'bold',
    fontSize: '1.1rem',
},
formField2:{
    display:'flex',
    justifyContent:'center',
    fontWeight:'bold',
    fontSize: '1.1rem',
},
button: {
    backgroundColor: 'black',
    fontFamily:'Barlow, sans-serif',
    border: 'none',
    color: 'white',
    padding: '12px 15px',
    textAlign: 'center',
    textDecoration: 'none',
    display: 'inline-block',
    fontSize: '16px',
    margin: '4px 2px',
    cursor: 'pointer',
    borderRadius: '8px',
    outline: 0,
    fontWeight:'bold',
    '&:hover': {
        color: "#4fc3f7",
        boxShadow: '4px 4px 4px grey',
     },
  },
  feature: {
    display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        marginTop:'45px'
  },
  element:{
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'left',
    padding: '4px 4px 4px 4px',
  },
  fk:{
    display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  marginTop:'10px',
  borderStyle:'solid',
  outlineStyle:'solid',
  borderColor:'#4fc3f7',
  padding:'15px',
  boxShadow: '6px 6px 5px grey',
},
gk:{
    display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  marginTop:'10px',
  padding:'15px',
  
},
cln:{
    width:'30%',
    borderStyle:'solid',
  outlineStyle:'solid',
  borderColor:'#4fc3f7',
  marginTop:'20px',
},
gln:{
    height:'320px',
  overflowY: 'auto',
},
sd:{
    display: 'flex',
    flexWrap:'wrap',
    flexDirection:'row',
  justifyContent: 'space-around',
  alignItems: 'flex-start'
  
},
  }));

const GlLevel = (props) => {
    const { geo,policy, onSubmit, onChangeFormData} = props;
    const classes = useStyles();

    return (
        <div>
            <form id = "Gl" onSubmit={(event) => onSubmit(event,props.index)}>
                <div className={classes.fk}>
                
                <Grid container direction='row' spacing={2}>
                <Grid className={classes.formField2} item xs={12}>
                    <div className={classes.formField2}> 
                        <TextField required="true" label="Enter Customer ID" size ={'small'} value={policy.CUSTOMER_ID} variant="outlined" onChange={(e) => onChangeFormData(props.index,e, 'CUSTOMER_ID')}/>
                    </div>
                    </Grid>      


                    </Grid>
                
                </div>
            <div className={classes.sd}>
                <div className={classes.cln}>
                <h3 className={classes.title}>Boat Storage (10105)</h3>
                <div className={classes.gln}>
                <div className={classes.gk}>
                    <Grid container  direction='column' spacing={2}>

                    <Grid item xs={12}>
                <div className={classes.formField}> 
                    <label>Boat Storage and Moorage Gross Sales: </label>
                    <TextField type = 'number' size ={'small'} value={policy.BOAT_STORAGE_AND_MOORAGE_GROSS_SALES} variant="outlined" onChange={(e) => onChangeFormData(props.index,e, 'BOAT_STORAGE_AND_MOORAGE_GROSS_SALES')}/>
                </div> </Grid>

                    </Grid>
                </div>
                </div>
                </div>
            
                <div className={classes.cln}>
                <h3 className={classes.title}>Boats/Docks Additional Info</h3>
                <div className={classes.gln}>
                <div className={classes.gk}>
                    <Grid container  direction='column' spacing={2}>

                    <Grid item xs={12}>
                <div className={classes.formField}> 
                    <label>Boat Docks Slips Number: </label>
                    <TextField type = 'number' size ={'small'} value={policy.BOAT_DOCKS_SLIPS_NUMBER} variant="outlined" onChange={(e) => onChangeFormData(props.index,e, 'BOAT_DOCKS_SLIPS_NUMBER')}/>
                </div> </Grid>

                    <Grid item xs={12}>
                <div className={classes.formField}>
                <label>Boat Docks Slips Covered: </label>
                    <FormControl size ={'small'} variant='outlined'>
                    
                        <Select
                        native
                        value={policy.BOAT_DOCKS_SLIPS_COVERED}
                        onChange={(e) => onChangeFormData(props.index,e, 'BOAT_DOCKS_SLIPS_COVERED')}
                        >
                        <option value={''}></option>
                        <option value={'Yes'}>Yes</option>
                        <option value={'No'}>No</option>
                        </Select>
                    </FormControl>
                </div></Grid>

                <Grid item xs={12}>
                <div className={classes.formField}>
                <label>Boat Docks Slips Inspc Annually: </label>
                    <FormControl size ={'small'} variant='outlined'>
                    
                        <Select
                        native
                        value={policy.BOAT_DOCKS_SLIPS_INSPECTED_ANNUALLY}
                        onChange={(e) => onChangeFormData(props.index,e, 'BOAT_DOCKS_SLIPS_INSPECTED_ANNUALLY')}
                        >
                        <option value={''}></option>
                        <option value={'Yes'}>Yes</option>
                        <option value={'No'}>No</option>
                        </Select>
                    </FormControl>
                </div></Grid>

                <Grid item xs={12}>
                <div className={classes.formField}>
                <label>Boat Docks Slips Nonslip Surface: </label>
                    <FormControl size ={'small'} variant='outlined'>
                    
                        <Select
                        native
                        value={policy.BOAT_DOCKS_SLIPS_NONSLIP_SURFACE}
                        onChange={(e) => onChangeFormData(props.index,e, 'BOAT_DOCKS_SLIPS_NONSLIP_SURFACE')}
                        >
                        <option value={''}></option>
                        <option value={'Yes'}>Yes</option>
                        <option value={'No'}>No</option>
                        </Select>
                    </FormControl>
                </div></Grid>


                <Grid item xs={12}>
                <div className={classes.formField}>
                <label>Boat Docks Slips Posted Rules: </label>
                    <FormControl size ={'small'} variant='outlined'>
                    
                        <Select
                        native
                        value={policy.BOAT_DOCKS_SLIPS_POSTED_RULES}
                        onChange={(e) => onChangeFormData(props.index,e, 'BOAT_DOCKS_SLIPS_POSTED_RULES')}
                        >
                        <option value={''}></option>
                        <option value={'Yes'}>Yes</option>
                        <option value={'No'}>No</option>
                        </Select>
                    </FormControl>
                </div></Grid>
                    </Grid>
                </div>
                </div>
                </div>

                <div className={classes.cln}>
                <h3 className={classes.title}>Beaches (40072)</h3>
                <div className={classes.gln}>
                <div className={classes.gk}>
                    <Grid container  direction='column' spacing={2}>
                    <Grid item xs={12}>
                <div className={classes.formField}> 
                    <label>Number of Beaches: </label>
                    <TextField type = 'number' size ={'small'} value={policy.BEACHES_NUMBER_OF_BEACHES} variant="outlined" onChange={(e) => onChangeFormData(props.index,e, 'BEACHES_NUMBER_OF_BEACHES')}/>
                </div> </Grid>

                    <Grid item xs={12}>
                    <div className={classes.formField}>
                    <label>Beaches Lifeguards Present: </label>
                        <FormControl size ={'small'} variant='outlined'>
                        
                            <Select
                            native
                            value={policy.BEACHES_LIFEGUARDS_PRESENT}
                            onChange={(e) => onChangeFormData(props.index,e, 'BEACHES_LIFEGUARDS_PRESENT')}
                            >
                            <option value={''}></option>
                            <option value={'Yes'}>Yes</option>
                            <option value={'No'}>No</option>
                            </Select>
                        </FormControl>
                    </div></Grid>

                    <Grid item xs={12}>
                <div className={classes.formField}>
                <label>Beaches marked Swimming Area: </label>
                    <FormControl size ={'small'} variant='outlined'>
                    
                        <Select
                        native
                        value={policy.BEACHES_MARKED_SWIMMING_AREA}
                        onChange={(e) => onChangeFormData(props.index,e, 'BEACHES_MARKED_SWIMMING_AREA')}
                        >
                        <option value={''}></option>
                        <option value={'Yes'}>Yes</option>
                        <option value={'No'}>No</option>
                        </Select>
                    </FormControl>
                </div></Grid>

                <Grid item xs={12}>
                <div className={classes.formField}>
                <label>Beaches Posted Rules: </label>
                    <FormControl size ={'small'} variant='outlined'>
                    
                        <Select
                        native
                        value={policy.BEACHES_POSTED_RULES}
                        onChange={(e) => onChangeFormData(props.index,e, 'BEACHES_POSTED_RULES')}
                        >
                        <option value={''}></option>
                        <option value={'Yes'}>Yes</option>
                        <option value={'No'}>No</option>
                        </Select>
                    </FormControl>
                </div></Grid>

                    </Grid>
                </div>
                </div>
                </div>

                <div className={classes.cln}>
                <h3 className={classes.title}>Clubhouse (46622)</h3>
                <div className={classes.gln}>
                <div className={classes.gk}>
                    <Grid container  direction='column' spacing={2}>

                    <Grid item xs={12}>
                <div className={classes.formField}> 
                    <label>Clubs Square Footage: </label>
                    <TextField type = 'number' size ={'small'} value={policy.CLUBS_SQUARE_FOOTAGE} variant="outlined" onChange={(e) => onChangeFormData(props.index,e, 'CLUBS_SQUARE_FOOTAGE')}/>
                </div> </Grid>
                    </Grid>
                </div>
                </div>
                </div>

                <div className={classes.cln}>
                <h3 className={classes.title}>Lakes/Reservoirs (45524)</h3>
                <div className={classes.gln}>
                <div className={classes.gk}>
                    <Grid container  direction='column' spacing={2}>
                    <Grid item xs={12}>
                <div className={classes.formField}> 
                    <label>Number of Lakes or Reservoirs: </label>
                    <TextField type = 'number' size ={'small'} value={policy.LAKES_OR_RESERVOIRS_NUMBER_OF_LAKES_OR_RESERVOIRS} variant="outlined" onChange={(e) => onChangeFormData(props.index,e, 'LAKES_OR_RESERVOIRS_NUMBER_OF_LAKES_OR_RESERVOIRS')}/>
                </div> </Grid>

                    </Grid>
                </div>
                </div>
                </div>

                <div className={classes.cln}>
                <h3 className={classes.title}>Parking/Carports (46622)</h3>
                <div className={classes.gln}>
                <div className={classes.gk}>
                    <Grid container  direction='column' spacing={2}>
                    <Grid item xs={12}>
                <div className={classes.formField}> 
                    <label>Parking Square Footage: </label>
                    <TextField type = 'number' size ={'small'} value={policy.PARKING_PARKING_SQUARE_FOOTAGE} variant="outlined" onChange={(e) => onChangeFormData(props.index,e, 'PARKING_PARKING_SQUARE_FOOTAGE')}/>
                </div> </Grid>

                    </Grid>
                </div>
                </div>
                </div>


                <div className={classes.cln}>
                <h3 className={classes.title}>Parks/Playgrounds/Tot Lots (46671)</h3>
                <div className={classes.gln}>
                <div className={classes.gk}>
                    <Grid container  direction='column' spacing={2}>

                    <Grid item xs={12}>
                <div className={classes.formField}> 
                    <label>Number of Parks: </label>
                    <TextField type = 'number' size ={'small'} value={policy.PARKS_OR_PLAYGROUNDS_NUMBER_OF_PARKS_INCLUDING_DOG_PARKS} variant="outlined" onChange={(e) => onChangeFormData(props.index,e, 'PARKS_OR_PLAYGROUNDS_NUMBER_OF_PARKS_INCLUDING_DOG_PARKS')}/>
                </div> </Grid>

                <Grid item xs={12}>
                <div className={classes.formField}> 
                    <label>Number of Dog Parks: </label>
                    <TextField type = 'number' size ={'small'} value={policy.DOG_PARK} variant="outlined" onChange={(e) => onChangeFormData(props.index,e, 'DOG_PARK')}/>
                </div> </Grid>

                    <Grid item xs={12}>
                <div className={classes.formField}> 
                    <label>Playground age of Equipment: </label>
                    <TextField size ={'small'} value={policy.PLAYGROUND_AGE_OF_EQUIPMENT} variant="outlined" onChange={(e) => onChangeFormData(props.index,e, 'PLAYGROUND_AGE_OF_EQUIPMENT')}/>
                </div>
                </Grid>

                <Grid item xs={12}>
                <div className={classes.formField}> 
                    <label>Playground Regular Inspection: </label>
                    <TextField  size ={'small'} value={policy.PLAYGROUND_REGULAR_INSPECTIONS} variant="outlined" onChange={(e) => onChangeFormData(props.index,e, 'PLAYGROUND_REGULAR_INSPECTIONS')}/>
                </div>
                </Grid>

                <Grid item xs={12}>
                <div className={classes.formField}> 
                    <label>Playground Surface: </label>
                    <TextField size ={'small'} value={policy.PLAYGROUND_SURFACE} variant="outlined" onChange={(e) => onChangeFormData(props.index,e, 'PLAYGROUND_SURFACE')}/>
                </div>
                </Grid>

                <Grid item xs={12}>
                <div className={classes.formField}> 
                    <label>Playground Surface Describe Other: </label>
                    <TextField size ={'small'} value={policy.PLAYGROUND_SURFACE_DESCRIBE_OTHER} variant="outlined" onChange={(e) => onChangeFormData(props.index,e, 'PLAYGROUND_SURFACE_DESCRIBE_OTHER')}/>
                </div>
                </Grid>
                    </Grid>
                </div>
                </div>
                </div>

                <div className={classes.cln}>
                <h3 className={classes.title}>Street/Roads (48727)</h3>
                <div className={classes.gln}>
                <div className={classes.gk}>
                    <Grid container  direction='column' spacing={2}>

                    <Grid item xs={12}>
                <div className={classes.formField}> 
                    <label>Streets Roads Highways or Bridges: </label>
                    <TextField type = 'number' size ={'small'} value={policy.STREETS_ROADS_HIGHWAYS_OR_BRIDGES_MILES} variant="outlined" onChange={(e) => onChangeFormData(props.index,e, 'STREETS_ROADS_HIGHWAYS_OR_BRIDGES_MILES')}/>
                </div> </Grid>
                    </Grid>
                </div>
                </div>
                </div>

                <div className={classes.cln}>
                <h3 className={classes.title}>Pools (48925)</h3>
                <div className={classes.gln}>
                <div className={classes.gk}>
                    <Grid container  direction='column' spacing={2}>

                    <Grid item xs={12}>
                <div className={classes.formField}> 
                    <label>Number of Swimming Pools: </label>
                    <TextField type = 'number' size ={'small'} value={policy.SWIMMING_POOLS_NUMBER_OF_POOLS} variant="outlined" onChange={(e) => onChangeFormData(props.index,e, 'SWIMMING_POOLS_NUMBER_OF_POOLS')}/>
                </div> </Grid>

                <Grid item xs={12}>
                <div className={classes.formField}> 
                    <label>Pool's Location: </label>
                    <TextField size ={'small'} value={policy.POOLS_LOCATION} variant="outlined" onChange={(e) => onChangeFormData(props.index,e, 'POOLS_LOCATION')}/>
                </div>
                </Grid>

                    


                    <Grid item xs={12}>
                <div className={classes.formField}>
                <label>Pool Slides: </label>
                    <FormControl size ={'small'} variant='outlined'>
                    
                        <Select
                        native
                        value={policy.POOLS_SLIDES}
                        onChange={(e) => onChangeFormData(props.index,e, 'POOLS_SLIDES')}
                        >
                        <option value={''}></option>
                        <option value={'Yes'}>Yes</option>
                        <option value={'No'}>No</option>
                        </Select>
                    </FormControl>
                </div></Grid>

                    <Grid item xs={12}>
                <div className={classes.formField}>
                <label>Pools Diving Boards: </label>
                    <FormControl size ={'small'} variant='outlined'>
                    
                        <Select
                        native
                        value={policy.POOLS_DIVING_BOARDS}
                        onChange={(e) => onChangeFormData(props.index,e, 'POOLS_DIVING_BOARDS')}
                        >
                        <option value={''}></option>
                        <option value={'Yes'}>Yes</option>
                        <option value={'No'}>No</option>
                        </Select>
                    </FormControl>
                </div></Grid>

                <Grid item xs={12}>
                <div className={classes.formField}>
                <label>Swimming Pools Compliance: </label>
                    <FormControl size ={'small'} variant='outlined'>
                    
                        <Select
                        native
                        value={policy.SWIMMING_POOLS_COMPLIANCE}
                        onChange={(e) => onChangeFormData(props.index,e, 'SWIMMING_POOLS_COMPLIANCE')}
                        >
                        <option value={''}></option>
                        <option value={'Yes'}>Yes</option>
                        <option value={'No'}>No</option>
                        </Select>
                    </FormControl>
                </div></Grid>

                <Grid item xs={12}>
                <div className={classes.formField}> 
                    <label>Pool's Describe Additional Equipment: </label>
                    <TextField size ={'small'} value={policy.POOLS_DESCRIBE_ADDITIONAL_EQUIPMENT} variant="outlined" onChange={(e) => onChangeFormData(props.index,e, 'POOLS_DESCRIBE_ADDITIONAL_EQUIPMENT')}/>
                </div>
                </Grid>

                
                    </Grid>
                </div>
                </div>
                </div>

                <div className={classes.cln}>
                <h3 className={classes.title}>Building Lessors Risk (61218)</h3>
                <div className={classes.gln}>
                <div className={classes.gk}>
                    <Grid container  direction='column' spacing={2}>

                    <Grid item xs={12}>
                <div className={classes.formField}> 
                    <label>Build/Prem Square Footage: </label>
                    <TextField type = 'number' size ={'small'} value={policy.BUILDING_OR_PREMISES_LESSORS_RISK_SQUARE_FOOTAGE} variant="outlined" onChange={(e) => onChangeFormData(props.index,e, 'BUILDING_OR_PREMISES_LESSORS_RISK_SQUARE_FOOTAGE')}/>
                </div> </Grid>

                
                
                    </Grid>
                </div>
                </div>
                </div>

                <div className={classes.cln}>
                <h3 className={classes.title}>Building Lessors Risk other than Retail (61225)</h3>
                <div className={classes.gln}>
                <div className={classes.gk}>
                    <Grid container  direction='column' spacing={2}>
                    <Grid item xs={12}>
                <div className={classes.formField}> 
                    <label>Build/Prem other than Retail Square Footage: </label>
                    <TextField  type = 'number' size ={'small'} value={policy.BUILDING_OR_PREMISES_LESSORS_RISK_OTHER_THAN_RETAIL_EXPOSURE} variant="outlined" onChange={(e) => onChangeFormData(props.index,e, 'BUILDING_OR_PREMISES_LESSORS_RISK_OTHER_THAN_RETAIL_EXPOSURE')}/>
                </div> </Grid>

                <Grid item xs={12}>
                <div className={classes.formField}> 
                    <label>Build/Prem describe other than Retail Exposure: </label>
                    <TextField  type = 'number' size ={'small'} value={policy.BUILDING_OR_PREMISES_LESSORS_RISK_DESCRIBE_RETAIL_EXPOSURE} variant="outlined" onChange={(e) => onChangeFormData(props.index,e, 'BUILDING_OR_PREMISES_LESSORS_RISK_DESCRIBE_RETAIL_EXPOSURE')}/>
                </div> </Grid>

                    </Grid>
                </div>
                </div>
                </div>

                <div className={classes.cln}>
                <h3 className={classes.title}>Warehouse (68707)</h3>
                <div className={classes.gln}>
                <div className={classes.gk}>
                    <Grid container  direction='column' spacing={2}>

                    <Grid item xs={12}>
                <div className={classes.formField}> 
                    <label>Warehouses Square Footage: </label>
                    <TextField type = 'number' size ={'small'} value={policy.WAREHOUSES_SQUARE_FOOTAGE} variant="outlined" onChange={(e) => onChangeFormData(props.index,e, 'WAREHOUSES_SQUARE_FOOTAGE')}/>
                </div> </Grid>

                    </Grid>
                </div>
                </div>
                </div>
                
            </div>

            
            </form>
            <div className={classes.feature }>
                    <input form="Gl" type="submit" value="Save GL" className={classes.button} />
                </div>
        </div>
    )
}

export default GlLevel;