import React from 'react';
import PropTypes from 'prop-types';
import { createMuiTheme, ThemeProvider, withStyles } from '@material-ui/core/styles';
import CssBaseline from '@material-ui/core/CssBaseline';
import CpLevel from './CpLevel';
import GlLevel from './GlLevel';
import EditIcon from '@material-ui/icons/Edit';
import { Route, Switch} from "react-router-dom";
import { Link, useLocation } from 'react-router-dom';
import clsx from 'clsx';
import { makeStyles} from '@material-ui/core/styles';
import Box from '@material-ui/core/Box';


let theme = createMuiTheme({
  palette: {
    primary: {
      light: '#63ccff',
      main: '#009be5',
      dark: '#006db3',
    },
  },
  typography: {
    fontFamily: 'Barlow, sans-serif',
    h5: {
      fontWeight: 500,
      fontSize: 26,
      letterSpacing: 0.5,
    },
  },
  shape: {
    borderRadius: 8,
  },
  props: {
    MuiTab: {
      disableRipple: true,
    },
  },
  mixins: {
    toolbar: {
      minHeight: 48,
    },
  },
});

theme = {
  ...theme,
  overrides: {
    MuiDrawer: {
      paper: {
        backgroundColor: '#18202c',
      },
    },
    MuiButton: {
      label: {
        textTransform: 'none',
      },
      contained: {
        boxShadow: 'none',
        '&:active': {
          boxShadow: 'none',
        },
      },
    },
    MuiTabs: {
      root: {
        marginLeft: theme.spacing(1),
      },
      indicator: {
        height: 3,
        borderTopLeftRadius: 3,
        borderTopRightRadius: 3,
        backgroundColor: theme.palette.common.white,
      },
    },
    MuiTab: {
      root: {
        textTransform: 'none',
        margin: '0 16px',
        minWidth: 0,
        padding: 0,
        [theme.breakpoints.up('md')]: {
          padding: 0,
          minWidth: 0,
        },
      },
    },
    MuiIconButton: {
      root: {
        padding: theme.spacing(1),
      },
    },
    MuiTooltip: {
      tooltip: {
        borderRadius: 4,
      },
    },
    MuiDivider: {
      root: {
        backgroundColor: '#404854',
      },
    },
    MuiListItemText: {
      primary: {
        fontWeight: theme.typography.fontWeightMedium,
      },
    },
    MuiListItemIcon: {
      root: {
        color: 'inherit',
        marginRight: 0,
        '& svg': {
          fontSize: 20,
        },
      },
    },
    MuiAvatar: {
      root: {
        width: 32,
        height: 32,
      },
    },
  },
};

const drawerWidth = 256;

const styles = {
  root: {
    display: 'flex',
    minHeight: '75vh',
    marginTop:'40px'
    
  },
  drawer: {
    [theme.breakpoints.up('sm')]: {
      width: drawerWidth,
      flexShrink: 0,
    },
  },
  app: {
    flex: 1,
    display: 'flex',
    flexDirection: 'column',
  },
  main: {
    flex: 1,
    padding: theme.spacing(6, 4),
    background: '#eaeff1',
  },
  footer: {
    padding: theme.spacing(2),
    background: '#eaeff1',
  },
  itemActiveItem: {
    color: '#4fc3f7',
    textDecoration:'none',
  },
  item: {
    color: 'black',
    textDecoration:'none',
  },
};

const useStyles = makeStyles((theme) => ({
hg:{
  float:'left',
  height: '500px',
  
 
  // width: '200px'
},
sc:{
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center'
},
bor:{
  borderStyle:'solid',
  fontSize:'1.0rem',
  fontWeight: 'bold',
  alignText:'left',
  height: '500px',
  overflowY: 'auto',
  width:'200px',
  outlineStyle:'solid',
  borderColor:'#4fc3f7',
  padding:'15px',
  boxShadow: '6px 6px 5px grey',
},
par:{
  marginTop:'0px',
  fontSize:'1.3rem'
},
}));

function Bench(props) {
  const { classes, data, customerId, onChangeFormData, onSubmit, benchType,ID } = props;
  const [mobileOpen, setMobileOpen] = React.useState(false);
  const location = useLocation();
  const isActive = (link) => link === location.pathname;
  const clas = useStyles();

  const handleDrawerToggle = () => {
    setMobileOpen(!mobileOpen);
  };

  console.debug('Bench data', data);

  const renderRouteContent = (dataItem) => {
    
    if(benchType === 'CP') {
      return (<CpLevel
        policy={dataItem}
        onChangeFormData={onChangeFormData}
        onSubmit={onSubmit}
     />);
    } else if (benchType === 'GL'){
      return(
        <GlLevel
          policy={dataItem}
          onChangeFormData={onChangeFormData}
          onSubmit={onSubmit}
        />
      )
    }
      
  }

  return (
    <ThemeProvider theme={theme}>
      <div className={classes.root}>
        <CssBaseline />
        <div className={clas.hg}>
          <Box
            className={clsx(
              clas.bor,
              // clas.sc
            )}
            p={1}
            bgcolor="background.paper"
            >
            <p className={clas.par} align="center">Choose Iteration:</p>
            {
              data.map((eachDataItem,index) => (<li>
                <Link className={clsx(isActive(`/tab/${customerId}/${eachDataItem[ID]}`) ? classes.itemActiveItem:classes.item)} style={{textDecoration:'none'}} to={`/tab/${customerId}/${eachDataItem[ID]}`} > Iteration Num: {eachDataItem.ITERATION_NUMBER}</Link>
              </li>
              ))
            }
          </Box>
        </div>
          <main style={{padding: '1em'}}>
            <Switch>
              {
                data.map(
                  (eachDataItem) => {
                    return (
                      <Route key={eachDataItem} path={`/tab/${customerId}/${eachDataItem.[ID]}`} >
                        {renderRouteContent(eachDataItem)}
                      </Route>
                    );
                  }
                )
              }
              
            </Switch>
          </main>
      </div>
    </ThemeProvider>
  );
}

Bench.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(Bench);