import React from 'react';
import TextField from '@material-ui/core/TextField'
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';
import { makeStyles, ThemeProvider, createMuiTheme } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';


const useStyles = makeStyles((theme) => ({
    
    col:{
        color:'black',
    },
    title:{
      fontFamily:'Barlow, sans-serif',
      
  },
  formField:{
    display:'flex',
    justifyContent:'space-between',
    fontWeight:'bold',
    fontSize: '1.1rem',
},
font:{
    fontFamily: 'Barlow, sans-serif',
    fontSize: '1.1rem',
    textDecoration: 'none',
    paddingTop:'10px',
    paddingBottom:'10px',
    color:'black',
    textAlign: 'center',
},
button: {
    backgroundColor: 'black',
    fontFamily:'Barlow, sans-serif',
    border: 'none',
    color: 'white',
    padding: '12px 15px',
    textAlign: 'center',
    textDecoration: 'none',
    display: 'inline-block',
    fontSize: '16px',
    margin: '4px 2px',
    cursor: 'pointer',
    borderRadius: '8px',
    outline: 0,
    fontWeight:'bold',
    '&:hover': {
        color: "#4fc3f7",
        boxShadow: '4px 4px 4px grey',
     },
  },
  feature: {
    display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        marginTop:'45px'
  },
  fk:{
    display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  marginTop:'50px',
  borderStyle:'solid',
    outlineStyle:'solid',
    borderColor:'#4fc3f7',
    padding:'15px',
    boxShadow: '6px 6px 5px grey',
}
  
  }));

const CpLevel = (props) => {
    const { geo,policy, onSubmit, onChangeFormData} = props;
    const classes = useStyles();
    // console.log(geo)

    return (
        
        <div > 
            <div className={classes.fk}>
            <form id ="Cp" onSubmit={(event) => onSubmit(event, props.index)}>
            <Grid container  direction='row' spacing={2}>
            <Grid item xs={3}>
                <div className={classes.formField}> 
                    <label>Customer ID: </label>
                    <TextField required = "true" size ={'small'}
                        label="Enter Customer ID"
                        value={policy.CUSTOMER_ID}
                        variant="outlined"
                        onChange={
                            (e) => onChangeFormData(props.index,e, 'CUSTOMER_ID')
                        }
                    />
                </div>
                </Grid>
                <Grid item xs={3}>
                <div className={classes.formField}> 
                    <label>Iteration Number: </label>
                    <TextField required = "true" size ={'small'}
                        type="number"
                        value={policy.ITERATION_NUMBER}
                        variant="outlined"
                        onChange={
                            (e) => onChangeFormData(props.index,e, 'ITERATION_NUMBER')
                        }
                    />
                </div>
                </Grid>

                <Grid item xs={3}>
                <div className={classes.formField}> 
                    <label>Premises Number: </label>
                    <TextField required = "true" size ={'small'}
                        type="number"
                        value={policy.PREMISES_NUM}
                        variant="outlined"
                        onChange={
                            (e) => onChangeFormData(props.index,e, 'PREMISES_NUM')
                        }
                    />
                </div>
                </Grid>
                
                <Grid item xs={3}>
                <div className={classes.formField}> 
                    <label>Building Number: </label>
                    <TextField required = "true" size ={'small'}
                        type="number"
                        value={policy.BUILDING_NUM}
                        variant="outlined"
                        onChange={
                            (e) => onChangeFormData(props.index,e, 'BUILDING_NUM')
                        }
                    />
                </div>
                </Grid>
        
                <Grid item xs={3}>
                <div className={classes.formField}> 
                    <label>Wind/Hail Excluded: </label>
                    <TextField required = "true" size ={'small'}
                        value={policy.WINDSTORM_OR_HAIL_EXCLUDED}
                        variant="outlined"
                        onChange={
                            (e) => onChangeFormData(props.index,e, 'WINDSTORM_OR_HAIL_EXCLUDED')
                        }
                    />
                </div>
                </Grid>
                <Grid item xs={3}>
                <div className={classes.formField}> 
                    <label>Sewer PD: </label>
                    <TextField required = "true" size ={'small'}
                        value={policy.SEWER_PD}
                        variant="outlined"
                        onChange={
                            (e) => onChangeFormData(props.index,e, 'SEWER_PD')
                        }
                    />
                </div>
                </Grid>
                <Grid item xs={3}>
                <div className={classes.formField}> 
                    <label>Sewer BI: </label>
                    <TextField required = "true" size ={'small'}
                        value={policy.SEWER_BI}
                        variant="outlined"
                        onChange={
                            (e) => onChangeFormData(props.index,e, 'SEWER_BI')
                        }
                    />
                </div>
                </Grid>
                <Grid item xs={3}>
                <div className={classes.formField}> 
                    <label>Property Limit of Insurance: </label>
                    <TextField required = "true" size ={'small'}
                        type="number"
                        value={policy.PROPERTY_LIMIT_OF_INSURANCE}
                        variant="outlined"
                        onChange={
                            (e) => onChangeFormData(props.index,e, 'PROPERTY_LIMIT_OF_INSURANCE')
                        }
                    />
                </div>
                </Grid>
                <Grid item xs={3}>
                <div className={classes.formField}> 
                    <label>BI Limit of Insurance: </label>
                    <TextField required = "true" size ={'small'}
                        type="number"
                        value={policy.BI_LIMIT_OF_INSURANCE}
                        variant="outlined"
                        onChange={
                            (e) => onChangeFormData(props.index,e, 'BI_LIMIT_OF_INSURANCE')
                        }
                    />
                </div>
                </Grid>
                <Grid item xs={3}>
                <div className={classes.formField}> 
                    <label>BPP Limit of Insurance: </label>
                    <TextField required = "true" size ={'small'}
                        type="number"
                        value={policy.BPP_LIMIT_OF_INSURANCE}
                        variant="outlined"
                        onChange={
                            (e) => onChangeFormData(props.index,e, 'BPP_LIMIT_OF_INSURANCE')
                        }
                    />
                </div>
                </Grid>
                <Grid item xs={3}>
                <div className={classes.formField}> 
                    <label>Total Building Area: </label>
                    <TextField required = "true" size ={'small'}
                        type="number"
                        value={policy.TOTAL_BUILDING_AREA}
                        variant="outlined"
                        onChange={
                            (e) => onChangeFormData(props.index,e, 'TOTAL_BUILDING_AREA')
                        }
                    />
                </div>
                </Grid>
                <Grid item xs={3}>
                <div className={classes.formField}>
                <label>State: </label>
                    <TextField required = "true" size ={'small'}
                        value={geo[0].state}
                        variant="outlined"
                        onChange={
                            (e) => onChangeFormData(props.index,e, 'STATE')
                    }
                    />
                </div>
                </Grid>
                <Grid item xs={3}>
                <div className={classes.formField}>
                <label>City: </label>
                    <TextField required = "true" size ={'small'}
                        value={geo[0].city}
                        variant="outlined"
                        onChange={
                            (e) => onChangeFormData(props.index,e, 'CITY')
                    }
                    />
                </div>
                </Grid>
                <Grid item xs={3}>
                <div className={classes.formField}>
                <label>County: </label>
                    <TextField required = "true" size ={'small'}
                        value={geo[0].county}
                        variant="outlined"
                        onChange={
                            (e) => onChangeFormData(props.index,e, 'COUNTY')
                    }
                    />
                </div>
                </Grid>
                <Grid item xs={3}>
                <div className={classes.formField}>
                <label>PPC: </label>
                    <TextField required = "true" size ={'small'}
                        value={geo[0].ppc.value}
                        variant="outlined"
                        onChange={
                            (e) => onChangeFormData(props.index,e, 'PPC')
                    }
                    />
                </div>
                </Grid>
                
                <Grid item xs={3}>
                <div className={classes.formField}>
                <label>BGI Territory: </label>
                    <TextField required = "true" size ={'small'}
                        value={geo[0].bgITerritory.value}
                        variant="outlined"
                        onChange={
                            (e) => onChangeFormData(props.index,e, 'BGI_TERRITORY')
                    }
                    />
                </div>
                </Grid>
                <Grid item xs={3}>
                <div className={classes.formField}>
                <label>BGII Territory: </label>
                    <TextField required = "true" size ={'small'}
                        value={geo[0].bgIITerritory.value}
                        variant="outlined"
                        onChange={
                            (e) => onChangeFormData(props.index,e, 'BGII_TERRITORY')
                    }
                    />
                </div>
                </Grid>
                <Grid item xs={3}>
                <div className={classes.formField}>
                <label>Special Territory </label>
                    <TextField required = "true" size ={'small'}
                        value={geo[0].specialTerritory.value}
                        variant="outlined"
                        onChange={
                            (e) => onChangeFormData(props.index,e, 'SPECIAL_TERRITORY')
                    }
                    />
                </div>
                </Grid>
                <Grid item xs={3}>
                <div className={classes.formField}>
                <label>Terrorism Territory: </label>
                    <TextField required = "true" size ={'small'}
                        value={geo[0].terrorismTerritory.value}
                        variant="outlined"
                        onChange={
                            (e) => onChangeFormData(props.index,e, 'TERRORISM_TERRITORY')
                    }
                    />
                </div>
                </Grid>
                <Grid item xs={3}>
                <div className={classes.formField}>
                <label>District: </label>
                    <FormControl required = "true" size ={'small'} variant="outlined">
                        
                        <Select
                        native
                        value={geo[0].district.value}
                        onChange={(e) => onChangeFormData(props.index,e, 'DISTRICT')}
                        >
                        <option value={''}></option>
                        <option value={'Unknown'}>Unknown</option>
                        <option value={'District I'}>District I</option>
                        <option value={'District II'}>District II</option>
                        </Select>
                </FormControl>
                </div>
                </Grid>
                <Grid item xs={3}>
                <div className={classes.formField}> 
                <label>Class Code: </label>
                    <FormControl required = "true" size ={'small'} variant="outlined">
                        
                        <Select size ={'small'}
                        native
                        value={policy.CLASS_CODE}
                        onChange={(e) => onChangeFormData(props.index,e, 'CLASS_CODE')}
                        >
                        <option value={''}></option>
                        <option value={'0331'}>0331</option>
                        <option value={'0332'}>0332</option>
                        <option value={'0333'}>0333</option>
                        <option value={'0702'}>0702</option>
                        <option value={'0756'}>0756</option>
                        <option value={'0931'}>0931</option>
                        <option value={'1190'}>1190</option>
                        <option value={'1220'}>1220</option>
                        </Select>
                </FormControl>
                </div>
                </Grid>
                <Grid item xs={3}>
                <div className={classes.formField}> 
                <label>Number of Stories: </label>
                    <FormControl required = "true" size ={'small'} variant="outlined">
                        
                        <Select size ={'small'}
                        native
                        value={policy.NUM_STORIES}
                        onChange={(e) => onChangeFormData(props.index,e, 'NUM_STORIES')}
                        >
                        <option value={''}></option>
                        <option value={'1-3'}>1-3</option>
                        <option value={'4'}>4</option>
                        <option value={'5-7'}>5-7</option>
                        <option value={'8'}>8</option>
                        <option value={'9-20'}>9-20</option>
                        <option value={'Over 20'}>Over 20</option>
                        </Select>
                </FormControl>
                </div>
                </Grid>
                <Grid item xs={3}>
                <div className={classes.formField}> 
                <label>Theft Deductible: </label>
                    <FormControl required = "true" size ={'small'} variant="outlined">
                        
                        <Select size ={'small'}
                        native
                        value={policy.THEFT_DEDUCTIBLE}
                        onChange={(e) => onChangeFormData(props.index,e, 'THEFT_DEDUCTIBLE')}
                        >
                        <option value={''}></option>
                        <option value={'Not Applicable'}>Not Applicable</option>
                        <option value={'None'}>None</option>
                        <option value={'500'}>500</option>
                        <option value={'1,000'}>1,000</option>
                        <option value={'2,500'}>2,500</option>
                        <option value={'5,000'}>5,000</option>
                        <option value={'10,000'}>10,000</option>
                        <option value={'25,000'}>25,000</option>
                        <option value={'50,000'}>50,000</option>
                        <option value={'75,000'}>75,000</option>
                        </Select>
                </FormControl>
                </div>
                </Grid>

                <Grid item xs={3}>
                <div className={classes.formField}> 
                <label>Wind/Hail Deductible: </label>
                    <FormControl required = "true" size ={'small'} variant="outlined">
                        
                        <Select size ={'small'}
                        native
                        value={policy.WINDHAIL_DEDUCTIBLE}
                        onChange={(e) => onChangeFormData(props.index,e, 'WINDHAIL_DEDUCTIBLE')}
                        >
                        <option value={''}></option>
                        <option value={'Excluded'}>Excluded</option>
                        <option value={'None'}>None</option>
                        <option value={'500'}>500</option>
                        <option value={'1,000'}>1,000</option>
                        <option value={'2,500'}>2,500</option>
                        <option value={'5,000'}>5,000</option>
                        <option value={'10,000'}>10,000</option>
                        <option value={'25,000'}>25,000</option>
                        <option value={'50,000'}>50,000</option>
                        <option value={'75,000'}>75,000</option>
                        <option value={'1%'}>1%</option>
                        <option value={'2%'}>2%</option>
                        <option value={'5%'}>5%</option>
                        </Select>
                </FormControl>
                </div>
                </Grid>
                <Grid item xs={3}>
                <div className={classes.formField}> 
                <label>Property Deductible: </label>
                    <FormControl required = "true" size ={'small'} variant="outlined">
                        
                        <Select size ={'small'}
                        native
                        value={policy.PROPERTY_DEDUCTIBLE}
                        onChange={(e) => onChangeFormData(props.index,e, 'PROPERTY_DEDUCTIBLE')}
                        >
                        <option value={''}></option>
                        <option value={'None'}>None</option>
                        <option value={'500'}>500</option>
                        <option value={'1,000'}>1,000</option>
                        <option value={'2,500'}>2,500</option>
                        <option value={'5,000'}>5,000</option>
                        <option value={'10,000'}>10,000</option>
                        <option value={'25,000'}>25,000</option>
                        <option value={'50,000'}>50,000</option>
                        <option value={'75,000'}>75,000</option>
                        </Select>
                </FormControl>
                </div>
                </Grid>
                
                <Grid item xs={3}>
                <div className={classes.formField}>
                <label>Construction Type: </label>
                    <FormControl required = "true" fullWidth = "true" size ={'small'} variant="outlined">
                        
                        <Select
                        native
                        value={policy.CONSTRUCTION_TYPE}
                        onChange={(e) => onChangeFormData(props.index,e, 'CONSTRUCTION_TYPE')}
                        >
                        <option value={''}></option>
                        <option value={'Frame'}>Frame</option>
                        <option value={'Joisted Masonry - Reinforced'}>Joisted Masonry - Reinforced</option>
                        <option value={'Joisted Masonry - Reinforced - Superior Roofing'}>Joisted Masonry - Reinforced - Superior Roofing</option>
                        <option value={'Joisted Masonry - Other Than Reinforced'}>Joisted Masonry - Other Than Reinforced</option>
                        <option value={'Joisted Masonry - Other Than Reinforced - Superior Roofing'}>Joisted Masonry - Other Than Reinforced - Superior Roofing</option>
                        <option value={'Non-Combustible - Light Steel'}>Non-Combustible - Light Steel</option>
                        <option value={'Non-Combustible - Light Steel - Superior Roofing'}>Non-Combustible - Light Steel - Superior Roofing</option>
                        <option value={'Non-Combustible - Other Than Light Steel'}>Non-Combustible - Other Than Light Steel</option>
                        <option value={'Non-Combustible - Other Than Light Steel - Superior Roofing'}>Non-Combustible - Other Than Light Steel - Superior Roofing</option>
                        <option value={'Masonry Non-Combustible - Reinforced - Light Steel'}>Masonry Non-Combustible - Reinforced - Light Steel</option>
                        <option value={'Masonry Non-Combustible - Reinforced - Light Steel - Superior Roofing'}>Masonry Non-Combustible - Reinforced - Light Steel - Superior Roofing</option>
                        <option value={'Masonry Non-Combustible - Other Than Reinforced - Light Steel'}>Masonry Non-Combustible - Other Than Reinforced - Light Steel</option>
                        <option value={'Masonry Non-Combustible - Other Than Reinforced - Light Steel - Superior Roofing'}>Masonry Non-Combustible - Other Than Reinforced - Light Steel - Superior Roofing</option>
                        <option value={'Masonry Non-Combustible - Reinforced - Other Than Light Steel'}>Masonry Non-Combustible - Reinforced - Other Than Light Steel</option>
                        <option value={'Masonry Non-Combustible - Reinforced - Other Than Light Steel - Superior Roofing'}>Masonry Non-Combustible - Reinforced - Other Than Light Steel - Superior Roofing</option>
                        <option value={'Masonry Non-Combustible - Other Than Reinforced - Other Than Light Steel'}>Masonry Non-Combustible - Other Than Reinforced - Other Than Light Steel</option>
                        <option value={'Masonry Non-Combustible - OT Reinforced - OT Light Steel - Superior Roofing'}>Masonry Non-Combustible - OT Reinforced - OT Light Steel - Superior Roofing</option>
                        <option value={'Modified Fire Resistive - Reinforced Masonry - Light Steel'}>Modified Fire Resistive - Reinforced Masonry - Light Steel</option>
                        <option value={'Modified Fire Resistive - Other Than Reinforced Masonry - Light Steel'}>Modified Fire Resistive - Other Than Reinforced Masonry - Light Steel</option>
                        <option value={'Modified Fire Resistive - Reinforced Masonry - Other Than Light Steel'}>Modified Fire Resistive - Reinforced Masonry - Other Than Light Steel</option>
                        <option value={'Modified Fire Resistive - Other Than Reinforced Masonry - Other Than Light Steel'}>Modified Fire Resistive - Other Than Reinforced Masonry - Other Than Light Steel</option>
                        <option value={'Fire Resistive - Reinforced Masonry - Light Steel'}>Fire Resistive - Reinforced Masonry - Light Steel</option>
                        <option value={'Fire Resistive - Other Than Reinforced Masonry - Light Steel'}>Fire Resistive - Other Than Reinforced Masonry - Light Steel</option>
                        <option value={'Fire Resistive - Reinforced Masonry - Other Than Light Steel'}>Fire Resistive - Reinforced Masonry - Other Than Light Steel</option>
                        <option value={'Fire Resistive - Other Than Reinforced Masonry - Other Than Light Steel'}>Fire Resistive - Other Than Reinforced Masonry - Other Than Light Steel</option>
                        </Select>
                </FormControl>
                </div>
                </Grid>

                <Grid item xs={3}>
                <div className={classes.formField}>
                <label>Construction Type to Use: </label>
                <FormControl required = "true" size ={'small'} variant="outlined">
                        
                        <Select
                        native
                        value={policy.CONSTRUCTION_TYPE_TO_USE}
                        onChange={(e) => onChangeFormData(props.index,e, 'CONSTRUCTION_TYPE_TO_USE')}
                        >
                        <option value={''}></option>
                        <option value={'Frame'}>Frame</option>
                        <option value={'Fire Resistive'}>Fire Resistive</option>
                        <option value={'Modified Fire Resistive'}>Modified Fire Resistive</option>
                        <option value={'Joisted Masonry'}>Joisted Masonry</option>
                        <option value={'Masonry Non-Combustible'}>Masonry Non-Combustible</option>
                        <option value={'Non-Combustible'}>Non-Combustible</option>
                        </Select>
                </FormControl>
                </div>
                </Grid>

            </Grid>
            </form>
            </div>
            <div className={classes.feature }>
                    <input form ="Cp" type="submit" value="Save CP" className={classes.button} />
                    <input className={classes.button} type="button" value="Rate Policy" onClick={(event) =>  props.onRate(event)} />
                </div>
        </div>
        
    )
}

export default CpLevel;