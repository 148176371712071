import React from 'react';
import TextField from '@material-ui/core/TextField'
import InputLabel from '@material-ui/core/InputLabel';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';
import { makeStyles, ThemeProvider, createMuiTheme } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import Box from '@material-ui/core/Box';

const useStyles = makeStyles((theme) => ({
    hg:{
        maxHeight:'60px'
    },
    font:{
        fontFamily: 'Barlow, sans-serif',
        fontSize: '1.1rem',
        textDecoration: 'none',
        paddingTop:'10px',
        paddingBottom:'10px',
        color:'black',
        textAlign: 'center',
    },
    // dis:{
    //     display:'flex',
    //     flexWrap: 'wrap',
    //     justifyContent: 'flex-start',
    // },
    root: {
        flexGrow: 1,
      },
      paper: {
        padding: theme.spacing(1),
        textAlign: 'center',
        color: theme.palette.text.secondary,
      },
      formField:{
          display:'flex',
          justifyContent:'space-between',
          fontWeight:'bold',
          fontSize: '1.0rem',
          
      },
    //   aln:{
    //     marginTop:'18px'
    //   },
      button: {
        backgroundColor: 'black',
        fontFamily:'Barlow, sans-serif',
        border: 'none',
        color: 'white',
        padding: '12px 15px',
        textAlign: 'center',
        textDecoration: 'none',
        display: 'inline-block',
        fontSize: '16px',
        margin: '10px 0px 0px 0px',
        cursor: 'pointer',
        borderRadius: '8px',
        outline: 0,
        fontWeight:'bold',
        '&:hover': {
            color: "#4fc3f7",
            boxShadow: '4px 4px 4px grey',
         },
      },
      feature: {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        marginTop:'30px',
        marginBottom:'0px',
        
      },
      fk:{
          display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        marginTop:'50px',
        borderStyle:'solid',
        outlineStyle:'solid',
        borderColor:'#4fc3f7',
        padding:'15px',
        boxShadow: '6px 6px 5px grey',
        marginTop:'5px'
      },
      gk:{
        marginLeft:'0px',
        marginRight:'0px',
        borderStyle:'solid',
        outlineStyle:'solid',
        borderColor:'#4fc3f7',
        boxShadow: '6px 6px 5px grey',
        marginTop:'15px'
    },
    lk:{
        backgroundColor: 'black',
        fontFamily:'Barlow, sans-serif',
        border: 'none',
        color: 'white',
        padding: '12px 15px',
        textAlign: 'center',
        textDecoration: 'none',
        display: 'inline-block',
        fontSize: '16px',
        margin: '0px 0px 0px 0px',
        cursor: 'pointer',
        borderRadius: '8px',
        outline: 0,
        fontWeight:'bold',
        '&:hover': {
            color: "#4fc3f7",
            boxShadow: '4px 4px 4px grey',
         },
      
    },
    space:{
        paddingRight:'5px'
    }
  }));

const PlLevel = (props) => {
    const classes = useStyles();

    return (
        
        <div>
            
            <form onSubmit={(event) => props.onGeoSubmit(event)}>
            
                <Box className={classes.gk} display="flex" justifyContent= "space-around" alignItems="center" alignContent="center" flexWrap="wrap" flexDirection="row" p={0.3} m={1} bgcolor="background.paper">
        
                    <Box p={1}  >
                        <TextField required="true" label={'Enter Address'} size={'small'} value={props.geo[0].StAdd1} variant="outlined" onChange={(e) => props.onChangeFormGeoData(e, 'StAdd1')}/>
                    </Box>
                    <Box p={1} >
                    <TextField required="true" label={'Enter City'} size={'small'} value={props.geo[0].City} variant="outlined" onChange={(e) => props.onChangeFormGeoData(e, 'City')}/>
                    </Box>
                    <Box p={1} > 
                        <TextField required="true" label={'Enter State'} value={props.geo[0].State} size={'small'} variant="outlined" onChange={(e) => props.onChangeFormGeoData(e, 'State')}/> 
                    </Box>
                    <Box p={1} > 
                        <TextField required="true" type = "number" label={'Enter Zipcode'} value={props.geo[0].Zipcode} size={'small'} variant="outlined" onChange={(e) => props.onChangeFormGeoData(e, 'Zipcode')}/> 
                    </Box>
                    <Box p={1} >
                        <div>
                            <input className={classes.lk} type="submit" value="Lookup" />
                        </div>
                    </Box>
                </Box>
                
            </form>
                    
        <div className={classes.fk}>
            <form id = "Pl" onSubmit={(event) => props.onSubmit(event, props.index)}>
            <Grid container  direction='row' spacing={2}>
            
                <Grid item xs={3}>
                <div className={classes.formField}> 
                    <label>Customer ID: </label>
                    <TextField required = "true" label="Enter Customer ID" size ={'small'} value={props.policy.CUSTOMER_ID} variant="outlined" onChange={(e) => props.onChangeFormData(props.index,e, 'CUSTOMER_ID')}/>
                </div>
                </Grid>
                
                <Grid item xs={3}>
                <div className={classes.formField}>
                <label>Policy Number: </label>
                    <TextField required = "true" size ={'small'} value={props.policy.POLICY_NUMBER} variant="outlined" onChange={(e) => props.onChangeFormData(props.index,e, 'POLICY_NUMBER')}/>
                </div></Grid>

                <Grid item xs={3}>
                <div className={classes.formField}>
                <label>Expiring Premium: </label>
                    <TextField required = "true" size ={'small'} type = "number" value={props.policy.EXPIRING_PREMIUM} variant="outlined" onChange={(e) => props.onChangeFormData(props.index,e, 'EXPIRING_PREMIUM')}/>
                </div></Grid>

                <Grid item xs={3}>
                <div className={classes.formField}>
                <label>TIV: </label>
                    <TextField required = "true" size ={'small'} type = "number" value={props.policy.TIV} variant="outlined" onChange={(e) => props.onChangeFormData(props.index,e, 'TIV')}/>
                </div></Grid>
                <Grid item xs={3}>
                <div className={classes.formField}>
                <label>Total Building amount of Insurance: </label>
                    <TextField required = "true" size ={'small'} type = "number" value={props.policy.TOTAL_BUILDING_AMT_OF_INSURANCE} variant="outlined" onChange={(e) => props.onChangeFormData(props.index,e, 'TOTAL_BUILDING_AMT_OF_INSURANCE')}/>
                </div></Grid>
                <Grid item xs={3}>
                <div className={classes.formField}>
                <label>BPP Limit: </label>
                    <TextField required = "true" size ={'small'} type = "number" value={props.policy.BPP_LIMIT} variant="outlined" onChange={(e) => props.onChangeFormData(props.index,e, 'BPP_LIMIT')}/>
                </div></Grid>
                <Grid item xs={3}>
                <div className={classes.formField}>
                <label>BI Limit: </label>
                    <TextField required = "true" size ={'small'} type = "number" value={props.policy.BI_LIMIT} variant="outlined" onChange={(e) => props.onChangeFormData(props.index,e, 'BI_LIMIT')}/>
                </div></Grid>

                <Grid item xs={3}>
                <div className={classes.formField}>
                <label>Company Number Additional Insured: </label>
                    <TextField required = "true" size ={'small'} value={props.policy.COMPANY_NUMBER_ADDITIONAL_INSURED} variant="outlined" onChange={(e) => props.onChangeFormData(props.index,e, 'COMPANY_NUMBER_ADDITIONAL_INSURED')}/>
                </div></Grid>
                
                <Grid item xs={3}>
                <div className={classes.formField}>
                <label>Carrier: </label>
                    <FormControl required = "true" required = "true" size ={'small'} variant='outlined'>
                    
                        <Select
                        native
                        value={props.policy.CARRIER}
                        onChange={(e) => props.onChangeFormData(props.index,e, 'CARRIER')}
                        >
                        <option value={''}></option>
                        <option value={'Arden'}>Arden</option>
                        <option value={'CAU'}>CAU</option>
                        <option value={'Deans & Homer'}>Deans & Homer</option>
                        <option value={'Great American'}>Great American</option>
                        <option value={'Other'}>Other</option>
                        <option value={'Philly'}>Philly</option>
                        <option value={'UCA'}>UCA</option>
                        </Select>
                    </FormControl>
                </div></Grid>
                <Grid item xs={3}>
                <div className={classes.formField}>
                <label>Batch: </label>
                    <TextField size ={'small'} value={props.policy.BATCH} variant="outlined" onChange={(e) => props.onChangeFormData(props.index,e, 'BATCH')}/>
                </div></Grid>
                <Grid item xs={3}>
                <div className={classes.formField}>
                <label>County: </label>
                    <TextField required = "true" size ={'small'} value={props.geo2[0].county} variant="outlined" onChange={(e) => props.onChangeFormData(props.index,e, 'COUNTY')}/>
                </div></Grid>

                <Grid item xs={3}>
                <div className={classes.formField}>
                <label>Company Maintenance Fees:</label>
                <FormControl required = "true" size ={'small'} variant="outlined">
                
                    <Select
                    native
                    value={props.policy.COMPANY_MAINTENANCEFEES}
                    onChange={(e) => props.onChangeFormData(props.index,e, 'COMPANY_MAINTENANCEFEES')}
                    >
                    <option value={''}></option>
                    <option value={1}>Yes</option>
                    <option value={0}>No</option>
                    </Select>
                </FormControl>
                </div></Grid>

                <Grid item xs={3}>
                <div className={classes.formField}>
                <label>Company Auto Hired:</label>
                <FormControl required = "true" size ={'small'} variant="outlined">
                
                    <Select
                    native
                    value={props.policy.COMPANY_AUTO_HIRED}
                    onChange={(e) => props.onChangeFormData(props.index,e, 'COMPANY_AUTO_HIRED')}
                    >
                    <option value={''}></option>
                    <option value={1}>Yes</option>
                    <option value={0}>No</option>
                    </Select>
                </FormControl>
                </div></Grid>
                
                <Grid item xs={3}>
                <div className={classes.formField}>
                <label>Company GL Premier Endorsement:</label>
                <FormControl required = "true" size ={'small'} variant="outlined">
                
                    <Select
                    native
                    value={props.policy.COMPANY_GL_PREMIER_ENDORSEMENT}
                    onChange={(e) => props.onChangeFormData(props.index,e, 'COMPANY_GL_PREMIER_ENDORSEMENT')}
                    >
                    <option value={''}></option>
                    <option value={1}>Yes</option>
                    <option value={0}>No</option>
                    </Select>
                </FormControl>
                </div></Grid>
                
                <Grid item xs={3}>
                <div className={classes.formField}>
                <label>Company Limitation Coverage:</label>
                <FormControl required = "true" size ={'small'} variant="outlined">
                
                    <Select
                    native
                    value={props.policy.COMPANY_LIMITATION_COVERAGE}
                    onChange={(e) => props.onChangeFormData(props.index,e, 'COMPANY_LIMITATION_COVERAGE')}
                    >
                    <option value={''}></option>
                    <option value={1}>Yes</option>
                    <option value={0}>No</option>
                    </Select>
                </FormControl>
                </div></Grid>

                <Grid item xs={3}>
                <div className={classes.formField}>
                <label>Company Terrorism:</label>
                <FormControl required = "true" size ={'small'} variant="outlined">
                
                    <Select
                    native
                    value={props.policy.COMPANY_TERRORISM}
                    onChange={(e) => props.onChangeFormData(props.index,e, 'COMPANY_TERRORISM')}
                    >
                    <option value={''}></option>
                    <option value={1}>Yes</option>
                    <option value={0}>No</option>
                    </Select>
                </FormControl>
                </div></Grid>
                
                <Grid item xs={3}>
                <div className={classes.formField}>
                <label>Company Designated Location:</label>
                <FormControl required = "true" size ={'small'} variant="outlined">
                
                    <Select
                    native
                    value={props.policy.COMPANY_DESIGNATED_LOCATION}
                    onChange={(e) => props.onChangeFormData(props.index,e, 'COMPANY_DESIGNATED_LOCATION')}
                    >
                    <option value={''}></option>
                    <option value={'1'}>Yes</option>
                    <option value={'0'}>No</option>
                    </Select>
                </FormControl>
                </div></Grid>

                <Grid item xs={3}>
                <div className={classes.formField}>
                <label>Company Employee Benefits Liability:</label>
                <FormControl required = "true" size ={'small'} variant="outlined">
                
                    <Select
                    native
                    value={props.policy.COMPANY_EMPLOYEE_BENEFITS_LIABILITY}
                    onChange={(e) => props.onChangeFormData(props.index,e, 'COMPANY_EMPLOYEE_BENEFITS_LIABILITY')}
                    >
                    <option value={''}></option>
                    <option value={'1'}>Yes</option>
                    <option value={'0'}>No</option>
                    </Select>
                </FormControl>
                </div></Grid>

                <Grid item xs={3}>
                <div className={classes.formField}>
                <label>Company Prop Premier Endorsement:</label>
                <FormControl required = "true" size ={'small'} variant="outlined">
                
                    <Select
                    native
                    value={props.policy.COMPANY_PROP_PREMIER_ENDORSEMENT}
                    onChange={(e) => props.onChangeFormData(props.index,e, 'COMPANY_PROP_PREMIER_ENDORSEMENT')}
                    >
                    <option value={''}></option>
                    <option value={1}>Yes</option>
                    <option value={0}>No</option>
                    </Select>
                </FormControl>
                </div></Grid>

                <Grid item xs={3}>
                <div className={classes.formField}>
                <label>Company Waterdamage:</label>
                <FormControl required = "true" size ={'small'} variant="outlined">
                
                    <Select
                    native
                    value={props.policy.COMPANY_WATERDAMAGE}
                    onChange={(e) => props.onChangeFormData(props.index,e, 'COMPANY_WATERDAMAGE')}
                    >
                    <option value={''}></option>
                    <option value={1}>Yes</option>
                    <option value={0}>No</option>
                    </Select>
                </FormControl>
                </div></Grid>

                <Grid item xs={3}>
                <div className={classes.formField}>
                <label>Company Ice Damming:</label>
                <FormControl required = "true" size ={'small'} variant="outlined">
                
                    <Select
                    native
                    value={props.policy.COMPANY_ICEDAMMING}
                    onChange={(e) => props.onChangeFormData(props.index,e, 'COMPANY_ICEDAMMING')}
                    >
                    <option value={''}></option>
                    <option value={1}>Yes</option>
                    <option value={0}>No</option>
                    </Select>
                </FormControl>
                </div></Grid>

                <Grid item xs={3}>
                <div className={classes.formField}>
                <label>Company Equipment Breakdown:</label>
                <FormControl required = "true" size ={'small'} variant="outlined">
                
                    <Select
                    native
                    value={props.policy.COMPANY_EQUIPMENT_BREAKDOWN}
                    onChange={(e) => props.onChangeFormData(props.index,e, 'COMPANY_EQUIPMENT_BREAKDOWN')}
                    >
                    <option value={''}></option>
                    <option value={1}>Yes</option>
                    <option value={0}>No</option>
                    </Select>
                </FormControl>
                </div></Grid>

                <Grid item xs={3}>
                <div className={classes.formField}>
                <label>Company Nonsprinklered:</label>
                <FormControl required = "true" size ={'small'} variant="outlined">
                
                    <Select
                    native
                    value={props.policy.COMPANY_NONSPRINKLERED}
                    onChange={(e) => props.onChangeFormData(props.index,e, 'COMPANY_NONSPRINKLERED')}
                    >
                    <option value={''}></option>
                    <option value={1}>Yes</option>
                    <option value={0}>No</option>
                    </Select>
                </FormControl>
                </div></Grid>

                <Grid item xs={3}>
                <div className={classes.formField}>
                <label>Effective Date: </label>
                    <TextField required = "true" size ={'small'} type = "date" value={props.policy.EFF_DATE} variant="outlined" onChange={(e) => props.onChangeFormData(props.index,e, 'EFF_DATE')}/>
                </div></Grid>
                <Grid item xs={3}>
                <div className={classes.formField}>
                <label>Expiry Date: </label>
                    <TextField required = "true" size ={'small'} type = "date" value={props.policy.EXP_DATE} variant="outlined" onChange={(e) => props.onChangeFormData(props.index,e, 'EXP_DATE')}/>
                </div></Grid>

                <Grid item xs={3}>
                <div className={classes.formField}>
                <label>Company Association By Laws: </label>
                    <TextField required = "true" size ={'small'} value={props.policy.COMPANY_ASSOCIATION_BYLAWS} variant="outlined" onChange={(e) => props.onChangeFormData(props.index,e, 'COMPANY_ASSOCIATION_BYLAWS')}/>
                </div></Grid>
                <Grid item xs={3}>
                <div className={classes.formField}>
                <label>Policy Type:</label>
                <FormControl required = "true" size ={'small'} variant="outlined">
                
                    <Select
                    native
                    value={props.policy.PROGRAM}
                    onChange={(e) => props.onChangeFormData(props.index,e, 'PROGRAM')}
                    >
                    <option value={''}></option>
                    <option value={'PCKG'}>PCKG</option>
                    <option value={'GL'}>GL</option>
                    </Select>
                </FormControl>
                </div></Grid>
                <Grid item xs={3}>
                <div className={classes.formField}>
                <label>Program:</label>
                    <FormControl required = "true" size ={'small'} variant='outlined'>
                    
                        <Select
                        native
                        value={props.policy.PROPERTY_TYPE}
                        onChange={(e) => props.onChangeFormData(props.index,e, 'PROPERTY_TYPE')}
                        >
                        <option value={''}></option>
                        <option value={'Condo'}>Condo</option>
                        <option value={'HOA'}>HOA</option>
                        </Select>
                    </FormControl>
                </div></Grid>
                <Grid item xs={3}>
                <div className={classes.formField}>
                <label>Each Occurence Limit CD: </label>
                    <TextField required = "true" size ={'small'} value={props.policy.EACH_OCCURRENCE_LIMIT_CD} variant="outlined" onChange={(e) => props.onChangeFormData(props.index,e, 'EACH_OCCURRENCE_LIMIT_CD')}/>
                </div></Grid>
                <Grid item xs={3}>
                <div className={classes.formField}>
                <label>General Aggregate Limit CD: </label>
                    <TextField required = "true" size ={'small'} value={props.policy.GENERAL_AGGREGATE_LIMIT_CD} variant="outlined" onChange={(e) => props.onChangeFormData(props.index,e, 'GENERAL_AGGREGATE_LIMIT_CD')}/>
                </div></Grid>
                <Grid item xs={3}>
                <div className={classes.formField}>
                <label>Prods Compld Ops Limit CD: </label>
                    <TextField required = "true" size ={'small'} value={props.policy.PRODS_COMPLD_OPS_AGGREGATE_LIMIT_CD} variant="outlined" onChange={(e) => props.onChangeFormData(props.index,e, 'PRODS_COMPLD_OPS_AGGREGATE_LIMIT_CD')}/>
                </div></Grid>

                <Grid item xs={3}>
                <div className={classes.formField}>
                <label>IRPM Building Features: </label>
                    <TextField required="true" size ={'small'} type = "number" value={props.policy.IRPMBUILDINGFEATURES} variant="outlined" onChange={(e) => props.onChangeFormData(props.index,e, 'IRPMBUILDINGFEATURES')}/>
                </div></Grid>

                <Grid item xs={3}>
                <div className={classes.formField}>
                <label>IRPM Employees: </label>
                    <TextField required="true" size ={'small'} type = "number" value={props.policy.IRPMEMPLOYEES} variant="outlined" onChange={(e) => props.onChangeFormData(props.index,e, 'IRPMEMPLOYEES')}/>
                </div></Grid>

                <Grid item xs={3}>
                <div className={classes.formField}>
                <label>IRPM Location: </label>
                    <TextField required="true" size ={'small'} type = "number" value={props.policy.IRPMLOCATION} variant="outlined" onChange={(e) => props.onChangeFormData(props.index,e, 'IRPMLOCATION')}/>
                </div></Grid>

                <Grid item xs={3}>
                <div className={classes.formField}>
                <label>IRPM Management: </label>
                    <TextField required="true" size ={'small'} type = "number" value={props.policy.IRPMMANAGEMENT} variant="outlined" onChange={(e) => props.onChangeFormData(props.index,e, 'IRPMMANAGEMENT')}/>
                </div></Grid>

                <Grid item xs={3}>
                <div className={classes.formField}>
                <label>IRPM Premises and Equipment: </label>
                    <TextField required="true" size ={'small'} type = "number" value={props.policy.IRPMPREMISESANDEQUIPMENT} variant="outlined" onChange={(e) => props.onChangeFormData(props.index,e, 'IRPMPREMISESANDEQUIPMENT')}/>
                </div></Grid>

                <Grid item xs={3}>
                <div className={classes.formField}>
                <label>IRPM Protection: </label>
                    <TextField required="true" size ={'small'} type = "number" value={props.policy.IRPMPROTECTION} variant="outlined" onChange={(e) => props.onChangeFormData(props.index,e, 'IRPMPROTECTION')}/>
                </div></Grid>

                <Grid item xs={3}>
                <div className={classes.formField}>
                <label>Schedule Rating Location: </label>
                    <TextField required="true" size ={'small'} type = "number" value={props.policy.SCHEDULERATINGLOCATION} variant="outlined" onChange={(e) => props.onChangeFormData(props.index,e, 'SCHEDULERATINGLOCATION')}/>
                </div></Grid>

                <Grid item xs={3}>
                <div className={classes.formField}>
                <label>Schedule Rating Cooperation: </label>
                    <TextField required="true" size ={'small'} type = "number" value={props.policy.SCHEDULERATINGCOOPERATION} variant="outlined" onChange={(e) => props.onChangeFormData(props.index,e, 'SCHEDULERATINGCOOPERATION')}/>
                </div></Grid>

                <Grid item xs={3}>
                <div className={classes.formField}>
                <label>Schedule Rating Classification: </label>
                    <TextField required="true" size ={'small'} type = "number" value={props.policy.SCHEDULERATINGCLASSIFICATION} variant="outlined" onChange={(e) => props.onChangeFormData(props.index,e, 'SCHEDULERATINGCLASSIFICATION')}/>
                </div></Grid>

                <Grid item xs={3}>
                <div className={classes.formField}>
                <label>Schedule Rating Employees: </label>
                    <TextField required="true" size ={'small'} type = "number" value={props.policy.SCHEDULERATINGEMPLOYEES} variant="outlined" onChange={(e) => props.onChangeFormData(props.index,e, 'SCHEDULERATINGEMPLOYEES')}/>
                </div></Grid>

                <Grid item xs={3}>
                <div className={classes.formField}>
                <label>Schedule Rating Equipment: </label>
                    <TextField required="true" size ={'small'} type = "number" value={props.policy.SCHEDULERATINGEQUIPMENT} variant="outlined" onChange={(e) => props.onChangeFormData(props.index,e, 'SCHEDULERATINGEQUIPMENT')}/>
                </div></Grid>

                <Grid item xs={3}>
                <div className={classes.formField}>
                <label>Schedule Rating Premises: </label>
                    <TextField required="true" size ={'small'} type = "number" value={props.policy.SCHEDULERATINGPREMISES} variant="outlined" onChange={(e) => props.onChangeFormData(props.index,e, 'SCHEDULERATINGPREMISES')}/>
                </div></Grid>

                </Grid>
    
            </form>
            </div>
            <div className={classes.feature}>
                <div className={classes.space}>
                    <input form="Pl" type="submit" value="Save Policy" className={classes.button} />
                    </div>
                    <div>
                    <input className={classes.button} type="button" value="Rate Policy" onClick={(event) =>  props.onRate(event)} />
                    </div>
                </div>
                
        </div>
    )
}

export default PlLevel;