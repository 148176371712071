import React from 'react';
import TextField from '@material-ui/core/TextField'
import Grid from '@material-ui/core/Grid';
import { makeStyles, ThemeProvider, createMuiTheme } from '@material-ui/core/styles';
import clsx from 'clsx';
import Select from '@material-ui/core/Select';
import FormControl from '@material-ui/core/FormControl';

const useStyles = makeStyles((theme) => ({
    
    col:{
        color:'black',
    },
    titleb:{
        fontFamily:'Barlow, sans-serif',
    },
    title:{
      fontFamily:'Barlow, sans-serif',
      fontSize:'1.5rem',
      textAlign:'center',
      paddingTop:'0px',
      paddingBottom:'0px',
      marginTop: '10px',
      marginBottom: '10px',
  },
  formField:{
    display:'flex',
    justifyContent:'space-between',
    fontWeight:'bold',
    fontSize: '1.1rem',
},
formField2:{
    display:'flex',
    justifyContent:'center',
    fontWeight:'bold',
    fontSize: '1.1rem',
},
button: {
    backgroundColor: 'black',
    fontFamily:'Barlow, sans-serif',
    border: 'none',
    color: 'white',
    padding: '12px 15px',
    textAlign: 'center',
    textDecoration: 'none',
    display: 'inline-block',
    fontSize: '16px',
    margin: '4px 2px',
    cursor: 'pointer',
    borderRadius: '8px',
    outline: 0,
    fontWeight:'bold',
    '&:hover': {
        color: "#4fc3f7",
        boxShadow: '4px 4px 4px grey',
     },
  },
  feature: {
    display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        marginTop:'45px'
  },
  element:{
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'left',
    padding: '4px 4px 4px 4px',
  },
  fk:{
    display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  marginTop:'10px',
  borderStyle:'solid',
  outlineStyle:'solid',
  borderColor:'#4fc3f7',
  padding:'15px',
  boxShadow: '6px 6px 5px grey',
},
gk:{
    display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  marginTop:'10px',
  padding:'15px',
  
},
cln:{
    width:'30%',
    borderStyle:'solid',
  outlineStyle:'solid',
  borderColor:'#4fc3f7',
  marginTop:'20px',
},
gln:{
    height:'300px',
  overflowY: 'auto',
},
sd:{
    display: 'flex',
    flexWrap:'wrap',
    flexDirection:'row',
  justifyContent: 'space-around',
  alignItems: 'flex-start'
  
},
  }));

const EndLevel = (props) => {
    const { geo,policy, onSubmit, onChangeFormData} = props;
    const classes = useStyles();

    return (
        <div>
            <form id = "END" onSubmit={(event) => onSubmit(event,props.index)}>
            <div className={classes.fk}>
            
            <Grid container  direction='row' spacing={2}>
            <Grid item xs={12}>
                <div className={classes.formField2}> 
                    <TextField required = "true" label="Enter Customer ID" size ={'small'} value={policy.CUSTOMER_ID} variant="outlined" onChange={(e) => onChangeFormData(props.index,e, 'CUSTOMER_ID')}/>
                </div>
                </Grid>
            </Grid>
            
            </div>
            <div className={classes.sd}>
                <div className={classes.cln}>
                <h3 className={classes.title}>Employee Benefits (CG 04 35)</h3>
                <div className={classes.gln}>
                <div className={classes.gk}>
                    <Grid container  direction='column' spacing={2}>

                <Grid item xs={12}>
                <div className={classes.formField}>
                <label>Emp Benefits Cov Ind: </label>
                    <FormControl size ={'small'} variant='outlined'>
                    
                        <Select
                        native
                        value={policy.EMP_BENEFITS_COV_IND}
                        onChange={(e) => onChangeFormData(props.index,e, 'EMP_BENEFITS_COV_IND')}
                        >
                        <option value={''}></option>
                        <option value={'Yes'}>Yes</option>
                        <option value={'No'}>No</option>
                        </Select>
                    </FormControl>
                </div></Grid>

                <Grid item xs={12}>
                <div className={classes.formField}> 
                    <label>Emp Benefits Amt Insurance each EMP: </label>
                    <TextField  size ={'small'} value={policy.EMP_BENEFITS_AMOUNT_INSURANCE_EACH_EMP} variant="outlined" 
                    onChange={(e) => onChangeFormData(props.index,e, 'EMP_BENEFITS_AMOUNT_INSURANCE_EACH_EMP')}/>
                </div>
                </Grid>

                <Grid item xs={12}>
                <div className={classes.formField}> 
                    <label>Emp Benefits Number EMPS: </label>
                    <TextField size ={'small'} value={policy.EMP_BENEFITS_NUMBER_EMPS} variant="outlined" 
                    onChange={(e) => onChangeFormData(props.index,e, 'EMP_BENEFITS_NUMBER_EMPS')}/>
                </div>
                </Grid>

                <Grid item xs={12}>
                <div className={classes.formField}> 
                    <label>Emp Benefits Agg Limit: </label>
                    <TextField type="number" size ={'small'} value={policy.EMP_BENEFITS_AGGREGATE_LIMIT} variant="outlined" 
                    onChange={(e) => onChangeFormData(props.index,e, 'EMP_BENEFITS_AGGREGATE_LIMIT')}/>
                </div>
                </Grid>

                <Grid item xs={12}>
                <div className={classes.formField}> 
                    <label>Emp Benefits Retroactive Date: </label>
                    <TextField size ={'small'} value={policy.EMP_BENEFITS_RETROACTIVE_DATE} variant="outlined" 
                    onChange={(e) => onChangeFormData(props.index,e, 'EMP_BENEFITS_RETROACTIVE_DATE')}/>
                </div>
                </Grid>

                

                <Grid item xs={12}>
                <div className={classes.formField}> 
                    <label>Emp Benefits Liability Deductible Per Claim: </label>
                    <TextField  size ={'small'} value={policy.EMP_BENEFITS_LIABILITY_DEDUCTIBLE_PER_CLAIM} variant="outlined" 
                    onChange={(e) => onChangeFormData(props.index,e, 'EMP_BENEFITS_LIABILITY_DEDUCTIBLE_PER_CLAIM')}/>
                </div>
                </Grid>

                    </Grid>
                </div>
                </div>
                </div>
            
                <div className={classes.cln}>
                <h3 className={classes.title}>Liqour Amendments (CG 21 51)</h3>
                <div className={classes.gln}>
                <div className={classes.gk}>
                    <Grid container  direction='column' spacing={2}>

                    <Grid item xs={12}>
                <div className={classes.formField}> 
                <label>Amend Liq Liability Excl Exception for Premises Ops Prem Ind: </label>
                    <FormControl size ={'small'} variant='outlined'>
                    
                        <Select
                        native
                        value={policy.AMENDMENT_LIQUOR_LIABILITY_EXCLUSION_EXCEPTION_FOR_PREMISES_OPS_PREM_IND}
                        onChange={(e) => onChangeFormData(props.index,e, 'AMENDMENT_LIQUOR_LIABILITY_EXCLUSION_EXCEPTION_FOR_PREMISES_OPS_PREM_IND')}
                        >
                        <option value={''}></option>
                        <option value={'Yes'}>Yes</option>
                        <option value={'No'}>No</option>
                        </Select>
                    </FormControl>
                </div></Grid>

                <Grid item xs={12}>
                <div className={classes.formField}> 
                    <label>Amend Liq Liability Excl Desc Premises Activities Prem: </label>
                    <TextField  size ={'small'} value={policy.AMENDMENT_LIQUOR_LIABILITY_EXCLUSION_DESCRIPTION_PREMISES_ACTIVITIES_PREM} variant="outlined" 
                    onChange={(e) => onChangeFormData(props.index,e, 'AMENDMENT_LIQUOR_LIABILITY_EXCLUSION_DESCRIPTION_PREMISES_ACTIVITIES_PREM')}/>
                </div>
                </Grid>


                    </Grid>
                </div>
                </div>
                </div>

                <div className={classes.cln}>
                <h3 className={classes.title}>Liqour Amendments (CG 29 53)</h3>
                <div className={classes.gln}>
                <div className={classes.gk}>
                    <Grid container  direction='column' spacing={2}>

                    <Grid item xs={12}>
                <div className={classes.formField}> 
                    <label>Amend Liq Liability Excl Exception for Premises Ops Prod Ind: </label>
                    <TextField  size ={'small'} value={policy.AMENDMENT_LIQUOR_LIABILITY_EXCLUSION_EXCEPTION_FOR_PREMISES_OPS_PROD_IND} variant="outlined" 
                    onChange={(e) => onChangeFormData(props.index,e, 'AMENDMENT_LIQUOR_LIABILITY_EXCLUSION_EXCEPTION_FOR_PREMISES_OPS_PROD_IND')}/>
                </div>
                </Grid>

                    <Grid item xs={12}>
                <div className={classes.formField}> 
                    <label>Amend Liq Liability Excl Desc Premises Activities Prod: </label>
                    <TextField  size ={'small'} value={policy.AMENDMENT_LIQUOR_LIABILITY_EXCLUSION_DESCRIPTION_PREMISES_ACTIVITIES_PROD} variant="outlined" 
                    onChange={(e) => onChangeFormData(props.index,e, 'AMENDMENT_LIQUOR_LIABILITY_EXCLUSION_DESCRIPTION_PREMISES_ACTIVITIES_PROD')}/>
                </div>
                </Grid>

                

                

                    </Grid>
                </div>
                </div>
                </div>

                <div className={classes.cln}>
                <h3 className={classes.title}>Boat Liability (CG 24 12)</h3>
                <div className={classes.gln}>
                <div className={classes.gk}>
                    <Grid container  direction='column' spacing={2}>
                    <Grid item xs={12}>
                <div className={classes.formField}>
                <label>Boat Liability Cov Ind: </label>
                    <FormControl size ={'small'} variant='outlined'>
                    
                        <Select
                        native
                        value={policy.BOAT_LIABILITY_COV_IND}
                        onChange={(e) => onChangeFormData(props.index,e, 'BOAT_LIABILITY_COV_IND')}
                        >
                        <option value={''}></option>
                        <option value={'Yes'}>Yes</option>
                        <option value={'No'}>No</option>
                        </Select>
                    </FormControl>
                </div></Grid>

                <Grid item xs={12}>
                <div className={classes.formField}> 
                    <label>Boat Liability Describe Watercraft: </label>
                    <TextField  size ={'small'} value={policy.BOAT_LIABILITY_DESCRIBE_WATERCRAFT} variant="outlined" 
                    onChange={(e) => onChangeFormData(props.index,e, 'BOAT_LIABILITY_DESCRIBE_WATERCRAFT')}/>
                </div>
                </Grid>

                    </Grid>
                </div>
                </div>
                </div>

                <div className={classes.cln}>
                <h3 className={classes.title}>Utility Services - Direct Damage (CP 04 17)</h3>
                <div className={classes.gln}>
                <div className={classes.gk}>
                    <Grid container  direction='column' spacing={2}>

                    <Grid item xs={12}>
                <div className={classes.formField}> 
                    <label>Utility Svc DD Utility Svc Limit: </label>
                    <TextField  type = 'number' size ={'small'} value={policy.UTILITY_SERVICES_DD_UTILITY_SERVICES_LIMIT} variant="outlined" onChange={(e) => onChangeFormData(props.index,e, 'UTILITY_SERVICES_DD_UTILITY_SERVICES_LIMIT')}/>
                </div> </Grid>

                <Grid item xs={12}>
                <div className={classes.formField}>
                <label>Utility Svc DD Water Supply Property Covg Ind: </label>
                    <FormControl size ={'small'} variant='outlined'>
                    
                        <Select
                        native
                        value={policy.UTILITY_SERVICES_DD_WATER_SUPPLY_PROPERTY_COVERAGE_INDICATOR}
                        onChange={(e) => onChangeFormData(props.index,e, 'UTILITY_SERVICES_DD_WATER_SUPPLY_PROPERTY_COVERAGE_INDICATOR')}
                        >
                        <option value={''}></option>
                        <option value={'Yes'}>Yes</option>
                        <option value={'No'}>No</option>
                        </Select>
                    </FormControl>
                </div></Grid>

                <Grid item xs={12}>
                <div className={classes.formField}> 
                    <label>Utility Svc DD Comm Supply inc Ovhd Trans Lines: </label>
                    <TextField  size ={'small'} value={policy.UTILITY_SERVICES_DD_COMMUNICATION_SUPPLY_INCLUDING_OVERHEAD_TRANSMISSION_LINES} variant="outlined" onChange={(e) => onChangeFormData(props.index,e, 'UTILITY_SERVICES_DD_COMMUNICATION_SUPPLY_INCLUDING_OVERHEAD_TRANSMISSION_LINES')}/>
                </div>
                </Grid>


                <Grid item xs={12}>
                <div className={classes.formField}> 
                    <label>Utility Svc DD Comm Supply not inc Ovhd Trans Lines: </label>
                    <TextField  size ={'small'} value={policy.UTILITY_SERVICES_DD_COMMUNICATION_SUPPLY_NOT_INCLUDING_OVERHEAD_TRANSMISSION_LINES} variant="outlined" onChange={(e) => onChangeFormData(props.index,e, 'UTILITY_SERVICES_DD_COMMUNICATION_SUPPLY_NOT_INCLUDING_OVERHEAD_TRANSMISSION_LINES')}/>
                </div>
                </Grid>

                <Grid item xs={12}>
                <div className={classes.formField}> 
                    <label>Utility Svc DD Power Supply inc Ovhd Trans Lines: </label>
                    <TextField  size ={'small'} value={policy.UTILITY_SERVICES_DD_POWER_SUPPLY_INCLUDING_OVERHEAD_TRANSMISSION_LINES} variant="outlined" onChange={(e) => onChangeFormData(props.index,e, 'UTILITY_SERVICES_DD_POWER_SUPPLY_INCLUDING_OVERHEAD_TRANSMISSION_LINES')}/>
                </div>
                </Grid>

                <Grid item xs={12}>
                <div className={classes.formField}> 
                    <label>Utility Svc DD Power Supply not inc Ovhd Trans Lines: </label>
                    <TextField  size ={'small'} value={policy.UTILITY_SERVICES_DD_POWER_SUPPLY_NOT_INCLUDING_OVERHEAD_TRANSMISSION_LINES} variant="outlined" onChange={(e) => onChangeFormData(props.index,e, 'UTILITY_SERVICES_DD_POWER_SUPPLY_NOT_INCLUDING_OVERHEAD_TRANSMISSION_LINES')}/>
                </div>
                </Grid>

                <Grid item xs={12}>
                <div className={classes.formField}> 
                    <label>Utility Svc Direct Damage covd Propert Desc: </label>
                    <TextField  size ={'small'} value={policy.UTILITY_SERVICES_DIRECT_DAMAGE_COVERED_PROPERTY_DESCRIPTION} variant="outlined" 
                    onChange={(e) => onChangeFormData(props.index,e, 'UTILITY_SERVICES_DIRECT_DAMAGE_COVERED_PROPERTY_DESCRIPTION')}/>
                </div>
                </Grid>

                    <Grid item xs={12}>
                <div className={classes.formField}> 
                    <label>Utility Svc DD Causes of Loss Form Applicable: </label>
                    <TextField  size ={'small'} value={policy.UTILITY_SERVICES_DD_CAUSES_OF_LOSS_FORM_APPLICABLE} variant="outlined" onChange={(e) => onChangeFormData(props.index,e, 'UTILITY_SERVICES_DD_CAUSES_OF_LOSS_FORM_APPLICABLE')}/>
                </div>
                </Grid>

                    </Grid>
                </div>
                </div>
                </div>

                <div className={classes.cln}>
                <h3 className={classes.title}>Utility Services - Time Element (CP 15 14)</h3>
                <div className={classes.gln}>
                <div className={classes.gk}>
                    <Grid container  direction='column' spacing={2}>

                    <Grid item xs={12}>
                <div className={classes.formField}> 
                    <label>Utility Svc TE Water Supply Property Covg: </label>
                    <TextField  size ={'small'} value={policy.UTILITY_SERVICES_TE_WATER_SUPPLY_PROPERTY_COVERAGE} variant="outlined" 
                    onChange={(e) => onChangeFormData(props.index,e, 'UTILITY_SERVICES_TE_WATER_SUPPLY_PROPERTY_COVERAGE')}/>
                </div>
                </Grid>

                    

                <Grid item xs={12}>
                <div className={classes.formField}> 
                    <label>Utility Svc TE Comm Supply inc Ovhd Trans Lines: </label>
                    <TextField  size ={'small'} value={policy.UTILITY_SERVICES_TE_COMMUNICATION_SUPPLY_INCLUDING_OVERHEAD_TRANSMISSION_LINES} variant="outlined" 
                    onChange={(e) => onChangeFormData(props.index,e, 'UTILITY_SERVICES_TE_COMMUNICATION_SUPPLY_INCLUDING_OVERHEAD_TRANSMISSION_LINES')}/>
                </div>
                </Grid>

                <Grid item xs={12}>
                <div className={classes.formField}> 
                    <label>Utility Svc TE Comm Supply not inc Ovhd Trans Lines: </label>
                    <TextField  size ={'small'} value={policy.UTILITY_SERVICES_TE_COMMUNICATION_SUPPLY_NOT_INCLUDING_OVERHEAD_TRANSMISSION_LINES} variant="outlined" 
                    onChange={(e) => onChangeFormData(props.index,e, 'UTILITY_SERVICES_TE_COMMUNICATION_SUPPLY_NOT_INCLUDING_OVERHEAD_TRANSMISSION_LINES')}/>
                </div>
                </Grid>


                <Grid item xs={12}>
                <div className={classes.formField}> 
                    <label>Utility Svc TE Power Supply inc Ovhd Trans Lines: </label>
                    <TextField  size ={'small'} value={policy.UTILITY_SERVICES_TE_POWER_SUPPLY_INCLUDING_OVERHEAD_TRANSMISSION_LINES} variant="outlined" 
                    onChange={(e) => onChangeFormData(props.index,e, 'UTILITY_SERVICES_TE_POWER_SUPPLY_INCLUDING_OVERHEAD_TRANSMISSION_LINES')}/>
                </div>
                </Grid>

                <Grid item xs={12}>
                <div className={classes.formField}> 
                    <label>Utility Svc TE Comm Supply not inc Ovhd Trans Lines: </label>
                    <TextField  size ={'small'} value={policy.UTILITY_SERVICES_TE_POWER_SUPPLY_NOT_INCLUDING_OVERHEAD_TRANSMISSION_LINES} variant="outlined" 
                    onChange={(e) => onChangeFormData(props.index,e, 'UTILITY_SERVICES_TE_POWER_SUPPLY_NOT_INCLUDING_OVERHEAD_TRANSMISSION_LINES')}/>
                </div>
                </Grid>

                <Grid item xs={12}>
                <div className={classes.formField}> 
                    <label>Utility Svc TE covd Propert Desc: </label>
                    <TextField  size ={'small'} value={policy.UTILITY_SERVICES_TE_COVERED_PROPERTY_DESCRIPTION} variant="outlined" 
                    onChange={(e) => onChangeFormData(props.index,e, 'UTILITY_SERVICES_TE_COVERED_PROPERTY_DESCRIPTION')}/>
                </div>
                </Grid>


                <Grid item xs={12}>
                <div className={classes.formField}> 
                    <label>Utility Svc TE Wastewater Removal Property: </label>
                    <TextField  size ={'small'} value={policy.UTILITY_SERVICES_TE_WASTEWATER_REMOVAL_PROPERTY} variant="outlined" 
                    onChange={(e) => onChangeFormData(props.index,e, 'UTILITY_SERVICES_TE_WASTEWATER_REMOVAL_PROPERTY')}/>
                </div>
                </Grid>

                <Grid item xs={12}>
                <div className={classes.formField}> 
                    <label>Utility Svc TE Loss Form Applicable: </label>
                    <TextField  size ={'small'} value={policy.UTILITY_SERVICES_TE_CAUSES_OF_LOSS_FORM_APPLICABLE} variant="outlined" 
                    onChange={(e) => onChangeFormData(props.index,e, 'UTILITY_SERVICES_TE_CAUSES_OF_LOSS_FORM_APPLICABLE')}/>
                </div>
                </Grid>

                <Grid item xs={12}>
                <div className={classes.formField}> 
                    <label>Utility Svc TE Utility Svc Limit: </label>
                    <TextField type="number" size ={'small'} value={policy.UTILITY_SERVICES_TE_UTILITY_SERVICES_LIMIT} variant="outlined" 
                    onChange={(e) => onChangeFormData(props.index,e, 'UTILITY_SERVICES_TE_UTILITY_SERVICES_LIMIT')}/>
                </div>
                </Grid>


                    </Grid>
                </div>
                </div>
                </div>

                <div className={classes.cln}>
                <h3 className={classes.title}>Discharge from Sewer (CP 10 38)</h3>
                <div className={classes.gln}>
                <div className={classes.gk}>
                    <Grid container  direction='column' spacing={2}>
                    <Grid item xs={12}>
                <div className={classes.formField}>
                <label>Discharge water backup and sump overflow covg Ind: </label>
                    <FormControl size ={'small'} variant='outlined'>
                    
                        <Select
                        native
                        value={policy.DISCHARGE_WATER_BACKUP_AND_SUMP_OVERFLOW_COVERAGE_INDICATOR}
                        onChange={(e) => onChangeFormData(props.index,e, 'DISCHARGE_WATER_BACKUP_AND_SUMP_OVERFLOW_COVERAGE_INDICATOR')}
                        >
                        <option value={''}></option>
                        <option value={'Yes'}>Yes</option>
                        <option value={'No'}>No</option>
                        </Select>
                    </FormControl>
                </div></Grid>

                <Grid item xs={12}>
                <div className={classes.formField}> 
                    <label>Discharge water backup PD Limit: </label>
                    <TextField  type = 'number' size ={'small'} value={policy.DISCHARGE_WATER_BACKUP_PROPERTY_DAMAGE_LIMIT} variant="outlined" onChange={(e) => onChangeFormData(props.index,e, 'DISCHARGE_WATER_BACKUP_PROPERTY_DAMAGE_LIMIT')}/>
                </div> </Grid>

                    </Grid>
                </div>
                </div>
                </div>
                
            </div>
            </form>

            <div className={classes.feature }>
                    <input form="END" type="submit" value="Save End." className={classes.button} />
                </div>
        </div>
    )
}

export default EndLevel;