import React from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import { withStyles } from '@material-ui/core/styles';
import Divider from '@material-ui/core/Divider';
import Drawer from '@material-ui/core/Drawer';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import HomeIcon from '@material-ui/icons/Home';
import BuildIcon from '@material-ui/icons/Build';
import EditIcon from '@material-ui/icons/Edit';
import { Link, useLocation } from 'react-router-dom';
import InsertDriveFileIcon from '@material-ui/icons/InsertDriveFile';
import { makeStyles } from '@material-ui/core/styles';
import SearchIcon from '@material-ui/icons/Search';
import DesktopWindowsIcon from '@material-ui/icons/DesktopWindows';
import LocationOnIcon from '@material-ui/icons/LocationOn';
import ThumbUpAltIcon from '@material-ui/icons/ThumbUpAlt';

const categories = [
  {
    id: 'Rating Analysis',
    children: [
      {id: 'Policy Rating', icon: <EditIcon />, link: '/wb/cdv' },
      { id: 'Rating XML', icon: <EditIcon />, link: '/wb/xml'},
      { id: 'Create Policy', icon: <EditIcon />, link: '/wb/cr'},
      { id: 'Rating Inputs', icon: <EditIcon />, link: '/wb/pi'},
      { id: 'Location Lookup', icon: <LocationOnIcon />, link: '/wb/gc'},
    ],
  },
  {
    id: 'Policy Data Entry',
    children: [
      { id: 'Policy Tables', icon: <SearchIcon />, link: '/wb/pt' },
      { id: 'Create Policy', icon: <EditIcon />, link: '/wb/cpt'},
      { id: 'Approve Policy', icon: <ThumbUpAltIcon />, link: '/wb/ap'},
    ],
  },
  {
    id: 'Bulkload Funnel',
    children: [
      { id: 'Customer Status', icon: <SearchIcon />, link: '/wb/sts' },
    ],
  },
  {
    id: 'Documents',
    children: [
      { id: 'Customer Policy', icon: <SearchIcon />, link: '/wb/doc' },
    ],
  },
  // {
  //   id: 'Rating Analysis',
  //   children: [
  //     { id: 'Dashboard', icon: <DesktopWindowsIcon />, link: '/wb/bi' },
  //   ],
  // },
];
const useStyles = makeStyles((theme) => ({
    size:{
      fontSize: '2.2rem',
    },
  divColor:{
      backgroundColor:'black',
  },
root: {
  flexGrow: 1,
},
menuButton: {
  marginRight: theme.spacing(2),
},
title: {
  flexGrow: 1,
  color:'white',
  fontFamily:'Barlow, sans-serif',

},
}));
const styles = (theme) => ({
  categoryHeader: {
    
    paddingTop: theme.spacing(2),
    paddingBottom: theme.spacing(2),
    
  },
  categoryHeaderPrimary: {
    color: theme.palette.common.white,
  },
  item: {
    paddingTop: 1,
    paddingBottom: 1,
    color: 'white',
    '&:hover,&:focus': {
      textDecoration:'none',
    },
  },
  itemCategory: {
    backgroundColor: 'black',
    boxShadow: '0 -1px 0 #404854 inset',
    paddingTop: theme.spacing(2),
    paddingBottom: theme.spacing(2),
  },
  firebase: {
    fontSize: 24,
    color: theme.palette.common.white,
  },
  itemActiveItem: {
    color: '#4fc3f7',
    textDecoration:'none',
    outline:'none'

  },
  itemPrimary: {
    fontSize: '1.1rem',
    textDecoration:'none',
  },

  itemBench: {
    fontSize: '1.5rem',
    textDecoration:'none',
  },
  itemIcon: {
    minWidth: 'auto',
    marginRight: theme.spacing(2),
  },
  divider: {
    marginTop: theme.spacing(2),
  },
});

function Navigator(props) {
  const location = useLocation();
  const cls = useStyles();
  const { classes, ...other } = props;

  const isActive = (link) => link === location.pathname;


  return (
    <Drawer  variant="permanent" {...other}>
      <List disablePadding>
        {/* <ListItem className={clsx(cls.divColor,classes.firebase, classes.item, classes.itemCategory)}>
          LIO Insurance
        </ListItem> */}
        <ListItem className={clsx(classes.item, classes.itemCategory)}>
          <ListItemIcon className={classes.itemIcon}>
            <BuildIcon />
          </ListItemIcon>
          <ListItemText
            classes={{
              primary: classes.itemBench,
            }}
          >
            Workbench
          </ListItemText>
        </ListItem>
        {categories.map(({ id, children }) => (
          <React.Fragment key={id}>
            <ListItem className={classes.categoryHeader}>
              <ListItemText className={cls.size}
                classes={{
                  primary: classes.categoryHeaderPrimary,
                }}
              >
                {id}
              </ListItemText>
            </ListItem>
            {children.map(({ id: childId, icon, link = '' }) => (
              <ListItem
                key={childId}
                button
                className={clsx(classes.item, isActive(link) && classes.itemActiveItem)}
              >
                <ListItemIcon className={classes.itemIcon}>{icon}</ListItemIcon>
                <ListItemText
                  classes={{
                    primary: classes.itemPrimary,
                  }}
                >
                  <Link 
                    className={clsx(classes.item, isActive(link) && classes.itemActiveItem)}
                    to={link} style={{ textDecoration: 'none'}}> {childId} </Link>
                </ListItemText>
                
              </ListItem>
            ))}

            <Divider className={classes.divider} />
          </React.Fragment>
        ))}
      </List>
    </Drawer>
  );
}

Navigator.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(Navigator);