import React, { useState, useEffect } from 'react';
import { DataGrid } from '@material-ui/data-grid';
import { Link } from 'react-router-dom';
import Button from '@material-ui/core/Button';
import axios from 'axios';
import PropTypes from 'prop-types';
import EditIcon from '@material-ui/icons/Edit';
import { makeStyles, ThemeProvider, createMuiTheme } from '@material-ui/core/styles';
import config from "./../config";
import CircularProgress from '@material-ui/core/CircularProgress';

const useStyles = makeStyles((theme) => ({
  
    root: {
        '& .super--cell': {
          backgroundColor: 'rgba(224, 183, 60, 0.55)',
          color: '#1a3e72',
          fontWeight: '600',
        },
        '& .super--cell2': {
          color: 'black',
          textAlign:'center',
          fontSize:'1.0rem'
      
          
        },
        '& .super-app-theme--header': {
          fontSize:'1.1rem',
        },
      },
    col:{
        color:'black',
    },
    title:{
      fontFamily:'Barlow, sans-serif',
      fontSize:'1.6rem',
      textAlign:'center',
      marginTop:'10px',
    marginBottom:'45px'
  },
  load:{
    fontFamily:'Barlow, sans-serif',
    fontSize:'1.6rem',
    textAlign:'center',
},
cent:{display: 'flex',
    justifyContent:'center',
    alignItems:'center',
    alignContent:'center',
    marginTop:'180px',
},
  }));

const DataGridxml = () => {
    const classes = useStyles();
    const columns=[
        {
            field: 'action',
            headerName: 'Action',
            description: 'This column has a value getter and is not sortable.',
            sortable: false,
            headerClassName: 'super-app-theme--header',
            width: 100,
            renderCell: (params) => <Link target={"_blank"} to={`/editxml/${params.row.id}`}><EditIcon className={classes.col}/></Link>
          },
        { field: 'DV_S3FILE_KEY', headerName: 'DV S3 File Key',cellClassName: 'super--cell2',headerClassName: 'super-app-theme--header',headerAlign: 'center', flex:1},
        { field: 'DV_LOAD_DTS', headerName: 'DV Load Dts', width: 300,cellClassName: 'super--cell2',headerClassName: 'super-app-theme--header',headerAlign: 'center' },
        { field: 'BUCKET', headerName: 'Bucket', width: 300,cellClassName: 'super--cell2',headerClassName: 'super-app-theme--header',headerAlign: 'center' },
        ];
    const [dataRows, setDataRows ] = useState([]);
    const [loading, setLoading] = useState(true);
    const [shouldRefresh, setShouldRefresh] = useState(false);
    

    useEffect( () => {
        axios.get(config.server_url + '/xml/')
        .then(response => {
            setDataRows(response.data);
            setLoading(false);
        })
        .catch((error) => {
            console.log(error);
        })
    }, [shouldRefresh]);

  const renderLoadingMessage = () => <div className={classes.cent}><CircularProgress color="primary" /></div>;

  const renderGrid = () => (
    <div className={classes.root} style={{ height: 800, width: '100%' }}>
        <DataGrid rows={dataRows} columns={columns} pageSize={10} getRowId={(eachRow) => encodeURIComponent(eachRow.DV_S3FILE_KEY.concat(eachRow.DV_LOAD_DTS),"UTF-8") }   autoHeight='true' />
    </div>
    );

    return (<>
         <h3 className={classes.title}>XML</h3>
        {
            loading ? renderLoadingMessage() : renderGrid()
        }
    </>);
}

DataGridxml.propTypes = {
    columns: PropTypes.array.isRequired,
    pageSize: PropTypes.number.isRequired,
  };

export default DataGridxml