import React from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
import AppBar from '@material-ui/core/AppBar';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import Typography from '@material-ui/core/Typography';
import Box from '@material-ui/core/Box';
import { useEffect, useState } from 'react';
import axios from 'axios';
import CreatePL from './CreatePL'
import CreateCP from './CreateCP'
import CreateGL from './CreateGL'
import Bench from './Bench'
import { Link } from 'react-router-dom';
import { createMuiTheme, ThemeProvider, withStyles } from '@material-ui/core/styles';
import ArrowBackIcon from '@material-ui/icons/ArrowBack';
import { Button } from '@material-ui/core';
import CssBaseline from '@material-ui/core/CssBaseline';
import config from "./../config"
import CircularProgress from '@material-ui/core/CircularProgress';


let theme = createMuiTheme({
    palette: {
      primary: {
        light: '#63ccff',
        main: '#009be5',
        dark: '#006db3',
      },
    },
    typography: {
      fontFamily: 'Barlow, sans-serif',
      h5: {
        fontWeight: 500,
        fontSize: 26,
        letterSpacing: 0.5,
        fontFamily:'Barlow, sans-serif',
      },
    },
    shape: {
      borderRadius: 8,
    },
    props: {
      MuiTab: {
        disableRipple: true,
      },
    },
    mixins: {
      toolbar: {
        minHeight: 48,
      },
    },
  });
  
  theme = {
    ...theme,
    overrides: {
      MuiDrawer: {
        paper: {
          backgroundColor: 'black',
        },
      },
      MuiButton: {
        label: {
          textTransform: 'none',
        },
        contained: {
          boxShadow: 'none',
          '&:active': {
            boxShadow: 'none',
          },
        },
      },
      MuiTabs: {
        root: {
          marginLeft: theme.spacing(1),
        },
        indicator: {
          height: 3,
          borderTopLeftRadius: 3,
          borderTopRightRadius: 3,
          backgroundColor: '#4fc3f7',
        },
      },
      MuiTab: {
        
        root: {
          textTransform: 'none',
          margin: '0 16px',
          minWidth: 0,
          padding: 0,
          [theme.breakpoints.up('md')]: {
            padding: 0,
            minWidth: 0,
          },
        },
      },
      MuiIconButton: {
        root: {
          padding: theme.spacing(1),
        },
      },
      MuiTooltip: {
        tooltip: {
          borderRadius: 4,
        },
      },
      MuiDivider: {
        root: {
          backgroundColor: '#404854',
        },
      },
      MuiListItemText: {
        primary: {fontSize: 25,
          fontWeight: theme.typography.fontWeightMedium,
        },
      },
      MuiListItemIcon: {
        root: {
          color: 'inherit',
          marginRight: 0,
          '& svg': {
            fontSize: 20,
          },
        },
      },
      MuiAvatar: {
        root: {
          width: 32,
          height: 32,
        },
      },
    },
  };

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box p={3}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.any.isRequired,
  value: PropTypes.any.isRequired,
};

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    'aria-controls': `simple-tabpanel-${index}`,
  };
}

const useStyles = makeStyles((theme) => ({

    font:{
        fontFamily: 'Barlow, sans-serif',
        fontSize: '1.1rem',
        textDecoration: 'none',
        paddingTop:'10px',
        color:'black',
        fontWeight:'bold',
        paddingBottom:'0px',
    },
    col:{
        backgroundColor:'black',
        width:'50%',
        minWidth:'50%',
        display:'flex',
        justifyContent:'center',
        borderRadius:'8px'
    },
    coldiv:{
      display:'flex',
      justifyContent:'center'
    },
    size:{
        fontSize:'1.1rem',
        display:'inline-block'
    },
  root: {
    flexGrow: 1,
    
  },
  title:{
    fontFamily:'Barlow, sans-serif',
    fontSize:'1.6rem',
    textAlign:'center',
    paddingTop:'0px',
    paddingBottom:'0px',
    marginTop: '20px',
    marginBottom: '0px',
},
load:{
  fontFamily:'Barlow, sans-serif',
  fontSize:'1.6rem',
  textAlign:'center',
},
button: {
  backgroundColor: '#F6FBFE',
  fontFamily:'Barlow, sans-serif',
  border: 'none',
  color: 'black',
  padding: '8px 8px',
  textAlign: 'center',
  textDecoration: 'none',
  display: 'inline-block',
  fontSize: '16px',
  margin: '8px 8px',
  cursor: 'pointer',
  borderRadius: '8px',
  outline: 0,
  fontWeight:'bold',
},
agn:{
  marginLeft: 'auto',
marginRight: '0'
},
cent:{display: 'flex',
    justifyContent:'center',
    alignItems:'center',
    alignContent:'center',
    marginTop:'150px',
},
}));

export default function SimpleTabs(props) {
  const classes = useStyles();
  const [value, setValue] = React.useState(0);
  const [result, setResult] = useState([]);
  const [rateCustomer, setRateCustomer] = useState('None');
  const [loading, setLoading] = useState(true);
  const [customerPrev, setCustomerPrev] = useState('ALL');
  const [GeoData, setGeoData] = useState([{StAdd1: '',City: '',State: '',Zipcode: ''}])
  const [PlData, setPlData] = useState([{BATCH: "",BI_LIMIT: "",BPP_LIMIT: "",CARRIER: "",CITY: "",COMPANY_ASSOCIATION_BYLAWS: "Single entity",
  COMPANY_AUTO_HIRED: 0,COMPANY_DESIGNATED_LOCATION: "0",COMPANY_EMPLOYEE_BENEFITS_LIABILITY: "0",COMPANY_EQUIPMENT_BREAKDOWN: 1,COMPANY_GL_PREMIER_ENDORSEMENT: 1,
  COMPANY_ICEDAMMING: 1, COMPANY_LIMITATION_COVERAGE: 0,COMPANY_MAINTENANCEFEES: 0,COMPANY_NONSPRINKLERED: 1,COMPANY_NUMBER_ADDITIONAL_INSURED: "0",
  COMPANY_PROP_PREMIER_ENDORSEMENT: 0,COMPANY_TERRORISM: 1,COMPANY_WATERDAMAGE: 0,COUNTY: "",CUSTOMER_ID: "",EACH_OCCURRENCE_LIMIT_CD: "1,000,000 CSL",
  EFF_DATE: '',EXPIRING_PREMIUM: "",EXP_DATE: '',GENERAL_AGGREGATE_LIMIT_CD: "2,000,000 CSL",
  POLICY_NUMBER: "",PRODS_COMPLD_OPS_AGGREGATE_LIMIT_CD: "1,000,000 CSL",
  PROGRAM: "",PROPERTY_TYPE: "",STATE: "",STREET_ADDR: "",TIV: "",TOTAL_BUILDING_AMT_OF_INSURANCE: "",
  ZIP:"",IRPMBUILDINGFEATURES:0,IRPMEMPLOYEES:0,IRPMLOCATION:0,IRPMMANAGEMENT:0,IRPMPREMISESANDEQUIPMENT:0,
  IRPMPROTECTION:0,SCHEDULERATINGLOCATION:0,SCHEDULERATINGCOOPERATION:0,SCHEDULERATINGCLASSIFICATION:0,
  SCHEDULERATINGEMPLOYEES:0,SCHEDULERATINGEQUIPMENT:0,SCHEDULERATINGPREMISES:0}]);

  const [CpData, setCpData] = useState([{BGII_TERRITORY: "",BGI_TERRITORY: "",BI_LIMIT_OF_INSURANCE: 0,BPP_LIMIT_OF_INSURANCE: 0,
  BUILDING_NUM: 1,CITY: "",CLASS_CODE: "",CONSTRUCTION_TYPE: "",CONSTRUCTION_TYPE_TO_USE: "",COUNTY: "",
  CUSTOMER_ID: "",DISTRICT: "",ITERATION_NUMBER: 1,NUM_STORIES: "",PPC: "",
  PREMISES_NUM: 1,PROPERTY_DEDUCTIBLE: "",PROPERTY_LIMIT_OF_INSURANCE: 0,SEWER_BI: "0",SEWER_PD: "0",
  SPECIAL_TERRITORY: "",STATE: "",TERRORISM_TERRITORY: "",THEFT_DEDUCTIBLE: "",TOTAL_BUILDING_AREA: 0,
  WINDHAIL_DEDUCTIBLE: "",WINDSTORM_OR_HAIL_EXCLUDED: "0",}]);


  const [GlData, setGlData] = useState([{CLASS_CODE: 10105,
    CUSTOMER_ID: "",ITERATION_NUMBER: 1,OTHER_THAN_PRODS_COMPLD_OPS_COV: "Premises/Operations Including Prods/CompldOps",
    PREMISES_OPERATIONS_TERRITORY: "", PREM_OPS_BIPD_DEDUCTIBLE: "No Deductible",
    PREM_OPS_EXPOSURE: 0,PREM_OPS_PREMIUM_BASIS: "Sales",
    PRODS_COMPLD_OPS_BIPD_DEDUCTIBLE: "No Deductible",PRODS_COMPLD_OPS_COV: "Products/Completed Operations",
    PRODS_COMPLD_OPS_EXPOSURE: 0,PRODS_COMPLD_OPS_PREMIUM_BASIS: "Sales",PRODS_COMPLD_OPS_TERRITORY: "999",
    STATE: "",
    }]);


  const [data2, setData2] = useState([{
    bgIITerritory:{id: "", value: ""},terrorismTerritory:{id: "", value: ""},district:{id: "", value: ""},
    bgITerritory: {id: "", value: ""},city: "",county: "",cpTerritory: {id: "", value: ""},distanceToCoast: "",
    freezeScore: {id: "", value: ""},freezeScoreid:{id: "", value: ""},
    hailScore: {id: "", value: ""},latitude: "",longitude: "",ppc: {id: "", value: ""},
    sToRMi: {id: "", value: ""},specialTerritory: {id: "", value: ""},state: "",
    straightLineWindScore: {id: "", value: ""},street1: "",
    tornadoScore: {id: "", value: ""},premOpsTerritory: {id: "", value: ""},
    wildfireScore: {id: "", value: ""},
    windPoolScore: {id: "", value: ""},
    zipcode: "",plus4Code:"",rawFreezeScore:""}])

        useEffect( () => {
          axios.get(config.server_url + '/pl/customer')
          .then(response => {
              let result = response.data.map(a => a.CUSTOMER_ID);
              setResult(result)
              setLoading(false);
          })
          .catch((error) => {
              console.log(error);
          })
      }, []);

  const [isSubmit, setIsSubmit] = useState(false);
//   console.log('id=', props.match.params.id);
  

const handleChange = (event, newValue) => {
    setValue(newValue);
  };
  
  const onChangeFormGeoData = (e, fieldName) => {
    const newData = [...GeoData]
    newData[0] = {
        ...newData[0],
      [fieldName]: e.target.value
    }
    console.log(newData)
    setGeoData(newData)
  }

  const onChangeFormPlData = (index, e, fieldName) => {
    const newData = [...PlData]
    const newCpData = [...CpData]
    const newGlData = [...GlData]
    if (fieldName==='EFF_DATE') {
      console.log(e.target.value)
      var event = new Date(e.target.value);
      function convert(str) {
        var date = new Date(str),
          mnth = ("0" + (date.getMonth() + 1)).slice(-2),
          day = ("0" + (date.getDate()+1)).slice(-2);
        return [date.getFullYear()+1, mnth, day].join("-");
      }
      newData[index] = {
        ...newData[index],
      [fieldName]: e.target.value,['EXP_DATE']:convert(event)
    }
    setPlData(newData)
    }
    else if (fieldName==='CUSTOMER_ID') {
      newData[index] = {
        ...newData[index],
      [fieldName]: e.target.value
    }
    newCpData[index] = {
      ...newCpData[index],
    [fieldName]: e.target.value
  }
    newGlData[index] = {
      ...newGlData[index],
    [fieldName]: e.target.value
  }
    console.log(newData)
    console.log(newCpData)
    setCpData(newCpData)
    setGlData(newGlData)
    setPlData(newData)
    }
    else {
    newData[index] = {
        ...newData[index],
      [fieldName]: e.target.value
    }
    console.log(newData)
    setPlData(newData)
  }
  }

  const onChangeFormCpData = (index, e, fieldName) => {
    const newData = [...CpData]
    newData[index] = {
        ...newData[index],
      [fieldName]: e.target.value
    }
    console.log(newData)
    setCpData(newData)
  }

  const onChangeFormGlData = (index, e, fieldName) => {
    const newData = [...GlData]
    console.log(fieldName==='CLASS_CODE')
    if (fieldName==='CLASS_CODE') {
      console.log('Hi')
      switch(e.target.value.toString()) {
        case '10105':
          newData[index] = {
            ...newData[index],
          [fieldName]: e.target.value,['PREM_OPS_PREMIUM_BASIS']: 'Sales',['PRODS_COMPLD_OPS_PREMIUM_BASIS']: 'Sales'
        }
        console.log(newData)
        setGlData(newData)
        break;

        case '40072':
          newData[index] = {
            ...newData[index],
          [fieldName]: e.target.value,['PREM_OPS_PREMIUM_BASIS']: 'Each Beach',['PRODS_COMPLD_OPS_PREMIUM_BASIS']: 'Each Beach'
        }
        console.log(newData)
        setGlData(newData)
        break;

        case '41668':
          newData[index] = {
            ...newData[index],
          [fieldName]: e.target.value,['PREM_OPS_PREMIUM_BASIS']: 'Area',['PRODS_COMPLD_OPS_PREMIUM_BASIS']: 'Area'
        }
        console.log(newData)
        setGlData(newData)
        break;

        case '45524':
          newData[index] = {
            ...newData[index],
          [fieldName]: e.target.value,['PREM_OPS_PREMIUM_BASIS']: 'Each Lake or reservoir',['PRODS_COMPLD_OPS_PREMIUM_BASIS']: 'Each Lake or reservoir'
        }
        console.log(newData)
        setGlData(newData)
        break;

        case '46622':
          newData[index] = {
            ...newData[index],
          [fieldName]: e.target.value,['PREM_OPS_PREMIUM_BASIS']: 'Area',['PRODS_COMPLD_OPS_PREMIUM_BASIS']: 'Area'
        }
        console.log(newData)
        setGlData(newData)
        break;

        case '46671':
          newData[index] = {
            ...newData[index],
          [fieldName]: e.target.value,['PREM_OPS_PREMIUM_BASIS']: 'Each Park or Playground',['PRODS_COMPLD_OPS_PREMIUM_BASIS']: 'Each Park or Playground'
        }
        console.log(newData)
        setGlData(newData)
        break;

        case '48727':
          newData[index] = {
            ...newData[index],
          [fieldName]: e.target.value,['PREM_OPS_PREMIUM_BASIS']: 'Each Mile',['PRODS_COMPLD_OPS_PREMIUM_BASIS']: 'Each Mile'
        }
        console.log(newData)
        setGlData(newData)
        break;

        case '48925':
          newData[index] = {
            ...newData[index],
          [fieldName]: e.target.value,['PREM_OPS_PREMIUM_BASIS']: 'Each pool',['PRODS_COMPLD_OPS_PREMIUM_BASIS']: 'Each pool'
        }
        console.log(newData)
        setGlData(newData)
        break;

        case '61218':
          newData[index] = {
            ...newData[index],
          [fieldName]: e.target.value,['PREM_OPS_PREMIUM_BASIS']: 'Area',['PRODS_COMPLD_OPS_PREMIUM_BASIS']: 'Area'
        }
        console.log(newData)
        setGlData(newData)
        break;

        case '61225':
          newData[index] = {
            ...newData[index],
          [fieldName]: e.target.value,['PREM_OPS_PREMIUM_BASIS']: 'Area',['PRODS_COMPLD_OPS_PREMIUM_BASIS']: 'Area'
        }
        console.log(newData)
        setGlData(newData)
        break;

        case '62003':
          newData[index] = {
            ...newData[index],
          [fieldName]: e.target.value,['PREM_OPS_PREMIUM_BASIS']: 'Units',['PRODS_COMPLD_OPS_PREMIUM_BASIS']: 'Units'
        }
        console.log(newData)
        setGlData(newData)
        break;

        case '68500':
          newData[index] = {
            ...newData[index],
          [fieldName]: e.target.value,['PREM_OPS_PREMIUM_BASIS']: 'Units',['PRODS_COMPLD_OPS_PREMIUM_BASIS']: 'Units'
        }
        console.log(newData)
        setGlData(newData)
        break;

        case '68707':
          newData[index] = {
            ...newData[index],
          [fieldName]: e.target.value,['PREM_OPS_PREMIUM_BASIS']: 'Area',['PRODS_COMPLD_OPS_PREMIUM_BASIS']: 'Area'
        }
        console.log(newData)
        setGlData(newData)
        break;
      }
    }
    else {
    newData[index] = {
        ...newData[index],
      [fieldName]: e.target.value
    }
    console.log(newData)
    setGlData(newData)
  }
  }

  const failure = (reason) => {
    alert('Failed to save policy!' + ' Reason: ' + reason);
  }

  const failureGeo = (reason) => {
    alert('Failed to retrieve data!' + ' Reason: ' + reason);
  }

  const failureRate = (reason) => {
    alert('Failed to rate policy id: ' + rateCustomer + ' Reason: ' + reason);
  }

  const onGeoSubmit = (event) => {
    setIsSubmit(true)
    event.preventDefault();
    {console.log('submit!!')}

    const geoadd = {
        
        StAdd1:GeoData[0].StAdd1,
        City:GeoData[0].City,
        State:GeoData[0].State,
        Zipcode:GeoData[0].Zipcode,
    }
    console.log(geoadd);

    axios.post(config.server_url + '/geo/', geoadd)
            .then(res => {
                console.log(res.data)
                if(res && res.status === 200){
                    setData2(res.data);
                    console.log(res.data)
                    setIsSubmit(false)
                alert('Location details loaded successfully!');
                //   window.location = '/wb/pol'
                }
            }, rejection => {
                setIsSubmit(false)
                failureGeo('Request rejected! Have you input all details?');
                window.location = '/wb/cr'
            }).catch(error => {
                
                setIsSubmit(false)
                failureGeo('Error sending request due to invalid address.');
                window.location = '/wb/cr'
            });
            
}
  const onSubmitPl = (event,index) => {
    setIsSubmit(true)
    event.preventDefault();
    if(result.includes(PlData[index].CUSTOMER_ID) || customerPrev===PlData[index].CUSTOMER_ID) {
      setIsSubmit(false)
      alert("Customer ID already exists. Please enter a different Customer ID.")
      return;
    }
    const policy = {
        
        CUSTOMER_ID: PlData[index].CUSTOMER_ID,
        STATE: data2[0].state,
        COMPANY_AUTO_HIRED: PlData[index].COMPANY_AUTO_HIRED,
        COMPANY_GL_PREMIER_ENDORSEMENT: PlData[index].COMPANY_GL_PREMIER_ENDORSEMENT,
        COMPANY_LIMITATION_COVERAGE:PlData[index].COMPANY_LIMITATION_COVERAGE,
        COMPANY_TERRORISM:PlData[index].COMPANY_TERRORISM,
        EACH_OCCURRENCE_LIMIT_CD:PlData[index].EACH_OCCURRENCE_LIMIT_CD,
        GENERAL_AGGREGATE_LIMIT_CD:PlData[index].GENERAL_AGGREGATE_LIMIT_CD,
        PRODS_COMPLD_OPS_AGGREGATE_LIMIT_CD:PlData[index].PRODS_COMPLD_OPS_AGGREGATE_LIMIT_CD,
        COMPANY_DESIGNATED_LOCATION:PlData[index].COMPANY_DESIGNATED_LOCATION,
        COMPANY_EMPLOYEE_BENEFITS_LIABILITY:PlData[index].COMPANY_EMPLOYEE_BENEFITS_LIABILITY,
        COMPANY_NUMBER_ADDITIONAL_INSURED:PlData[index].COMPANY_NUMBER_ADDITIONAL_INSURED,
        TIV:PlData[index].TIV,
        TOTAL_BUILDING_AMT_OF_INSURANCE:PlData[index].TOTAL_BUILDING_AMT_OF_INSURANCE,
        BPP_LIMIT:PlData[index].BPP_LIMIT,
        BI_LIMIT:PlData[index].BI_LIMIT,
        CARRIER:PlData[index].CARRIER,
        BATCH:PlData[index].BATCH,
        STREET_ADDR:data2[0].street1,
        CITY:data2[0].city,
        ZIP:data2[0].zipcode,
        COUNTY:data2[0].county,
        PROGRAM:PlData[index].PROGRAM,
        POLICY_NUMBER:PlData[index].POLICY_NUMBER,
        EXPIRING_PREMIUM:PlData[index].EXPIRING_PREMIUM,
        EFF_DATE:PlData[index].EFF_DATE,
        EXP_DATE:PlData[index].EXP_DATE,
        PROPERTY_TYPE:PlData[index].PROPERTY_TYPE,
        COMPANY_PROP_PREMIER_ENDORSEMENT:PlData[index].COMPANY_PROP_PREMIER_ENDORSEMENT,
        COMPANY_MAINTENANCEFEES:PlData[index].COMPANY_MAINTENANCEFEES,
        COMPANY_WATERDAMAGE:PlData[index].COMPANY_WATERDAMAGE,
        COMPANY_ICEDAMMING:PlData[index].COMPANY_ICEDAMMING,
        COMPANY_EQUIPMENT_BREAKDOWN:PlData[index].COMPANY_EQUIPMENT_BREAKDOWN,
        COMPANY_NONSPRINKLERED:PlData[index].COMPANY_NONSPRINKLERED,
        COMPANY_ASSOCIATION_BYLAWS:PlData[index].COMPANY_ASSOCIATION_BYLAWS,
        IRPMBUILDINGFEATURES:PlData[index].IRPMBUILDINGFEATURES,
        IRPMEMPLOYEES:PlData[index].IRPMEMPLOYEES,
        IRPMLOCATION:PlData[index].IRPMLOCATION,
        IRPMMANAGEMENT:PlData[index].IRPMMANAGEMENT,
        IRPMPREMISESANDEQUIPMENT:PlData[index].IRPMPREMISESANDEQUIPMENT,
        IRPMPROTECTION:PlData[index].IRPMPROTECTION,
        SCHEDULERATINGLOCATION:PlData[index].SCHEDULERATINGLOCATION,
        SCHEDULERATINGCOOPERATION:PlData[index].SCHEDULERATINGCOOPERATION,
        SCHEDULERATINGCLASSIFICATION:PlData[index].SCHEDULERATINGCLASSIFICATION,
        SCHEDULERATINGEMPLOYEES:PlData[index].SCHEDULERATINGEMPLOYEES,
        SCHEDULERATINGEQUIPMENT:PlData[index].SCHEDULERATINGEQUIPMENT,
        SCHEDULERATINGPREMISES:PlData[index].SCHEDULERATINGPREMISES,

    }

    

    axios.post(config.server_url + '/pl/insert/' + PlData[index].CUSTOMER_ID, policy)
          .then(res => {
            if(res && res.status === 200){
              setIsSubmit(false)
              setCustomerPrev(PlData[index].CUSTOMER_ID)
              setRateCustomer(PlData[index].CUSTOMER_ID)
              alert('Policy Successfuly Saved!');
            //   window.location = '/wb/pol'
            }
          }, rejection => {
            setIsSubmit(false)
            failure('Request rejected for Policy!');
          }).catch(error => {
            setIsSubmit(false)
            failure('Error sending request.');
          });
    
  }

  const onSubmitCp = (event, index) => {
    setIsSubmit(true)
    event.preventDefault();
    axios.get(config.server_url + '/policy/customer/' + CpData[index].CUSTOMER_ID)
        .then(response => {
            const newdata = response.data.map(a => a.ITERATION_NUMBER.toString())
            console.log(newdata)
            console.log(CpData[index].ITERATION_NUMBER)
            if(newdata.includes(CpData[index].ITERATION_NUMBER.toString())) {
              setIsSubmit(false)
              alert("Customer ID with this iteration number already exists. Please enter a different iteration number.")
              return;
            }
            else {
            const policy = {
              
              CUSTOMER_ID: CpData[index].CUSTOMER_ID,
              ITERATION_NUMBER: CpData[index].ITERATION_NUMBER,
              BUILDING_NUM: CpData[index].BUILDING_NUM,
              PREMISES_NUM: CpData[index].PREMISES_NUM,
              CLASS_CODE: CpData[index].CLASS_CODE,
              NUM_STORIES: CpData[index].NUM_STORIES,
              THEFT_DEDUCTIBLE: CpData[index].THEFT_DEDUCTIBLE,
              WINDHAIL_DEDUCTIBLE: CpData[index].WINDHAIL_DEDUCTIBLE,
              PROPERTY_DEDUCTIBLE: CpData[index].PROPERTY_DEDUCTIBLE,
              WINDSTORM_OR_HAIL_EXCLUDED: CpData[index].WINDSTORM_OR_HAIL_EXCLUDED,
              SEWER_PD: CpData[index].SEWER_PD,
              SEWER_BI: CpData[index].SEWER_BI,
              PROPERTY_LIMIT_OF_INSURANCE: CpData[index].PROPERTY_LIMIT_OF_INSURANCE,
              TOTAL_BUILDING_AREA:CpData[index].TOTAL_BUILDING_AREA,
              STATE: data2[0].state,
              CITY: data2[0].city,
              COUNTY:data2[0].county,
              PPC: data2[0].ppc.value,
              DISTRICT: data2[0].district.value,
              BGI_TERRITORY:data2[0].bgITerritory.value,
              BGII_TERRITORY: data2[0].bgIITerritory.value,
              SPECIAL_TERRITORY: data2[0].specialTerritory.value,
              TERRORISM_TERRITORY: data2[0].terrorismTerritory.value,
              CONSTRUCTION_TYPE: CpData[index].CONSTRUCTION_TYPE,
              CONSTRUCTION_TYPE_TO_USE: CpData[index].CONSTRUCTION_TYPE_TO_USE,
              BI_LIMIT_OF_INSURANCE: CpData[index].BI_LIMIT_OF_INSURANCE,
              BPP_LIMIT_OF_INSURANCE: CpData[index].BPP_LIMIT_OF_INSURANCE
          }
          console.log(policy);
          axios.post(config.server_url + '/policy/insert/' + CpData[index].CUSTOMER_ID, policy)
                .then(res => {
                  if(res && res.status === 200){
                    setIsSubmit(false)
                    alert('Policy Successfuly Saved!');
                  //   window.location = '/wb/pol'
                  }
                }, rejection => {
                  setIsSubmit(false)
                  failure('Request rejected for CP! Have you entered Customer ID?');
                }).catch(error => {
                  setIsSubmit(false)
                  failure('Error sending request.');
                });
              }
        })
        .catch((error) => {
            console.log(error);
        })

  }

  const onSubmitGl = (event,index) => {
    setIsSubmit(true)
    event.preventDefault()
    axios.get(config.server_url + '/gl/customer/' + GlData[index].CUSTOMER_ID)
        .then(response => {
            const newdata = response.data.map(a => a.ITERATION_NUMBER.toString())
            console.log(newdata)
            console.log(GlData[index].ITERATION_NUMBER)
            if(newdata.includes(GlData[index].ITERATION_NUMBER.toString())) {
              setIsSubmit(false)
              alert("Customer ID with this iteration number already exists. Please enter a different iteration number.")
              return;
            }
            else {

        const policy = {
            
            CUSTOMER_ID: GlData[index].CUSTOMER_ID,
            STATE: data2[0].state,
            PREMISES_OPERATIONS_TERRITORY: data2[0].premOpsTerritory.value,
            PRODS_COMPLD_OPS_TERRITORY: GlData[index].PRODS_COMPLD_OPS_TERRITORY,
            ITERATION_NUMBER: GlData[index].ITERATION_NUMBER,
            CLASS_CODE: GlData[index].CLASS_CODE,
            PREM_OPS_EXPOSURE: GlData[index].PREM_OPS_EXPOSURE,
            PRODS_COMPLD_OPS_EXPOSURE: GlData[index].PRODS_COMPLD_OPS_EXPOSURE,
            PREM_OPS_PREMIUM_BASIS: GlData[index].PREM_OPS_PREMIUM_BASIS,
            PRODS_COMPLD_OPS_PREMIUM_BASIS: GlData[index].PRODS_COMPLD_OPS_PREMIUM_BASIS,
            PREM_OPS_BIPD_DEDUCTIBLE: GlData[index].PREM_OPS_BIPD_DEDUCTIBLE,
            PRODS_COMPLD_OPS_BIPD_DEDUCTIBLE: GlData[index].PRODS_COMPLD_OPS_BIPD_DEDUCTIBLE,
            PRODS_COMPLD_OPS_COV: GlData[index].PRODS_COMPLD_OPS_COV,
            OTHER_THAN_PRODS_COMPLD_OPS_COV: GlData[index].OTHER_THAN_PRODS_COMPLD_OPS_COV,
        }

        console.log(policy);

        axios.post(config.server_url + '/gl/insert/' + GlData[index].CUSTOMER_ID, policy)
            .then(res => {
                if(res && res.status === 200){
                  setIsSubmit(false)
                alert('Policy Successfuly Saved!');
                //   window.location = '/wb/pol'
                }
            }, rejection => {
              setIsSubmit(false)
                failure('Request rejected for GL! Have you entered Customer ID?');
            }).catch(error => {
              setIsSubmit(false)
                failure('Error sending request.');
            });
          }
        })
        .catch((error) => {
            console.log(error);
        })
    
  }

  const handleClick = (event) => {
    setIsSubmit(true)
    event.preventDefault();

    if (rateCustomer==='None') {
      alert("Please submit the policy first.")
      setIsSubmit(false)
      return;
    }

    axios.get(config.server_url + '/pl/rate/' + rateCustomer)
    .then(res => {
      if(res && res.status === 200){
        alert('Successfuly Rated!');}
        setIsSubmit(false)
    },rejection => {
      setIsSubmit(false)
        failureRate('Request rejected.');
    })
    .catch((error) => {
        console.log(error);
        setIsSubmit(false)
        failureRate('Error sending request.');
    })
  }

  const renderLoadingMessage = () => <div className={classes.cent}><CircularProgress color="primary" /></div>;
  const renderPolicyRows = () => {
    return PlData.map((currentpolicy, index) => {
    return <CreatePL geo2={data2} onChangeFormGeoData={onChangeFormGeoData} onGeoSubmit={onGeoSubmit} geo ={GeoData} policy={currentpolicy} index={index} onRate={handleClick} onSubmit={onSubmitPl} onChangeFormData={onChangeFormPlData} key={index}/>;
    })
}

  const renderCPRows = () => {
    return CpData.map((currentpolicy, index) => {
    return <CreateCP geo={data2} policy={currentpolicy} index={index} onSubmit={onSubmitCp} onChangeFormData={onChangeFormCpData} onRate={handleClick} key={index}/>;
    })
  }

  const renderGLRows = () => {
    return GlData.map((currentpolicy, index) => {
    return <CreateGL geo={data2} policy={currentpolicy} index={index} onSubmit={onSubmitGl} onChangeFormData={onChangeFormGlData} onRate={handleClick} key={index}/>;
    })
  }
  // console.log(PlData)

  return (
    <ThemeProvider theme={theme}>
      <CssBaseline />
    <div className={classes.root}>
        <div className={classes.coldiv}>
      <AppBar className={classes.col} position="static">
        <Tabs centered value={value} onChange={handleChange} aria-label="simple tabs example">
          <Tab className={classes.size} label="Policy Level" {...a11yProps(0)} />
          <Tab className={classes.size} label="Commercial Property" {...a11yProps(1)} />
          <Tab className={classes.size} label="General Liability" {...a11yProps(2)} />
        </Tabs>
      </AppBar>
      </div>
      <TabPanel value={value} index={0}>
      <div>
      <h3 className={classes.title}>Enter Policy Details for Customer: {PlData[0].CUSTOMER_ID}</h3>
        {loading? <div className={classes.cent}><CircularProgress color="primary" /></div> : isSubmit? <div className={classes.cent}><CircularProgress color="primary" /></div>:renderPolicyRows()}
        </div>
      </TabPanel>
      <TabPanel value={value} index={1}>
        <div>
        <h3 className={classes.title}>Enter CP Details for Customer: {CpData[0].CUSTOMER_ID}</h3>
        {isSubmit? <div className={classes.cent}><CircularProgress color="primary" /></div>:renderCPRows()}
        </div>
      </TabPanel>
      <TabPanel value={value} index={2}>
        <div>
        <h3 className={classes.title}>Enter GL Details for Customer: {GlData[0].CUSTOMER_ID}</h3>
        {isSubmit? <div className={classes.cent}><CircularProgress color="primary" /></div>: renderGLRows()}
        </div>
      </TabPanel>
    </div>
    </ThemeProvider>
  );
}
 