import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import Typography from '@material-ui/core/Typography';
import { Link, useLocation } from 'react-router-dom';

const useStyles = makeStyles((theme) => ({
    
    header:{
        backgroundColor:'black',
        color:'white',
    },
  root: {
    flexGrow: 1,
  },
  menuButton: {
    marginRight: theme.spacing(2),
  },
  title: {
    flexGrow: 1,
    color:'white',
    fontFamily:'Barlow, sans-serif',

  },
}));

export default function ButtonAppBar(props) {
  const classes = useStyles();

  return (
    <div className={classes.root}>
      <AppBar position="static" className={classes.header}>
        <Toolbar>
          <Typography variant="h5" className={classes.title}>
            LIO
          </Typography>
          <Typography variant="h6" className={classes.title} align="right">
              <Link to = '/wb' style={{ textDecoration: 'none', color: 'white' }}>
            Workbench</Link>
          </Typography>
          <Typography variant="h6" className={classes.title} align="right" style={{maxWidth: '100px'}}>
              <Link style={{ textDecoration: 'none', color: 'white' }}>
            {props.LoginBtn}</Link>
          </Typography>
        </Toolbar>
      </AppBar>
    </div>
    
  );
}