import React, { useEffect, useState } from 'react';
import axios from 'axios';
import { makeStyles, ThemeProvider, createMuiTheme } from '@material-ui/core/styles';
import { TextareaAutosize } from '@material-ui/core';
import TextField from '@material-ui/core/TextField'
import EditBar from './EditBar';
import clsx from 'clsx';
import config from "./../config"
import CircularProgress from '@material-ui/core/CircularProgress';
import Grid from '@material-ui/core/Grid';
import Select from '@material-ui/core/Select';
import FormControl from '@material-ui/core/FormControl';

let theme = createMuiTheme({
  palette: {
    primary: {
      light: '#63ccff',
      main: '#009be5',
      dark: '#006db3',
    },
  },
  typography: {
    fontFamily: 'Barlow, sans-serif',
    h5: {
      fontWeight: 500,
      fontSize: 26,
      letterSpacing: 0.5,
      fontFamily:'Barlow, sans-serif',
    },
  },
  shape: {
    borderRadius: 8,
  },
  props: {
    MuiTab: {
      disableRipple: true,
    },
  },
  mixins: {
    toolbar: {
      minHeight: 48,
    },
  },
});

theme = {
  ...theme,
  overrides: {
    MuiDataGrid: {
      colCellTitle: {
        overflowWrap: 'break-word',
        lineHeight: '1em',
        whiteSpace: 'break-spaces',
      },
    },
    MuiDrawer: {
      paper: {
        backgroundColor: 'black',
      },
    },
    MuiButton: {
      label: {
        textTransform: 'none',
      },
      contained: {
        boxShadow: 'none',
        '&:active': {
          boxShadow: 'none',
        },
      },
    },
    MuiTabs: {
      root: {
        marginLeft: theme.spacing(1),
      },
      indicator: {
        height: 3,
        borderTopLeftRadius: 3,
        borderTopRightRadius: 3,
        backgroundColor: theme.palette.common.white,
      },
    },
    MuiTab: {
      root: {
        textTransform: 'none',
        margin: '0 16px',
        minWidth: 0,
        padding: 0,
        [theme.breakpoints.up('md')]: {
          padding: 0,
          minWidth: 0,
        },
      },
    },
    MuiIconButton: {
      root: {
        padding: theme.spacing(1),
      },
    },
    MuiTooltip: {
      tooltip: {
        borderRadius: 4,
      },
    },
    MuiDivider: {
      root: {
        backgroundColor: '#404854',
      },
    },
    MuiListItemText: {
      primary: {fontSize: 25,
        fontWeight: theme.typography.fontWeightMedium,
      },
    },
    MuiListItemIcon: {
      root: {
        color: 'inherit',
        marginRight: 0,
        '& svg': {
          fontSize: 20,
        },
      },
    },
    MuiAvatar: {
      root: {
        width: 32,
        height: 32,
      },
    },
  },
};

const useStyles = makeStyles((theme) => ({
  root: {
    width: '100%',
  },
  button: {
    marginTop: '20px',
    marginRight: theme.spacing(1),
  },
  buttons: {
    display: 'flex',
    justifyContent: 'flex-end'
  },
  textarea: {
    width: '80%',
    fontFamily:'Barlow, sans-serif',
    fontSize:'1.2rem',
    textAlign:'center',
    borderStyle:'solid',
  outlineStyle:'solid',
  borderColor:'#4fc3f7',
  padding:'15px',
  boxShadow: '6px 6px 5px grey',
  },
  element:{
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    padding: '4px 4px 4px 4px',
    
  },
  head: {
    fontFamily:'Barlow, sans-serif',
    fontSize:'1.4rem',
    textAlign:'center'
  },
  feature: {
    fontFamily:'Barlow, sans-serif',
    fontSize:'1.4rem',
    textAlign:'center'
  },
  button: {
    backgroundColor: '#F6FBFE',
    fontFamily:'Barlow, sans-serif',
    border: 'none',
    color: 'black',
    padding: '8px 8px',
    textAlign: 'center',
    textDecoration: 'none',
    display: 'inline-block',
    fontSize: '16px',
    margin: '8px 8px',
    cursor: 'pointer',
    borderRadius: '8px',
    outline: 0,
    fontWeight:'bold',
    '&:hover': {
      background: "#4fc3f7",
   },
  },
  load:{
    fontFamily:'Barlow, sans-serif',
    fontSize:'1.6rem',
    textAlign:'center',
},
  space:{
    marginTop:'20px',
  },
  cent:{display: 'flex',
    justifyContent:'center',
    alignItems:'center',
    alignContent:'center',
    marginTop:'180px',
    color:'#4fc3f7',
},
fk:{
    display: 'flex',
    flexWrap:'wrap',
  justifyContent: 'space-between',
  alignItems: 'center',
  marginTop:'90px',
  borderStyle:'solid',
  outlineStyle:'solid',
  borderColor:'#4fc3f7',
  padding:'15px',
  boxShadow: '6px 6px 5px grey',
  marginLeft:'15px',
    marginRight:'15px'
},
formField:{
    fontFamily:'Barlow, sans-serif',
    display:'flex',
    justifyContent:'space-between',
    fontWeight:'bold',
    fontSize: '1.1rem',
},

}));


const EditStatus = (props) => {
    const [data, setFormData] = useState({});
    const [loading, setLoading] = useState(true);
    const classes = useStyles();

    //get request for data
    useEffect(() => {
      console.log('props.match.params.id', props.match.params.id);
      axios.get(config.server_url + '/sts/' + props.match.params.id)
      .then(response => {
          console.log(response.data);
          setFormData(response.data)
          setLoading(false);
      })
      .catch(function (error) {
        console.log(error);
      })
    }, [props.match.params.id])

  
  const onChangeFormData = (e, fieldName) => {
    const newData = [...data]
    newData[0] = {
        ...newData[0],
      [fieldName]: e.target.value
    }
    console.log(newData)
    setFormData(newData)
  }

  const failure = (reason) => {
    alert('Failed to edit customer ID: ' + props.match.params.id + ' Reason: ' + reason);
  }


  const onSubmit = (e) => {
    setLoading(true)
    e.preventDefault();

    const customer = {
    CUSTOMER_ID: data[0].CUSTOMER_ID,
    UPLOAD_APPROVED: data[0].UPLOAD_APPROVED,
    DOCUMENTS_EXTRACTED: data[0].DOCUMENTS_EXTRACTED,
    DOCUMENT_COMMENTS: data[0].DOCUMENT_COMMENTS,
    CODED: data[0].CODED,
    QUALITY_REVIEWED: data[0].QUALITY_REVIEWED,
    QUALITY_COMMENTS: data[0].QUALITY_COMMENTS,
    CODING_CYCLE: data[0].CODING_CYCLE,
    CODING_ELIGIBLE: data[0].CODING_ELIGIBLE,
    CODING_ISSUE: data[0].CODING_ISSUE,
    WILDFIRE_ACTION: data[0].WILDFIRE_ACTION,
    DISTANCE_TO_RELEVANT_WILDFIRE_BAND: data[0].DISTANCE_TO_RELEVANT_WILDFIRE_BAND,
    WILDFIRE_RESULT: data[0].WILDFIRE_RESULT,
    CONSTRUCTION_TIV_THRESHOLD: data[0].CONSTRUCTION_TIV_THRESHOLD,
    OTHER_UW_ELIGIBILITY: data[0].OTHER_UW_ELIGIBILITY,
    OTHER_UW_ELIGIBILITY_COMMENTS: data[0].OTHER_UW_ELIGIBILITY_COMMENTS,
    LOSS_RUNS: data[0].LOSS_RUNS,
    LOSS_RUNS_COMMENTS: data[0].LOSS_RUNS_COMMENTS,
    UW_COMMENTS: data[0].UW_COMMENTS,
    UW_ACTION_PENDING: data[0].UW_ACTION_PENDING,
    UW_ACTION: data[0].UW_ACTION,
    DATA_UPDATES_REQUIRED: data[0].DATA_UPDATES_REQUIRED,
    DATA_UPDATED_COMPLETED: data[0].DATA_UPDATED_COMPLETED,
    DATA_COMMENTS: data[0].DATA_COMMENTS,
    REFERRAL_TO_UW: data[0].REFERRAL_TO_UW,
    REFERRAL_TO_UW_COMMENTS: data[0].REFERRAL_TO_UW_COMMENTS,
    UW_DECISION: data[0].UW_DECISION,
    US_ISSUE: data[0].US_ISSUE,
    RATING_STATUS: data[0].RATING_STATUS,
    BROKER_STATUS: data[0].BROKER_STATUS,
    BROKER_REMOVAL_REASON: data[0].BROKER_REMOVAL_REASON,
    UW_PROPOSAL_LOCK: data[0].UW_PROPOSAL_LOCK,
    UW_BIND_LOCK: data[0].UW_BIND_LOCK,
    QUOTE_LOSS_REASON: data[0].QUOTE_LOSS_REASON,
    FAC_REQUIRED: data[0].FAC_REQUIRED,
    FAC_ACQUIRED: data[0].FAC_ACQUIRED,
    FAC_REINSURER: data[0].FAC_REINSURER,
    FAC_CERTIFICATE_NUMBER: data[0].FAC_CERTIFICATE_NUMBER,
    UPLOAD_COMMENTS: data[0].UPLOAD_COMMENTS,
    }

    console.log(customer);

    axios.post(config.server_url + '/sts/update/' + props.match.params.id, customer)
          .then(res => {
            if(res && res.status === 200){
              setLoading(false)
              alert('Successfuly Edited!');
              // window.location = '/wb/xml'
            }
          }, rejection => {
            setLoading(false)
            failure('Request rejected!');
          }).catch(error => {
            setLoading(false)
            failure('Error sending request.');
          });

    ;
  }

    return (<ThemeProvider theme={theme}><><EditBar />
    { loading ?
    <div className={classes.cent}><CircularProgress  /></div>
    : (<ThemeProvider theme={theme}>
        <div>
        <div className={classes.fk}>
        <form  id = "sts" onSubmit={(event) => onSubmit(event)}>
        <Grid container  direction='row' spacing={2}>
        <Grid item xs={3}>
            <div className={classes.formField}> 
                <label>Customer ID: </label>
                <TextField required = "true" label="Enter Customer ID" size ={'small'} value={data[0].CUSTOMER_ID} variant="outlined" onChange={(e) => onChangeFormData(e, 'CUSTOMER_ID')}/>
            </div>
            </Grid>
        
            <Grid item xs={3}>
                <div className={classes.formField}>
                <label>Upload Approved: </label>
                    <FormControl required = "true" size ={'small'} variant="outlined">
                        
                        <Select size ={'small'}
                        native
                        value={data[0].UPLOAD_APPROVED}
                        onChange={(e) => onChangeFormData(e, 'UPLOAD_APPROVED')}
                        >
                        <option value={'true'}>Yes</option>
                        <option value={'false'}>No</option>
                        </Select>
                </FormControl>
                </div>
                </Grid>

                <Grid item xs={3}>
                <div className={classes.formField}>
                <label>Documents Extracted: </label>
                    <FormControl required = "true" size ={'small'} variant="outlined">
                        
                        <Select size ={'small'}
                        native
                        value={data[0].DOCUMENTS_EXTRACTED}
                        onChange={(e) => onChangeFormData(e, 'DOCUMENTS_EXTRACTED')}
                        >
                        <option value={'true'}>Yes</option>
                        <option value={'false'}>No</option>
                        </Select>
                </FormControl>
                </div>
                </Grid>

                <Grid item xs={3}>
                    <div className={classes.formField}> 
                    <label>Document Comments: </label>
                    <TextField size ={'small'} value={data[0].DOCUMENT_COMMENTS} variant="outlined" onChange={(e) => onChangeFormData(e, 'DOCUMENT_COMMENTS')}/>
                    </div>
                </Grid>

                <Grid item xs={3}>
                <div className={classes.formField}>
                <label>Coded: </label>
                    <FormControl required = "true" size ={'small'} variant="outlined">
                        
                        <Select size ={'small'}
                        native
                        value={data[0].CODED}
                        onChange={(e) => onChangeFormData(e, 'CODED')}
                        >
                        <option value={'true'}>Yes</option>
                        <option value={'false'}>No</option>
                        </Select>
                </FormControl>
                </div>
                </Grid>

                <Grid item xs={3}>
                <div className={classes.formField}>
                <label>Quality Reviewed: </label>
                    <FormControl required = "true" size ={'small'} variant="outlined">
                        
                        <Select size ={'small'}
                        native
                        value={data[0].QUALITY_REVIEWED}
                        onChange={(e) => onChangeFormData(e, 'QUALITY_REVIEWED')}
                        >
                        <option value={'true'}>Yes</option>
                        <option value={'false'}>No</option>
                        </Select>
                </FormControl>
                </div>
                </Grid>

                <Grid item xs={3}>
                <div className={classes.formField}> 
                <label>Quality Comments: </label>
                <TextField size ={'small'} value={data[0].QUALITY_COMMENTS} variant="outlined" onChange={(e) => onChangeFormData(e, 'QUALITY_COMMENTS')}/>
                </div>
                </Grid>

                <Grid item xs={3}>
                <div className={classes.formField}> 
                <label>Coding Cycle: </label>
                <TextField size ={'small'} value={data[0].CODING_CYCLE} variant="outlined" onChange={(e) => onChangeFormData(e, 'CODING_CYCLE')}/>
                </div>
                </Grid>

                <Grid item xs={3}>
                <div className={classes.formField}>
                <label>Coding Eligible: </label>
                    <FormControl required = "true" size ={'small'} variant="outlined">
                        
                        <Select size ={'small'}
                        native
                        value={data[0].CODING_ELIGIBLE}
                        onChange={(e) => onChangeFormData(e, 'CODING_ELIGIBLE')}
                        >
                        <option value={'true'}>Yes</option>
                        <option value={'false'}>No</option>
                        </Select>
                </FormControl>
                </div>
                </Grid>

                <Grid item xs={3}>
                <div className={classes.formField}> 
                <label>Coding Issue: </label>
                <TextField size ={'small'} value={data[0].CODING_ISSUE} variant="outlined" onChange={(e) => onChangeFormData(e, 'CODING_ISSUE')}/>
                </div>
                </Grid>

                <Grid item xs={3}>
                <div className={classes.formField}> 
                <label>Wildfire Action: </label>
                <TextField size ={'small'} value={data[0].WILDFIRE_ACTION} variant="outlined" onChange={(e) => onChangeFormData(e, 'WILDFIRE_ACTION')}/>
                </div>
                </Grid>

                <Grid item xs={3}>
                <div className={classes.formField}> 
                <label>Distance to Wildfire Band: </label>
                <TextField size ={'small'} value={data[0].DISTANCE_TO_RELEVANT_WILDFIRE_BAND} variant="outlined" onChange={(e) => onChangeFormData(e, 'DISTANCE_TO_RELEVANT_WILDFIRE_BAND')}/>
                </div>
                </Grid>

                <Grid item xs={3}>
                <div className={classes.formField}> 
                <label>Wildfire Result: </label>
                <TextField size ={'small'} value={data[0].WILDFIRE_RESULT} variant="outlined" onChange={(e) => onChangeFormData(e, 'WILDFIRE_RESULT')}/>
                </div>
                </Grid>

                <Grid item xs={3}>
                <div className={classes.formField}> 
                <label>Construction TIV Threshold: </label>
                <TextField size ={'small'} value={data[0].CONSTRUCTION_TIV_THRESHOLD} variant="outlined" onChange={(e) => onChangeFormData(e, 'CONSTRUCTION_TIV_THRESHOLD')}/>
                </div>
                </Grid>

                <Grid item xs={3}>
                <div className={classes.formField}>
                <label>Other UW Eligibility: </label>
                    <FormControl required = "true" size ={'small'} variant="outlined">
                        
                        <Select size ={'small'}
                        native
                        value={data[0].OTHER_UW_ELIGIBILITY}
                        onChange={(e) => onChangeFormData(e, 'OTHER_UW_ELIGIBILITY')}
                        >
                        <option value={'true'}>Yes</option>
                        <option value={'false'}>No</option>
                        </Select>
                </FormControl>
                </div>
                </Grid>

                <Grid item xs={3}>
                <div className={classes.formField}> 
                <label>Other UW Eligibility Comments: </label>
                <TextField size ={'small'} value={data[0].OTHER_UW_ELIGIBILITY_COMMENTS} variant="outlined" onChange={(e) => onChangeFormData(e, 'OTHER_UW_ELIGIBILITY_COMMENTS')}/>
                </div>
                </Grid>

                <Grid item xs={3}>
                <div className={classes.formField}>
                <label>Loss Runs: </label>
                    <FormControl required = "true" size ={'small'} variant="outlined">
                        
                        <Select size ={'small'}
                        native
                        value={data[0].LOSS_RUNS}
                        onChange={(e) => onChangeFormData(e, 'LOSS_RUNS')}
                        >
                        <option value={'true'}>Yes</option>
                        <option value={'false'}>No</option>
                        </Select>
                </FormControl>
                </div>
                </Grid>

                <Grid item xs={3}>
                <div className={classes.formField}> 
                <label>Loss Runs Comments: </label>
                <TextField size ={'small'} value={data[0].LOSS_RUNS_COMMENTS} variant="outlined" onChange={(e) => onChangeFormData(e, 'LOSS_RUNS_COMMENTS')}/>
                </div>
                </Grid>

                <Grid item xs={3}>
                <div className={classes.formField}> 
                <label>UW Comments: </label>
                <TextField size ={'small'} value={data[0].UW_COMMENTS} variant="outlined" onChange={(e) => onChangeFormData(e, 'UW_COMMENTS')}/>
                </div>
                </Grid>

                <Grid item xs={3}>
                <div className={classes.formField}>
                <label>UW Action Pending: </label>
                    <FormControl required = "true" size ={'small'} variant="outlined">
                        
                        <Select size ={'small'}
                        native
                        value={data[0].UW_ACTION_PENDING}
                        onChange={(e) => onChangeFormData(e, 'UW_ACTION_PENDING')}
                        >
                        <option value={'true'}>Yes</option>
                        <option value={'false'}>No</option>
                        </Select>
                </FormControl>
                </div>
                </Grid>

                <Grid item xs={3}>
                <div className={classes.formField}> 
                <label>UW Action: </label>
                <TextField size ={'small'} value={data[0].UW_ACTION} variant="outlined" onChange={(e) => onChangeFormData(e, 'UW_ACTION')}/>
                </div>
                </Grid>


                <Grid item xs={3}>
                <div className={classes.formField}>
                <label>Data Updates Required: </label>
                    <FormControl required = "true" size ={'small'} variant="outlined">
                        
                        <Select size ={'small'}
                        native
                        value={data[0].UW_ACTION_PENDING}
                        onChange={(e) => onChangeFormData(e, 'UW_ACTION_PENDING')}
                        >
                        <option value={'true'}>Yes</option>
                        <option value={'false'}>No</option>
                        </Select>
                </FormControl>
                </div>
                </Grid>

                <Grid item xs={3}>
                <div className={classes.formField}>
                <label>Data Updates Completed: </label>
                    <FormControl required = "true" size ={'small'} variant="outlined">
                        
                        <Select size ={'small'}
                        native
                        value={data[0].DATA_UPDATED_COMPLETED}
                        onChange={(e) => onChangeFormData(e, 'DATA_UPDATED_COMPLETED')}
                        >
                        <option value={'true'}>Yes</option>
                        <option value={'false'}>No</option>
                        </Select>
                </FormControl>
                </div>
                </Grid>

                <Grid item xs={3}>
                <div className={classes.formField}> 
                <label>Data Comments: </label>
                <TextField size ={'small'} value={data[0].DATA_COMMENTS} variant="outlined" onChange={(e) => onChangeFormData(e, 'DATA_COMMENTS')}/>
                </div>
                </Grid>

                <Grid item xs={3}>
                <div className={classes.formField}>
                <label>Referral to UW: </label>
                    <FormControl required = "true" size ={'small'} variant="outlined">
                        
                        <Select size ={'small'}
                        native
                        value={data[0].REFERRAL_TO_UW}
                        onChange={(e) => onChangeFormData(e, 'REFERRAL_TO_UW')}
                        >
                        <option value={'true'}>Yes</option>
                        <option value={'false'}>No</option>
                        </Select>
                </FormControl>
                </div>
                </Grid>

                <Grid item xs={3}>
                <div className={classes.formField}> 
                <label>Referral to UW Comments: </label>
                <TextField size ={'small'} value={data[0].REFERRAL_TO_UW_COMMENTS} variant="outlined" onChange={(e) => onChangeFormData(e, 'REFERRAL_TO_UW_COMMENTS')}/>
                </div>
                </Grid>

                <Grid item xs={3}>
                <div className={classes.formField}>
                <label>UW Decision: </label>
                    <FormControl size ={'small'} variant="outlined">
                        
                        <Select size ={'small'}
                        native
                        value={data[0].UW_DECISION}
                        onChange={(e) => onChangeFormData(e, 'UW_DECISION')}
                        >
                        <option value={''}></option>
                        <option value={'Accept'}>Accept</option>
                        <option value={'Decline'}>Decline</option>
                        <option value={'Decline Property but accept GL'}>Decline Property but accept GL</option>
                        </Select>
                </FormControl>
                </div>
                </Grid>

                <Grid item xs={3}>
                <div className={classes.formField}> 
                <label>US Issue: </label>
                <TextField size ={'small'} value={data[0].US_ISSUE} variant="outlined" onChange={(e) => onChangeFormData(e, 'US_ISSUE')}/>
                </div>
                </Grid>

                <Grid item xs={3}>
                <div className={classes.formField}>
                <label>Rating Status: </label>
                    <FormControl size ={'small'} variant="outlined">
                        
                        <Select size ={'small'}
                        native
                        value={data[0].RATING_STATUS}
                        onChange={(e) => onChangeFormData(e, 'RATING_STATUS')}
                        >
                        <option value={''}></option>
                        <option value={'Released with Issues - Very Low'}>Released with Issues - Very Low</option>
                        <option value={'Released with Issues - Low'}>Released with Issues - Low</option>
                        <option value={'Released - In Range'}>Released - In Range</option>
                        <option value={'Released with Issues - High'}>Released with Issues - High</option>
                        <option value={'Released with Issues - Very High'}>Released with Issues - Very High</option>
                        </Select>
                </FormControl>
                </div>
                </Grid>

                <Grid item xs={3}>
                <div className={classes.formField}>
                <label>Broker Status: </label>
                    <FormControl size ={'small'} variant="outlined">
                        
                        <Select size ={'small'}
                        native
                        value={data[0].BROKER_STATUS}
                        onChange={(e) => onChangeFormData(e, 'BROKER_STATUS')}
                        >
                        <option value={''}></option>
                        <option value={'Active'}>Active</option>
                        <option value={'Approved'}>Approved</option>
                        <option value={'Partner Created'}>Partner Created</option>
                        <option value={'Prospect'}>Prospect</option>
                        <option value={'Terminated'}>Terminated</option>
                        </Select>
                </FormControl>
                </div>
                </Grid>

                <Grid item xs={3}>
                <div className={classes.formField}> 
                <label>Broker Removal Reason: </label>
                <TextField size ={'small'} value={data[0].BROKER_REMOVAL_REASON} variant="outlined" onChange={(e) => onChangeFormData(e, 'BROKER_REMOVAL_REASON')}/>
                </div>
                </Grid>

                <Grid item xs={3}>
                <div className={classes.formField}>
                <label>UW Proposal Lock: </label>
                    <FormControl required = "true" size ={'small'} variant="outlined">
                        
                        <Select size ={'small'}
                        native
                        value={data[0].UW_PROPOSAL_LOCK}
                        onChange={(e) => onChangeFormData(e, 'UW_PROPOSAL_LOCK')}
                        >
                        <option value={'true'}>Yes</option>
                        <option value={'false'}>No</option>
                        </Select>
                </FormControl>
                </div>
                </Grid>

                <Grid item xs={3}>
                <div className={classes.formField}>
                <label>UW Bind Lock: </label>
                    <FormControl required = "true" size ={'small'} variant="outlined">
                        
                        <Select size ={'small'}
                        native
                        value={data[0].UW_BIND_LOCK}
                        onChange={(e) => onChangeFormData(e, 'UW_BIND_LOCK')}
                        >
                        <option value={'true'}>Yes</option>
                        <option value={'false'}>No</option>
                        </Select>
                </FormControl>
                </div>
                </Grid>

                <Grid item xs={3}>
                <div className={classes.formField}>
                <label>Quote Loss Reason: </label>
                    <FormControl size ={'small'} variant="outlined">
                        
                        <Select size ={'small'}
                        native
                        value={data[0].QUOTE_LOSS_REASON}
                        onChange={(e) => onChangeFormData(e, 'QUOTE_LOSS_REASON')}
                        >
                        <option value={''}></option>
                        <option value={'Price'}>Price</option>
                        <option value={'Coverage'}>Coverage</option>
                        <option value={'Quoted Too Late'}>Quoted Too Late</option>
                        <option value={'Loyalty'}>Loyalty</option>
                        <option value={'LIO Broker Lost Account'}>LIO Broker Lost Account</option>
                        <option value={'Other'}>Other</option>
                        </Select>
                </FormControl>
                </div>
                </Grid>

                <Grid item xs={3}>
                <div className={classes.formField}>
                <label>FAC Required: </label>
                    <FormControl required = "true" size ={'small'} variant="outlined">
                        
                        <Select size ={'small'}
                        native
                        value={data[0].FAC_REQUIRED}
                        onChange={(e) => onChangeFormData(e, 'FAC_REQUIRED')}
                        >
                        <option value={'true'}>Yes</option>
                        <option value={'false'}>No</option>
                        </Select>
                </FormControl>
                </div>
                </Grid>

                <Grid item xs={3}>
                <div className={classes.formField}>
                <label>FAC Acquired: </label>
                    <FormControl required = "true" size ={'small'} variant="outlined">
                        
                        <Select size ={'small'}
                        native
                        value={data[0].FAC_ACQUIRED}
                        onChange={(e) => onChangeFormData(e, 'FAC_ACQUIRED')}
                        >
                        <option value={'true'}>Yes</option>
                        <option value={'false'}>No</option>
                        </Select>
                </FormControl>
                </div>
                </Grid>

                <Grid item xs={3}>
                <div className={classes.formField}> 
                <label>FAC Reinsurer: </label>
                <TextField size ={'small'} value={data[0].FAC_REINSURER} variant="outlined" onChange={(e) => onChangeFormData(e, 'FAC_REINSURER')}/>
                </div>
                </Grid>

                <Grid item xs={3}>
                <div className={classes.formField}> 
                <label>FAC Certificate Number: </label>
                <TextField size ={'small'} value={data[0].FAC_CERTIFICATE_NUMBER} variant="outlined" onChange={(e) => onChangeFormData(e, 'FAC_CERTIFICATE_NUMBER')}/>
                </div>
                </Grid>

                <Grid item xs={3}>
                <div className={classes.formField}> 
                <label>Upload Comments: </label>
                <TextField size ={'small'} value={data[0].UPLOAD_COMMENTS} variant="outlined" onChange={(e) => onChangeFormData(e, 'UPLOAD_COMMENTS')}/>
                </div>
                </Grid>


            </Grid>
        </form>
        </div>
    </div>
    </ThemeProvider>
  )}
    </></ThemeProvider>);
}

export default EditStatus;