import React from 'react';
import TextField from '@material-ui/core/TextField'
import Grid from '@material-ui/core/Grid';
import { makeStyles, ThemeProvider, createMuiTheme } from '@material-ui/core/styles';
import clsx from 'clsx';
import Select from '@material-ui/core/Select';
import FormControl from '@material-ui/core/FormControl';
import Checkbox from '@material-ui/core/Checkbox';
import FormControlLabel from '@material-ui/core/FormControlLabel';

const useStyles = makeStyles((theme) => ({
    
    col:{
        color:'black',
    },
    title:{
        fontFamily:'Barlow, sans-serif',
        fontSize:'1.5rem',
        textAlign:'center',
        paddingTop:'0px',
        paddingBottom:'0px',
        marginTop: '30px',
        marginBottom: '0px',
  },
  formField:{
    display:'flex',
    justifyContent:'space-between',
    fontWeight:'bold',
    fontSize: '1.1rem',
},
button: {
    backgroundColor: 'black',
    fontFamily:'Barlow, sans-serif',
    border: 'none',
    color: 'white',
    padding: '12px 15px',
    textAlign: 'center',
    textDecoration: 'none',
    display: 'inline-block',
    fontSize: '16px',
    margin: '4px 2px',
    cursor: 'pointer',
    borderRadius: '8px',
    outline: 0,
    fontWeight:'bold',
    '&:hover': {
        color: "#4fc3f7",
        boxShadow: '4px 4px 4px grey',
     },
  },
  feature: {
    display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        marginTop:'45px'
  },
  element:{
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'left',
    padding: '4px 4px 4px 4px',
  },
  fk:{
    display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  marginTop:'10px',
  borderStyle:'solid',
  outlineStyle:'solid',
  borderColor:'#4fc3f7',
  padding:'15px',
  boxShadow: '6px 6px 5px grey',
}
  }));

const CvgLevel = (props) => {
    const { geo,policy, onSubmit, onChangeFormData,onChangeCvgCheck,isCheckedA,isCheckedAO,isCheckedPP,
        isCheckedOPP,isCheckedNF,isCheckedML,isCheckedDA,isCheckedHA,isCheckedNAO} = props;
    const classes = useStyles();

    return (
        <div>
            
            <form id = "CVG" onSubmit={(event) => onSubmit(event,props.index)}>
            <h3 className={classes.title}>Limit Coverages</h3>
            <div className={classes.fk}>
            <Grid container  direction='row' spacing={2}>
            <Grid item xs={3}>
                <div className={classes.formField}> 
                    <label>Customer ID: </label>
                    <TextField required="true" label="Enter Customer ID" size ={'small'} value={policy.CUSTOMER_ID} variant="outlined" onChange={(e) => onChangeFormData(props.index,e, 'CUSTOMER_ID')}/>
                </div>
                </Grid>

                <Grid item xs={3}>
                <div className={classes.formField}> 
                    <label>GL General Agg Limit: </label>
                    <TextField type="number" size ={'small'} value={policy.GL_GENERAL_AGGREGATE_LIMIT_LIST} variant="outlined" onChange={(e) => onChangeFormData(props.index,e, 'GL_GENERAL_AGGREGATE_LIMIT_LIST')}/>
                </div>
                </Grid>

                <Grid item xs={3}>
                <div className={classes.formField}> 
                    <label>GL Products Completed Ops Aggregate: </label>
                    <TextField type="number" size ={'small'} value={policy.GL_PRODUCTS_COMPLETED_OPERATIONS_AGGREGATE} variant="outlined" onChange={(e) => onChangeFormData(props.index,e, 'GL_PRODUCTS_COMPLETED_OPERATIONS_AGGREGATE')}/>
                </div>
                </Grid>

                <Grid item xs={3}>
                <div className={classes.formField}> 
                    <label>GL Personal Advt Injury Liability: </label>
                    <TextField type="number" size ={'small'} value={policy.GL_PERSONAL_ADVERTISING_INJURY_LIABILITY_LIMIT_LIST} variant="outlined" onChange={(e) => onChangeFormData(props.index,e, 'GL_PERSONAL_ADVERTISING_INJURY_LIABILITY_LIMIT_LIST')}/>
                </div>
                </Grid>

                <Grid item xs={3}>
                <div className={classes.formField}> 
                    <label>GL Occurrence Limit: </label>
                    <TextField type="number" size ={'small'} value={policy.GL_EACH_OCCURRENCE_LIMIT_LIST} variant="outlined" onChange={(e) => onChangeFormData(props.index,e, 'GL_EACH_OCCURRENCE_LIMIT_LIST')}/>
                </div>
                </Grid>

                <Grid item xs={3}>
                <div className={classes.formField}> 
                    <label>GL Damage to Rented Premises Covg Limit: </label>
                    <TextField type="number" size ={'small'} value={policy.POLICYGL_GL_DAMAGE_TO_RENTED_PREMISES_COV_LIMIT} variant="outlined" onChange={(e) => onChangeFormData(props.index,e, 'POLICYGL_GL_DAMAGE_TO_RENTED_PREMISES_COV_LIMIT')}/>
                </div>
                </Grid>

                <Grid item xs={3}>
                <div className={classes.formField}> 
                    <label>GL Medical Expense Limit: </label>
                    <TextField type="number" size ={'small'} value={policy.GL_MEDICAL_EXPENSE_LIMIT_LIST} variant="outlined" onChange={(e) => onChangeFormData(props.index,e, 'GL_MEDICAL_EXPENSE_LIMIT_LIST')}/>
                </div>
                </Grid>

                <Grid item xs={3}>
                <div className={classes.formField}> 
                    <label>GL Enhancement Ind: </label>
                    <TextField  size ={'small'} value={policy.GL_ENHANCEMENT_IND} variant="outlined" onChange={(e) => onChangeFormData(props.index,e, 'GL_ENHANCEMENT_IND')}/>
                </div>
                </Grid>

                </Grid>
                </div>

                <h3 className={classes.title}>Additional Coverages</h3>
                <div className={classes.fk}>
                <Grid container  direction='row' spacing={2}>
                <Grid item xs={3}>
                <div className={classes.formField}>
                <label>Auto Type: </label>
                </div></Grid>

                <Grid item xs={3}>
                            <FormControlLabel 
                            control={
                            <Checkbox 
                                checked={isCheckedA}
                                onChange= {(e) => onChangeCvgCheck(e,'A')}
                                color="primary"
                            />
                            }
                            label="Any Auto"
                        />
                        </Grid>

                        <Grid item xs={3}>
                            <FormControlLabel 
                            control={
                            <Checkbox 
                                checked={isCheckedAO}
                                onChange= {(e) => onChangeCvgCheck(e,'AO')}
                                color="primary"
                            />
                            }
                            label="Owned Autos Only"
                        />
                        </Grid>

                        <Grid item xs={3}>
                            <FormControlLabel 
                            control={
                            <Checkbox 
                                checked={isCheckedPP}
                                onChange= {(e) => onChangeCvgCheck(e,'PP')}
                                color="primary"
                            />
                            }
                            label="Owned Private Passenger Autos Only"
                        />
                        </Grid>

                        <Grid item xs={3}>
                            <FormControlLabel 
                            control={
                            <Checkbox 
                                checked={isCheckedOPP}
                                onChange= {(e) => onChangeCvgCheck(e,'OPP')}
                                color="primary"
                            />
                            }
                            label="Owned Autos Other than Private Passenger Autos Only"
                        />
                        </Grid>

                        <Grid item xs={3}>
                            <FormControlLabel 
                            control={
                            <Checkbox 
                                checked={isCheckedNF}
                                onChange= {(e) => onChangeCvgCheck(e,'NF')}
                                color="primary"
                            />
                            }
                            label="Owned Autos Subject to a No-Fault"
                        />
                        </Grid>

                        <Grid item xs={3}>
                            <FormControlLabel 
                            control={
                            <Checkbox 
                                checked={isCheckedML}
                                onChange= {(e) => onChangeCvgCheck(e,'ML')}
                                color="primary"
                            />
                            }
                            label="Owned Autos Subject to a Compulsory Uninsured Motorists Law"
                        />
                        </Grid>

                        <Grid item xs={3}>
                            <FormControlLabel 
                            control={
                            <Checkbox 
                                checked={isCheckedDA}
                                onChange= {(e) => onChangeCvgCheck(e,'DA')}
                                color="primary"
                            />
                            }
                            label="Specifically Described Autos"
                        />
                        </Grid>

                        <Grid item xs={3}>
                            <FormControlLabel 
                            control={
                            <Checkbox 
                                checked={isCheckedHA}
                                onChange= {(e) => onChangeCvgCheck(e,'HA')}
                                color="primary"
                            />
                            }
                            label="Hired Autos Only"
                        />
                        </Grid>

                        <Grid item xs={3}>
                            <FormControlLabel 
                            control={
                            <Checkbox 
                                checked={isCheckedNAO}
                                onChange= {(e) => onChangeCvgCheck(e,'NAO')}
                                color="primary"
                            />
                            }
                            label="Non-Owned Autos Only"
                        />
                        </Grid>


                <Grid item xs={3}>
                <div className={classes.formField}>
                <label>Terrorism Indicator: </label>
                    <FormControl size ={'small'} variant='outlined'>
                    
                        <Select
                        native
                        value={policy.TERRORISM_IND}
                        onChange={(e) => onChangeFormData(props.index,e, 'TERRORISM_IND')}
                        >
                        <option value={''}></option>
                        <option value={1}>Yes</option>
                        <option value={0}>No</option>
                        </Select>
                    </FormControl>
                </div></Grid>

                <Grid item xs={3}>
                <div className={classes.formField}>
                <label>Comp Prop Premier Endorsement: </label>
                    <FormControl size ={'small'} variant='outlined'>
                    
                        <Select
                        native
                        value={policy.COMPANYPROPPREMIERENDORSEMENT}
                        onChange={(e) => onChangeFormData(props.index,e, 'COMPANYPROPPREMIERENDORSEMENT')}
                        >
                        <option value={''}></option>
                        <option value={'Yes'}>Yes</option>
                        <option value={'No'}>No</option>
                        </Select>
                    </FormControl>
                </div></Grid>

                {/* <Grid item xs={3}>
                <div className={classes.formField}>
                <label>Ordinance or Law Covg A Indicator: </label>
                    <FormControl size ={'small'} variant='outlined'>
                    
                        <Select
                        native
                        value={policy.ORDINANCE_OR_LAW_COVERAGE_A_INDICATOR}
                        onChange={(e) => onChangeFormData(props.index,e, 'ORDINANCE_OR_LAW_COVERAGE_A_INDICATOR')}
                        >
                        <option value={''}></option>
                        <option value={'Yes'}>Yes</option>
                        <option value={'No'}>No</option>
                        </Select>
                    </FormControl>
                </div></Grid> */}

                <Grid item xs={3}>
                <div className={classes.formField}> 
                    <label>Ordinance or Law Covg B Limit: </label>
                    <TextField  type = "number" size ={'small'} value={policy.ORDINANCE_OR_LAW_COVERAGE_B_LIMIT} variant="outlined" onChange={(e) => onChangeFormData(props.index,e, 'ORDINANCE_OR_LAW_COVERAGE_B_LIMIT')}/>
                </div>
                </Grid>

                <Grid item xs={3}>
                <div className={classes.formField}> 
                    <label>Ordinance or Law Covg C Limit: </label>
                    <TextField  type = "number" size ={'small'} value={policy.ORDINANCE_OR_LAW_COVERAGE_C_LIMIT} variant="outlined" onChange={(e) => onChangeFormData(props.index,e, 'ORDINANCE_OR_LAW_COVERAGE_C_LIMIT')}/>
                </div>
                </Grid>

                {/* <Grid item xs={3}>
                <div className={classes.formField}>
                <label>Business Income Indicator: </label>
                    <FormControl size ={'small'} variant='outlined'>
                    
                        <Select
                        native
                        value={policy.BUSINESS_INCOMEEXTRA_EXPENSE_INDICATOR}
                        onChange={(e) => onChangeFormData(props.index,e, 'BUSINESS_INCOMEEXTRA_EXPENSE_INDICATOR')}
                        >
                        <option value={''}></option>
                        <option value={'Yes'}>Yes</option>
                        <option value={'No'}>No</option>
                        </Select>
                    </FormControl>
                </div></Grid> */}

                <Grid item xs={3}>
                <div className={classes.formField}> 
                    <label>Business Income Limit: </label>
                    <TextField type ="number" size ={'small'} value={policy.BUSINESS_INCOME_WITH_EXTRA_EXPENSE_LIMIT} variant="outlined" onChange={(e) => onChangeFormData(props.index,e, 'BUSINESS_INCOME_WITH_EXTRA_EXPENSE_LIMIT')}/>
                </div>
                </Grid>

                {/* <Grid item xs={3}>
                <div className={classes.formField}>
                <label>Personal Property Indicator: </label>
                    <FormControl size ={'small'} variant='outlined'>
                    
                        <Select
                        native
                        value={policy.PERSONAL_PROPERTY_INDICATOR}
                        onChange={(e) => onChangeFormData(props.index,e, 'PERSONAL_PROPERTY_INDICATOR')}
                        >
                        <option value={''}></option>
                        <option value={'Yes'}>Yes</option>
                        <option value={'No'}>No</option>
                        </Select>
                    </FormControl>
                </div></Grid> */}

                <Grid item xs={3}>
                <div className={classes.formField}> 
                    <label>Personal Property Limit: </label>
                    <TextField  type="number" size ={'small'} value={policy.PERSONAL_PROPERTY_LIMIT} variant="outlined" onChange={(e) => onChangeFormData(props.index,e, 'PERSONAL_PROPERTY_LIMIT')}/>
                </div>
                </Grid>

                </Grid>
                </div>

            </form>
            
            <div className={classes.feature }>
                    <input form="CVG" type="submit" value="Save CC" className={classes.button} />
                </div>
        </div>
    )
}

export default CvgLevel;