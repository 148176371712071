import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import axios from 'axios';
import { makeStyles, ThemeProvider, createMuiTheme } from '@material-ui/core/styles';
import {orange} from '@material-ui/core/colors'
import GenericDataGrid from './GenericDataGrid';
import AppBar from './AppBar';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';
import { PowerBIEmbed } from 'powerbi-client-react';
import { models } from 'powerbi-client';
import './PowerBI.css';

const useStyles = makeStyles((theme) => ({
    
  col:{
      color:'black',
  },
  title:{
    fontFamily:'Barlow, sans-serif',
    fontSize:'1.6rem',
    textAlign:'center',
    marginTop:'3px',
    marginBottom:'10px'
},

textleft:{
    fontFamily:'Barlow, sans-serif',
    fontSize:'1.6rem',
    textAlign:'left',
},

load:{
    fontFamily:'Barlow, sans-serif',
    fontSize:'1.6rem',
    textAlign:'center',
},
container:{
    height:'100%',
    width:'100%',
    display:"flex",
    justifyContent: "center", 
    alignItems:"flex-start", 
    alignContent:"center", 
    flexWrap:"wrap", 
    flexDirection:"row"
},
}));

const HomePage = () => {
const classes = useStyles();


const [loading, setLoading] = useState(false);



const renderLoadingMessage = () => <h4 className={classes.load}> Loading... </h4>;
// console.log(policies);

  const renderBI = () => (
    <div className={classes.container}>
            <PowerBIEmbed 
                // style={{
                //     height: '95vh'
                // }}
            // className={classes.container}
            embedConfig = {{
                type: 'report',   // Supported types: report, dashboard, tile, visual and qna
                id: '21bdf4a6-6a8a-40b7-afc0-61baf026d898',
                embedUrl: "https://app.powerbi.com/reportEmbed?reportId=21bdf4a6-6a8a-40b7-afc0-61baf026d898&groupId=1129165b-5e60-4d50-9e32-272e73f38b0f&w=2&config=eyJjbHVzdGVyVXJsIjoiaHR0cHM6Ly9XQUJJLVVTLVdFU1QyLXJlZGlyZWN0LmFuYWx5c2lzLndpbmRvd3MubmV0IiwiZW1iZWRGZWF0dXJlcyI6eyJtb2Rlcm5FbWJlZCI6dHJ1ZX19",
                accessToken: 'eyJ0eXAiOiJKV1QiLCJhbGciOiJSUzI1NiIsIng1dCI6Im5PbzNaRHJPRFhFSzFqS1doWHNsSFJfS1hFZyIsImtpZCI6Im5PbzNaRHJPRFhFSzFqS1doWHNsSFJfS1hFZyJ9.eyJhdWQiOiJodHRwczovL2FuYWx5c2lzLndpbmRvd3MubmV0L3Bvd2VyYmkvYXBpIiwiaXNzIjoiaHR0cHM6Ly9zdHMud2luZG93cy5uZXQvNDEyMWIwNmMtZTY2Zi00NGU0LTg1OWQtNmQ5MTY3NmY4OTc0LyIsImlhdCI6MTYxNjA5OTkwMiwibmJmIjoxNjE2MDk5OTAyLCJleHAiOjE2MTYxMDM4MDIsImFjY3QiOjAsImFjciI6IjEiLCJhaW8iOiJBVFFBeS84VEFBQUFNZFpSeVFpVW9meEIrT1RTY1NiTjQyeGhnTHJsanNGQ2w2bGw3ajM2QjRYQkJtTFlQd3d2dHg0aHJVYWFsdzRPIiwiYW1yIjpbInB3ZCJdLCJhcHBpZCI6Ijg3MWMwMTBmLTVlNjEtNGZiMS04M2FjLTk4NjEwYTdlOTExMCIsImFwcGlkYWNyIjoiMiIsImZhbWlseV9uYW1lIjoiU2hhbWFzdW5kYXIiLCJnaXZlbl9uYW1lIjoiU2hyZXlhcyIsImlwYWRkciI6Ijk3LjEyNi41OS4xMTAiLCJuYW1lIjoiU2hyZXlhcyBTaGFtYXN1bmRhciIsIm9pZCI6ImU0ZmVhM2NmLTNiZTUtNDg2NC1hZDU3LTg2MzljNTllZmRmMCIsInB1aWQiOiIxMDAzMjAwMTBFNDlBNTY5IiwicmgiOiIwLkFBQUFiTEFoUVdfbTVFU0ZuVzJSWjItSmRBOEJISWRoWHJGUGc2eVlZUXAta1JCM0FDRS4iLCJzY3AiOiJ1c2VyX2ltcGVyc29uYXRpb24iLCJzdWIiOiI5RE9uYnZBTVF0NE1pNlRnRDlfb1l0UkdCR3g4QWlSakoxVDJPQkVvVGtJIiwidGlkIjoiNDEyMWIwNmMtZTY2Zi00NGU0LTg1OWQtNmQ5MTY3NmY4OTc0IiwidW5pcXVlX25hbWUiOiJzaHJleWFzLnNoYW1hc3VuZGFyQGxpb2luc3VyYW5jZS5jb20iLCJ1cG4iOiJzaHJleWFzLnNoYW1hc3VuZGFyQGxpb2luc3VyYW5jZS5jb20iLCJ1dGkiOiJHNXJZUW5ON0cweTF2eW1RY3BuVkFBIiwidmVyIjoiMS4wIiwid2lkcyI6WyJiNzlmYmY0ZC0zZWY5LTQ2ODktODE0My03NmIxOTRlODU1MDkiXX0.OyPeignnuMrkoaC1G_hvapzVQDDDgCrHN1wu-AbcNipspQP0-Ef8VFXG83KaPPSFEF80ZGkz4vHxNn27rIVUklh1n0DAUOYrthEEM9a5QjesA_h1GfJkovwsOUSDRyLKkgdfoK5gHkWDg7p58AtdZLCjEResyXZc5_vpTs289gMMCS5MmvCohNVvA_uJ_sbcEl7QesloAkMB1t1SoqXpGUBsugQkW1CneOO-fK74PO8ZXypZ2_85leZ04qIkN8kSaVd9t4Wpmq641XYf4q7-2-R17ErUJYxxYL05bcMiupzI-MLhKLz6z5KSDVH70Eouzuj4za-Wzw7g3PAG1A7MGQ',
                tokenType: models.TokenType.Aad,
                settings: {
                    panes: {
                        filters: {
                            expanded: false,
                            visible: false
                        }
                    },
                    background: models.BackgroundType.Transparent,
                }
            }}

            eventHandlers = { 
                new Map([
                    ['loaded', function () {console.log('Report loaded');}],
                    ['rendered', function () {console.log('Report rendered');}],
                    ['error', function (event) {console.log(event.detail);}]
                ])
            }
                
            cssClassName = { "report-style-class" }

            getEmbeddedComponent = { (embeddedReport) => {
                window.report = embeddedReport;
            }}
        />
    </div>
  
  );


  return (<>
    <h3 className={classes.title}>Rating Analysis</h3>
   {
       loading ? renderLoadingMessage() : renderBI()
   }
</>);
}

export default HomePage;