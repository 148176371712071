import React, { useEffect, useState } from 'react';
import axios from 'axios';
import { makeStyles, ThemeProvider, createMuiTheme } from '@material-ui/core/styles';
import { TextareaAutosize } from '@material-ui/core';
import TextField from '@material-ui/core/TextField'
import XmlBar from '../components/XmlBar';
import clsx from 'clsx';
import config from "./../config";
import CircularProgress from '@material-ui/core/CircularProgress';

let theme = createMuiTheme({
  palette: {
    primary: {
      light: '#63ccff',
      main: '#009be5',
      dark: '#006db3',
    },
  },
  typography: {
    fontFamily: 'Barlow, sans-serif',
    h5: {
      fontWeight: 500,
      fontSize: 26,
      letterSpacing: 0.5,
      fontFamily:'Barlow, sans-serif',
    },
  },
  shape: {
    borderRadius: 8,
  },
  props: {
    MuiTab: {
      disableRipple: true,
    },
  },
  mixins: {
    toolbar: {
      minHeight: 48,
    },
  },
});

theme = {
  ...theme,
  overrides: {
    MuiDataGrid: {
      colCellTitle: {
        overflowWrap: 'break-word',
        lineHeight: '1em',
        whiteSpace: 'break-spaces',
      },
    },
    MuiDrawer: {
      paper: {
        backgroundColor: 'black',
      },
    },
    MuiButton: {
      label: {
        textTransform: 'none',
      },
      contained: {
        boxShadow: 'none',
        '&:active': {
          boxShadow: 'none',
        },
      },
    },
    MuiTabs: {
      root: {
        marginLeft: theme.spacing(1),
      },
      indicator: {
        height: 3,
        borderTopLeftRadius: 3,
        borderTopRightRadius: 3,
        backgroundColor: theme.palette.common.white,
      },
    },
    MuiTab: {
      root: {
        textTransform: 'none',
        margin: '0 16px',
        minWidth: 0,
        padding: 0,
        [theme.breakpoints.up('md')]: {
          padding: 0,
          minWidth: 0,
        },
      },
    },
    MuiIconButton: {
      root: {
        padding: theme.spacing(1),
      },
    },
    MuiTooltip: {
      tooltip: {
        borderRadius: 4,
      },
    },
    MuiDivider: {
      root: {
        backgroundColor: '#404854',
      },
    },
    MuiListItemText: {
      primary: {fontSize: 25,
        fontWeight: theme.typography.fontWeightMedium,
      },
    },
    MuiListItemIcon: {
      root: {
        color: 'inherit',
        marginRight: 0,
        '& svg': {
          fontSize: 20,
        },
      },
    },
    MuiAvatar: {
      root: {
        width: 32,
        height: 32,
      },
    },
  },
};

const useStyles = makeStyles((theme) => ({
  root: {
    width: '100%',
  },
  button: {
    marginTop: '20px',
    marginRight: theme.spacing(1),
  },
  buttons: {
    display: 'flex',
    justifyContent: 'flex-end'
  },
  textarea: {
    width: '80%',
    fontFamily:'Barlow, sans-serif',
    fontSize:'1.2rem',
    textAlign:'center',
    borderStyle:'solid',
  outlineStyle:'solid',
  borderColor:'#4fc3f7',
  padding:'15px',
  boxShadow: '6px 6px 5px grey',
  },
  element:{
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    padding: '4px 4px 4px 4px',
    
  },
  head: {
    fontFamily:'Barlow, sans-serif',
    fontSize:'1.4rem',
    textAlign:'center'
  },
  feature: {
    fontFamily:'Barlow, sans-serif',
    fontSize:'1.4rem',
    textAlign:'center'
  },
  button: {
    backgroundColor: '#F6FBFE',
    fontFamily:'Barlow, sans-serif',
    border: 'none',
    color: 'black',
    padding: '8px 8px',
    textAlign: 'center',
    textDecoration: 'none',
    display: 'inline-block',
    fontSize: '16px',
    margin: '8px 8px',
    cursor: 'pointer',
    borderRadius: '8px',
    outline: 0,
    fontWeight:'bold',
    '&:hover': {
      background: "#4fc3f7",
   },
  },
  load:{
    fontFamily:'Barlow, sans-serif',
    fontSize:'1.6rem',
    textAlign:'center',
},
  space:{
    marginTop:'20px',
  },
  cent:{display: 'flex',
    justifyContent:'center',
    alignItems:'center',
    alignContent:'center',
    marginTop:'180px',
    color:'#4fc3f7',
},
}));


const EditPolicy = (props) => {
    const [formData, setFormData] = useState({});
    const [loading, setLoading] = useState(true);
    const classes = useStyles();

    //get request for data
    useEffect(() => {
      console.log('props.match.params.id', props.match.params.id);
      axios.get(config.server_url + '/xml/' + props.match.params.id)
      .then(response => {
          console.log('response.data[0]', response.data[0]);
          setLoading(false);
          setFormData({
            dv_s3_key: response.data[0].DV_S3FILE_KEY,
            bucket:response.data[0].BUCKET,
            object_key:response.data[0].OBJECT_KEY,
            dv_load_dts:response.data[0].DV_LOAD_DTS,
            dv_rec_src:response.data[0].DV_REC_SRC,
            properties:response.data[0].PROPERTIES,
            payload: response.data[0].PAYLOAD,
          })
      })
      .catch(function (error) {
        console.log(error);
      })
    }, [props.match.params.id])

  const onChangeFormData = (e, fieldName) => {
    setFormData({
      ...formData,
      [`${fieldName}`]: e.target.value
    });
  }

  const failure = (reason) => {
    alert('Failed to edit policy id' + props.match.params.id + ' Reason:' + reason);
  }


  const onSubmit = (e) => {
    setLoading(true)
    e.preventDefault();

    const policy = {
      DV_S3FILE_KEY: formData.dv_s3_key,
      BUCKET:formData.bucket,
      OBJECT_KEY:formData.object_key,
      DV_LOAD_DTS:formData.dv_load_dts,
      DV_REC_SRC:formData.dv_rec_src,
      PROPERTIES:formData.properties,
      PAYLOAD: formData.payload,
    }

    console.log(policy);

    axios.post(config.server_url + '/xml/insert/' + props.match.params.id, policy)
          .then(res => {
            if(res && res.status === 200){
              setLoading(false)
              alert('Successfuly Edited!');
              // window.location = '/wb/xml'
            }
          }, rejection => {
            setLoading(false)
            failure('Request rejected!');
          }).catch(error => {
            setLoading(false)
            failure('Error sending request.');
          });

    ;
  }

    return (<ThemeProvider theme={theme}><><XmlBar />
    { loading ?
    <div className={classes.cent}><CircularProgress  /></div>
    : (<div className={classes.space}>
    <form id="xml" onSubmit={onSubmit}>
      <div className={classes.element}>
    <label className={classes.feature}>DV_S3FILE_KEY: </label>
    </div>
      <div className={classes.element}> 
        <TextareaAutosize className={classes.textarea} fullWidth='true' value={formData.dv_s3_key} variant="outlined" onChange={(e) => onChangeFormData(e, 'dv_s3_key')}/>
      </div>
      <div className={classes.element}>
      <label className={classes.feature}>XML: </label>
      </div>
      <div className={classes.element}>
        <TextareaAutosize value={formData.payload} className={classes.textarea} onChange={(e) => onChangeFormData(e, 'payload')}/>
      </div>
    </form>
  </div>
  )}
    </></ThemeProvider>);
}

export default EditPolicy;