import React, { useState, useEffect } from 'react';
import { DataGrid,GridToolbar,GridToolbarContainer,GridToolbarExport } from '@material-ui/data-grid';
import { Link } from 'react-router-dom';
import Button from '@material-ui/core/Button';
import axios from 'axios';
import PropTypes from 'prop-types';
import { makeStyles, ThemeProvider, createMuiTheme } from '@material-ui/core/styles';
import './GenericDataGrid.css';
import CircularProgress from '@material-ui/core/CircularProgress';

const useStyles = makeStyles((theme) => ({
    
    col:{
        color:'black',
    },
    title:{
      fontFamily:'Barlow, sans-serif',
      fontSize:'1.6rem',
      textAlign:'center',
  },
  load:{
    fontFamily:'Barlow, sans-serif',
    fontSize:'1.6rem',
    textAlign:'center',
},
cent:{display: 'flex',
    justifyContent:'center',
    alignItems:'center',
    alignContent:'center',
    marginTop:'250px',
},
box:{
    borderBottomStyle:'solid',
    borderWidth:'1px',
    borderColor:'#E2E2E2',
    display: 'flex',
    justifyContent:'center',
    alignItems:'center',
    alignContent:'center',
}

  }));

const GenericDataGrid = ({
    dataRows,
    columns,
    pageSize,
    updateFunc,
    ht,
    ID,
}) => {
    // const [dataRows, setDataRows ] = useState([]);
    const [loading, setLoading] = useState(true);
    const [shouldRefresh, setShouldRefresh] = useState(false);
    const classes = useStyles();
    // console.log(ID)

    function CustomToolbar() {
        return (
          <GridToolbarContainer className={classes.box}>
            <GridToolbarExport />
          </GridToolbarContainer>
        );
      }
  const renderLoadingMessage = () => <div className={classes.cent}><CircularProgress color="primary" /></div>;

  const renderGrid = () => (
    <div
        className={classes.root}
        style={{ height: ht, width: '100%' }}
    >
        <DataGrid
            headerHeight={70}
            density={'compact'}
            rows={dataRows}
            columns={columns}
            pageSize={pageSize}
            onRowSelected={updateFunc}
            getRowId={(eachRow) => eachRow[ID] } 
            components={{
                Toolbar: CustomToolbar,
              }}
         />
    </div>
    );

    return (<>
        
        {
            renderGrid()
        }
    </>);
}

GenericDataGrid.propTypes = {
    columns: PropTypes.array.isRequired,
    pageSize: PropTypes.number.isRequired,
  };

export default GenericDataGrid