import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import axios from 'axios';
import { makeStyles, ThemeProvider, createMuiTheme } from '@material-ui/core/styles';
import {orange} from '@material-ui/core/colors'
import GenericDataGrid from '../components/GenericDataGrid';
import AppBar from './AppBar';
import config from "./../config"
import EditIcon from '@material-ui/icons/Edit';

const useStyles = makeStyles((theme) => ({
    
  col:{
      color:'black',
  },
  title:{
    fontFamily:'Barlow, sans-serif',
    fontSize:'1.6rem',
    textAlign:'center',
    marginTop:'10px',
    marginBottom:'45px'
},
root: {
  marginTop:'40px',
  '& .super--cell': {
    backgroundColor: '#4fc3f7',
    color: 'black',
    fontWeight: '600',
    textAlign:'center'
    
  },
  '& .super--cell2': {
    color: 'black',
    textAlign:'center'
    
  },
  '& .super--cell3': {
    color: 'black',
    fontWeight: '600',
    textAlign:'center',
    
  },
  '& .super4--cell': {
    backgroundColor: '#4fc3f7',
    color: 'black',
    fontWeight: '600',
    textAlign:'center'
  },
  '& .super--header': {
    backgroundColor: 'rgba(255, 7, 0, 0.55)',
    fontWeight: 'bold',
    flexWrap:'wrap',
    alignItems:'center',
    textAlign:'center',
  },
}
}));

const HomePage = ({data}) => {
  const classes = useStyles();

  const [policies, setPolicies] = useState([]);

  const currencyFormatter = new Intl.NumberFormat('en-US', {
    style: 'currency',
    currency: 'USD',
    minimumFractionDigits:0,
  });
  
  const usdPrice = {
    type: 'number',
    width: 130,
    valueFormatter: ({ value }) => currencyFormatter.format(Number(value)),
    cellClassName: 'super--cell',
  };
  const usdPrice2 = {
    type: 'number',
    width: 180,
    valueFormatter: ({ value }) => currencyFormatter.format(Number(value)),
    cellClassName: 'super--cell3',
  };
  const usdPrice4 = {
    type: 'number',
    width: 130,
    valueFormatter: ({ value }) => currencyFormatter.format(Number(value)),
    cellClassName: 'super4--cell',
  };

  const renderPolicyList = () => (
    
    <ThemeProvider >
    <div className={classes.root}>
    <GenericDataGrid 
      dataRows={data}
      ID = {'GL_DETAILS_KEY'}
      ht={800}
      columns={[
        {
          field: 'action',
          headerName: 'Action',
          description: 'This column has a value getter and is not sortable.',
          sortable: false,
          width: 100,
          renderCell: (params) => <Link to={`/tab/${params.row.CUSTOMER_ID}`}><EditIcon className={classes.col}/></Link>,
          cellClassName: 'super--cell2',headerAlign: 'center',
        },
        { field: 'GL_DETAILS_KEY', headerName: 'ID', width: 70,cellClassName: 'super--cell2',headerAlign: 'center' },
        { field: 'CUSTOMER_ID', headerName: 'Customer ID', width: 130,cellClassName: 'super--cell2',headerAlign: 'center' },
        { field: 'ITERATION_NUMBER', headerName: 'Iteration Number', width: 130,cellClassName: 'super--cell2',headerAlign: 'center',type:'number' },
        { field: 'CLASS_CODE', headerName: 'Class Code', width: 130,cellClassName: 'super--cell2',headerAlign: 'center' },
        { field: 'PREM_OPS_EXPOSURE', headerName: 'Prem Ops Exposure', width: 150,cellClassName: 'super--cell2',headerAlign: 'center',type:'number',...usdPrice4 },
        { field: 'PRODS_COMPLD_OPS_EXPOSURE', headerName: 'Prods Compld Ops Exposure',cellClassName: 'super--cell2',headerAlign: 'center',type:'number',...usdPrice4 , width: 180},
        { field: 'PREM_OPS_PREMIUM_BASIS', headerName: 'Prem Ops Premium Basis', width: 180,cellClassName: 'super--cell2',headerAlign: 'center' },
        { field: 'PRODS_COMPLD_OPS_PREMIUM_BASIS', headerName: 'Prods Compld Ops Premium Basis', width: 180,cellClassName: 'super--cell2',headerAlign: 'center' },
        { field: 'PREM_OPS_BIPD_DEDUCTIBLE', headerName: 'Prem Ops Bipd Deductible', width: 180,cellClassName: 'super--cell2',headerAlign: 'center' },
        { field: 'PRODS_COMPLD_OPS_BIPD_DEDUCTIBLE', headerName: 'Prods Compld Ops Bipd Deductible', width: 180,cellClassName: 'super--cell2',headerAlign: 'center' },
        { field: 'PRODS_COMPLD_OPS_COV', headerName: 'Prods Compld Ops Cov', width: 180 ,cellClassName: 'super--cell2',headerAlign: 'center'},
        { field: 'OTHER_THAN_PRODS_COMPLD_OPS_COV', headerName: 'Other than Prods Compld Ops Cov', width: 180,cellClassName: 'super--cell2',headerAlign: 'center' },
        { field: 'PREMISES_OPERATIONS_TERRITORY', headerName: 'Premises Operation Territory', width: 180,cellClassName: 'super--cell2',headerAlign: 'center' },
        { field: 'PRODS_COMPLD_OPS_TERRITORY', headerName: 'Prods Compld Ops Territory', width: 180,cellClassName: 'super--cell2',headerAlign: 'center' },
        { field: 'STATE', headerName: 'State', width: 130,cellClassName: 'super--cell2',headerAlign: 'center' },
    ]}
    pageSize={100}
    />
  </div>
  </ThemeProvider>
  );


    return renderPolicyList();
}

export default HomePage;