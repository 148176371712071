import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import Typography from '@material-ui/core/Typography';
import { Link, useLocation } from 'react-router-dom';
import clsx from 'clsx';

const useStyles = makeStyles((theme) => ({
    
    header:{
        backgroundColor:'black',
        color:'white',
    },
  root: {
    flexGrow: 1,
  },
  menuButton: {
    marginRight: theme.spacing(2),
  },
  title: {
    flexGrow: 1,
    color:'white',
    fontFamily:'Barlow, sans-serif',
    paddingLeft:'105px'

  },
  button: {
    backgroundColor: '#F6FBFE',
    fontFamily:'Barlow, sans-serif',
    border: 'none',
    color: 'black',
    padding: '8px 8px',
    justifyContent: 'center',
    textDecoration: 'none',
    display: 'flex',
    fontSize: '16px',
    margin: '8px 8px',
    cursor: 'pointer',
    borderRadius: '8px',
    outline: 0,
    fontWeight:'bold',
    '&:hover': {
      background: "#4fc3f7",
   },
  },
}));

export default function XmlBar() {
  const classes = useStyles();

  return (
    <div className={classes.root}>
      <AppBar position="static" className={classes.header}>
        <Toolbar>
          <Typography variant="h5" className={classes.title} align='center' >
            Edit XML
          </Typography>
          <Typography align="right">
          <input form="xml" className={classes.button} type="submit" value="Submit XML"  />
          </Typography>
        </Toolbar>
      </AppBar>
    </div>
    
  );
}