import React, { useState, useEffect } from 'react';
import { DataGrid,GridToolbar,GridToolbarContainer,GridToolbarExport  } from '@material-ui/data-grid';
import { Link } from 'react-router-dom';
import Button from '@material-ui/core/Button';
import axios from 'axios';
import PropTypes from 'prop-types';
import EditIcon from '@material-ui/icons/Edit';
import { makeStyles, ThemeProvider, createMuiTheme } from '@material-ui/core/styles';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';
import TextField from '@material-ui/core/TextField'
import InputLabel from '@material-ui/core/InputLabel';
import Box from '@material-ui/core/Box';
import { flexbox } from '@material-ui/system';
import Checkbox from '@material-ui/core/Checkbox';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import clsx from 'clsx';
import config from "./../config"
import CircularProgress from '@material-ui/core/CircularProgress';
import AppBar from '@material-ui/core/AppBar';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import Typography from '@material-ui/core/Typography';
import {withStyles } from '@material-ui/core/styles';

let theme = createMuiTheme({
    palette: {
      primary: {
        light: '#63ccff',
        main: '#009be5',
        dark: '#006db3',
      },
    },
    typography: {
      fontFamily: 'Barlow, sans-serif',
      h5: {
        fontWeight: 500,
        fontSize: 26,
        letterSpacing: 0.5,
        fontFamily:'Barlow, sans-serif',
      },
    },
    shape: {
      borderRadius: 8,
    },
    props: {
      MuiTab: {
        disableRipple: true,
      },
    },
    mixins: {
      toolbar: {
        minHeight: 48,
      },
    },
  });
  
  theme = {
    ...theme,
    overrides: {
      MuiDrawer: {
        paper: {
          backgroundColor: 'black',
        },
      },
      MuiButton: {
        label: {
          textTransform: 'none',
        },
        contained: {
          boxShadow: 'none',
          '&:active': {
            boxShadow: 'none',
          },
        },
      },
      MuiTabs: {
        root: {
          marginLeft: theme.spacing(1),
        },
        indicator: {
          height: 3,
          borderTopLeftRadius: 3,
          borderTopRightRadius: 3,
          backgroundColor: '#4fc3f7',
        },
      },
      MuiTab: {
        
        root: {
          textTransform: 'none',
          margin: '0 16px',
          minWidth: 0,
          padding: 0,
          [theme.breakpoints.up('md')]: {
            padding: 0,
            minWidth: 0,
          },
        },
      },
      MuiIconButton: {
        root: {
          padding: theme.spacing(1),
        },
      },
      MuiTooltip: {
        tooltip: {
          borderRadius: 4,
        },
      },
      MuiDivider: {
        root: {
          backgroundColor: '#404854',
        },
      },
      MuiListItemText: {
        primary: {fontSize: 25,
          fontWeight: theme.typography.fontWeightMedium,
        },
      },
      MuiListItemIcon: {
        root: {
          color: 'inherit',
          marginRight: 0,
          '& svg': {
            fontSize: 20,
          },
        },
      },
      MuiAvatar: {
        root: {
          width: 32,
          height: 32,
        },
      },
    },
  };

const useStyles = makeStyles((theme) => ({
    
    col:{
        color:'black',
    },
    fon:{
        color:'black',
        fontWeight: 'bold'
    },
    title:{
      fontFamily:'Barlow, sans-serif',
      fontSize:'1.6rem',
      textAlign:'center',
      paddingTop:'0px'
  },
  load:{
    fontFamily:'Barlow, sans-serif',
    fontSize:'1.6rem',
    textAlign:'center',
    paddingTop:'0px',
    paddingBottom:'0px'
},
button: {
    backgroundColor: 'black',
    fontFamily:'Barlow, sans-serif',
    border: 'none',
    color: 'white',
    padding: '12px 15px',
    textAlign: 'center',
    textDecoration: 'none',
    display: 'inline-block',
    fontSize: '16px',
    margin: '4px 2px',
    cursor: 'pointer',
    borderRadius: '8px',
    outline: 0,
    fontWeight:'bold',
    '&:hover': {
        color: "#4fc3f7",
        boxShadow: '4px 4px 4px grey',
     },
  },
  bor:{
      borderStyle:'solid',
      fontSize:'1.0rem',
      fontWeight: 'bold',
      boxShadow: '6px 6px 5px grey',
      outlineStyle:'solid',
    borderColor:'#4fc3f7',
    borderWidth:'thin'
  },
  root: {
    '& .super--cell': {
      backgroundColor: '#4fc3f7',
      color: 'black',
      fontWeight: '600',
      textAlign:'center'
      
    },
    '& .super1--cell': {
        backgroundColor: '#B8CBD9',
        color: 'black',
        fontWeight: '600',
        textAlign:'center'
      },
      '& .super4--cell': {
        backgroundColor: '#4fc3f7',
        color: 'black',
        fontWeight: '600',
        textAlign:'center'
      },
      '& .super--cell2': {
        color: 'black',
        textAlign:'center'
        
      },
      '& .super--cell3': {
        color: 'black',
        fontWeight: '600',
        textAlign:'center',
        
      },
      '& .super-val.negative': {
        backgroundColor: '#FB6F51',
        color: 'black',
        fontWeight: '600',
        textAlign:'center'
      },
      '& .super-val.positive': {
        backgroundColor: '#6EFB70',
        color: 'black',
        fontWeight: '600',
        textAlign:'center'
      },
      '& .super-val.neutral': {
        backgroundColor: '#F2CA63',
        color: 'black',
        fontWeight: '600',
        textAlign:'center'
      },
  },
  win:{
        height:'40px'
  },
  cent:{display: 'flex',
    justifyContent:'center',
    alignItems:'center',
    alignContent:'center',
    marginTop:'250px',
    
},
colm:{
    backgroundColor:'black',
    width:'50%',
    minWidth:'50%',
    display:'flex',
    justifyContent:'center',
    borderRadius:'8px'
},
coldiv:{
    display:'flex',
    justifyContent:'center',
    marginTop:'15px',
    marginBottom:'15px',
  },
  root1: {
    flexGrow: 1,
    
  },
  size:{
      fontSize:'1.1rem'
  },
  box:{
    borderBottomStyle:'solid',
    borderWidth:'1px',
    borderColor:'#E2E2E2',
    display: 'flex',
    justifyContent:'center',
    alignItems:'center',
    alignContent:'center',
}
  }));

  function TabPanel(props) {
    const { children, value, index, ...other } = props;
  
    return (
      <div
        role="tabpanel"
        hidden={value !== index}
        id={`simple-tabpanel-${index}`}
        aria-labelledby={`simple-tab-${index}`}
        {...other}
      >
        {value === index && (
          <Box p={3}>
            <Typography>{children}</Typography>
          </Box>
        )}
      </div>
    );
  }
  
  TabPanel.propTypes = {
    children: PropTypes.node,
    index: PropTypes.any.isRequired,
    value: PropTypes.any.isRequired,
  };
  
  function a11yProps(index) {
    return {
      id: `simple-tab-${index}`,
      'aria-controls': `simple-tabpanel-${index}`,
    };
  }

  export default function SimpleTabs(props) {
    const [value, setValue] = React.useState(0);
    const classes = useStyles();
    const handleChange = (event, newValue) => {
        setValue(newValue);
      };
    const currencyFormatter = new Intl.NumberFormat('en-US', {
        style: 'currency',
        currency: 'USD',
        minimumFractionDigits:0,
      });
      const percentFormatter = new Intl.NumberFormat('en-US', {
        style: 'percent',
        minimumFractionDigits:2,
      });
      
      const usdPrice = {
        type: 'number',
        width: 130,
        valueFormatter: ({ value }) => currencyFormatter.format(Number(value)),
        cellClassName: 'super--cell',
      };
      const usdPrice2 = {
        type: 'number',
        width: 130,
        valueFormatter: ({ value }) => currencyFormatter.format(Number(value)),
        cellClassName: 'super1--cell',
      };
      const usdPrice3 = {
        type: 'number',
        width: 150,
        valueFormatter: ({ value }) => currencyFormatter.format(Number(value)),
        cellClassName: 'super--cell3',
      };
      const usdPrice4 = {
        type: 'number',
        width: 130,
        valueFormatter: ({ value }) => currencyFormatter.format(Number(value)),
        cellClassName: 'super4--cell',
      };
      const changePercent = {
        type: 'number',
        width: 130,
        valueFormatter: ({ value }) => percentFormatter.format(Number(value)),
        
      };
    const columns=[
        {
            field: 'action',
            headerName: 'Action',
            description: 'This column has a value getter and is not sortable.',
            sortable: false,
            width: 100,
            renderCell: (params) => <Link to={`/tab/${params.row.CUSTOMER_ID}`}><EditIcon className={classes.col}/></Link>
          },
        { field: 'CUSTOMER_ID', headerName: 'Customer', width: 130,cellClassName: 'super--cell2',headerAlign: 'center' },
        { field: 'POLICY_EXPIRATION_DATE', headerName: 'Exp Date', width: 130,cellClassName: 'super--cell2',headerAlign: 'center',type:'date' },
        { field: 'POLICY_CARRIER', headerName: 'Carrier', width: 130,cellClassName: 'super--cell2',headerAlign: 'center' },
        { field: 'POLICY_TYPE', headerName: 'Type', width: 110,cellClassName: 'super--cell2',headerAlign: 'center' },
        { field: 'PROGRAM', headerName: 'Program', width: 110 ,cellClassName: 'super--cell2',headerAlign: 'center'},
        { field: 'DV_LOAD_DTS', headerName: 'Rated On', width: 180,cellClassName: 'super--cell2',headerAlign: 'center',type:'date'},
        { field: 'WRITTENPREMIUM', headerName: 'Total Rated Premium', width: 150,cellClassName: 'super--cell2',headerAlign: 'center',...usdPrice3},
        { field: 'PRIORWRITTENPREMIUM', headerName: 'Expiring Premium', width: 150,cellClassName: 'super--cell2',headerAlign: 'center',...usdPrice3},
        { field: 'PREMIUMCHANGEAMOUNT', headerName: 'Premium Change', width: 150,cellClassName: 'super--cell2',headerAlign: 'center',...usdPrice3},
        { field: 'PREMIUMCHANGEPERCENT', headerName: 'Premium Change %', type:'number',width: 150,cellClassName: 'super--cell2',headerAlign: 'center',...changePercent,cellClassName: (params) =>
        clsx('super-val', {
          negative: params.value >= 0.25 || params.value <= -0.25,
          positive: params.value >=-0.10 && params.value <=0.10,
          neutral: params.value > 0.10 && params.value <0.25 || params.value <= -0.10 && params.value > -0.25,
        }), },
        { field: 'GL_RATED_PREMIUM', headerName: 'GL Rated Premium',type:'number', width: 150,cellClassName: 'super--cell2',headerAlign: 'center',...usdPrice  },
        { field: 'CP_RATED_PREMIUM', headerName: 'Property Rated Premium',cellClassName: 'super--cell2',headerAlign: 'center',...usdPrice2, width: 180 },
        { field: 'ADDITIONALDUETOMP', headerName: 'Min Prem Monoline', width: 150,cellClassName: 'super--cell2',headerAlign: 'center',...usdPrice2 },
        { field: 'ADDITIONALDUETOMPGL', headerName: 'Min Prem GL', width: 150,cellClassName: 'super--cell2',headerAlign: 'center',...usdPrice  },
        { field: 'ADDITIONALDUETOMPPROP', headerName: 'Min Prem Property', width: 150,cellClassName: 'super--cell2',headerAlign: 'center',...usdPrice2 },
        { field: 'ADDITIONALINSURED', headerName: 'Additional Insured Prem' ,cellClassName: 'super--cell2',headerAlign: 'center',...usdPrice, width: 180 },
        { field: 'AUTOHIRED', headerName: 'Auto Hired Premium', width: 150 ,cellClassName: 'super--cell2',headerAlign: 'center',...usdPrice },
        { field: 'DESIGNATEDLOCATION', headerName: 'Designated Location Prem' ,cellClassName: 'super--cell2',headerAlign: 'center',...usdPrice , width: 180},
        { field: 'EMPLOYEEBENEFITSLIABILITY', headerName: 'Employee Benefits Prem',cellClassName: 'super--cell2',headerAlign: 'center',...usdPrice , width: 180 },
        { field: 'GLPREMIERENDORSEMENT', headerName: 'GL Premier Endorsement Prem',cellClassName: 'super--cell2',headerAlign: 'center',...usdPrice , width: 180 },
        { field: 'TERRORISMGL', headerName: 'GL Terrorism', width: 150 ,cellClassName: 'super--cell2',headerAlign: 'center',...usdPrice },
        { field: 'LIMITATIONCOVERAGE', headerName: 'Limitation Covg Prem', width: 150,cellClassName: 'super--cell2',headerAlign: 'center' ,...usdPrice },
        { field: 'PREMOPSPREMIUM', headerName: 'PremOps Premium', width: 150 ,cellClassName: 'super--cell2',headerAlign: 'center',...usdPrice },
        { field: 'PRODUCTPREMIUM', headerName: 'Product Prem', width: 150 ,cellClassName: 'super--cell2',headerAlign: 'center',...usdPrice },
        { field: 'ASSOCIATIONBYLAWS', headerName: 'Association ByLaws Prem',cellClassName: 'super--cell2',headerAlign: 'center',...usdPrice2, width: 180 },
        { field: 'BI', headerName: 'BI Prem', width: 150,cellClassName: 'super--cell2',headerAlign: 'center',...usdPrice2 },
        { field: 'BUILDING', headerName: 'Building Prem', width: 150,cellClassName: 'super--cell2',headerAlign: 'center',...usdPrice2 },
        { field: 'EQUIPMENTBREAKDOWN', headerName: 'Equip Breakdown Prem',cellClassName: 'super--cell2',headerAlign: 'center',...usdPrice2 , width: 180},
        { field: 'ICEDAMMING', headerName: 'Ice Damming Prem',cellClassName: 'super--cell2',headerAlign: 'center', ...usdPrice2, width: 180},
        { field: 'MAINTENANCEFEES', headerName: 'Maintenance Fees Prem', width: 150,cellClassName: 'super--cell2',headerAlign: 'center',...usdPrice2 },
        { field: 'NONSPRINKLERED', headerName: 'Non Sprinklered Prem',cellClassName: 'super--cell2',headerAlign: 'center',...usdPrice2, width: 180 },
        { field: 'PROPPREMIERENDORSEMENT', headerName: 'Property Premier Endorsement Prem',cellClassName: 'super--cell2',headerAlign: 'center',...usdPrice2, width: 180 },
        { field: 'PROPERTY', headerName: 'Pers Property Prem' ,cellClassName: 'super--cell2',headerAlign: 'center',...usdPrice2, width: 180},
        { field: 'TERRORISMCP', headerName: 'Property Terrorism', width: 150 ,cellClassName: 'super--cell2',headerAlign: 'center',...usdPrice2},
        { field: 'WATERDAMAGE', headerName: 'Water Damage Prem' ,cellClassName: 'super--cell2',headerAlign: 'center',...usdPrice2, width: 180},
        { field: 'REQUESTLINK', headerName: 'Package Rating Link', width: 150,renderCell: (params) =>
        <a className={classes.fon}target={"_blank"} href={`${params.row.REQUESTLINK}`}>{params.row.REQUESTLINK} </a> },
        { field: 'GLREQUESTLINK', headerName: 'GL Rating Link', width: 150,renderCell: (params) =>
        <a className={classes.fon} target={"_blank"} href={`${params.row.GLREQUESTLINK}`}>{params.row.GLREQUESTLINK} </a> },
        { field: 'CPREQUESTLINK', headerName: 'Property Rating Link', width: 150,renderCell: (params) =>
        <a className={classes.fon} target={"_blank"} href={`${params.row.CPREQUESTLINK}`}>{params.row.CPREQUESTLINK} </a> },
        ];
    
    const columnscp=[
        {
            field: 'action',
            headerName: 'Action',
            description: 'This column has a value getter and is not sortable.',
            sortable: false,
            width: 100,
            renderCell: (params) => <Link to={`/tab/${params.row.CUSTOMER_ID}`}><EditIcon className={classes.col}/></Link>
          },
        { field: 'CUSTOMER_ID', headerName: 'Customer', width: 130,cellClassName: 'super--cell2',headerAlign: 'center' },
        { field: 'DV_LOAD_DTS', headerName: 'Rated On', width: 180, type: 'date',cellClassName: 'super--cell2',headerAlign: 'center'},
        { field: 'ITERATIONNUMBER', headerName: 'Iteration Number', width: 130,cellClassName: 'super--cell2',headerAlign: 'center',type:'number' },
        { field: 'POLICY_EXPIRATION_DATE', headerName: 'Exp Date', width: 130,cellClassName: 'super--cell2',headerAlign: 'center',type:'date'},
        { field: 'POLICY_CARRIER', headerName: 'Carrier', width: 130,cellClassName: 'super--cell2',headerAlign: 'center' },
        { field: 'POLICY_TYPE', headerName: 'Type', width: 130,cellClassName: 'super--cell2',headerAlign: 'center' },
        { field: 'PROGRAM', headerName: 'Program', width: 130,cellClassName: 'super--cell2',headerAlign: 'center' },
        { field: 'CP_RATED_PREMIUM', headerName: 'Property Rated Prem', width: 130,cellClassName: 'super--cell2',headerAlign: 'center',...usdPrice4 },
        { field: 'ASSOCIATIONBYLAWS', headerName: 'Association By Laws', width: 130,cellClassName: 'super--cell2',headerAlign: 'center',...usdPrice4 },
        { field: 'BI', headerName: 'Business Income', width: 130,cellClassName: 'super--cell2',headerAlign: 'center',...usdPrice4 },
        { field: 'BUILDING', headerName: 'Building Prem', width: 130,cellClassName: 'super--cell2',headerAlign: 'center',...usdPrice4 },
        { field: 'EQUIPMENTBREAKDOWN', headerName: 'Equipment Breakdown Prem',cellClassName: 'super--cell2',headerAlign: 'center',...usdPrice4 , width: 180},
        { field: 'ICEDAMMING', headerName: 'Ice Damming Prem',cellClassName: 'super--cell2',headerAlign: 'center',...usdPrice4, width: 150 },
        { field: 'MAINTENANCEFEES', headerName: 'Maitenance Fees Prem', width: 130,cellClassName: 'super--cell2',headerAlign: 'center',...usdPrice4 },
        { field: 'NONSPRINKLERED', headerName: 'Non Sprinklered Prem',cellClassName: 'super--cell2',headerAlign: 'center',...usdPrice4 , width: 180},
        { field: 'PROPPREMIERENDORSEMENT', headerName: 'Property Premier Endorsement Prem',cellClassName: 'super--cell2',headerAlign: 'center',...usdPrice4, width: 180 },
        { field: 'PROPERTY', headerName: 'Personal Property Prem',cellClassName: 'super--cell2',headerAlign: 'center',...usdPrice4, width: 180 },
        { field: 'TERRORISMCP', headerName: 'Property Terrorism Prem',cellClassName: 'super--cell2',headerAlign: 'center',...usdPrice4 , width: 180},
        { field: 'WATERDAMAGE', headerName: 'Water Damage Prem',cellClassName: 'super--cell2',headerAlign: 'center',...usdPrice4 , width: 150},
        { field: 'COMMERCIALPROPERTY_LOSSCOSTMULTIPLIER', headerName: 'Property LCM', width: 130,cellClassName: 'super--cell2',headerAlign: 'center' },
        { field: 'COMMERCIALPROPERTYSTRUCTURE_LOSSCOSTMULTIPLIER', headerName: 'Structure LCM', width: 130,cellClassName: 'super--cell2',headerAlign: 'center' },
        { field: 'COMMERCIALPROPERTYBUSINESSINCOME_LOSSCOSTMULTIPLIER', headerName: 'BUilding LCM', width: 130,cellClassName: 'super--cell2',headerAlign: 'center' },
        { field: 'COMMERCIALPROPERTYPERSONALPROPERTY_LOSSCOSTMULTIPLIER', headerName: 'Personal Property LCM',cellClassName: 'super--cell2',headerAlign: 'center', width: 180 },
        { field: 'CP_COMPANYASSOCIATIONBYLAWS', headerName: 'Association ByLaws Covg',cellClassName: 'super--cell2',headerAlign: 'center' , width: 180},
        { field: 'CP_COMPANYPROPPREMIERENDORSEMENT', headerName: 'Property Premier Endorsement Covg',cellClassName: 'super--cell2',headerAlign: 'center', width: 180 },
        { field: 'CP_COMPANYMAINTENANCEFEES', headerName: 'Maintenance Fees Covg', width: 130,cellClassName: 'super--cell2',headerAlign: 'center' },
        { field: 'CP_COMPANYWATERDAMAGE', headerName: 'Water Damage Covg',cellClassName: 'super--cell2',headerAlign: 'center', width: 150 },
        { field: 'CP_COMPANYICEDAMMING', headerName: 'Ice Damming Covg',cellClassName: 'super--cell2',headerAlign: 'center', width: 150 },
        { field: 'CP_COMPANYEQUIPMENTBREAKDOWN', headerName: 'Equipment Breakdown Covg',cellClassName: 'super--cell2',headerAlign: 'center', width: 180 },
        { field: 'CP_COMPANYNONSPRINKLERED', headerName: 'Non Sprinklered Covg',cellClassName: 'super--cell2',headerAlign: 'center', width: 180 },
        { field: 'CP_PACKAGEMODIFICATIONASSIGNMENT', headerName: 'Package Modification Assignment',cellClassName: 'super--cell2',headerAlign: 'center', width: 200 },
        { field: 'CP_COMPANYTERRORISM', headerName: 'Property Terrorism Covg',cellClassName: 'super--cell2',headerAlign: 'center', width: 180 },
        { field: 'CP_IRPMMANAGEMENT', headerName: 'IRPM Management', width: 130,cellClassName: 'super--cell2',headerAlign: 'center' },
        { field: 'CP_IRPMLOCATION', headerName: 'IRPM Location', width: 130,cellClassName: 'super--cell2',headerAlign: 'center' },
        { field: 'CP_IRPMBUILDINGFEATURES', headerName: 'IRPM Building Features',cellClassName: 'super--cell2',headerAlign: 'center', width: 150 },
        { field: 'CP_IRPMPREMISESANDEQUIPMENT', headerName: 'IRPM Premises and Equipment',cellClassName: 'super--cell2',headerAlign: 'center', width: 180 },
        { field: 'CP_IRPMEMPLOYEES', headerName: 'IRPM Employees', width: 130,cellClassName: 'super--cell2',headerAlign: 'center' },
        { field: 'CP_IRPMPROTECTION', headerName: 'IRPM Protection', width: 130,cellClassName: 'super--cell2',headerAlign: 'center' },
        { field: 'COMMERCIALPROPERTYLOCATION_LOCATION', headerName: 'Location', width: 130,cellClassName: 'super--cell2',headerAlign: 'center' },
        { field: 'COMMERCIALPROPERTYLOCATION_BUILDING', headerName: 'Building', width: 130,cellClassName: 'super--cell2',headerAlign: 'center' },
        { field: 'COMMERCIALPROPERTYLOCATION_COMMERCIALPROPERTY_STATE', headerName: 'State', width: 130,cellClassName: 'super--cell2',headerAlign: 'center' },
        { field: 'COMMERCIALPROPERTYLOCATION_COMMERCIALPROPERTY_CITY', headerName: 'City', width: 130,cellClassName: 'super--cell2',headerAlign: 'center' },
        { field: 'COMMERCIALPROPERTYLOCATION_COMMERCIALPROPERTY_COUNTY', headerName: 'County', width: 130,cellClassName: 'super--cell2',headerAlign: 'center' },
        { field: 'COMMERCIALPROPERTYLOCATION_PROTECTCLASS', headerName: 'PPC', width: 130,cellClassName: 'super--cell2',headerAlign: 'center' },
        { field: 'COMMERCIALPROPERTYLOCATION_DISTRICT', headerName: 'District', width: 130,cellClassName: 'super--cell2',headerAlign: 'center' },
        { field: 'COMMERCIALPROPERTYLOCATION_BASICGROUPIRATINGTERR', headerName: 'BGI Rating Territory', width: 180,cellClassName: 'super--cell2',headerAlign: 'center' },
        { field: 'COMMERCIALPROPERTYLOCATION_BASICGROUPIIRATINGTERR', headerName: 'BGII Rating Territory', width: 130,cellClassName: 'super--cell2',headerAlign: 'center' },
        { field: 'COMMERCIALPROPERTYLOCATION_SPECIALRATINGTERR', headerName: 'Special Rating Territory',cellClassName: 'super--cell2',headerAlign: 'center', width: 150 },
        { field: 'COMMERCIALPROPERTYLOCATION_TERRORISMTERRITORY', headerName: 'Terrorism Territory', width: 250,cellClassName: 'super--cell2',headerAlign: 'center' },
        { field: 'COMMERCIALPROPERTYSTRUCTURE_COVTYPE', headerName: 'Structure Covg Type', width: 130,cellClassName: 'super--cell2',headerAlign: 'center' },
        { field: 'COMMERCIALPROPERTYSTRUCTURE_RATINGTYPE', headerName: 'Rating Type', width: 130,cellClassName: 'super--cell2',headerAlign: 'center' },
        { field: 'COMMERCIALPROPERTYSTRUCTURE_CONSTRUCTIONTYPE', headerName: 'Construction Type', width: 130,cellClassName: 'super--cell2',headerAlign: 'center' },
        { field: 'COMMERCIALPROPERTYSTRUCTURE_CONSTRUCTIONTYPETOUSE', headerName: 'Construction Type To Use', width: 130,cellClassName: 'super--cell2',headerAlign: 'center' },
        { field: 'COMMERCIALPROPERTYSTRUCTURE_CLASSCODE', headerName: 'Class Code', width: 130,cellClassName: 'super--cell2',headerAlign: 'center' },
        { field: 'COMMERCIALPROPERTYSTRUCTURE_RATINGTYPEBASICGROUPII', headerName: 'Rating Type Basic Group II',cellClassName: 'super--cell2',headerAlign: 'center', width: 150 },
        { field: 'COMMERCIALPROPERTYSTRUCTURE_NUMSTORIES', headerName: 'Num Stories', width: 130,cellClassName: 'super--cell2',headerAlign: 'center' },
        { field: 'COMMERCIALPROPERTYSTRUCTURE_DEDUCTIBLE', headerName: 'Deductible', width: 130,cellClassName: 'super--cell2',headerAlign: 'center' },
        { field: 'COMMERCIALPROPERTYSTRUCTURE_WINDSTORMORHAILDEDUCTIBLE', headerName: 'Windstorm/Hail Deductible', width: 130,cellClassName: 'super--cell2',headerAlign: 'center' },
        { field: 'COMMERCIALPROPERTYSTRUCTURE_BCEG', headerName: 'BCEG', width: 130,cellClassName: 'super--cell2',headerAlign: 'center' },
        { field: 'COMMERCIALPROPERTYSTRUCTURE_BCEGCLASS', headerName: 'BCEG Class', width: 130,cellClassName: 'super--cell2',headerAlign: 'center' },
        { field: 'COMMERCIALPROPERTYSTRUCTURE_CAUSEOFLOSS', headerName: 'Cause of Loss', width: 130,cellClassName: 'super--cell2',headerAlign: 'center' },
        { field: 'COMMERCIALPROPERTYSTRUCTURE_LIMIT', headerName: 'Structure Limit', width: 130,cellClassName: 'super--cell2',headerAlign: 'center' },
        { field: 'COMMERCIALPROPERTYSTRUCTURE_THEFTDEDUCTIBLE', headerName: 'Structure Theft Deductible',cellClassName: 'super--cell2',headerAlign: 'center', width: 180 },
        { field: 'COMMERCIALPROPERTYSTRUCTURE_VALUATIONTYPE', headerName: 'Structure Valuation Type',cellClassName: 'super--cell2',headerAlign: 'center' , width: 180},
        { field: 'COMMERCIALPROPERTYSTRUCTURE_COINSURANCE', headerName: 'Structure Coinsurance', width: 130,cellClassName: 'super--cell2',headerAlign: 'center' },
        { field: 'COMMERCIALPROPERTYSTRUCTURE_AGREEDVALUE', headerName: 'Structure Agreed Value',cellClassName: 'super--cell2',headerAlign: 'center', width: 150 },
        { field: 'COMMERCIALPROPERTYSTRUCTURE_CLASSCODEMISCDESCRIPTION', headerName: 'MISC Class Code Desc', width: 200,cellClassName: 'super--cell2',headerAlign: 'center' },
        { field: 'COMMERCIALPROPERTYBUSINESSINCOME_COVTYPE', headerName: 'BI Covg Type', width: 280,cellClassName: 'super--cell2',headerAlign: 'center' },
        { field: 'COMMERCIALPROPERTYBUSINESSINCOME_LIMIT', headerName: 'BI Limit', width: 130,cellClassName: 'super--cell2',headerAlign: 'center' },
        { field: 'COMMERCIALPROPERTYBUSINESSINCOME_TYPEOFRISK', headerName: 'BI Type of Risk', width: 250,cellClassName: 'super--cell2',headerAlign: 'center' },
        { field: 'COMMERCIALPROPERTYBUSINESSINCOME_MAXPERIOD', headerName: 'BI Max Period', width: 130,cellClassName: 'super--cell2',headerAlign: 'center' },
        { field: 'COMMERCIALPROPERTYBUSINESSINCOME_MONTHLYLIMITOFINDEMNITY', headerName: 'BI Monthly Limit of Idemnity',cellClassName: 'super--cell2',headerAlign: 'center', width: 180 },
        { field: 'COMMERCIALPROPERTYBUSINESSINCOME_COINSURANCE', headerName: 'BI Coinsurance', width: 130,cellClassName: 'super--cell2',headerAlign: 'center' },
        { field: 'COMMERCIALPROPERTYBUSINESSINCOME_CAUSEOFLOSS', headerName: 'BI Cause of Loss', width: 130,cellClassName: 'super--cell2',headerAlign: 'center' },
        { field: 'COMMERCIALPROPERTYBUSINESSINCOME_AGREEDVALUE', headerName: 'BI Agreed Value', width: 130,cellClassName: 'super--cell2',headerAlign: 'center' },
        { field: 'COMMERCIALPROPERTYOCCUPCLASS_CLASSCODE', headerName: 'Occupancy Class Code', width: 130,cellClassName: 'super--cell2',headerAlign: 'center' },
        { field: 'COMMERCIALPROPERTYOCCUPCLASS_THEFTDEDUCTIBLE', headerName: 'Occupancy Theft Deductible',cellClassName: 'super--cell2',headerAlign: 'center', width: 180 },
        { field: 'COMMERCIALPROPERTYOCCUPCLASS_OCCUPCATEGORY', headerName: 'Occupancy Category', width: 280,cellClassName: 'super--cell2',headerAlign: 'center' },
        { field: 'COMMERCIALPROPERTYOCCUPCLASS_OCCUPCATEGORYRISKSEVERITY', headerName: 'Occupancy Risk Severity',cellClassName: 'super--cell2',headerAlign: 'center', width: 180 },
        { field: 'COMMERCIALPROPERTYOCCUPCLASS_COVFORM', headerName: 'Occupancy Covg Form', width: 280,cellClassName: 'super--cell2',headerAlign: 'center' },
        { field: 'COMMERCIALPROPERTYPERSONALPROPERTY_LIMIT', headerName: 'BPP Limit', width: 130,cellClassName: 'super--cell2',headerAlign: 'center' },
        { field: 'COMMERCIALPROPERTYPERSONALPROPERTY_VALUATIONTYPE', headerName: 'BPP Valuation Type',cellClassName: 'super--cell2',headerAlign: 'center', width: 150 },
        { field: 'COMMERCIALPROPERTYPERSONALPROPERTY_COINSURANCE', headerName: 'BPP Coinsurance', width: 130,cellClassName: 'super--cell2',headerAlign: 'center' },
        { field: 'COMMERCIALPROPERTYPERSONALPROPERTY_CAUSEOFLOSS', headerName: 'BPP Cause of Loss', width: 130,cellClassName: 'super--cell2',headerAlign: 'center' },
        { field: 'COMMERCIALPROPERTYPERSONALPROPERTY_AGREEDVALUE', headerName: 'BPP Agreed Value', width: 130,cellClassName: 'super--cell2',headerAlign: 'center' },
        { field: 'COMMERCIALPROPERTYPERSONALPROPERTY_INCLUDEDINBLKT', headerName: 'BPP Included in Blanket',cellClassName: 'super--cell2',headerAlign: 'center', width: 150 },
        ];

    const columnsgl=[
        {
            field: 'action',
            headerName: 'Action',
            description: 'This column has a value getter and is not sortable.',
            sortable: false,
            width: 100,
            renderCell: (params) => <Link to={`/tab/${params.row.CUSTOMER_ID}`}><EditIcon className={classes.col}/></Link>
          },
        { field: 'CUSTOMER_ID', headerName: 'Customer', width: 130,cellClassName: 'super--cell2',headerAlign: 'center' },
        { field: 'DV_LOAD_DTS', headerName: 'Rated On', width: 180 },
        { field: 'ITERATIONNUMBER', headerName: 'Iteration Number', width: 130,cellClassName: 'super--cell2',headerAlign: 'center',type:'number' },
        { field: 'POLICY_EXPIRATION_DATE', headerName: 'Exp Date', width: 130,cellClassName: 'super--cell2',headerAlign: 'center',type:'date' },
        { field: 'POLICY_CARRIER', headerName: 'Carrier', width: 130,cellClassName: 'super--cell2',headerAlign: 'center' },
        { field: 'GL_RATED_PREMIUM', headerName: 'GL Rated Premium', width: 130,cellClassName: 'super--cell2',headerAlign: 'center',...usdPrice },
        { field: 'ADDITIONALINSURED', headerName: 'Additional Insured Prem',cellClassName: 'super--cell2',headerAlign: 'center',...usdPrice , width: 180},
        { field: 'AUTOHIRED', headerName: 'Auto Hired Prem', width: 130,cellClassName: 'super--cell2',headerAlign: 'center',...usdPrice },
        { field: 'DESIGNATEDLOCATION', headerName: 'Designated Location Prem',cellClassName: 'super--cell2',headerAlign: 'center',...usdPrice, width: 180 },
        { field: 'EMPLOYEEBENEFITSLIABILITY', headerName: 'Employee Benefits Liability Prem',cellClassName: 'super--cell2',headerAlign: 'center',...usdPrice, width: 180 },
        { field: 'GLPREMIERENDORSEMENT', headerName: 'GL Premier Endorsement Prem',cellClassName: 'super--cell2',headerAlign: 'center',...usdPrice , width: 180},
        { field: 'LIMITATIONCOVERAGE', headerName: 'RLimitation Covg Prem', width: 130,cellClassName: 'super--cell2',headerAlign: 'center' ,...usdPrice},
        { field: 'PREMOPSPREMIUM', headerName: 'PremOps Prem', width: 130,cellClassName: 'super--cell2',headerAlign: 'center',...usdPrice },
        { field: 'PRODUCTPREMIUM', headerName: 'Product Prem', width: 130,cellClassName: 'super--cell2',headerAlign: 'center',...usdPrice },
        { field: 'TERRORISMGL', headerName: 'GL Terrorism Prem',cellClassName: 'super--cell2',headerAlign: 'center',...usdPrice, width: 150 },
        { field: 'GENERALLIABILITYCLASSIFICATIONPREMOPSCOVERAGE_LCM', headerName: 'PremOps LCM', width: 130,cellClassName: 'super--cell2',headerAlign: 'center' },
        { field: 'GENERALLIABILITYCLASSIFICATIONPRODSCOMPLDOPSCOVERAGE_LCM', headerName: 'Products LCM', width: 130,cellClassName: 'super--cell2',headerAlign: 'center' },
        { field: 'GL_COMPANYAUTOHIRED', headerName: 'Auto Hired Covg', width: 130,cellClassName: 'super--cell2',headerAlign: 'center' },
        { field: 'GL_COMPANYGLPREMIERENDORSEMENT', headerName: 'GL Premier Endorsement Covg',cellClassName: 'super--cell2',headerAlign: 'center', width: 180 },
        { field: 'GL_COMPANYDESIGNATEDLOCATION', headerName: 'Designated Location Covg',cellClassName: 'super--cell2',headerAlign: 'center' , width: 180},
        { field: 'GL_COMPANYLIMITATIONCOVERAGE', headerName: 'Limitation Covg', width: 130,cellClassName: 'super--cell2',headerAlign: 'center' },
        { field: 'GL_COMPANYEMPLOYEEBENEFITSLIABILITY', headerName: 'Employee Benefits Liability Covg',cellClassName: 'super--cell2',headerAlign: 'center', width: 180 },
        { field: 'GL_COMPANYNUMBEROFADDITIONALINSUREDS', headerName: 'Number of Additional Insureds',cellClassName: 'super--cell2',headerAlign: 'center', width: 180 },
        { field: 'GL_SCHEDULERATINGLOCATION', headerName: 'Schedule Rating Location',cellClassName: 'super--cell2',headerAlign: 'center', width: 180 },
        { field: 'GL_SCHEDULERATINGPREMISES', headerName: 'Schedule Rating Premises',cellClassName: 'super--cell2',headerAlign: 'center', width: 180 },
        { field: 'GL_SCHEDULERATINGEQUIPMENT', headerName: 'Schedule Rating Equipment',cellClassName: 'super--cell2',headerAlign: 'center', width: 180 },
        { field: 'GL_SCHEDULERATINGCLASSIFICATION', headerName: 'Schedule Rating Classification',cellClassName: 'super--cell2',headerAlign: 'center', width: 180 },
        { field: 'TGL_SCHEDULERATINGEMPLOYEES', headerName: 'Schedule Rating Employees',cellClassName: 'super--cell2',headerAlign: 'center', width: 180 },
        { field: 'GL_SCHEDULERATINGCOOPERATION', headerName: 'Schedule Rating Cooperation',cellClassName: 'super--cell2',headerAlign: 'center', width: 180 },
        { field: 'GL_SUBLINE', headerName: 'Subline', width: 130,cellClassName: 'super--cell2',headerAlign: 'center' },
        { field: 'GL_COMPANYTERRORISM', headerName: 'GL Terrorism Covg',cellClassName: 'super--cell2',headerAlign: 'center', width: 150 },
        { field: 'GL_PREMOPSPRODSCOVERAGEFORM', headerName: 'PremOps Covg Form',cellClassName: 'super--cell2',headerAlign: 'center', width: 150 },
        { field: 'GL_PREMOPSPRODSEACHOCCURRENCELIMIT', headerName: 'PremOps Each Occurence Limit',cellClassName: 'super--cell2',headerAlign: 'center', width: 180 },
        { field: 'GL_GENERALAGGREGATELIMIT', headerName: 'General Aggregate Limit',cellClassName: 'super--cell2',headerAlign: 'center', width: 180 },
        { field: 'GL_PRODSCOMPLDOPSAGGREGATELIMIT', headerName: 'Product Aggregate Limit',cellClassName: 'super--cell2',headerAlign: 'center', width: 180 },
        { field: 'GL_PACKAGEMODIFICATIONASSIGNMENT', headerName: 'Package Modification Assignment',cellClassName: 'super--cell2',headerAlign: 'center', width: 200 },
        { field: 'GENERALLIABILITYLOCATION_STATE', headerName: 'State', width: 130,cellClassName: 'super--cell2',headerAlign: 'center' },
        { field: 'GENERALLIABILITYLOCATION_PREMISESOPERATIONSTERRITORY', headerName: 'PremOps Territory', width: 130,cellClassName: 'super--cell2',headerAlign: 'center' },
        { field: 'GENERALLIABILITYLOCATION_PRODSCOMPLDOPSTERRITORY', headerName: 'Product Territory', width: 130,cellClassName: 'super--cell2',headerAlign: 'center' },
        { field: 'GENERALLIABILITYLOCATION_PREMOPSBIPDDEDUCTIBLE', headerName: 'PremOps BI/PD Deductible',cellClassName: 'super--cell2',headerAlign: 'center', width: 150 },
        { field: 'PREMOPSELPOVERRIDE', headerName: 'PremOps ELP Override',cellClassName: 'super--cell2',headerAlign: 'center', width: 150 },
        { field: 'PRODSCOMPLDOPSELPOVERRIDE', headerName: 'Product ELP Override',cellClassName: 'super--cell2',headerAlign: 'center', width: 150 },
        { field: 'GENERALLIABILITY_CLASSCODE', headerName: 'Class Code', width: 130,cellClassName: 'super--cell2',headerAlign: 'center' },
        { field: 'PREMOPSEXPOSURE', headerName: 'PremOps Exposure', width: 130,cellClassName: 'super--cell2',headerAlign: 'center' },
        { field: 'PRODSCOMPLDOPSEXPOSURE', headerName: 'Product Exposure', width: 130,cellClassName: 'super--cell2',headerAlign: 'center' },
        { field: 'GENERALLIABILITYCLASSIFICATIONPREMOPSCOVERAGE', headerName: 'PremOps Covg', width: 130,cellClassName: 'super--cell2',headerAlign: 'center' },
        { field: 'GENERALLIABILITYCLASSIFICATIONPRODSCOMPLDOPSCOVERAGE', headerName: 'Product Covg', width: 130,cellClassName: 'super--cell2',headerAlign: 'center' },
        { field: 'PREMOPSPREMIUMBASIS', headerName: 'PremOps Premium Basis',cellClassName: 'super--cell2',headerAlign: 'center', width: 150 },
        { field: 'PRODSCOMPLDOPSPREMIUMBASIS', headerName: 'Product Premium Basis',cellClassName: 'super--cell2',headerAlign: 'center', width: 150 },
        { field: 'PREMOPSBIPDDEDUCTIBLE', headerName: 'PremOps BI/PD Deductible',cellClassName: 'super--cell2',headerAlign: 'center', width: 150 },
        { field: 'PRODSCOMPLDOPSBIPDDEDUCTIBLE', headerName: 'Product BI/PD Deductible',cellClassName: 'super--cell2',headerAlign: 'center', width: 150 },
        { field: 'OTHERTHANPRODSCOMPLDOPSCOV', headerName: 'Other than Product Covg',cellClassName: 'super--cell2',headerAlign: 'center', width: 150 },
        ];
    const [dataRows, setDataRows ] = useState([]);
    const [dataCpRows, setDataCpRows ] = useState([]);
    const [dataGlRows, setDataGlRows ] = useState([]);
    const [loading, setLoading] = useState(true);
    const [loadingCp, setCpLoading] = useState(true);
    const [loadingGl, setGlLoading] = useState(true);
    const [shouldRefresh, setShouldRefresh] = useState(false);
    const [customer, setCustomer] = useState('ALL');
    const [isSubmit, setSubmit] = useState(false);
    const [date1, setDate1] = useState('2018-05-24');
    const [date2, setDate2] = useState('2021-10-24');
    const [isChecked, setChecked] = useState(true);
    const [totalPremium, setTotalPremium] = useState(0);
    const [expPremium, setExpPremium] = useState(0);
    const [numPolicies, setNumPolicies] = useState(0);
    const [glPremium, setglPremium] = useState(0);
    const [cpPremium, setcpPremium] = useState(0);
    const [filterRows, setFilterRows ] = useState([]);
    const [filterCpRows, setFilterCpRows ] = useState([]);
    const [filterGlRows, setFilterGlRows ] = useState([]);
    const [carrierFilter, setCarrierFilter ] = useState('Equals');
    const [carrierName, setCarrierName ] = useState('ALL');
    const [dataAllRows, setDataAllRows ] = useState([]);
    const [dataAllCpRows, setDataAllCpRows ] = useState([]);
    const [dataAllGlRows, setDataAllGlRows ] = useState([]);
    const [customerPrev, setCustomerPrev] = useState('ALL');
    const [isPlFilter, setIsPlFilter] = useState(false);
    const [isCpFilter, setIsCpFilter] = useState(false);
    const [isGlFilter, setIsGlFilter] = useState(false);
    

    useEffect( () => {
        axios.get(config.server_url + '/cdv/')
        .then(response => {
          console.log(response.data)
            const newdata = response.data.sort((a,b)=> a.CUSTOMER_ID.localeCompare(b.CUSTOMER_ID)).map((d,index)=> {d.id = index; return d})
            setDataRows(newdata);
            setTotalPremium(response.data.map(item => item.WRITTENPREMIUM).reduce((prev, next) => prev + next));
            setExpPremium(response.data.map(item => item.PRIORWRITTENPREMIUM).reduce((prev, next) => prev + next));
            setNumPolicies(response.data.map(item => item.CUSTOMER_ID).reduce((item) => {return item+1},0));
            setglPremium(response.data.map(item => item.GL_RATED_PREMIUM).reduce((prev, next) => prev + next));
            setcpPremium(response.data.map(item => item.CP_RATED_PREMIUM).reduce((prev, next) => prev + next));
            setLoading(false);
        })
        .catch((error) => {
            console.log(error);
        })

        axios.get(config.server_url + '/cdv/cp')
        .then(response => {
            const newdata = response.data.map((d,index)=> {d.id = index; return d})
            setDataCpRows(newdata);
            setCpLoading(false);
        })
        .catch((error) => {
            console.log(error);
        })

        axios.get(config.server_url + '/cdv/gl')
        .then(response => {
            const newdata = response.data.map((d,index)=> {d.id = index; return d})
            setDataGlRows(newdata);
            setGlLoading(false);
        })
        .catch((error) => {
            console.log(error);
        })
    }, [shouldRefresh]);

    const onChangeCustomerData=(e) => {
        e.preventDefault();
        const newData = e.target.value;
        console.log(e.target.value);
        setCustomer(newData);
    }

    const onChangeDate1Data=(e) => {
        e.preventDefault();
        const newData = e.target.value;
        console.log(e.target.value);
        setDate1(newData);
    }

    const onChangeDate2Data=(e) => {
        e.preventDefault();
        const newData = e.target.value;
        console.log(e.target.value);
        setDate2(newData);
    }

    const onChangeChecked=(e) => {
        e.preventDefault();
        const newData = e.target.checked;
        console.log(newData);
        setChecked(newData);
    }

    const onChangeFilter=(e) => {
        e.preventDefault();
        const newData = e.target.value;
        console.log(newData);
        setCarrierFilter(newData);
    }

    const onChangeCarrier=(e) => {
        e.preventDefault();
        const newData = e.target.value;
        console.log(newData);
        setCarrierName(newData);
    }
    
    const renderAllRows1 = async () => {
        console.log("All rows Exec")
        try {
            setIsPlFilter(true)
            setIsCpFilter(true)
            setIsGlFilter(true)
            var result = []
            const response = await axios.get(config.server_url + '/cdv/allPl/'+customer)
            const newdata = response.data.map((d,index)=> {d.id = index; return d})
            console.log(newdata)
            setDataAllRows(newdata);
            setCustomerPrev(customer);
            const filRows = newdata.filter(row => row.POLICY_EXPIRATION_DATE >= date1 && row.POLICY_EXPIRATION_DATE <= date2 )
            
            if (carrierFilter==='Equals' && carrierName==='ALL'){
                  result = filRows
              }
            else if (carrierFilter==='Equals' && carrierName !='ALL'){
                   result = filRows.filter(row => row.POLICY_CARRIER===carrierName)
              }
            else if (carrierFilter==='Does Not Equal' && carrierName !='ALL'){
                   result = filRows.filter(row => row.POLICY_CARRIER!=carrierName)   
              }  
            else if (carrierFilter==='Does Not Equal' && carrierName ==='ALL') {
                  alert("This filter returns no data!");
                  return;
              }
            setFilterRows(result)
            if (Array.isArray(result) && result.length) {
              setTotalPremium(result.map(item => item.WRITTENPREMIUM).reduce((prev, next) => prev + next));
              setExpPremium(result.map(item => item.PRIORWRITTENPREMIUM).reduce((prev, next) => prev + next));
              setNumPolicies(result.map(item => item.CUSTOMER_ID).reduce((item) => {return item+1},0));
              setglPremium(result.map(item => item.GL_RATED_PREMIUM).reduce((prev, next) => prev + next));
              setcpPremium(result.map(item => item.CP_RATED_PREMIUM).reduce((prev, next) => prev + next));
              // console.log(result)
              } else {
                  setTotalPremium(0);
                  setExpPremium(0);
                  setNumPolicies(0);
                  setglPremium(0);
                  setcpPremium(0);
                  console.log(result)
              }
              setSubmit(true);
              setIsPlFilter(false)
        }
         catch (error) {
            console.log(error);
            return;
         }
    }
    const renderAllRows2 = async () => {
        try {
            var result = []
            const response = await axios.get(config.server_url + '/cdv/allCp/'+customer)
            const newdata = response.data.map((d,index)=> {d.id = index; return d})
            setDataAllCpRows(newdata);
            const filRows = newdata.filter(row => row.POLICY_EXPIRATION_DATE >= date1 && row.POLICY_EXPIRATION_DATE <= date2 )
            
            if (carrierFilter==='Equals' && carrierName==='ALL'){
                  result = filRows
              }
            else if (carrierFilter==='Equals' && carrierName !='ALL'){
                   result = filRows.filter(row => row.POLICY_CARRIER===carrierName)
              }
            else if (carrierFilter==='Does Not Equal' && carrierName !='ALL'){
                   result = filRows.filter(row => row.POLICY_CARRIER!=carrierName)   
              }  
            else if (carrierFilter==='Does Not Equal' && carrierName ==='ALL') {
                  alert("This filter returns no data!");
                  return;
              }
            setFilterCpRows(result)
            setSubmit(true);
            setIsCpFilter(false)
        }
         catch (error) {
            console.log(error);
            return;
         }
    }
    const renderAllRows3 = async () => {
        try {
            var result = []
            const response = await axios.get(config.server_url + '/cdv/allGl/'+customer)
            const newdata = response.data.map((d,index)=> {d.id = index; return d})
            setDataAllGlRows(newdata);
            const filRows = newdata.filter(row => row.POLICY_EXPIRATION_DATE >= date1 && row.POLICY_EXPIRATION_DATE <= date2 )
            
            if (carrierFilter==='Equals' && carrierName==='ALL'){
                  result = filRows
              }
            else if (carrierFilter==='Equals' && carrierName !='ALL'){
                   result = filRows.filter(row => row.POLICY_CARRIER===carrierName)
              }
            else if (carrierFilter==='Does Not Equal' && carrierName !='ALL'){
                   result = filRows.filter(row => row.POLICY_CARRIER!=carrierName)   
              }  
            else if (carrierFilter==='Does Not Equal' && carrierName ==='ALL') {
                  alert("This filter returns no data!");
                  return;
              }
            setFilterGlRows(result)
            setSubmit(true);
            setIsGlFilter(false)
        }
         catch (error) {
            console.log(error);
            return;
         }
    }

    const onSubmit = async (e) => {
        e.preventDefault();
        console.log(carrierName)
        console.log(carrierFilter)
        var result = []
        var resultCp = []
        var resultGl = []
        console.log(date1)
        console.log(date2)
        const filRows = dataRows.filter(row => row.POLICY_EXPIRATION_DATE >= date1 && row.POLICY_EXPIRATION_DATE <= date2 )
        const filCpRows = dataCpRows.filter(row => row.POLICY_EXPIRATION_DATE >= date1 && row.POLICY_EXPIRATION_DATE <= date2 )
        const filGlRows = dataGlRows.filter(row => row.POLICY_EXPIRATION_DATE >= date1 && row.POLICY_EXPIRATION_DATE <= date2 )
        console.log(filRows)
        console.log(customer)
        if (isChecked) {
            
                if (customer==='ALL'){
                    if (carrierFilter==='Equals' && carrierName ==='ALL'){
                        console.log("Im here equals")
                        result = filRows
                        resultCp = filCpRows
                        resultGl = filGlRows
                    }
                    else if (carrierFilter==='Equals' && carrierName !='ALL') {
                        result = filRows.filter(row => row.POLICY_CARRIER===carrierName)
                        resultCp = filCpRows.filter(row => row.POLICY_CARRIER===carrierName)
                        resultGl = filGlRows.filter(row => row.POLICY_CARRIER===carrierName)
                    }
                    else if (carrierFilter==='Does Not Equal' && carrierName !='ALL'){
                        result = filRows.filter(row => row.POLICY_CARRIER!=carrierName)
                        resultCp = filCpRows.filter(row => row.POLICY_CARRIER!=carrierName)
                        resultGl = filGlRows.filter(row => row.POLICY_CARRIER!=carrierName)
                    }
                    else if (carrierFilter==='Does Not Equal' && carrierName ==='ALL') {
                        alert("This filter returns no data!");
                        return;
                    }
                    setFilterRows(result) 
                    setFilterCpRows(resultCp)
                    setFilterGlRows(resultGl)
                    if (Array.isArray(result) && result.length) {
                    setTotalPremium(result.map(item => item.WRITTENPREMIUM).reduce((prev, next) => prev + next));
                    setExpPremium(result.map(item => item.PRIORWRITTENPREMIUM).reduce((prev, next) => prev + next));
                    setNumPolicies(result.map(item => item.CUSTOMER_ID).reduce((item) => {return item+1},0));
                    setglPremium(result.map(item => item.GL_RATED_PREMIUM).reduce((prev, next) => prev + next));
                    setcpPremium(result.map(item => item.CP_RATED_PREMIUM).reduce((prev, next) => prev + next));
                    // console.log(result)
                    } else {
                        setTotalPremium(0);
                        setExpPremium(0);
                        setNumPolicies(0);
                        setglPremium(0);
                        setcpPremium(0);
                        console.log(result)
                    }
                    setSubmit(true);
                }
                else {
                    if (carrierFilter==='Equals' && carrierName ==='ALL'){
                    result = filRows.filter(row => row.CUSTOMER_ID===customer)
                    resultCp = filCpRows.filter(row => row.CUSTOMER_ID===customer)
                    resultGl = filGlRows.filter(row => row.CUSTOMER_ID===customer)
                }
                    else if (carrierFilter==='Equals' && carrierName !='ALL'){
                        result = filRows.filter(row => row.CUSTOMER_ID===customer && row.POLICY_CARRIER===carrierName)
                        resultCp = filCpRows.filter(row => row.CUSTOMER_ID===customer && row.POLICY_CARRIER===carrierName)
                        resultGl = filGlRows.filter(row => row.CUSTOMER_ID===customer && row.POLICY_CARRIER===carrierName)
                    }
                    else if (carrierFilter==='Does Not Equal' && carrierName !='ALL'){
                        result = filRows.filter(row => row.CUSTOMER_ID===customer && row.POLICY_CARRIER!=carrierName)
                        resultCp = filCpRows.filter(row => row.CUSTOMER_ID===customer && row.POLICY_CARRIER!=carrierName)
                        resultGl = filGlRows.filter(row => row.CUSTOMER_ID===customer && row.POLICY_CARRIER!=carrierName)
                    }
                    else if (carrierFilter==='Does Not Equal' && carrierName ==='ALL') {
                        alert("This filter returns no data!");
                        return;
                    }
                    setFilterRows(result) 
                    setFilterCpRows(resultCp)
                    setFilterGlRows(resultGl)
                    if (Array.isArray(result) && result.length) {
                    setTotalPremium(result.map(item => item.WRITTENPREMIUM).reduce((prev, next) => prev + next));
                    setExpPremium(result.map(item => item.PRIORWRITTENPREMIUM).reduce((prev, next) => prev + next));
                    setNumPolicies(result.map(item => item.CUSTOMER_ID).reduce((item) => {return item+1},0));
                    setglPremium(result.map(item => item.GL_RATED_PREMIUM).reduce((prev, next) => prev + next));
                    setcpPremium(result.map(item => item.CP_RATED_PREMIUM).reduce((prev, next) => prev + next));
                    // console.log(result)
                    } else {
                        setTotalPremium(0);
                        setExpPremium(0);
                        setNumPolicies(0);
                        setglPremium(0);
                        setcpPremium(0);
                        console.log(result)
                    }
                    setSubmit(true);
                }
            }
        else { 
            if (customer==='ALL'){
                if (carrierFilter==='Equals' && carrierName ==='ALL'){
                    alert("This filter is not allowed!");
                        return;
                    }
                else if (carrierFilter==='Equals' && carrierName !='ALL'){
                        alert("This filter is not allowed!");
                            return;
                    }
                else if (carrierFilter==='Does Not Equal' && carrierName !='ALL'){
                    alert("This filter is not allowed!");
                        return;
                    }
                else if (carrierFilter==='Does Not Equal' && carrierName ==='ALL') {
                    alert("This filter returns no data!");
                    return;
                    }
                }
                //ifCustomerIdUnChanged
              else if (customerPrev===customer)
                {
                const filRows = dataAllRows.filter(row => row.POLICY_EXPIRATION_DATE >= date1 && row.POLICY_EXPIRATION_DATE <= date2 )
                const filCpRows = dataAllCpRows.filter(row => row.POLICY_EXPIRATION_DATE >= date1 && row.POLICY_EXPIRATION_DATE <= date2 )
                const filGlRows = dataAllGlRows.filter(row => row.POLICY_EXPIRATION_DATE >= date1 && row.POLICY_EXPIRATION_DATE <= date2 )

                if (carrierFilter==='Equals' && carrierName==='ALL'){
                        result = filRows
                        resultCp = filCpRows
                        resultGl = filGlRows
                }
                else if (carrierFilter==='Equals' && carrierName !='ALL'){
                    result = filRows.filter(row => row.POLICY_CARRIER===carrierName)
                    resultCp = filCpRows.filter(row => row.POLICY_CARRIER===carrierName)
                    resultGl = filGlRows.filter(row => row.POLICY_CARRIER===carrierName)
                }
                else if (carrierFilter==='Does Not Equal' && carrierName !='ALL'){
                    result = filRows.filter(row => row.POLICY_CARRIER!=carrierName)
                    resultCp = filCpRows.filter(row => row.POLICY_CARRIER!=carrierName)
                    resultGl = filGlRows.filter(row => row.POLICY_CARRIER!=carrierName)
                }
                
                else if (carrierFilter==='Does Not Equal' && carrierName ==='ALL') {
                    alert("This filter returns no data!");
                    return;
                }
                
                setFilterRows(result) 
                setFilterCpRows(resultCp)
                setFilterGlRows(resultGl)
                if (Array.isArray(result) && result.length) {
                setTotalPremium(result.map(item => item.WRITTENPREMIUM).reduce((prev, next) => prev + next));
                setExpPremium(result.map(item => item.PRIORWRITTENPREMIUM).reduce((prev, next) => prev + next));
                setNumPolicies(result.map(item => item.CUSTOMER_ID).reduce((item) => {return item+1},0));
                setglPremium(result.map(item => item.GL_RATED_PREMIUM).reduce((prev, next) => prev + next));
                setcpPremium(result.map(item => item.CP_RATED_PREMIUM).reduce((prev, next) => prev + next));
                // console.log(result)
                } else {
                    setTotalPremium(0);
                    setExpPremium(0);
                    setNumPolicies(0);
                    setglPremium(0);
                    setcpPremium(0);
                    console.log(result)
                }
                setSubmit(true);
            }

            else {
                const newAllRows = await renderAllRows1();
                
                const newAllCpRows = await renderAllRows2();
                
                const newAllGlRows = await renderAllRows3();
          
            }
        }
        
    }
    const seen = new Set();

    function CustomToolbar() {
      return (
        <GridToolbarContainer className={classes.box}>
          <GridToolbarExport />
        </GridToolbarContainer>
      );
    }

    const renderLoadingMessage = () => <div className={classes.cent}><CircularProgress color="primary" /></div>;

    const renderPlGrid = () => (
        <div style={{ height: 650, width: '100%' }}>
            <DataGrid headerHeight={70} density={'compact'} className={classes.root} 
            rows={dataRows} columns={columns} pageSize={50} getRowId={(row)=> row.id }   
            components={{
              Toolbar: CustomToolbar,
            }} />
        </div>
        );
    const renderFilterPlGrid = () => (
        <div style={{ height: 650, width: '100%' }}>
            {console.log('Time6')}
            <DataGrid headerHeight={70} density={'compact'} className={classes.root} 
            rows={filterRows} columns={columns} pageSize={50} getRowId={(row)=> row.id } 
            components={{
              Toolbar: CustomToolbar,
            }}  />
        </div>
        );

    const renderCpGrid = () => (
        <div style={{  height: 650, width: '100%' }}>
            <DataGrid headerHeight={70} density={'compact'} className={classes.root} 
            rows={dataCpRows} columns={columnscp} pageSize={50} getRowId={(row)=> row.id } 
            components={{
              Toolbar: CustomToolbar,
            }}  />
        </div>
        );
    const renderFilterCpGrid = () => (
        <div style={{  height: 650,  width: '100%' }}>
            <DataGrid headerHeight={70} density={'compact'} className={classes.root} 
            rows={filterCpRows} columns={columnscp} pageSize={50}  getRowId={(row)=> row.id } 
            components={{
              Toolbar: CustomToolbar,
            }} />
        </div>
        );

    const renderGlGrid = () => (
        <div  style={{ height: 650, width: '100%' }}>
            <DataGrid headerHeight={70} density={'compact'} className={classes.root} 
            rows={dataGlRows} columns={columnsgl} pageSize={50} getRowId={(row)=> row.id } 
            components={{
              Toolbar: CustomToolbar,
            }}  />
        </div>
        );
    const renderFilterGlGrid = () => (
        <div  style={{ height:650, width: '100%' }}>
            <DataGrid headerHeight={70} density={'compact'} className={classes.root} 
            rows={filterGlRows} columns={columnsgl} pageSize={50} getRowId={(row)=> row.id } 
            components={{
              Toolbar: CustomToolbar,
            }}  />
        </div>
        );

    const renderfilter = () => (
    <div>
        <div style={{ width: '100%' }}>
            <form onSubmit={(event) => onSubmit(event)}>
                <Box className={classes.bor} display="flex" justifyContent= "space-around" alignItems="center" alignContent="center" flexWrap="wrap" flexDirection="row" p={0.3} m={1} bgcolor="background.paper">
                    <Box p={1} >
                        <FormControl size={'small'} variant='outlined'>
                        <InputLabel >Customer ID</InputLabel>
                        <Select
                        native
                        value={customer}
                        onChange={(e) => onChangeCustomerData(e)}
                        ><option value="ALL">ALL </option>
                            {dataRows.map(policy => {
                                return <option value={policy.CUSTOMER_ID}>{policy.CUSTOMER_ID}</option>
                            })}
                        </Select>
                        </FormControl>
                    </Box>
                    <Box p={1}  >

                        <TextField label={'Expiration Date'} size={'small'} type="date" value={date1} variant="outlined" onChange={(e) => onChangeDate1Data(e)}/>
                    </Box>
                    <Box p={1} >
                         To
                    </Box>
                    <Box p={1} >
                        <div > 
                        <TextField label={'Expiration Date'} size={'small'} type="date" value={date2} variant="outlined" onChange={(e) => onChangeDate2Data(e)}/>
                        </div> 
                    </Box>
                    <Box p={1} justifyContent={'space-between'}>
                    
                    
                    <FormControl label={'Expiration Date'} size ={'small'} variant='outlined' style={{marginRight: '5px'}}>
                    <InputLabel >Carrier:</InputLabel>
                        <Select
                        native
                        value={carrierFilter}
                        onChange={(e) => onChangeFilter(e)}
                        >
                        <option value={'Equals'}>Equals</option>
                        <option value={'Does Not Equal'}>Does Not Equal</option>
                        </Select>
                    </FormControl>

                    <FormControl size ={'small'} variant='outlined'>
                    
                        <Select
                        native
                        value={carrierName}
                        onChange={(e) => onChangeCarrier(e)}
                        >
                        <option value={'ALL'}>ALL</option>
                        <option value={'Arden'}>Arden</option>
                        <option value={'CAU'}>CAU</option>
                        <option value={'Deans & Homer'}>Deans & Homer</option>
                        <option value={'Great American'}>Great American</option>
                        <option value={'Other'}>Other</option>
                        <option value={'Philly'}>Philly</option>
                        <option value={'UCA'}>UCA</option>
                        </Select>
                    </FormControl>
                    </Box>
                    <Box p={1} >
                        <FormControlLabel 
                            control={
                            <Checkbox 
                                checked={isChecked}
                                onChange= {(e) => onChangeChecked(e)}
                                color="primary"
                            />
                            }
                            label="Show Most Recent"
                        />
                    </Box>
                    <Box p={1} >
                        <div>
                            <input className={classes.button} type="submit" value="Apply Filter" />
                        </div>
                    </Box>
                </Box>
            </form>
                <Box className={classes.bor} display="flex" justifyContent= "space-around" flexDirection="row" p={2} m={1} bgcolor="background.paper">
                    <Box p={1} >
                        Total Premium: ${totalPremium.toLocaleString('en-US', {maximumFractionDigits:1})}
                    </Box>
                    <Box p={1} >
                        Expiring Premium: ${expPremium.toLocaleString('en-US', {maximumFractionDigits:1})}
                    </Box>
                    <Box p={1} >
                        Change $: {(totalPremium - expPremium).toLocaleString('en-US', {maximumFractionDigits:1})}
                    </Box>
                    <Box p={1} >
                        % Change: {(((totalPremium - expPremium)/expPremium)*100).toLocaleString('en-US', {maximumFractionDigits:1})}%
                    </Box>
                    <Box p={1} >
                        GL Premium: ${glPremium.toLocaleString('en-US', {maximumFractionDigits:1})}
                    </Box>
                    <Box p={1} >
                        Property Premium: ${cpPremium.toLocaleString('en-US', {maximumFractionDigits:1})}
                    </Box>
                    <Box p={1} >
                        # of Rated Policies: {numPolicies}
                    </Box>
                    {/* {console.log('values change')} */}
                </Box>
        </div>
            <div >
            <ThemeProvider theme={theme}>
            <div className={classes.root1}>
        <div className={classes.coldiv}>
      <AppBar className={classes.colm} position="static">
        <Tabs centered value={value} onChange={handleChange} aria-label="simple tabs example">
          <Tab className={classes.size} label="Policy Rating" {...a11yProps(0)} />
          <Tab className={classes.size} label="Property Rating" {...a11yProps(1)} />
          <Tab className={classes.size} label="GL Rating" {...a11yProps(2)} />
        </Tabs>
      </AppBar>
      </div>
      <TabPanel value={value} index={0}>
      <div>
      {isPlFilter? renderLoadingMessage(): isSubmit? renderFilterPlGrid() : renderPlGrid()}
        </div>
      </TabPanel>
      <TabPanel value={value} index={1}>
        <div>
        {loadingCp? renderLoadingMessage(): isCpFilter? renderLoadingMessage(): isSubmit? renderFilterCpGrid():renderCpGrid()}
        </div>
      </TabPanel>
      <TabPanel value={value} index={2}>
        <div>
        {loadingGl? renderLoadingMessage(): isGlFilter? renderLoadingMessage(): isSubmit? renderFilterGlGrid():renderGlGrid()}
        </div>
      </TabPanel>
    </div></ThemeProvider>
        </div>      
    </div>
        );

    return (<>
        {
            loading ? renderLoadingMessage() : renderfilter()
        }
    </>);
}


