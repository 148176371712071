import React from 'react';
import TextField from '@material-ui/core/TextField'
import Grid from '@material-ui/core/Grid';
import { makeStyles, ThemeProvider, createMuiTheme } from '@material-ui/core/styles';
import clsx from 'clsx';
import Select from '@material-ui/core/Select';
import FormControl from '@material-ui/core/FormControl';

const useStyles = makeStyles((theme) => ({
    
    col:{
        color:'black',
    },
    title:{
        fontFamily:'Barlow, sans-serif',
        fontSize:'1.5rem',
        textAlign:'center',
        paddingTop:'0px',
        paddingBottom:'0px',
        marginTop: '10px',
        marginBottom: '10px',
  },

  titleb:{
    fontFamily:'Barlow, sans-serif',
    fontSize:'1.5rem',
    textAlign:'center',
    paddingTop:'0px',
    paddingBottom:'0px',
    marginTop: '30px',
    marginBottom: '0px',
},
  formField:{
    display:'flex',
    justifyContent:'space-between',
    fontWeight:'bold',
    fontSize: '1.1rem',
},
button: {
    backgroundColor: 'black',
    fontFamily:'Barlow, sans-serif',
    border: 'none',
    color: 'white',
    padding: '12px 15px',
    textAlign: 'center',
    textDecoration: 'none',
    display: 'inline-block',
    fontSize: '16px',
    margin: '4px 2px',
    cursor: 'pointer',
    borderRadius: '8px',
    outline: 0,
    fontWeight:'bold',
    '&:hover': {
        color: "#4fc3f7",
        boxShadow: '4px 4px 4px grey',
     },
  },
  feature: {
    display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        marginTop:'45px'
  },
  element:{
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'left',
    padding: '4px 4px 4px 4px',
  },
  fk:{
    display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  marginTop:'10px',
  borderStyle:'solid',
  outlineStyle:'solid',
  borderColor:'#4fc3f7',
  padding:'15px',
  boxShadow: '6px 6px 5px grey',
  outlineWidth:'3px',
  
  
},

gk:{
    display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  marginTop:'10px',
  padding:'15px',
  
},
cln:{
    width:'30%',
    borderStyle:'solid',
  outlineStyle:'solid',
  borderColor:'#4fc3f7',
  marginTop:'20px',
},
gln:{
    height:'685px',
  overflowY: 'auto',
},
sd:{
    display: 'flex',
    flexDirection:'row',
  justifyContent: 'space-around',
  alignItems: 'flex-start'
  
},
  }));

const BdLevel = (props) => {
    const { bnData,policy, onSubmit,onSubmitBn, onChangeFormData,onChangeFormBnData} = props;
    const classes = useStyles();
    const numberRange = (start, end) => {
        return new Array(end - start).fill().map((d, i) => i + start);
      }
    const years = numberRange(1900, 2022)
    var num1 = bnData[0].BLANKET_NUMBER_1
    var num2 = bnData[0].BLANKET_NUMBER_2
    console.log(num1)

    return (
        <div>
            
            <form id = "BN" onSubmit={(event) => onSubmitBn(event,props.index)}>
            <h3 className={classes.titleb}>Blanket Information</h3>
                <div className={classes.fk}>
                <Grid container  direction='row' spacing={2}>

                <Grid item xs={3}>
                <div className={classes.formField}> 
                    <label>Customer ID: </label>
                    <TextField required="true" label="Enter Customer ID" size ={'small'} value={bnData[0].CUSTOMER_ID} variant="outlined" onChange={(e) => onChangeFormBnData(props.index,e, 'CUSTOMER_ID')}/>
                </div> </Grid>

                <Grid item xs={3}>
                <div className={classes.formField}>
                <label>Blanket Number 1: </label>
                    <FormControl  size ={'small'} variant='outlined'>
                    
                        <Select
                        native
                        value={bnData[0].BLANKET_NUMBER_1}
                        onChange={(e) => onChangeFormBnData(props.index,e, 'BLANKET_NUMBER_1')}
                        >
                        <option value={''}></option>
                        <option value={1}>1</option>
                        </Select>
                    </FormControl>
                </div></Grid>
                
                {num1 == 1? <Grid item xs={3}>
                <div className={classes.formField}>
                <label>Property Blanket Type 1: </label>
                    <FormControl required = "true"  size ={'small'} variant='outlined'>
                    
                        <Select
                        native
                        value={bnData[0].PROPERTY_BLANKET_TYPE_1}
                        onChange={(e) => onChangeFormBnData(props.index,e, 'PROPERTY_BLANKET_TYPE_1')}
                        >
                        <option value={''}></option>
                        <option value={'Building'}>Building</option>
                        <option value={'Business Personal Property'}>Business Personal Property</option>
                        </Select>
                    </FormControl>
                </div></Grid>:null}

                

                {num1 == 1? <Grid item xs={3}>
                <div className={classes.formField}> 
                    <label>Property Blanket Description 1: </label>
                    <TextField  size ={'small'} value={bnData[0].PROPERTY_BLANKET_DESCRIPTION_1} variant="outlined" onChange={(e) => onChangeFormBnData(props.index,e, 'PROPERTY_BLANKET_DESCRIPTION_1')}/>
                </div> </Grid>:null}

                {num1 == 1? <Grid item xs={3}>
                <div className={classes.formField}> 
                    <label>Blanket Property Coinsurance 1: </label>
                    <TextField required = "true"  type = 'number' size ={'small'} value={bnData[0].BLANKET_PROPERTY_COINSURANCE_1} variant="outlined" onChange={(e) => onChangeFormBnData(props.index,e, 'BLANKET_PROPERTY_COINSURANCE_1')}/>
                </div> </Grid>:null}

                {num1 == 1? <Grid item xs={3}>
                <div className={classes.formField}> 
                    <label>Property Blanket Limit 1: </label>
                    <TextField required = "true"  type = 'number' size ={'small'} value={bnData[0].PROPERTY_BLANKET_LIMIT_1} variant="outlined" onChange={(e) => onChangeFormBnData(props.index,e, 'PROPERTY_BLANKET_LIMIT_1')}/>
                </div> </Grid>:null}

                <Grid item xs={3}>
                <div className={classes.formField}>
                <label>Blanket Number 2: </label>
                    <FormControl  size ={'small'} variant='outlined'>
                    
                        <Select
                        native
                        value={bnData[0].BLANKET_NUMBER_2}
                        onChange={(e) => onChangeFormBnData(props.index,e, 'BLANKET_NUMBER_2')}
                        >
                        <option value={''}></option>
                        <option value={2}>2</option>
                        </Select>
                    </FormControl>
                </div></Grid>

                {num2 == 2? <Grid item xs={3}>
                <div className={classes.formField}>
                <label>Property Blanket Type 2: </label>
                    <FormControl required = "true" size ={'small'} variant='outlined'>
                    
                        <Select
                        native
                        value={bnData[0].PROPERTY_BLANKET_TYPE_2}
                        onChange={(e) => onChangeFormBnData(props.index,e, 'PROPERTY_BLANKET_TYPE_2')}
                        >
                        <option value={''}></option>
                        <option value={'Building'}>Building</option>
                        <option value={'Business Personal Property'}>Business Personal Property</option>
                        </Select>
                    </FormControl>
                </div></Grid>:null}

                {num2 == 2? <Grid item xs={3}>
                <div className={classes.formField}> 
                    <label>Property Blanket Description 2: </label>
                    <TextField  size ={'small'} value={bnData[0].PROPERTY_BLANKET_DESCRIPTION_2} variant="outlined" onChange={(e) => onChangeFormBnData(props.index,e, 'PROPERTY_BLANKET_DESCRIPTION_2')}/>
                </div> </Grid>:null}

                

                {num2 == 2? <Grid item xs={3}>
                <div className={classes.formField}> 
                    <label>Blanket Property Coinsurance 2: </label>
                    <TextField required = "true" type = 'number' size ={'small'} value={bnData[0].BLANKET_PROPERTY_COINSURANCE_2} variant="outlined" onChange={(e) => onChangeFormBnData(props.index,e, 'BLANKET_PROPERTY_COINSURANCE_2')}/>
                </div> </Grid>:null}

                {num2 == 2? <Grid item xs={3}>
                <div className={classes.formField}> 
                    <label>Property Blanket Limit 2: </label>
                    <TextField required = "true" type = 'number' size ={'small'} value={bnData[0].PROPERTY_BLANKET_LIMIT_2} variant="outlined" onChange={(e) => onChangeFormBnData(props.index,e, 'PROPERTY_BLANKET_LIMIT_2')}/>
                </div> </Grid>:null}


                </Grid>
                </div>
                </form>
                <div className={classes.feature }>
                    <input form="BN" type="submit" value="Save Blanket" className={classes.button} />
                </div>


                <form id = "BD" onSubmit={(event) => onSubmit(event,props.index)}>
                <div className={classes.sd}>
                
                <div className={classes.cln}>
                <h3 className={classes.title}>Building Level Information</h3>
                <div className={classes.gln}>
            <div className={classes.gk}>
            <Grid container  direction='column' spacing={2}>

                <Grid item xs={11}>
                <div className={classes.formField}> 
                    <label>Building Name: </label>
                    <TextField size ={'small'} value={policy.BUILDING_NAME} variant="outlined" onChange={(e) => onChangeFormData(props.index,e, 'BUILDING_NAME')}/>
                </div> </Grid>

                <Grid item xs={11}>
                <div className={classes.formField}> 
                    <label>Building Number: </label>
                    <TextField required="true" type = 'number' size ={'small'} value={policy.BUILDING_NUMBER} variant="outlined" onChange={(e) => onChangeFormData(props.index,e, 'BUILDING_NUMBER')}/>
                </div> </Grid>

                <Grid item xs={11}>
                <div className={classes.formField}> 
                    <label>Location Number: </label>
                    <TextField required="true" type = 'number' size ={'small'} value={policy.LOCATION_NUMBER} variant="outlined" onChange={(e) => onChangeFormData(props.index,e, 'LOCATION_NUMBER')}/>
                </div> </Grid>

                <Grid item xs={11}>
                <div className={classes.formField}> 
                <label>Class Code: </label>
                    <FormControl required="true" size ={'small'} variant="outlined">
                        
                        <Select size ={'small'}
                        native
                        value={policy.CLASS_CODE}
                        onChange={(e) => onChangeFormData(props.index,e, 'CLASS_CODE')}
                        >
                        <option value={''}></option>
                        <option value={'68500'}>68500</option>
                        <option value={'62003'}>62003</option>
                        </Select>
                </FormControl>
                </div>
                </Grid>

                <Grid item xs={11}>
                <div className={classes.formField}> 
                <label>Classification: </label>
                    <FormControl size ={'small'} variant="outlined">
                        
                        <Select size ={'small'}
                        native
                        value={policy.CLASSIFICATION}
                        onChange={(e) => onChangeFormData(props.index,e, 'CLASSIFICATION')}
                        >
                        <option value={''}></option>
                        <option value={'Condominiums-Residential - (Association Risk Only)'}>Condominiums-Residential - (Association Risk Only)</option>
                        <option value={'Townhouse or Homeowner Associations (association risk only)'}>Townhouse or Homeowner Associations (association risk only)</option>
                        
                        </Select>
                </FormControl>
                </div>
                </Grid>

                

                <Grid item xs={11}>
                <div className={classes.formField}> 
                    <label>Total Building Area: </label>
                    <TextField type = 'number' size ={'small'} value={policy.TOTAL_BUILDING_AREA} variant="outlined" onChange={(e) => onChangeFormData(props.index,e, 'TOTAL_BUILDING_AREA')}/>
                </div> </Grid>

                <Grid item xs={11}>
                <div className={classes.formField}> 
                <label>Blanket Num 1 Indicator: </label>
                    <FormControl  size ={'small'} variant="outlined">
                        
                        <Select size ={'small'}
                        native
                        value={policy.BLANKET_1_IND}
                        onChange={(e) => onChangeFormData(props.index,e, 'BLANKET_1_IND')}
                        >
                        <option value={''}></option>
                        <option value={'Yes'}>Yes</option>
                        <option value={'No'}>No</option>
                        </Select>
                </FormControl>
                </div>
                </Grid>

                <Grid item xs={11}>
                <div className={classes.formField}> 
                <label>Blanket Num 2 Indicator: </label>
                    <FormControl size ={'small'} variant="outlined">
                        
                        <Select size ={'small'}
                        native
                        value={policy.BLANKET_2_IND}
                        onChange={(e) => onChangeFormData(props.index,e, 'BLANKET_2_IND')}
                        >
                        <option value={''}></option>
                        <option value={'Yes'}>Yes</option>
                        <option value={'No'}>No</option>
                        </Select>
                </FormControl>
                </div>
                </Grid>

                <Grid item xs={11}>
                <div className={classes.formField}> 
                    <label>Building Amount of Insurance: </label>
                    <TextField type = 'number' size ={'small'} value={policy.BUILDING_AMOUNT_OF_INSURANCE} variant="outlined" onChange={(e) => onChangeFormData(props.index,e, 'BUILDING_AMOUNT_OF_INSURANCE')}/>
                </div> </Grid>


                <Grid item xs={11}>
                <div className={classes.formField}> 
                    <label>Number of Units: </label>
                    <TextField type = 'number' size ={'small'} value={policy.NUMBER_OF_UNITS} variant="outlined" onChange={(e) => onChangeFormData(props.index,e, 'NUMBER_OF_UNITS')}/>
                </div> </Grid>


                </Grid>
              
                </div>
                </div>
                </div>

                <div className={classes.cln}>
            <h3 className={classes.title}>General Building Information</h3>
            <div className={classes.gln}>
                <div className={classes.gk}>
                <Grid container  direction='column' spacing={2}>

                <Grid item xs={11}>
                <div className={classes.formField}> 
                    <label>Customer ID: </label>
                    <TextField required="true" label="Enter Customer ID" size ={'small'} value={policy.CUSTOMER_ID} variant="outlined" onChange={(e) => onChangeFormData(props.index,e, 'CUSTOMER_ID')}/>
                </div> </Grid>

                <Grid item xs={11}>
                <div className={classes.formField}>
                <label>Construction Type: </label>
                <FormControl size ={'small'} variant="outlined">
                        
                        <Select
                        native
                        value={policy.CONSTRUCTION_TYPE}
                        onChange={(e) => onChangeFormData(props.index,e, 'CONSTRUCTION_TYPE')}
                        >
                        <option value={''}></option>
                        <option value={'Frame'}>Frame</option>
                        <option value={'Fire Resistive'}>Fire Resistive</option>
                        <option value={'Modified Fire Resistive'}>Modified Fire Resistive</option>
                        <option value={'Joisted Masonry'}>Joisted Masonry</option>
                        <option value={'Masonry Non-Combustible'}>Masonry Non-Combustible</option>
                        <option value={'Non-Combustible'}>Non-Combustible</option>
                        </Select>
                </FormControl>
                </div>
                </Grid>

                <Grid item xs={11}>
                <div className={classes.formField}>
                <label>Year Built: </label>
                <FormControl size ={'small'} variant="outlined">
                        
                        <Select
                        native
                        value={policy.YEAR_BUILT}
                        onChange={(e) => onChangeFormData(props.index,e, 'YEAR_BUILT')}
                        >
                        <option value={''}></option>
                        {years.map(policy => {
                                return <option value={policy}>{policy}</option>
                            })}
                        </Select>
                </FormControl>
                </div>
                </Grid>

                <Grid item xs={11}>
                <div className={classes.formField}> 
                    <label>Number of Stories: </label>
                    <TextField type = 'number' size ={'small'} value={policy.NUM_STORIES} variant="outlined" onChange={(e) => onChangeFormData(props.index,e, 'NUM_STORIES')}/>
                </div> </Grid>

                <Grid item xs={11}>
                <div className={classes.formField}>
                <label>Roof Type: </label>
                    <FormControl size ={'small'} variant='outlined'>
                    
                        <Select
                        native
                        value={policy.ROOF_TYPE}
                        onChange={(e) => onChangeFormData(props.index,e, 'ROOF_TYPE')}
                        >
                        <option value={''}></option>
                        <option value={'Concrete or clay tiles'}>Concrete or clay tiles</option>
                        <option value={'Normal shingle (55 mph)'}>Normal shingle (55 mph)</option>
                        <option value={'Metal Sheathing '}>Metal Sheathing </option>
                        <option value={'Shingle rated for high wind speeds (110 mph)'}>Shingle rated for high wind speeds (110 mph)</option>
                        <option value={'Wood shake '}>Wood shake </option>
                        </Select>
                    </FormControl>
                </div></Grid>

                <Grid item xs={11}>
                <div className={classes.formField}> 
                    <label>Building Deductible: </label>
                    <TextField type = 'number' size ={'small'} value={policy.BUILDING_DEDUCTIBLE} variant="outlined" onChange={(e) => onChangeFormData(props.index,e, 'BUILDING_DEDUCTIBLE')}/>
                </div> </Grid>

                <Grid item xs={11}>
                <div className={classes.formField}>
                <label>Automatic Sprinkler: </label>
                    <FormControl size ={'small'} variant='outlined'>
                    
                        <Select
                        native
                        value={policy.AUTOMATIC_SPRINKLER}
                        onChange={(e) => onChangeFormData(props.index,e, 'AUTOMATIC_SPRINKLER')}
                        >
                        <option value={''}></option>
                        <option value={'Yes'}>Yes</option>
                        <option value={'No'}>No</option>
                        </Select>
                    </FormControl>
                </div></Grid>

                <Grid item xs={11}>
                <div className={classes.formField}>
                <label>Watchmen Security Service: </label>
                    <FormControl size ={'small'} variant='outlined'>
                    
                        <Select
                        native
                        value={policy.WATCHMEN_SECURITY_SERVICE}
                        onChange={(e) => onChangeFormData(props.index,e, 'WATCHMEN_SECURITY_SERVICE')}
                        >
                        <option value={''}></option>
                        <option value={'Yes'}>Yes</option>
                        <option value={'No'}>No</option>
                        </Select>
                    </FormControl>
                </div></Grid>

                <Grid item xs={11}>
                <div className={classes.formField}>
                <label>Armed Security Guards: </label>
                    <FormControl size ={'small'} variant='outlined'>
                    
                        <Select
                        native
                        value={policy.ARMED_SECURITY_GUARDS}
                        onChange={(e) => onChangeFormData(props.index,e, 'ARMED_SECURITY_GUARDS')}
                        >
                        <option value={''}></option>
                        <option value={'Yes'}>Yes</option>
                        <option value={'No'}>No</option>
                        </Select>
                    </FormControl>
                </div></Grid>

                <Grid item xs={11}>
                <div className={classes.formField}> 
                    <label>Rentals: </label>
                    <TextField size ={'small'} value={policy.RENTALS} variant="outlined" onChange={(e) => onChangeFormData(props.index,e, 'RENTALS')}/>
                </div> </Grid>

                <Grid item xs={11}>
                <div className={classes.formField}>
                <label>Rental Period: </label>
                    <FormControl size ={'small'} variant='outlined'>
                    
                        <Select
                        native
                        value={policy.RENTAL_PERIOD}
                        onChange={(e) => onChangeFormData(props.index,e, 'RENTAL_PERIOD')}
                        >
                        <option value={''}></option>
                        <option value={'No'}>No</option>
                        <option value={'110 Days / 1 Month '}>110 Days / 1 Month </option>
                        <option value={'6 Months'}>6 Months</option>
                        <option value={'Annual'}>Annual</option>
                        </Select>
                    </FormControl>
                </div></Grid>
                    
                <Grid item xs={11}>
                <div className={classes.formField}>
                <label>Contractor GL Required: </label>
                    <FormControl size ={'small'} variant='outlined'>
                    
                        <Select
                        native
                        value={policy.CONTRACTOR_GL_REQUIRED}
                        onChange={(e) => onChangeFormData(props.index,e, 'CONTRACTOR_GL_REQUIRED')}
                        >
                        <option value={''}></option>
                        <option value={'Yes'}>Yes</option>
                        <option value={'No'}>No</option>
                        </Select>
                    </FormControl>
                </div></Grid>

                <Grid item xs={11}>
                <div className={classes.formField}> 
                    <label>Building Coinsurance Percentage: </label>
                    <TextField type = 'number' size ={'small'} value={policy.BUILDING_COINSURANCE_PERCENTAGE} variant="outlined" onChange={(e) => onChangeFormData(props.index,e, 'BUILDING_COINSURANCE_PERCENTAGE')}/>
                </div> </Grid>

                
                
                <Grid item xs={11}>
                <div className={classes.formField}>
                <label>Building Valuation Type: </label>
                    <FormControl size ={'small'} variant='outlined'>
                    
                        <Select
                        native
                        value={policy.BUILDING_VALUATION_TYPE}
                        onChange={(e) => onChangeFormData(props.index,e, 'BUILDING_VALUATION_TYPE')}
                        >
                        <option value={''}></option>
                        <option value={'Extended Replacement Cost'}>Extended Replacement Cost</option>
                        <option value={'Guaranteed Replacement Cost'}>Guaranteed Replacement Cost</option>
                        </Select>
                    </FormControl>
                </div></Grid>

                <Grid item xs={11}>
                <div className={classes.formField}>
                <label>Electrical Last Updated: </label>
                <FormControl size ={'small'} variant="outlined">
                        
                        <Select
                        native
                        value={policy.ELECTRICAL_LAST_UPDATED}
                        onChange={(e) => onChangeFormData(props.index,e, 'ELECTRICAL_LAST_UPDATED')}
                        >
                        <option value={''}></option>
                        {years.map(policy => {
                                return <option value={policy}>{policy}</option>
                            })}
                        </Select>
                </FormControl>
                </div>
                </Grid>

                

                <Grid item xs={11}>
                <div className={classes.formField}>
                <label>Plumbing Last Updated: </label>
                <FormControl size ={'small'} variant="outlined">
                        
                        <Select
                        native
                        value={policy.PLUMBING_LAST_UPDATED}
                        onChange={(e) => onChangeFormData(props.index,e, 'PLUMBING_LAST_UPDATED')}
                        >
                        <option value={''}></option>
                        {years.map(policy => {
                                return <option value={policy}>{policy}</option>
                            })}
                        </Select>
                </FormControl>
                </div>
                </Grid>

                <Grid item xs={11}>
                <div className={classes.formField}>
                <label>Roof Last Updated: </label>
                <FormControl size ={'small'} variant="outlined">
                        
                        <Select
                        native
                        value={policy.ROOF_LAST_UPDATED}
                        onChange={(e) => onChangeFormData(props.index,e, 'ROOF_LAST_UPDATED')}
                        >
                        <option value={''}></option>
                        {years.map(policy => {
                                return <option value={policy}>{policy}</option>
                            })}
                        </Select>
                </FormControl>
                </div>
                </Grid>

                <Grid item xs={11}>
                <div className={classes.formField}>
                <label>Aluminum Wiring: </label>
                    <FormControl size ={'small'} variant='outlined'>
                    
                        <Select
                        native
                        value={policy.ALUMINUM_WIRING}
                        onChange={(e) => onChangeFormData(props.index,e, 'ALUMINUM_WIRING')}
                        >
                        <option value={''}></option>
                        <option value={'Yes'}>Yes</option>
                        <option value={'No'}>No</option>
                        </Select>
                    </FormControl>
                </div></Grid>

                <Grid item xs={11}>
                <div className={classes.formField}>
                <label>Polybutylene Piping: </label>
                    <FormControl size ={'small'} variant='outlined'>
                    
                        <Select
                        native
                        value={policy.POLYBUTYLENE_PIPING}
                        onChange={(e) => onChangeFormData(props.index,e, 'POLYBUTYLENE_PIPING')}
                        >
                        <option value={''}></option>
                        <option value={'Yes'}>Yes</option>
                        <option value={'No'}>No</option>
                        </Select>
                    </FormControl>
                </div></Grid>

                <Grid item xs={11}>
                <div className={classes.formField}>
                <label>Unoccupied Unit Heat: </label>
                    <FormControl size ={'small'} variant='outlined'>
                    
                        <Select
                        native
                        value={policy.UNOCCUPIED_UNIT_HEAT}
                        onChange={(e) => onChangeFormData(props.index,e, 'UNOCCUPIED_UNIT_HEAT')}
                        >
                        <option value={''}></option>
                        <option value={'Yes'}>Yes</option>
                        <option value={'No'}>No</option>
                        </Select>
                    </FormControl>
                </div></Grid>

                <Grid item xs={11}>
                <div className={classes.formField}>
                <label>HO 6 Policy: </label>
                    <FormControl size ={'small'} variant='outlined'>
                    
                        <Select
                        native
                        value={policy.HO_6_POLICY}
                        onChange={(e) => onChangeFormData(props.index,e, 'HO_6_POLICY')}
                        >
                        <option value={''}></option>
                        <option value={'Yes'}>Yes</option>
                        <option value={'No'}>No</option>
                        </Select>
                    </FormControl>
                </div></Grid>

                <Grid item xs={11}>
                <div className={classes.formField}> 
                    <label>Ice Damming Deductible: </label>
                    <TextField type = 'number' size ={'small'} value={policy.ICE_DAMMING_DEDUCTIBLE} variant="outlined" onChange={(e) => onChangeFormData(props.index,e, 'ICE_DAMMING_DEDUCTIBLE')}/>
                </div> </Grid>
 
                </Grid>
                </div>
                </div>
                </div>

                </div>

                
            </form>
            
            <div className={classes.feature }>
                    <input form="BD" type="submit" value="Save Building" className={classes.button} />
                </div>
        </div>
    )
}

export default BdLevel;