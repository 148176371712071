import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import axios from 'axios';
import { makeStyles, ThemeProvider, createMuiTheme } from '@material-ui/core/styles';
import {orange} from '@material-ui/core/colors'
import GenericDataGrid from './GenericDataGrid';
import AppBar from './AppBar';
import config from "./../config"
import EditIcon from '@material-ui/icons/Edit';
const useStyles = makeStyles((theme) => ({
    
  col:{
      color:'black',
  },
  title:{
    fontFamily:'Barlow, sans-serif',
    fontSize:'1.6rem',
    textAlign:'center',
    marginTop:'10px',
    marginBottom:'45px'
},
root: {
  marginTop:'40px',
  '& .super--cell': {
    backgroundColor: '#4fc3f7',
    color: 'black',
    fontWeight: '600',
    textAlign:'center'
    
  },
  '& .super--cell2': {
    color: 'black',
    textAlign:'center',

    
  },
  '& .super--cell3': {
    color: 'black',
    fontWeight: '600',
    textAlign:'center',
    
  },
  '& .super--header': {
    display: 'flex',
    justifyContent:'center',
    alignItems:'center',
    alignContent:'center',
    fontWeight:'2rem'
  },
}
}));

const HomePage = ({data}) => {
  const classes = useStyles();
  const [policies, setPolicies] = useState([]);
  console.log(data)

  
    const currencyFormatter = new Intl.NumberFormat('en-US', {
      style: 'currency',
      currency: 'USD',
      minimumFractionDigits:0,
    });
    
    const usdPrice = {
      type: 'number',
      width: 130,
      valueFormatter: ({ value }) => currencyFormatter.format(Number(value)),
      cellClassName: 'super--cell',
    };
    const usdPrice2 = {
      type: 'number',
      width: 180,
      valueFormatter: ({ value }) => currencyFormatter.format(Number(value)),
      cellClassName: 'super--cell3',
    };

  const renderPolicyList = () => (
    
    <ThemeProvider >
    <div className={classes.root}>
    <GenericDataGrid 
      dataRows={data}
      ID = {'POLICY_HEADER_KEY'}
      ht={800}
      columns={[
        {
          field: 'action',
          headerName: 'Action',
          description: 'This column has a value getter and is not sortable.',
          sortable: false,
          width: 100,
          renderCell: (params) => <Link to={`/tab/${params.row.CUSTOMER_ID}`}><EditIcon className={classes.col}/></Link>,
          cellClassName: 'super--cell2',headerAlign: 'center',
        },
        { field: 'POLICY_HEADER_KEY', headerName: 'ID', width: 70,cellClassName: 'super--cell2',headerAlign: 'center'},
        { field: 'CUSTOMER_ID', headerName: 'Customer ID', width: 130,cellClassName: 'super--cell2',headerAlign: 'center' },
        { field: 'STATE', headerName: 'State', width: 130,headerAlign: 'center',cellClassName: 'super--cell2' },
        { field: 'EFF_DATE', headerName: 'Eff Date', width: 130 ,cellClassName: 'super--cell2',headerAlign: 'center',type:'date'},
        { field: 'EXP_DATE', headerName: 'Exp Date', width: 130,cellClassName: 'super--cell2',headerAlign: 'center',type:'date' },
        { field: 'TIV', headerName: 'TIV', ...usdPrice },
        { field: 'TOTAL_BUILDING_AMT_OF_INSURANCE', headerName: 'Total Building Amount of Insurance',cellClassName: 'super--cell2',headerAlign: 'center' ,...usdPrice2},
        { field: 'BPP_LIMIT', headerName: 'BPP Limit', width: 130 ,...usdPrice},
        { field: 'BI_LIMIT', headerName: 'BI Limit', width: 130,cellClassName: 'super--cell2',headerAlign: 'center',...usdPrice2 },
        { field: 'EXPIRING_PREMIUM', headerName: 'Expiring Premium', width: 130 ,cellClassName: 'super--cell2',headerAlign: 'center',...usdPrice2},
        { field: 'COMPANY_AUTO_HIRED', headerName: 'Company Auto Hired', width: 130 ,cellClassName: 'super--cell2',headerAlign: 'center',type:'number'},
        { field: 'COMPANY_GL_PREMIER_ENDORSEMENT', headerName: 'Company GL Premier Endorsement', width: 200,cellClassName: 'super--cell2',headerAlign: 'center',type:'number'},
        { field: 'COMPANY_LIMITATION_COVERAGE', headerName: 'Company Limitation Coverage', width: 180,cellClassName: 'super--cell2',headerAlign: 'center',type:'number' },
        { field: 'COMPANY_TERRORISM', headerName: 'Company Terrorism', width: 130,cellClassName: 'super--cell2',headerAlign: 'center',type:'number' },
        { field: 'COMPANY_DESIGNATED_LOCATION', headerName: 'Company Designated Location', width: 180 ,cellClassName: 'super--cell2',headerAlign: 'center'},
        { field: 'COMPANY_EMPLOYEE_BENEFITS_LIABILITY', headerName: 'Company Employee Benefits Liability', width: 180 ,cellClassName: 'super--cell2',headerAlign: 'center'},
        { field: 'COMPANY_NUMBER_ADDITIONAL_INSURED', headerName: 'Company Number Additional ', width: 180 ,cellClassName: 'super--cell2',headerAlign: 'center'},
        { field: 'CARRIER', headerName: 'Carrier', width: 130 ,cellClassName: 'super--cell2',headerAlign: 'center'},
        { field: 'BATCH', headerName: 'Batch', width: 130,cellClassName: 'super--cell2',headerAlign: 'center' },
        { field: 'STREET_ADDR', headerName: 'Street Address', width: 180,cellClassName: 'super--cell2',headerAlign: 'center' },
        { field: 'CITY', headerName: 'City', width: 130,cellClassName: 'super--cell2',headerAlign: 'center' },
        { field: 'ZIP', headerName: 'ZIP', width: 130 ,cellClassName: 'super--cell2',headerAlign: 'center'},
        { field: 'COUNTY', headerName: 'County', width: 130 ,cellClassName: 'super--cell2',headerAlign: 'center'},
        { field: 'PROGRAM', headerName: 'Program', width: 130 ,cellClassName: 'super--cell2',headerAlign: 'center'},
        { field: 'POLICY_NUMBER', headerName: 'Policy Number', width: 130,cellClassName: 'super--cell2',headerAlign: 'center'},
        { field: 'PROPERTY_TYPE', headerName: 'Property Type', width: 150 ,cellClassName: 'super--cell2',headerAlign: 'center'},
        { field: 'COMPANY_PROP_PREMIER_ENDORSEMENT', headerName: 'Company Prop Premier Endorsement', width: 190,cellClassName: 'super--cell2',headerAlign: 'center',type:'number' },
        { field: 'COMPANY_MAINTENANCEFEES', headerName: 'Company Maintenance Fees', width: 180 ,cellClassName: 'super--cell2',headerAlign: 'center',type:'number'},
        { field: 'COMPANY_WATERDAMAGE', headerName: 'Company Water Damage', width: 150 ,cellClassName: 'super--cell2',headerAlign: 'center',type:'number'},
        { field: 'COMPANY_ICEDAMMING', headerName: 'Company Icedamming', width: 130 ,cellClassName: 'super--cell2',headerAlign: 'center',type:'number'},
        { field: 'COMPANY_EQUIPMENT_BREAKDOWN', headerName: 'Company Equipment Breakdown', width: 190 ,cellClassName: 'super--cell2',headerAlign: 'center'},
        { field: 'COMPANY_NONSPRINKLERED', headerName: 'Company Non-Sprinklered', width: 150 ,cellClassName: 'super--cell2',headerAlign: 'center',type:'number'},
        { field: 'EACH_OCCURRENCE_LIMIT_CD', headerName: 'Each Occurence Limit CD', width: 180 ,cellClassName: 'super--cell2',headerAlign: 'center'},
        { field: 'GENERAL_AGGREGATE_LIMIT_CD', headerName: 'General Aggregate Limit CD', width: 180,cellClassName: 'super--cell2',headerAlign: 'center' },
        { field: 'PRODS_COMPLD_OPS_AGGREGATE_LIMIT_CD', headerName: 'Prods Compld Ops Aggregate Limit CD', width: 180,cellClassName: 'super--cell2',headerAlign: 'center' },
        { field: 'COMPANY_ASSOCIATION_BYLAWS', headerName: 'Company Association ByLaws', width: 180 ,cellClassName: 'super--cell2',headerAlign: 'center'},
        { field: 'IRPMBUILDINGFEATURES', headerName: 'IRPM Building Features', width: 150 ,cellClassName: 'super--cell2',headerAlign: 'center',type:'number'},
        { field: 'IRPMEMPLOYEES', headerName: 'IRPM Employees', width: 130 ,cellClassName: 'super--cell2',headerAlign: 'center',type:'number'},
        { field: 'IRPMLOCATION', headerName: 'IRPM Location', width: 130 ,cellClassName: 'super--cell2',headerAlign: 'center',type:'number'},
        { field: 'IRPMMANAGEMENT', headerName: 'IRPM Management', width: 130 ,cellClassName: 'super--cell2',headerAlign: 'center',type:'number'},
        { field: 'IRPMPREMISESANDEQUIPMENT', headerName: 'IRPM Premises and Equipment', width: 150 ,cellClassName: 'super--cell2',headerAlign: 'center',type:'number'},
        { field: 'IRPMPROTECTION', headerName: 'IRPM Protection', width: 130 ,cellClassName: 'super--cell2',headerAlign: 'center',type:'number'},
        { field: 'SCHEDULERATINGLOCATION', headerName: 'Schedule Rating Location', width: 150 ,cellClassName: 'super--cell2',headerAlign: 'center',type:'number'},
        { field: 'SCHEDULERATINGCOOPERATION', headerName: 'Schedule Rating Cooperation', width: 160 ,cellClassName: 'super--cell2',headerAlign: 'center',type:'number'},
        { field: 'SCHEDULERATINGCLASSIFICATION', headerName: 'Schedule Rating Classification', width: 160 ,cellClassName: 'super--cell2',headerAlign: 'center',type:'number'},
        { field: 'SCHEDULERATINGEMPLOYEES', headerName: 'Schedule Rating Employees', width: 160 ,cellClassName: 'super--cell2',headerAlign: 'center',type:'number'},
        { field: 'SCHEDULERATINGEQUIPMENT', headerName: 'Schedule Rating Equipment', width: 160 ,cellClassName: 'super--cell2',headerAlign: 'center',type:'number'},
        { field: 'SCHEDULERATINGPREMISES', headerName: 'Schedule Rating Premises', width: 160 ,cellClassName: 'super--cell2',headerAlign: 'center',type:'number'},
    ]}
    pageSize={100}
    />
  </div>
  </ThemeProvider>
  );


    return renderPolicyList();
}

export default HomePage;