import React from 'react';
import TextField from '@material-ui/core/TextField'
import Grid from '@material-ui/core/Grid';
import { makeStyles, ThemeProvider, createMuiTheme } from '@material-ui/core/styles';
import clsx from 'clsx';
import Select from '@material-ui/core/Select';
import FormControl from '@material-ui/core/FormControl';

const useStyles = makeStyles((theme) => ({
    
    col:{
        color:'black',
    },
    title:{
        fontFamily:'Barlow, sans-serif',
        fontSize:'1.5rem',
        textAlign:'center',
        paddingTop:'0px',
        paddingBottom:'0px',
        marginTop: '30px',
        marginBottom: '0px',
  },
  formField:{
    display:'flex',
    justifyContent:'space-between',
    fontWeight:'bold',
    fontSize: '1.1rem',
},
button: {
    backgroundColor: 'black',
    fontFamily:'Barlow, sans-serif',
    border: 'none',
    color: 'white',
    padding: '12px 15px',
    textAlign: 'center',
    textDecoration: 'none',
    display: 'inline-block',
    fontSize: '16px',
    margin: '4px 2px',
    cursor: 'pointer',
    borderRadius: '8px',
    outline: 0,
    fontWeight:'bold',
    '&:hover': {
        color: "#4fc3f7",
        boxShadow: '4px 4px 4px grey',
     },
  },
  feature: {
    display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        marginTop:'45px'
  },
  element:{
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'left',
    padding: '4px 4px 4px 4px',
  },
  fk:{
    display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  marginTop:'10px',
  borderStyle:'solid',
  outlineStyle:'solid',
  borderColor:'#4fc3f7',
  padding:'15px',
  boxShadow: '6px 6px 5px grey',
  
}
  }));

const HdLevel = (props) => {
    const { geo2,geo,policy, onSubmit, onChangeFormData,onChangeFormGeoData,onGeoSubmit,onSubmitAdd} = props;
    const classes = useStyles();

    return (
        <div>
            
            <form id = "HD" onSubmit={(event) => onSubmit(event,props.index)}>
            <h3 className={classes.title}>Policy Level Validation</h3>
            <div className={classes.fk}>
            <Grid container  direction='row' spacing={2}>
            <Grid item xs={3}>
                <div className={classes.formField}> 
                    <label>Customer ID: </label>
                    <TextField required = "true" label="Enter Customer ID" size ={'small'} value={policy.CUSTOMER_ID} variant="outlined" onChange={(e) => onChangeFormData(props.index,e, 'CUSTOMER_ID')}/>
                </div>
                </Grid>

                <Grid item xs={3}>
                <div className={classes.formField}> 
                    <label>TAM Customer Full Name: </label>
                    <TextField required = "true" size ={'small'} value={policy.TAM_CUSTOMER_FULL_NAME} variant="outlined" onChange={(e) => onChangeFormData(props.index,e, 'TAM_CUSTOMER_FULL_NAME')}/>
                </div>
                </Grid>

                <Grid item xs={3}>
                <div className={classes.formField}>
                <label>Carrier: </label>
                    <FormControl required = "true" required = "true" size ={'small'} variant='outlined'>
                    
                        <Select
                        native
                        value={props.policy.CARRIER}
                        onChange={(e) => props.onChangeFormData(props.index,e, 'CARRIER')}
                        >
                        <option value={''}></option>
                        <option value={'Arden'}>Arden</option>
                        <option value={'CAU'}>CAU</option>
                        <option value={'Deans & Homer'}>Deans & Homer</option>
                        <option value={'Great American'}>Great American</option>
                        <option value={'Other'}>Other</option>
                        <option value={'Philly'}>Philly</option>
                        <option value={'UCA'}>UCA</option>
                        </Select>
                    </FormControl>
                </div></Grid>

                <Grid item xs={3}>
                <div className={classes.formField}>
                <label>Effective Date: </label>
                    <TextField required = "true" size ={'small'} type = "date" value={policy.EFF_DATE} variant="outlined" onChange={(e) => onChangeFormData(props.index,e, 'EFF_DATE')}/>
                </div></Grid>
                <Grid item xs={3}>
                <div className={classes.formField}>
                <label>Expiry Date: </label>
                    <TextField required = "true" size ={'small'} type = "date" value={policy.EXP_DATE} variant="outlined" onChange={(e) => onChangeFormData(props.index,e, 'EXP_DATE')}/>
                </div></Grid>

                {/* <Grid item xs={3}>
                <div className={classes.formField}>
                <label>Policy Type:</label>
                <FormControl size ={'small'} variant="outlined">
                
                    <Select
                    native
                    value={policy.POLICY_TYPE}
                    onChange={(e) => onChangeFormData(props.index,e, 'POLICY_TYPE')}
                    >
                    <option value={''}></option>
                    <option value={'PCKG'}>PCKG</option>
                    <option value={'GL'}>GL</option>
                    </Select>
                </FormControl>
                </div></Grid> */}

                <Grid item xs={3}>
                <div className={classes.formField}> 
                    <label>Policy Number: </label>
                    <TextField required = "true" size ={'small'} value={policy.POLICY_NUMBER} variant="outlined" onChange={(e) => onChangeFormData(props.index,e, 'POLICY_NUMBER')}/>
                </div>
                </Grid>

                {/* <Grid item xs={3}>
                <div className={classes.formField}>
                <label>Property Type:</label>
                    <FormControl size ={'small'} variant='outlined'>
                    
                        <Select
                        native
                        value={policy.PROPERTY_TYPE}
                        onChange={(e) => onChangeFormData(props.index,e, 'PROPERTY_TYPE')}
                        >
                        <option value={''}></option>
                        <option value={'Condo'}>Condo</option>
                        <option value={'HOA'}>HOA</option>
                        </Select>
                    </FormControl>
                </div></Grid> */}

                <Grid item xs={3}>
                    <div className={classes.formField}> 
                        <label>Comments: </label>
                        <TextField  size ={'small'}  value={policy.COMMENTS} variant="outlined" onChange={(e) => onChangeFormData(props.index,e, 'COMMENTS')}/>
                    </div></Grid>

                

                </Grid>
                </div>

                <h3 className={classes.title}>Premiums</h3>
                <div className={classes.fk}>
                <Grid container  direction='row' spacing={2}>
                <Grid item xs={4}>
                <div className={classes.formField}> 
                    <label>Expiring Premium: </label>
                    <TextField required = "true" size ={'small'} type="number" value={policy.EXPIRING_PREMIUM} variant="outlined" onChange={(e) => onChangeFormData(props.index,e, 'EXPIRING_PREMIUM')}/>
                </div></Grid>

                <Grid item xs={4}>
                <div className={classes.formField}> 
                    <label>Expiring GL Premium: </label>
                    <TextField size ={'small'} type="number" value={policy.EXPIRING_GL_PREMIUM} variant="outlined" onChange={(e) => onChangeFormData(props.index,e, 'EXPIRING_GL_PREMIUM')}/>
                </div></Grid>

                <Grid item xs={4}>
                <div className={classes.formField}> 
                    <label>Expiring Prop Premium: </label>
                    <TextField size ={'small'} type="number" value={policy.EXPIRING_PROP_PREMIUM} variant="outlined" onChange={(e) => onChangeFormData(props.index,e, 'EXPIRING_PROP_PREMIUM')}/>
                </div></Grid>
                </Grid>
                </div>
                </form>

                <h3 className={classes.title}>Address</h3>
                <form id = "AD" onSubmit={(event) => onSubmitAdd(event,props.index)}>
                <div className={classes.fk}>
                <Grid container  direction='row' spacing={2}>
                <Grid item xs={3}>
                <div className={classes.formField}> 
                    <TextField required = "true" label="Enter Customer ID" size ={'small'} value={geo[0].CUSTOMER_ID} variant="outlined" onChange={(e) => onChangeFormGeoData(e, 'CUSTOMER_ID')}/>
                </div>
                </Grid>

                <Grid item xs={3}>
                <div className={classes.formField}> 
                    <TextField required = "true" size ={'small'} label="Enter Location Number" type="number" value={geo[0].LOCATION_NUMBER} variant="outlined" onChange={(e) => onChangeFormGeoData(e, 'LOCATION_NUMBER')}/>
                </div></Grid>

                <Grid item xs={3}>
                <div className={classes.formField}> 
                    <TextField required = "true" size ={'small'} label="Enter Address Line 1"  value={geo[0].StAdd1} variant="outlined" onChange={(e) => onChangeFormGeoData(e, 'StAdd1')}/>
                </div></Grid>

                <Grid item xs={3}>
                <div className={classes.formField}> 
                    <TextField size ={'small'} label="Enter Address Line 2"  value={geo[0].StAdd2} variant="outlined" onChange={(e) => onChangeFormGeoData(e, 'StAdd2')}/>
                </div></Grid>

                <Grid item xs={3}>
                <div className={classes.formField}> 
                    <TextField required = "true" size ={'small'} label="Enter City"  value={geo[0].City} variant="outlined" onChange={(e) => onChangeFormGeoData(e, 'City')}/>
                </div></Grid>

                <Grid item xs={3}>
                <div className={classes.formField}> 
                    <TextField required = "true" size ={'small'} label="Enter State"  value={geo[0].State} variant="outlined" onChange={(e) => onChangeFormGeoData(e, 'State')}/>
                </div></Grid>

                <Grid item xs={3}>
                <div className={classes.formField}> 
                    <TextField required = "true" size ={'small'} label="Enter Zipcode" type="number" value={geo[0].Zipcode} variant="outlined" onChange={(e) => onChangeFormGeoData(e, 'Zipcode')}/>
                </div></Grid>

                <Grid item xs={3}>
                <div className={classes.formField}> 
                    <TextField required = "true" size ={'small'} label="County" value={geo2[0].county} variant="outlined" onChange={(e) => onChangeFormGeoData(e, 'county')}/>
                </div></Grid>

                </Grid>
                </div>
                </form>
                <div className={classes.feature }>
                    <input className={classes.button} type="button" value="Lookup" onClick={(event) =>  onGeoSubmit(event)} />
                    <input form="AD" type="submit" value="Save Address" className={classes.button} />
                </div>

                <h3 className={classes.title}>Eligibility</h3>
                <form id = "HD" onSubmit={(event) => onSubmit(event,props.index)}>
                <div className={classes.fk}>
                <Grid container  direction='row' spacing={2}>
                <Grid item xs={3}>
                <div className={classes.formField}>
                <label>Comm Condos: </label>
                    <FormControl required = "true" required = "true" size ={'small'} variant='outlined'>
                    
                        <Select
                        native
                        value={policy.COMM_CONDOS}
                        onChange={(e) => onChangeFormData(props.index,e, 'COMM_CONDOS')}
                        >
                        <option value={''}></option>
                        <option value={'Yes'}>Yes</option>
                        <option value={'No'}>No</option>
                        </Select>
                    </FormControl>
                </div></Grid>

                <Grid item xs={3}>
                <div className={classes.formField}>
                <label>Condotels: </label>
                    <FormControl required = "true" required = "true" size ={'small'} variant='outlined'>
                    
                        <Select
                        native
                        value={policy.CONDOTELS}
                        onChange={(e) => onChangeFormData(props.index,e, 'CONDOTELS')}
                        >
                        <option value={''}></option>
                        <option value={'Yes'}>Yes</option>
                        <option value={'No'}>No</option>
                        </Select>
                    </FormControl>
                </div></Grid>

                <Grid item xs={3}>
                <div className={classes.formField}>
                <label>Equestrian Exposures: </label>
                    <FormControl required = "true" required = "true" size ={'small'} variant='outlined'>
                    
                        <Select
                        native
                        value={policy.EQUESTRIAN_EXPOSURES}
                        onChange={(e) => onChangeFormData(props.index,e, 'EQUESTRIAN_EXPOSURES')}
                        >
                        <option value={''}></option>
                        <option value={'Yes'}>Yes</option>
                        <option value={'No'}>No</option>
                        </Select>
                    </FormControl>
                </div></Grid>

                <Grid item xs={3}>
                <div className={classes.formField}>
                <label>Hunting Shooting: </label>
                    <FormControl required = "true" required = "true" size ={'small'} variant='outlined'>
                    
                        <Select
                        native
                        value={policy.HUNTING_SHOOTING}
                        onChange={(e) => onChangeFormData(props.index,e, 'HUNTING_SHOOTING')}
                        >
                        <option value={''}></option>
                        <option value={'Yes'}>Yes</option>
                        <option value={'No'}>No</option>
                        </Select>
                    </FormControl>
                </div></Grid>

                <Grid item xs={3}>
                <div className={classes.formField}>
                <label>Ice Fishing: </label>
                    <FormControl required = "true" required = "true" size ={'small'} variant='outlined'>
                    
                        <Select
                        native
                        value={policy.ICE_FISHING}
                        onChange={(e) => onChangeFormData(props.index,e, 'ICE_FISHING')}
                        >
                        <option value={''}></option>
                        <option value={'Yes'}>Yes</option>
                        <option value={'No'}>No</option>
                        </Select>
                    </FormControl>
                </div></Grid>

                <Grid item xs={3}>
                <div className={classes.formField}>
                <label>Ice Skating: </label>
                    <FormControl required = "true" required = "true" size ={'small'} variant='outlined'>
                    
                        <Select
                        native
                        value={policy.ICE_SKATING}
                        onChange={(e) => onChangeFormData(props.index,e, 'ICE_SKATING')}
                        >
                        <option value={''}></option>
                        <option value={'Yes'}>Yes</option>
                        <option value={'No'}>No</option>
                        </Select>
                    </FormControl>
                </div></Grid>

                <Grid item xs={3}>
                <div className={classes.formField}>
                <label>Jet Skiing: </label>
                    <FormControl required = "true" required = "true" size ={'small'} variant='outlined'>
                    
                        <Select
                        native
                        value={policy.JET_SKIING}
                        onChange={(e) => onChangeFormData(props.index,e, 'JET_SKIING')}
                        >
                        <option value={''}></option>
                        <option value={'Yes'}>Yes</option>
                        <option value={'No'}>No</option>
                        </Select>
                    </FormControl>
                </div></Grid>

                <Grid item xs={3}>
                <div className={classes.formField}>
                <label>Lake over 100 Acre: </label>
                    <FormControl required = "true" required = "true" size ={'small'} variant='outlined'>
                    
                        <Select
                        native
                        value={policy.LAKE_OVER_100_ACRE}
                        onChange={(e) => onChangeFormData(props.index,e, 'LAKE_OVER_100_ACRE')}
                        >
                        <option value={''}></option>
                        <option value={'Yes'}>Yes</option>
                        <option value={'No'}>No</option>
                        </Select>
                    </FormControl>
                </div></Grid>

                <Grid item xs={3}>
                <div className={classes.formField}>
                <label>On Prem Golf: </label>
                    <FormControl required = "true" required = "true" size ={'small'} variant='outlined'>
                    
                        <Select
                        native
                        value={policy.ON_PREM_GOLF}
                        onChange={(e) => onChangeFormData(props.index,e, 'ON_PREM_GOLF')}
                        >
                        <option value={''}></option>
                        <option value={'Yes'}>Yes</option>
                        <option value={'No'}>No</option>
                        </Select>
                    </FormControl>
                </div></Grid>

                <Grid item xs={3}>
                <div className={classes.formField}>
                <label>Pools Diving Boards: </label>
                    <FormControl required = "true" required = "true" size ={'small'} variant='outlined'>
                    
                        <Select
                        native
                        value={policy.POOLS_DIVING_BOARDS}
                        onChange={(e) => onChangeFormData(props.index,e, 'POOLS_DIVING_BOARDS')}
                        >
                        <option value={''}></option>
                        <option value={'Yes'}>Yes</option>
                        <option value={'No'}>No</option>
                        </Select>
                    </FormControl>
                </div></Grid>

                <Grid item xs={3}>
                <div className={classes.formField}>
                <label>Pools Slides: </label>
                    <FormControl required = "true" required = "true" size ={'small'} variant='outlined'>
                    
                        <Select
                        native
                        value={policy.POOLS_SLIDES}
                        onChange={(e) => onChangeFormData(props.index,e, 'POOLS_SLIDES')}
                        >
                        <option value={''}></option>
                        <option value={'Yes'}>Yes</option>
                        <option value={'No'}>No</option>
                        </Select>
                    </FormControl>
                </div></Grid>

                <Grid item xs={3}>
                <div className={classes.formField}>
                <label>Snow Skiing: </label>
                    <FormControl required = "true" required = "true" size ={'small'} variant='outlined'>
                    
                        <Select
                        native
                        value={policy.SNOW_SKIING}
                        onChange={(e) => onChangeFormData(props.index,e, 'SNOW_SKIING')}
                        >
                        <option value={''}></option>
                        <option value={'Yes'}>Yes</option>
                        <option value={'No'}>No</option>
                        </Select>
                    </FormControl>
                </div></Grid>

                <Grid item xs={3}>
                <div className={classes.formField}>
                <label>State Parks: </label>
                    <FormControl required = "true" required = "true" size ={'small'} variant='outlined'>
                    
                        <Select
                        native
                        value={policy.STATE_PARKS}
                        onChange={(e) => onChangeFormData(props.index,e, 'STATE_PARKS')}
                        >
                        <option value={''}></option>
                        <option value={'Yes'}>Yes</option>
                        <option value={'No'}>No</option>
                        </Select>
                    </FormControl>
                </div></Grid>

                <Grid item xs={3}>
                <div className={classes.formField}>
                <label>Water Skiing: </label>
                    <FormControl required = "true" required = "true" size ={'small'} variant='outlined'>
                    
                        <Select
                        native
                        value={policy.WATER_SKIING}
                        onChange={(e) => onChangeFormData(props.index,e, 'WATER_SKIING')}
                        >
                        <option value={''}></option>
                        <option value={'Yes'}>Yes</option>
                        <option value={'No'}>No</option>
                        </Select>
                    </FormControl>
                </div></Grid>

                <Grid item xs={3}>
                <div className={classes.formField}>
                <label>Wood Deck Grilling: </label>
                    <FormControl required = "true" required = "true" size ={'small'} variant='outlined'>
                    
                        <Select
                        native
                        value={policy.WOOD_DECK_GRILLING}
                        onChange={(e) => onChangeFormData(props.index,e, 'WOOD_DECK_GRILLING')}
                        >
                        <option value={''}></option>
                        <option value={'Yes'}>Yes</option>
                        <option value={'No'}>No</option>
                        </Select>
                    </FormControl>
                </div></Grid>

                {/* <Grid item xs={3}>
                <div className={classes.formField}> 
                    <label>Misc Outdoor Property Total: </label>
                    <TextField  size ={'small'} type="number" value={policy.MISCELLANEOUS_OUTDOOR_PROPERTY_TOTAL} variant="outlined" onChange={(e) => onChangeFormData(props.index,e, 'MISCELLANEOUS_OUTDOOR_PROPERTY_TOTAL')}/>
                </div></Grid> */}

                

                </Grid>
                </div>
                
            </form>
            
            <div className={classes.feature }>
                    <input form="HD" type="submit" value="Save Policy" className={classes.button} />
                </div>
        </div>
    )
}

export default HdLevel;