import React from 'react';
import TextField from '@material-ui/core/TextField'
import Grid from '@material-ui/core/Grid';
import { makeStyles, ThemeProvider, createMuiTheme } from '@material-ui/core/styles';
import clsx from 'clsx';
import Select from '@material-ui/core/Select';
import FormControl from '@material-ui/core/FormControl';

const useStyles = makeStyles((theme) => ({
    
    col:{
        color:'black',
    },
    title:{
      fontFamily:'Barlow, sans-serif',
  },
  formField:{
    display:'flex',
    justifyContent:'space-between',
    fontWeight:'bold',
    fontSize: '1.1rem',
},
button: {
    backgroundColor: 'black',
    fontFamily:'Barlow, sans-serif',
    border: 'none',
    color: 'white',
    padding: '12px 15px',
    textAlign: 'center',
    textDecoration: 'none',
    display: 'inline-block',
    fontSize: '16px',
    margin: '4px 2px',
    cursor: 'pointer',
    borderRadius: '8px',
    outline: 0,
    fontWeight:'bold',
    '&:hover': {
        color: "#4fc3f7",
        boxShadow: '4px 4px 4px grey',
     },
  },
  feature: {
    display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        marginTop:'45px'
  },
  element:{
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'left',
    padding: '4px 4px 4px 4px',
  },
  fk:{
    display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  borderStyle:'solid',
  outlineStyle:'solid',
  borderColor:'#4fc3f7',
  padding:'15px',
  boxShadow: '6px 6px 5px grey',
}
  }));

const GlLevel = (props) => {
    const { policy, onSubmit, onChangeFormData} = props;
    const classes = useStyles();

    return (
        <div>
            <div className={classes.fk}>
            <form id = "Gl" onSubmit={(event) => onSubmit(event, policy.GL_DETAILS_KEY)}>
            <Grid container  direction='row' spacing={2}>
            <Grid item xs={3}>
                <div className={classes.formField}> 
                    <label>Customer ID: </label>
                    <TextField size ={'small'} value={policy.CUSTOMER_ID} variant="outlined" onChange={(e) => onChangeFormData(e, 'CUSTOMER_ID', policy.GL_DETAILS_KEY)}/>
                </div>
                </Grid>
                <Grid item xs={3}>
                <div className={classes.formField}> 
                    <label>Premises Operations Territory: </label>
                    <TextField size ={'small'} type="number" value={policy.PREMISES_OPERATIONS_TERRITORY} variant="outlined" onChange={(e) => onChangeFormData(e, 'PREMISES_OPERATIONS_TERRITORY', policy.GL_DETAILS_KEY)}/>
                </div></Grid>
                <Grid item xs={3}>
                <div className={classes.formField}> 
                    <label>Prods Compld Ops Territory: </label>
                    <TextField size ={'small'} type="number" value={policy.PRODS_COMPLD_OPS_TERRITORY} variant="outlined" onChange={(e) => onChangeFormData(e, 'PRODS_COMPLD_OPS_TERRITORY', policy.GL_DETAILS_KEY)}/>
                </div></Grid>
                <Grid item xs={3}>
                <div className={classes.formField}> 
                    <label>Iteration Number: </label>
                    <TextField size ={'small'} type="number" value={policy.ITERATION_NUMBER} variant="outlined" onChange={(e) => onChangeFormData(e, 'ITERATION_NUMBER', policy.GL_DETAILS_KEY)}/>
                </div></Grid>
                
                <Grid item xs={3}>
                <div className={classes.formField}> 
                    <label>Prem Ops Exposure: </label>
                    <TextField size ={'small'}  type="number" value={policy.PREM_OPS_EXPOSURE} variant="outlined" onChange={(e) => onChangeFormData(e, 'PREM_OPS_EXPOSURE', policy.GL_DETAILS_KEY)}/>
                </div></Grid>
                <Grid item xs={3}>
                <div className={classes.formField}> 
                    <label>Prods Compld Ops Exposure: </label>
                    <TextField size ={'small'}  type="number" value={policy.PRODS_COMPLD_OPS_EXPOSURE} variant="outlined" onChange={(e) => onChangeFormData(e, 'PRODS_COMPLD_OPS_EXPOSURE', policy.GL_DETAILS_KEY)}/>
                </div></Grid>
                <Grid item xs={3}>
                <div className={classes.formField}>
                <label>Prem Ops Premium Basis: </label>
                    <FormControl size ={'small'} variant="outlined">
                        
                        <Select size ={'small'}
                        native
                        value={policy.PREM_OPS_PREMIUM_BASIS}
                        onChange={(e) => onChangeFormData(e, 'PREM_OPS_PREMIUM_BASIS', policy.GL_DETAILS_KEY)}
                        >
                        <option value={'Area'}>Area</option>
                        <option value={'Sales'}>Sales</option>
                        <option value={'Units'}>Units</option>
                        <option value={'Each Beach'}>Each Beach</option>
                        <option value={'Each Lake or reservoir'}>Each Lake or reservoir</option>
                        <option value={'Each Mile'}>Each Mile</option>
                        <option value={'Each Park or Playground'}>Each Park or Playground</option>
                        <option value={'Each pool'}>Each pool</option>
                        </Select>
                </FormControl>
                </div>
                </Grid>
                <Grid item xs={3}>
                <div className={classes.formField}>
                <label>Prods Compld Ops Premium Basis: </label>
                    <FormControl size ={'small'} variant="outlined">
                        
                        <Select size ={'small'}
                        native
                        value={policy.PRODS_COMPLD_OPS_PREMIUM_BASIS}
                        onChange={(e) => onChangeFormData(e, 'PRODS_COMPLD_OPS_PREMIUM_BASIS', policy.GL_DETAILS_KEY)}
                        >
                        <option value={'Area'}>Area</option>
                        <option value={'Sales'}>Sales</option>
                        <option value={'Units'}>Units</option>
                        <option value={'Each Beach'}>Each Beach</option>
                        <option value={'Each Lake or reservoir'}>Each Lake or reservoir</option>
                        <option value={'Each Mile'}>Each Mile</option>
                        <option value={'Each Park or Playground'}>Each Park or Playground</option>
                        <option value={'Each pool'}>Each pool</option>
                        </Select>
                </FormControl>
                </div>
                </Grid>
                <Grid item xs={3}>
                <div className={classes.formField}>
                <label>Prem Ops BIPD Deductible: </label>
                    <TextField size ={'small'}  value={policy.PREM_OPS_BIPD_DEDUCTIBLE} variant="outlined" onChange={(e) => onChangeFormData(e, 'PREM_OPS_BIPD_DEDUCTIBLE', policy.GL_DETAILS_KEY)}/>
                </div></Grid>
                <Grid item xs={3}>
                <div className={classes.formField}>
                <label>Prods Compld Ops BIPD Deductible: </label>
                    <TextField size ={'small'}  value={policy.PRODS_COMPLD_OPS_BIPD_DEDUCTIBLE} variant="outlined" onChange={(e) => onChangeFormData(e, 'PRODS_COMPLD_OPS_BIPD_DEDUCTIBLE', policy.GL_DETAILS_KEY)}/>
                </div></Grid>
                <Grid item xs={3}>
                <div className={classes.formField}>
                <label>Prods Compld Ops Cov: </label>
                    <TextField size ={'small'} value={policy.PRODS_COMPLD_OPS_COV} variant="outlined" onChange={(e) => onChangeFormData(e, 'PRODS_COMPLD_OPS_COV', policy.GL_DETAILS_KEY)}/>
                </div></Grid>
                <Grid item xs={3}>
                <div className={classes.formField}>
                <label>Other Than Prods Compld Ops Cov: </label>
                    <TextField size ={'small'} value={policy.OTHER_THAN_PRODS_COMPLD_OPS_COV} variant="outlined" onChange={(e) => onChangeFormData(e, 'OTHER_THAN_PRODS_COMPLD_OPS_COV', policy.GL_DETAILS_KEY)}/>
                </div></Grid>
                <Grid item xs={3}>
                <div className={classes.formField}>
                <label>State: </label>
                    <TextField size ={'small'} value={policy.STATE} variant="outlined" onChange={(e) => onChangeFormData(e, 'STATE', policy.GL_DETAILS_KEY)}/>
                </div></Grid>
                <Grid item xs={3}>
                <div className={classes.formField}>
                <label>Class Code: </label>
                    <FormControl size ={'small'} variant="outlined">
                        
                        <Select size ={'small'}
                        native
                        value={policy.CLASS_CODE}
                        onChange={(e) => onChangeFormData(e, 'CLASS_CODE', policy.GL_DETAILS_KEY)}
                        >
                        <option value={10105}>10105</option>
                        <option value={40072}>40072</option>
                        <option value={41668}>41668</option>
                        <option value={45524}>45524</option>
                        <option value={46622}>46622</option>
                        <option value={46671}>46671</option>
                        <option value={48727}>48727</option>
                        <option value={48925}>48925</option>
                        <option value={61218}>61218</option>
                        <option value={61225}>61225</option>
                        <option value={62003}>62003</option>
                        <option value={68500}>68500</option>
                        <option value={68707}>68707</option>
                        </Select>
                </FormControl>
                </div>
                </Grid>
                </Grid>
            </form>
            </div>
            <div className={classes.feature }>
                    <input form="Gl" type="submit" value="Save Policy" className={classes.button} />
                </div>
        </div>
    )
}

export default GlLevel;