import React from 'react';
import TextField from '@material-ui/core/TextField'
import Grid from '@material-ui/core/Grid';
import { makeStyles, ThemeProvider, createMuiTheme } from '@material-ui/core/styles';
import clsx from 'clsx';
import Select from '@material-ui/core/Select';
import FormControl from '@material-ui/core/FormControl';
import Checkbox from '@material-ui/core/Checkbox';
import FormControlLabel from '@material-ui/core/FormControlLabel';

const useStyles = makeStyles((theme) => ({
    
    col:{
        color:'black',
    },
    title:{
      fontFamily:'Barlow, sans-serif',
  },
  formField:{
    display:'flex',
    justifyContent:'space-between',
    fontWeight:'bold',
    fontSize: '1.1rem',
},
button: {
    backgroundColor: 'black',
    fontFamily:'Barlow, sans-serif',
    border: 'none',
    color: 'white',
    padding: '12px 15px',
    textAlign: 'center',
    textDecoration: 'none',
    display: 'inline-block',
    fontSize: '16px',
    margin: '4px 2px',
    cursor: 'pointer',
    borderRadius: '8px',
    outline: 0,
    fontWeight:'bold',
    '&:hover': {
        color: "#4fc3f7",
        boxShadow: '4px 4px 4px grey',
     },
  },
  feature: {
    display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        marginTop:'45px'
  },
  element:{
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'left',
    padding: '4px 4px 4px 4px',
  },
  fk:{
    display: 'flex',
    flexWrap:'wrap',
    flexDirection:'row',
    justifyContent: 'center',
  alignItems: 'center',
  marginTop:'10px',
  borderStyle:'solid',
  outlineStyle:'solid',
  borderColor:'#4fc3f7',
  padding:'15px',
  boxShadow: '6px 6px 5px grey',
},
titleb:{
    fontFamily:'Barlow, sans-serif',
    fontSize:'1.5rem',
    textAlign:'center',
    paddingTop:'0px',
    paddingBottom:'0px',
    marginTop: '30px',
    marginBottom: '0px',
},
  }));

const MOELevel = (props) => {
    const { policy, onSubmit, onChangeFormData} = props;
    const classes = useStyles();

    return (
        <div>

            <h3 className={classes.titleb}> Edit Misc. Outdoor Property (SOV)</h3>
            <div className={classes.fk}>
            <form id = "MOE" onSubmit={(event) => onSubmit(event,props.index)}>
            
            <Grid container  direction='row' spacing={2}>

                <Grid item xs={3}>
                <div className={classes.formField}>
                <label>Property Type: </label>
                    <FormControl size ={'small'} variant='outlined'>
                    
                        <Select
                        native
                        value={policy.MISC_OUTDOOR_PROPERTY_TYPE}
                        onChange={(e) => onChangeFormData(props.index,e, 'MISC_OUTDOOR_PROPERTY_TYPE')}
                        >
                        <option value={''}></option>
                        <option value={'Boat Storage and Moorage'}>Boat Storage and Moorage</option>
                        <option value={'Bridges, roadways, walkways, patios, paved surfaces'}>Bridges, roadways, walkways, patios, paved surfaces</option>
                        <option value={'Bulkheads, docks, piers, retaining walls, wharves'}>Bulkheads, docks, piers, retaining walls, wharves</option>
                        <option value={'Cabana'}>Cabana</option>
                        <option value={'Gate House'}>Gate House</option>
                        <option value={'Irrigation System'}>Irrigation System</option>
                        <option value={'Lights/Poles'}>Lights/Poles</option>
                        <option value={'Mailboxes'}>Mailboxes</option>
                        <option value={'Pool House'}>Pool House</option>
                        <option value={'Signs/Monuments'}>Signs/Monuments</option>
                        <option value={'Tot lot/Playground Equipment'}>Tot lot/Playground Equipment</option>
                        <option value={'BPP'}>BPP</option>
                        <option value={'Other'}>Other</option>
                        </Select>
                    </FormControl>
                </div></Grid>

                <Grid item xs={3}>
                <div className={classes.formField}> 
                    <label>Property Amt of Insurance: </label>
                    <TextField type="number" size ={'small'} value={policy.MISC_OUTDOOR_PROPERTY_AMOUNT_OF_INSURANCE} variant="outlined" onChange={(e) => onChangeFormData(props.index,e, 'MISC_OUTDOOR_PROPERTY_AMOUNT_OF_INSURANCE')}/>
                </div>
                </Grid>

                <Grid item xs={3}>
                <div className={classes.formField}> 
                    <label>Property Description: </label>
                    <TextField  size ={'small'} value={policy.MISC_OUTDOOR_PROPERTY_DESCRIBE} variant="outlined" onChange={(e) => onChangeFormData(props.index,e, 'MISC_OUTDOOR_PROPERTY_DESCRIBE')}/>
                </div>
                </Grid>
        
                </Grid>
            </form>
            </div>
            <div className={classes.feature }>
                    <input form="MOE" type="submit" value="Save Outdoor" className={classes.button} />
                </div>
        </div>
    )
}

export default MOELevel;