import React from 'react';
import PropTypes from 'prop-types';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import { withStyles } from '@material-ui/core/styles';
import { makeStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import { Link, useLocation } from 'react-router-dom';

const lightColor = 'rgba(255, 255, 255, 0.7)';
const useStyles = makeStyles((theme) => ({
    
  header:{
      backgroundColor:'black',
      color:'white',
  },
root: {
  flexGrow: 1,
},
menuButton: {
  marginRight: theme.spacing(2),
},
title: {
  flexGrow: 1,
  color:'white',
  fontFamily:'Barlow, sans-serif',
  textAlign:'center'

},
}));
const styles = (theme) => ({
    header:{
      backgroundColor:'black',
        color:'white',
    },
  secondaryBar: {
    zIndex: 0,
  },
  menuButton: {
    marginLeft: -theme.spacing(1),
  },
  iconButtonAvatar: {
    padding: 4,
  },
  link: {
    textDecoration: 'none',
    color: lightColor,
    '&:hover': {
      color: theme.palette.common.white,
    },
  },
  button: {
    borderColor: lightColor,
  },
});

function Header(props) {
  const { classes, onDrawerToggle } = props;
  const cls = useStyles();

  return (
    <React.Fragment>
      <AppBar className={cls.header}color="primary" position="sticky" elevation={0}> 
        <Toolbar>
        <Typography variant="h6" className={classes.title} align="center">
              <Link to = '/wb' style={{ textDecoration: 'none', color: 'white' }}>
            Workbench</Link>
          </Typography>
          </Toolbar>
        </AppBar>
    </React.Fragment>
  );
}

Header.propTypes = {
  classes: PropTypes.object.isRequired,
  onDrawerToggle: PropTypes.func.isRequired,
};

export default withStyles(styles)(Header);